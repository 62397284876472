import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { MoreSettings } from '../SidekickActions/SidekickActionsTable';

export class AppItem extends Component {
  calculateSidekickActionCount = sidekickAction => {
    const sidekickActionSet = new Set();
    for (const app of this.props.appInstances) {
      for (const sa of app[sidekickAction]) {
        sidekickActionSet.add(sa.id);
      }
    }
    return sidekickActionSet.size;
  };

  render() {
    const app = this.props.appInstances[0];
    let attachDetachClassName = app.config.detached
      ? 'icon-apps-and-tps-enable-tp'
      : 'icon icon-apps-and-tps-disable-tp';
    return (
      <div className={'app-item'}>
        <div className={'app-header'}>
          <Checkbox checked={this.props.checked} onChange={() => this.props.handleAppSelect(app.key)} />
          <div>{app.name}</div>
          <div className={'actions'}>
            <i
              className={`tracepoint-action icon ${attachDetachClassName}`}
              onClick={() => this.props.onAttachDetachClicked(app)}
            />
            <i className={'tracepoint-action icon more-settings'} onClick={() => this.props.onSettingsClicked(app)}>
              <MoreSettings />
            </i>
          </div>
        </div>
        <div className={'app-tags'}>
          {app.stage !== '' && <div className={'app-tag'}>stage: {app.stage}</div>}
          {app.version !== '' && <div className={'app-tag'}>version: {app.version}</div>}
        </div>
        <div className={'app-stats'}>
          <div className={'stat'}>
            <span className={'number'}>{this.props.appInstances.length}</span>
            <span className={'label'}>instances</span>
          </div>
          <div className={'stat'}>
            <span className={'number'}>{this.calculateSidekickActionCount('tracePoints')}</span>
            <span className={'label'}>tracepoints</span>
          </div>
          <div className={'stat'}>
            <span className={'number'}>{this.calculateSidekickActionCount('logPoints')}</span>
            <span className={'label'}>logpoints</span>
          </div>
        </div>
      </div>
    );
  }
}
