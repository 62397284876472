import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { UPSERT_CARD } from 'store/constants/billing-upsert-card';

export const upsertCard = (workspaceId, stripeToken) => {
  return {
    type: UPSERT_CARD,
    payload: axios.post(
      CONFIG.apiUrl + `/customer-info/source?workspaceId=${workspaceId}`,
      { source: stripeToken },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
};
