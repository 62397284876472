import { connect } from 'react-redux';
import {
  cancelSubscription,
  getActiveSubscription,
  getBillingCustomer,
  getInvoices,
  getPlans,
  getWorkspaceBillingAccount,
  inviteBillingAdminToWorkspace,
  saveSubscription,
  setWorkspaceBillingAccount,
  upsertCard,
} from 'store/actions';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { BillingTab } from 'components';

function mapStateToProps(store) {
  return {
    activeWorkspace: store.activeWorkspace,

    upsertCardResult: store.upsertCard,

    billingPlans: store.billingPlans,
    billingInvoices: store.billingInvoices,
    billingCustomer: store.billingCustomer,
    activeSubscription: store.activeSubscription,
    workspaceBillingAccount: store.workspaceBillingAccount,
    setWorkspaceBillingAccountResult: store.setWorkspaceBillingAccount,
    inviteBillingAdminResult: store.inviteBillingAdminToWorkspace,
    saveSubscriptionResult: store.saveSubscription,
    cancelSubscriptionResult: store.cancelSubscription,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBillingCustomer(workspaceId) {
      dispatch(getBillingCustomer(workspaceId));
    },
    getPlans() {
      dispatch(getPlans());
    },
    getActiveSubscription(workspaceId) {
      dispatch(getActiveSubscription(workspaceId));
    },
    getInvoices(workspaceId) {
      dispatch(getInvoices(workspaceId));
    },
    saveSubscription(subscriptionInfo, onSuccess, onError) {
      dispatchWithExceptionHandler(saveSubscription(subscriptionInfo), false, onSuccess, onError);
    },
    cancelSubscription(workspaceId, onSuccess, onError) {
      dispatchWithExceptionHandler(cancelSubscription(workspaceId), false, onSuccess, onError);
    },
    upsertCard(workspaceId, stripeToken, onSuccess, onError) {
      dispatchWithExceptionHandler(upsertCard(workspaceId, stripeToken), false, onSuccess, onError);
    },
    getBillingAccount(workspaceId) {
      dispatch(getWorkspaceBillingAccount(workspaceId));
    },
    setBillingAccount(workspaceId, accountId, onSuccess, onError) {
      dispatchWithExceptionHandler(setWorkspaceBillingAccount(workspaceId, accountId), false, onSuccess, onError);
    },
    inviteBillingAdmin(workspaceId, email, onSuccess, onError) {
      dispatchWithExceptionHandler(inviteBillingAdminToWorkspace(workspaceId, email), false, onSuccess, onError);
    },
  };
}

export const BillingTabContainer = connect(mapStateToProps, mapDispatchToProps)(BillingTab);
