export const BROKER_CONNECT_REQUEST = 'REDUX_WEBSOCKET::WEBSOCKET_CONNECT';
export const BROKER_DISCONNECT_REQUEST = 'REDUX_WEBSOCKET::WEBSOCKET_DISCONNECT';
export const BROKER_SEND_REQUEST = 'REDUX_WEBSOCKET::WEBSOCKET_SEND';

export const BROKER_OPEN = 'REDUX_WEBSOCKET::OPEN';
export const BROKER_CLOSED = 'REDUX_WEBSOCKET::CLOSED';
export const BROKER_MESSAGE = 'REDUX_WEBSOCKET::MESSAGE';
export const BROKER_BROKEN = 'REDUX_WEBSOCKET::BROKEN';
export const BROKER_BEGIN_RECONNECT = 'REDUX_WEBSOCKET::BEGIN_RECONNECT';
export const BROKER_RECONNECT_ATTEMPT = 'REDUX_WEBSOCKET::RECONNECT_ATTEMPT';
export const BROKER_RECONNECTED = 'REDUX_WEBSOCKET::RECONNECTED';
export const BROKER_ERROR = 'REDUX_WEBSOCKET::ERROR';

export const TP_EVENTS_CLEAR = 'TP_EVENTS_CLEAR';
export const PD_TP_EVENTS_CLEAR = 'PD_TP_EVENTS_CLEAR';
export const LP_EVENTS_CLEAR = 'LP_EVENTS_CLEAR';
export const PD_LP_EVENTS_CLEAR = 'PD_LP_EVENTS_CLEAR';
export const ERROR_SNAPSHOTS_CLEAR = 'ERROR_SNAPSHOTS_CLEAR';

export const TP_ADD_WAITING = 'TP_ADD_WAITING';
export const LP_ADD_WAITING = 'LP_ADD_WAITING';

export const SA_CHECK_WAITING = 'SA_CHECK_WAITING';

export const FLUSH_REFERENCE_EVENT = 'FLUSH_REFERENCE_EVENT';
export const SET_EVENT_BEING_PROCESSED = 'SET_EVENT_BEING_PROCESSED';
