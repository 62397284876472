import { getSourceId, getSourceIndex, getSourceName } from 'utils/source-files';
import { hasArrayElement } from 'utils/array-util';
import { generatePathPrefixFromContextMetaData } from 'utils/string-util';

const IDEModel = {
  global: {
    splitterSize: 4,
    tabEnableDrag: false,
    borderBarSize: 20,
    tabEnableRename: false,
  },
  borders: [
    {
      type: 'border',
      location: 'bottom',
      id: 'ThundraEventTabSet',
      children: [
        {
          type: 'tab',
          id: 'TracePointEventsContainer',
          name: 'TracePoint Events',
          component: 'TracePointEventsContainer',
          enableClose: false,
        },
        {
          type: 'tab',
          id: 'LogPointEventsContainer',
          name: 'LogPoint Events',
          component: 'LogPointEventsContainer',
          enableClose: false,
        },
        {
          type: 'tab',
          id: 'ErrorSnapshotsContainer',
          name: 'Error Snapshots',
          component: 'ErrorSnapshotsContainer',
          enableClose: false,
        },
      ],
    },
    {
      type: 'border',
      selected: 0,
      location: 'right',
      size: 400,
      minSize: 400,
      children: [
        {
          type: 'tab',
          id: 'SidekickActions',
          name: 'Sidekick Actions',
          component: 'SidekickActionsContainer',
          enableClose: false,
        },
      ],
    },
  ],
  layout: {
    type: 'row',
    weight: 100,
    children: [
      {
        type: 'row',
        weight: 50,
        id: 'source-files-row',
        children: [
          {
            type: 'tabset',
            id: 'ThundraSourceTabSet',
            selected: 0,
            children: [
              {
                type: 'tab',
                id: 'ThundraSourceWelcomeContainer',
                name: 'Home',
                component: 'ThundraSourceWelcomeContainer',
                enableClose: false,
              },
            ],
          },
        ],
      },
    ],
  },
};

export function getDefaultIDEModel() {
  return IDEModel;
}

export function getIDEModel() {
  //If New Layout Update consist change of Old Components ID/Naming
  //If Conflict between CurrentLayout and Working JS Component
  //First Add Check here to Old ElementId  if Not Exist then
  //Switch off Loading storeModel with New Version Code Deploy
  const storedModel = localStorage.getItem('IDEModel');
  if (storedModel) {
    return JSON.parse(storedModel);
  }
  return IDEModel;
}

export function storeIDEModel2LocalStorage(model) {
  localStorage.setItem('IDEModel', model);
}

export function getIDEModelSourceNode(sourcePath) {
  // console.log("IDEModel, getIDEModelSourceNode; sourcePath: ", sourcePath);
  const sourceIndex = getSourceIndex(sourcePath);
  // console.log("IDEModel, getSourceIndex; sourceIndex: ", sourceIndex);

  const node = {
    type: 'tab',
    id: getSourceId(sourceIndex),
    name: getSourceName(sourceIndex),
    component: 'ThundraSourceEditorContainer',
    enableClose: true,
  };
  return node;
}

//ContextMetaData is metadata about SourceProvider, RepoOwner, Repo
export function getIdsOfTabsInIDEModel(contextMetaData) {
  const ideModel = getIDEModel();
  // debugger;
  if (ideModel && ideModel.layout && hasArrayElement(ideModel.layout.children)) {
    const tabs = ideModel.layout.children[0].children;
    if (hasArrayElement(tabs)) {
      const tabsIds = tabs.map(el => el.id);
      const pathPrefix = generatePathPrefixFromContextMetaData(contextMetaData);
      const tabsIdsFilters = tabsIds.filter(el => el.includes(pathPrefix));
      return tabsIdsFilters;
    }
  }
  return [];
}
