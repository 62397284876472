import { DAY_MAP, RETENTION } from './EventHistoryPage';
import { Button, Modal } from 'semantic-ui-react';
import React, { Component } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export class EventHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retention: RETENTION.DAYS_30,
      graphData: [],
      graphWidth: 0,
    };

    this.graphRef = React.createRef();
  }

  getProbeHitCount = () => {
    this.props.getProbeHitCount(data => {
      this.setState({ graphData: data });
    }, this.state.retention);
  };

  componentDidMount() {
    this.getProbeHitCount();
    this.setState({ graphWidth: this.graphRef.current.width });
  }

  createGraphData = () => {
    if (this.state.graphData.length < 1) {
      return [];
    }
    return new Array(this.state.retention.dataLength).fill(null).map((_, index) => {
      let date = new Date(`${this.state.graphData[index].hitDate}`);
      let name = '';
      if (this.state.retention.groupBy === 'DAILY') {
        name = `${date.getDate()} ${DAY_MAP[date.getDay()]}`;
      } else {
        name = `${date.getHours()}:00`;
      }
      return {
        name: name,
        ['hit count']: this.state.graphData[index].hitCount,
      };
    });
  };

  render() {
    const tickProp = { fill: '#d9d9d9' };
    return (
      <Modal className={'history-modal'} onClose={() => this.props.onClose()} open={true} closeOnDimmerClick={false}>
        <Modal.Header className={'modal-header'}>
          <div className={'header'}>Events</div>
          <div className={'button-group'}>
            {Object.entries(RETENTION).map(([key, val]) => (
              <Button
                className={'selection-button' + (this.state.retention.label === val.label ? ' selected' : '')}
                key={key}
                content={val.label}
                onClick={() => {
                  this.setState({ retention: val, graphData: [] }, () => {
                    this.getProbeHitCount();
                  });
                }}
              />
            ))}
          </div>
        </Modal.Header>
        <Modal.Content>
          <div className={'event-history-page-graph-container'} ref={this.graphRef}>
            <ResponsiveContainer width={this.state.graphWidth} height="90%">
              <BarChart
                width={800}
                height={320}
                data={this.createGraphData()}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={tickProp} />
                <YAxis tick={tickProp} />
                <Tooltip />
                <Legend />
                <Bar dataKey="hit count" stackId="a" fill={this.props.color} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button className={'secondary button-step-info'} content={'Close'} onClick={() => this.props.onClose()} />
        </Modal.Actions>
      </Modal>
    );
  }
}
