import { connect } from 'react-redux';
import { SourceExplorer } from 'components';
import { withRouter } from 'react-router';
import {
  deleteOnboardingSourceCache,
  deleteSourceCache,
  flushSelectedGitSourceCache,
  getSourceCacheList,
  getUserRepositoryBranchHead,
  notifyIDEContextReset,
  selectGitSourceCache,
  updateSourceCache,
} from 'store/actions';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    gitBranchData: store.gitBranches,
    sourceCache: store.sourceCache,
    activeWorkspace: store.activeWorkspace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectGitSourceCache(selectedSource) {
      dispatch(selectGitSourceCache(selectedSource));
    },
    flushSelectedGitSourceCache() {
      dispatch(flushSelectedGitSourceCache());
    },
    getSourceCacheList(workspaceId, onGetSourceCacheListSuccess, onGetSourceCacheListFail) {
      dispatchWithExceptionHandler(
        getSourceCacheList(workspaceId),
        false,
        onGetSourceCacheListSuccess,
        onGetSourceCacheListFail,
      );
    },
    updateSourceCache(sourceId, source, onUpdateSourceCacheSuccess, onUpdateSourceCacheFail) {
      console.log('SEC, updateSourceCache; sourceId, source: ', sourceId, source);
      dispatchWithExceptionHandler(
        updateSourceCache(sourceId, source),
        false,
        onUpdateSourceCacheSuccess,
        onUpdateSourceCacheFail,
      );
    },
    deleteSourceCache(sourceId, workspaceId, onDeleteSourceCacheSuccess, onDeleteSourceCacheFail) {
      console.log('SEC, deleteSourceCache; source: ', sourceId);
      if (sourceId === 'onboarding-sample-id') {
        dispatchWithExceptionHandler(
          deleteOnboardingSourceCache(sourceId),
          false,
          onDeleteSourceCacheSuccess,
          onDeleteSourceCacheFail,
        );
      } else {
        dispatchWithExceptionHandler(
          deleteSourceCache(sourceId, workspaceId),
          false,
          onDeleteSourceCacheSuccess,
          onDeleteSourceCacheFail,
        );
      }
    },

    getUserRepositoryBranchHead: (
      sourceProvider,
      owner,
      repo,
      branch,
      getRepoBranchHeadSuccess,
      getRepoBranchHeadFail,
    ) =>
      dispatch(
        getUserRepositoryBranchHead(
          sourceProvider,
          owner,
          repo,
          branch,
          getRepoBranchHeadSuccess,
          getRepoBranchHeadFail,
        ),
      ),

    handleNotifyIDEContextReset(sourceProvider, repoOwner, repoName, branch, commit) {
      dispatch(notifyIDEContextReset(sourceProvider, repoOwner, repoName, branch, commit));
    },
  };
}

const SourceExplorerContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SourceExplorer));
export default SourceExplorerContainer;
