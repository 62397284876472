import {
  GET_ON_PREM_IDE_TOKEN_FULFILLED,
  GET_ON_PREM_IDE_TOKEN_PENDING,
  GET_ON_PREM_IDE_TOKEN_REJECTED,
} from 'store/constants/onprem-ide-token';

const initialState = {
  ideToken: '',
  fetching: false,
  fetched: false,
  error: null,
};

export default function onpremIdeToken(state = initialState, action) {
  switch (action.type) {
    case GET_ON_PREM_IDE_TOKEN_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_ON_PREM_IDE_TOKEN_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        ideToken: action.payload.data,
      };
    case GET_ON_PREM_IDE_TOKEN_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    default:
      return state;
  }
}
