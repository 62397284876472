import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { UPDATE_WEBHOOK } from '../constants/webhook-update';

export const updateWebhook = (workspaceId, type, id, name, config) => {
  return {
    type: UPDATE_WEBHOOK,
    payload: axios.put(
      CONFIG.apiUrl + `/webhooks/${id}`,
      { type, workspaceId, name, config: JSON.stringify(config) },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
};
