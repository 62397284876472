import React, { Component } from 'react';
import auth from 'auth/auth-factory';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';

import './ProfilePage.scss';

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.checkIfProfileExistsOnLocalStorageOrAnonymous();
    const { userAccount } = this.props.userAccount;

    this.state = {
      fullname: userAccount.fullName || '',
      email: userAccount.email || '',
      isFormEdited: false,
      isResetPasswordInProgress: false,
      apiTokenCopied: false,
      showApiToken: false,
    };
  }

  componentDidMount() {
    this.fetchUserAccount();
  }

  fetchUserAccount = () => {
    this.props.getUserAccount(this.onGetUserAccountSuccess, () =>
      console.log('ProfilePage, CDM, getUserAccount error'),
    );
  };

  onGetUserAccountSuccess = () => {
    console.log('ProfilePage, onGetUserAccountSuccess; props: ', this.props);
    const { userAccount } = this.props.userAccount;

    this.setState({
      email: userAccount.email,
      fullname: userAccount.fullName,
    });
  };

  checkIfProfileExistsOnLocalStorageOrAnonymous = () => {
    const profile = auth.getCurrentUser();
    if (profile === null || profile === undefined) {
      auth.logout(false);
      // throw new Error("Profile was not loaded");
    }

    if (profile.anonymous) {
      console.error('Anonymous user cannot reach profile page');
      auth.logout(true);
    }
  };

  handleTemporaryShowMessage = (isPositive, header, content) => {
    this.setState({
      showMessage: true,
      messageHeader: header,
      messageContent: content,
      isMessagePositive: isPositive,
    });

    setTimeout(() => {
      this.setState({
        showMessage: false,
        messageHeader: '',
        messageContent: '',
      });
    }, 5000);
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      isFormEdited: true,
    });
  };

  handleSaveProfileInfoChanges = () => {
    console.log('handleSaveProfileInfoChanges; props, state: ', this.props, this.state);

    this.props.updateUserProfile(
      {
        fullname: this.state.fullname,
        company: this.props.userAccount.userAccount.company || '',
        companyRole: this.props.userAccount.userAccount.companyRole || 'USER',
      },
      this.onProfileInfoChangeSuccess,
      this.onProfileInfoChangeFail,
    );
  };

  onProfileInfoChangeSuccess = () => {
    console.log('profile change success');
    this.fetchUserAccount();

    this.setState({
      isFormEdited: false,
    });

    this.handleTemporaryShowMessage(true, 'Profile updated', 'User information is changed successfully.');
  };

  onProfileInfoChangeFail = () => {
    console.log('profile change fail');
    this.handleDiscardProfileInfoChanges();

    this.handleTemporaryShowMessage(false, "Couldn't update the profile", 'Error while changing user information');
  };

  handleDiscardProfileInfoChanges = () => {
    // TODO: stop reading user info/profile bits from profile @localstorage. Instead use userAccount prop.
    // when profile info changes are discarded, load them back from loaclStorage to states.
    const profile = auth.getCurrentUser();
    this.checkIfProfileExistsOnLocalStorageOrAnonymous();
    console.log('discard clicked; profile: ', profile);

    const { fullName } = this.props.userAccount.userAccount;

    this.setState({
      fullname: fullName || '',
      email: profile.email,
      isFormEdited: false,
    });
  };

  handleResetPasswordChange = () => {
    console.log('reset password clicked');
    if (!this.state.isResetPasswordInProgress) {
      this.setState({
        isResetPasswordInProgress: true,
      });

      const profile = auth.getCurrentUser();
      this.checkIfProfileExistsOnLocalStorageOrAnonymous();

      this.props.changeUserPassword(profile.email, this.onResetPasswordChangeSuccess, this.onResetPasswordChangeFail);
    }
  };

  onResetPasswordChangeSuccess = () => {
    console.log('reset pass success');

    this.setState({
      isResetPasswordInProgress: false,
    });

    this.handleTemporaryShowMessage(
      true,
      'Password reset request',
      'A reset password link has been sent to the email address below. Follow the link to select a new password.',
    );
  };

  onResetPasswordChangeFail = () => {
    console.log('reset pass fail');

    this.setState({
      isResetPasswordInProgress: false,
    });

    this.handleTemporaryShowMessage(
      false,
      "Couldn't send change password email",
      'Error while sending the password change email',
    );
  };

  renderMessage = (isPositive, messageHeader, messageContent) => {
    return (
      <Grid.Row className="message-box-row" centered columns={3}>
        {isPositive ? (
          <Grid.Column className="message-box-wrapper">
            <Icon name="info circle" />
            {messageHeader}
            <div className="message-content">{messageContent}</div>
          </Grid.Column>
        ) : (
          <></>
        )}
      </Grid.Row>
    );
  };

  handleShowKey = keyType => {
    this.setState({ [keyType]: true });
    setTimeout(() => this.setState({ [keyType]: false }), 5000);
  };

  isActionButtonsDisabled = () => {
    return !this.state.isFormEdited || this.props.userInformation.fetching || this.state.fullname === '';
  };

  copyValue = value => {
    navigator.clipboard.writeText(value);
    this.setState({
      apiTokenCopied: true,
    });
    setTimeout(() => {
      this.setState({
        apiTokenCopied: false,
      });
    }, 2000);
  };

  render() {
    const apiToken = this.props.userAccount?.userAccount?.apiToken;
    return (
      <div className="profile-page-wrapper">
        <Grid className="profile-page-subtitle-grid">
          <Grid.Row centered columns={3}>
            <Grid.Column className="page-title">Profile settings</Grid.Column>
          </Grid.Row>

          {this.state.showMessage &&
            this.renderMessage(this.state.isMessagePositive, this.state.messageHeader, this.state.messageContent)}

          <Grid.Row centered columns={3}>
            <Grid.Column className="top-space subtitle">Profile details</Grid.Column>
          </Grid.Row>

          <Grid.Row centered columns={3}>
            <Grid.Column>
              <Form className="profile-page-form">
                <Form.Group className="email-name-form-group">
                  <Form.Field>
                    <label>Name</label>
                    <Form.Input
                      placeholder="Name Surname"
                      name="fullname"
                      value={this.state.fullname}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>

                  <Form.Field disabled>
                    <label>E-mail</label>
                    <Form.Input placeholder="E-mail" name="email" value={this.state.email} />
                  </Form.Field>
                </Form.Group>

                <div className="profile-page-button-wrapper">
                  <Button
                    primary
                    className="medium-button"
                    onClick={this.handleSaveProfileInfoChanges}
                    content="Save"
                    disabled={this.isActionButtonsDisabled()}
                    loading={this.props.userInformation.fetching}
                  />

                  <Button
                    className="reject-button medium-button"
                    onClick={this.handleDiscardProfileInfoChanges}
                    content="Discard changes"
                    disabled={this.isActionButtonsDisabled()}
                  />
                </div>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered columns={3}>
            <Grid.Column className="top-space subtitle">Change Password</Grid.Column>
          </Grid.Row>

          <Grid.Row centered columns={3}>
            <Grid.Column className="content">
              If you need to change your password you can &nbsp;
              <span className="reset-password-text" onClick={this.handleResetPasswordChange}>
                reset your password
              </span>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className={'api-row'} centered columns={3}>
            <Grid.Column>
              <div className="top-space subtitle">Your API Token</div>
              <div className={'content'}>This api key is unique to you. Don't share with anyone else.</div>
              <div className={'highlight-box'}>
                <div className={'highlight-box-content'}>
                  {this.state.showApiToken ? apiToken : [...apiToken].map(() => '*')}
                </div>
                <Icon name={'eye'} className={'clickable'} onClick={() => this.handleShowKey('showApiToken')} />
                {this.state.apiTokenCopied ? (
                  <span className={'right-item'}>Copied!</span>
                ) : (
                  <i onClick={() => this.copyValue(apiToken)} className={'right-item clickable copy icon'} />
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default ProfilePage;
