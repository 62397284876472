import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { generateFullGitSourceURLPath } from 'utils/string-util';
import Fuse from 'fuse.js';
import { hasArrayElement } from 'utils/array-util';
import { SOURCE_PROVIDER_PLATFORM } from 'utils/source-provider-util';
import './SourceSearch.scss';
import folderIcon from 'assets/icons/folder_icon.svg';
import { Popup } from 'semantic-ui-react';
import debounce from 'lodash.debounce';

const STARTING_SEARCH_CHARACTER_THRESHOLD = 2;
export default class SourceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      pathResults: [],
      results: [],
      filesMetaData: [],
      openingFileName: '',
      fuse: '',
      delayedSearchActive: false,
    };

    this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
  }

  componentDidMount() {
    this.seedSearch();
    this.disablePressingEnterForSearchButton();
  }

  componentWillReceiveProps(nextProps) {
    const { selectedGitSourceCache } = { ...this.sourceCache };
    const { repository, commit } = { ...selectedGitSourceCache };
    if (
      selectedGitSourceCache &&
      nextProps.sourceCache.selectedGitSourceCache &&
      (nextProps.sourceCache.selectedGitSourceCache.repository !== repository ||
        nextProps.sourceCache.selectedGitSourceCache.commit !== commit)
    ) {
      this.seedSearch();
    }
  }

  disablePressingEnterForSearchButton() {
    const ENTER_BUTTON_KEYCODE = 13;
    window.addEventListener(
      'keydown',
      function (e) {
        if (e.keyIdentifier === 'U+000A' || e.keyIdentifier === 'Enter' || e.keyCode === ENTER_BUTTON_KEYCODE) {
          if (e.target.nodeName === 'INPUT' && e.target.type === 'text') {
            e.preventDefault();
            return false;
          }
        }
      },
      true,
    );
  }

  seedSearch = () => {
    if (this.props.gitTreeContent && hasArrayElement(this.props.gitTreeContent.gitTreeData)) {
      this.setState({
        fuse: new Fuse(this.generateFilesMetaData(), {
          keys: ['fileName'],
          threshold: 0.2,
        }),
        results: this.state.fuse.search(this.state.query),
      });
    }
  };

  resetInputQuery = () => {
    this.setState({
      pathResults: [],
    });
    this.setState({
      query: [],
    });
  };

  handleGitTreeFileClick = filePath => {
    this.setState({
      openingFileName: this.generateLeafPathFromFullPath(filePath),
    });
    this.setState({
      openingFilePath: filePath,
    });

    const { owner, repository, commit } = this.props.sourceCache.selectedGitSourceCache || {};
    console.log('Owner repo and commit, props ', owner, repository, commit, this.props);

    this.props.getUserRepoContent(SOURCE_PROVIDER_PLATFORM.GITHUB, owner, repository, filePath, commit, () => {
      const fileFullPath = generateFullGitSourceURLPath(
        SOURCE_PROVIDER_PLATFORM.GITHUB,
        owner,
        repository,
        filePath,
        commit,
      );
      this.props.handleSourceFocus(fileFullPath, 0);
    });
  };

  handleSearchQueryChange = e => {
    e.persist();
    this.setState({
      query: e.target.value,
    });
    this.setState({
      delayedSearchActive: true,
    });
    this.onChangeDebounced(e);
  };

  onChangeDebounced = e => {
    if (e.target.value.length >= STARTING_SEARCH_CHARACTER_THRESHOLD) {
      try {
        this.setState({
          results: this.state.fuse.search(e.target.value),
        });

        if (!this.state.results) {
          return;
        }

        this.setState({
          pathResults:
            this.props.gitTreeContent.gitTreeData[0].tree && this.state.query
              ? this.state.results.map(filePath => filePath.item)
              : this.generateFilesMetaData(),
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState({
        pathResults: [],
      });
    }
    this.setState({
      delayedSearchActive: false,
    });
  };

  generateLeafPathFromFullPath = fullPath => {
    return fullPath.split('\\').pop().split('/').pop();
  };

  generateFilesMetaData = () => {
    return this.props.gitTreeContent.gitTreeData[0].tree
      .filter(gitTreeItem => gitTreeItem.type === 'blob')
      .map(metadata => {
        return {
          path: metadata.path,
          fileName: this.generateLeafPathFromFullPath(metadata.path),
        };
      });
  };

  isDataFetchingForLoading = () => {
    if (this.state.delayedSearchActive || this.props.gitRepoContent.isFetching) {
      return true;
    }

    return false;
  };

  render() {
    console.log('SourceSearch; props, state: ', this.props, this.state);

    let noResults = false;
    if (this.state.pathResults.length === 0 && this.state.query !== '') {
      noResults = true;
    }

    return (
      <>
        {
          <div className="search-files-container">
            <>
              {this.isDataFetchingForLoading() && (
                <div className="repository-management-loading-layer">
                  <Icon className="icon-thundra-loading" />
                </div>
              )}
              <div className="search-title-and-form-container">
                <form className="sourcefiles-search-input-form">
                  <input
                    type="text"
                    className="sourcefiles-search-input"
                    placeholder="Search files"
                    autoComplete="off"
                    value={this.state.query}
                    onChange={e => {
                      this.handleSearchQueryChange(e);
                    }}
                    autoFocus={true}
                  />
                  {this.state.query.length > 0 && (
                    <i
                      className="icon-thundra-clear-button"
                      name="close"
                      onClick={() => {
                        this.setState({
                          pathResults: [],
                        });
                        this.setState({
                          query: [],
                        });
                      }}
                    />
                  )}
                </form>
              </div>

              <div className="sourcefiles-table">
                {this.state.query.length === 1 && (
                  <div className="no-results-text">Please type at least 2 characters</div>
                )}
                {!(this.state.query.length === 1) && noResults && !this.state.delayedSearchActive && (
                  <div className="no-results-text">No result found for "{this.state.query}"</div>
                )}
                {this.state.pathResults.map(filesMetaData => {
                  return (
                    <div
                      key={filesMetaData.path + filesMetaData.fileName}
                      className={'sourcefile-meta'}
                      onClick={() => this.handleGitTreeFileClick(filesMetaData.path)}
                    >
                      <Popup
                        className="sidekick-tooltip"
                        position="bottom center"
                        trigger={
                          <>
                            <div key={filesMetaData.path + filesMetaData.fileName} className="icon-filename-container">
                              <img src={folderIcon} className="source-folder-icon" alt={''} />
                              <div className="sourcefile-name">{filesMetaData.fileName}</div>
                            </div>

                            <div key={filesMetaData.path} className="sourcefile-path">
                              <span>{filesMetaData.path}</span>
                            </div>
                          </>
                        }
                        content={filesMetaData.path}
                        hoverable={true}
                        flowing={true}
                        on="hover"
                        mouseEnterDelay={1500}
                        hideOnScroll={true}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          </div>
        }
      </>
    );
  }
}
