import { connect } from 'react-redux';
import { IDEComplexLayout } from 'components';

import {
  appsAndTracePointsTabOpened,
  changeLayout,
  changeSourceTabSelection,
  changeSourceTabSelectionRequest,
  clearTracePointEvents,
  eventsTabOpened,
  failedIDEContextReset,
  notifyAppsAndRepositoriesStateChange,
  notifyAppsAndRepositoriesStateChangeCompleted,
  notifyCollapseAppsAndRepositories,
  notifyCollapseAppsAndRepositoriesCompleted,
  notifyFindAndAddSource,
  notifySourceExisted,
  notifySourceFocus,
  notifySourceNotAvailable,
  notifySourceSelect,
  openSidekickActionEditTab,
  openSidekickActionEventsTabIfClosed,
  resetUserRepoContent,
  sourcesTabOpened,
  succeedIDEContextReset,
} from 'store/actions';

function mapStateToProps(store) {
  return {
    broker: store.broker,
    sourceTabs: store.sourceTabs,
    sourceFocus: store.sourceFocus,
    sidekickActionEventsFocus: store.sidekickActionEventsFocus,
    sidekickActionEdit: store.sidekickActionEdit,
    gitRepoContent: store.gitRepoContent,
    ideToggleBorderTabs: store.ideToggleBorderTabs,
    ideContext: store.ideContext,
    ideAppsAndRepositoriesManagement: store.ideAppsAndRepositoriesManagement,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleLayoutChange(sourceTabIndex, lineNo) {
      dispatch(changeLayout(sourceTabIndex, lineNo));
    },

    handleSourceTabChange(sourceTabIndex, lineNo) {
      dispatch(changeSourceTabSelection(sourceTabIndex, lineNo));
    },
    handleSourceTabChangeRequest(sourceTabIndex, lineNo) {
      dispatch(changeSourceTabSelectionRequest(sourceTabIndex, lineNo));
    },
    handleNotifySourceFocus(sourceTabIndex, lineNo) {
      dispatch(notifySourceFocus(sourceTabIndex, lineNo));
    },
    handleNotifySourceNotAvailable(sourceTabIndex, lineNo) {
      dispatch(notifySourceNotAvailable(sourceTabIndex, lineNo));
    },
    handleNotifyFindAndAddSource(sourceTabIndex, lineNo) {
      dispatch(notifyFindAndAddSource(sourceTabIndex, lineNo));
    },
    handleNotifySourceExisted(sourceTabIndex, lineNo) {
      dispatch(notifySourceExisted(sourceTabIndex, lineNo));
    },
    handleNotifySourceSelect(sourceTabIndex, lineNo) {
      dispatch(notifySourceSelect(sourceTabIndex, lineNo));
    },
    handleOpenSidekickActionEventsTabIfClosed(sidekickAction, getReference, type) {
      dispatch(openSidekickActionEventsTabIfClosed(sidekickAction, getReference, type));
    },
    handleOpenSidekickActionEditTab(tracePoint) {
      dispatch(openSidekickActionEditTab(tracePoint));
    },
    handleSourcesTabOpened() {
      dispatch(sourcesTabOpened());
    },
    handleAppsAndTracePointsTabOpened() {
      dispatch(appsAndTracePointsTabOpened());
    },
    handleEventsTabOpened() {
      dispatch(eventsTabOpened());
    },
    handleFailedIDEContextReset(contextMetaData) {
      dispatch(failedIDEContextReset(contextMetaData));
    },
    handleSucceedIDEContextReset(contextMetaData) {
      dispatch(succeedIDEContextReset(contextMetaData));
    },
    handleTracePointEventClear(contextMetaData) {
      dispatch(clearTracePointEvents(contextMetaData));
    },
    handleUserRepoContentReset() {
      dispatch(resetUserRepoContent());
    },
    handleAppsAndRepositoriesStateChange(newState) {
      dispatch(notifyAppsAndRepositoriesStateChange(newState));
    },
    handleAppsAndRepositoriesStateChangeCompleted() {
      dispatch(notifyAppsAndRepositoriesStateChangeCompleted());
    },
    handleCollapseAppsAndRepositories() {
      dispatch(notifyCollapseAppsAndRepositories());
    },
    handleCollapseAppsAndRepositoriesCompleted() {
      dispatch(notifyCollapseAppsAndRepositoriesCompleted());
    },
  };
}

export const IDEComplexLayoutContainer = connect(mapStateToProps, mapDispatchToProps)(IDEComplexLayout);
