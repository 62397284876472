import React, { Component } from 'react';

export class VideoListItem extends Component {
  render() {
    return (
      <div
        className={'video-list-item' + (this.props.active ? ' active' : '')}
        onClick={() => this.props.onClick(this.props.id)}
      >
        {this.props.image && <div className={'caption'}>IMAGE</div>}
        <div className={'video-name'}>{this.props.name}</div>
        {this.props.lenght && <div className={'video-length'}>{this.props.length}</div>}
      </div>
    );
  }
}
