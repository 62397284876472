import {
  GET_GITHUB_ACCESS_TOKEN_PENDING,
  GET_GITHUB_ACCESS_TOKEN_FULFILLED,
  GET_GITHUB_ACCESS_TOKEN_REJECTED,
  GET_GITLAB_ACCESS_TOKEN_PENDING,
  GET_GITLAB_ACCESS_TOKEN_FULFILLED,
  GET_GITLAB_ACCESS_TOKEN_REJECTED,

  GET_SOURCE_PROVIDER_ACCOUNT_LIST_PENDING,
  GET_SOURCE_PROVIDER_ACCOUNT_LIST_FULFILLED,
  GET_SOURCE_PROVIDER_ACCOUNT_LIST_REJECTED,
  ADD_SOURCE_PROVIDER_ACCOUNT_PENDING,
  ADD_SOURCE_PROVIDER_ACCOUNT_FULFILLED,
  ADD_SOURCE_PROVIDER_ACCOUNT_REJECTED,
  DELETE_SOURCE_PROVIDER_ACCOUNT_PENDING,
  DELETE_SOURCE_PROVIDER_ACCOUNT_FULFILLED,
  DELETE_SOURCE_PROVIDER_ACCOUNT_REJECTED,
  DELETE_NEW_SOURCE_PROVIDER_DATA
} from 'store/constants/source-provider-account';

const initialState = {
  githubOauthToken: {},
  githubOauthTokenFetching: false,
  githubOauthTokenFetched: false,
  githubOauthTokenError: null,

  gitlabOauthToken: {},
  gitlabOauthTokenFetching: false,
  gitlabOauthTokenFetched: false,
  gitlabOauthTokenError: null,

  sourceAccountList: [],
  sourceAccountListFetching: false,
  sourceAccountListFetched: false,
  sourceAccountListError: null,

  addSourceAccount: {},
  addSourceAccountFetching: false,
  addSourceAccountFetched: false,
  addSourceAccountError: null,

  deleteSourceAccount: {},
  deleteSourceAccountFetching: false,
  deleteSourceAccountFetched: false,
  deleteSourceAccountError: null,
};

export default function sourceProviderAccount(state = initialState, action) {
  switch (action.type) {
    case DELETE_NEW_SOURCE_PROVIDER_DATA:
      // console.log("DELETE_NEW_SOURCE_PROVIDER_DATA");
      return {
        ...state,
        githubOauthToken: {},
        gitlabOauthToken: {}, // !!
        addSourceAccount: {} // !!
      };
    /////////////////////////////////////
    case GET_GITHUB_ACCESS_TOKEN_PENDING:
      return {
        ...state,
        githubOauthTokenFetching: true,
        githubOauthTokenFetched: false,
        githubOauthTokenError: null,
      };
    case GET_GITHUB_ACCESS_TOKEN_FULFILLED:
      // console.log('GET_GITHUB_ACCESS_TOKEN_FULFILLED reducer; action: ', action);
      return {
        ...state,
        githubOauthTokenFetching: false,
        githubOauthTokenFetched: true,
        githubOauthTokenError: null,
        githubOauthToken: action.payload.data,
      };
    case GET_GITHUB_ACCESS_TOKEN_REJECTED:
      return {
        ...state,
        githubOauthTokenFetching: false,
        githubOauthTokenFetched: false,
        githubOauthTokenError: action.payload,
      };
    case GET_GITLAB_ACCESS_TOKEN_PENDING:
      return {
        ...state,
        gitlabOauthTokenFetching: true,
        gitlabOauthTokenFetched: false,
        gitlabOauthTokenError: null,
      };
    case GET_GITLAB_ACCESS_TOKEN_FULFILLED:
      // console.log('GET_GITLAB_ACCESS_TOKEN_FULFILLED reducer; action: ', action);
      return {
        ...state,
        gitlabOauthTokenFetching: false,
        gitlabOauthTokenFetched: true,
        gitlabOauthTokenError: null,
        gitlabOauthToken: action.payload.data,
      };
    case GET_GITLAB_ACCESS_TOKEN_REJECTED:
      return {
        ...state,
        gitlabOauthTokenFetching: false,
        gitlabOauthTokenFetched: false,
        gitlabOauthTokenError: action.payload,
      };
    //////////////////////////////////////////////
    case GET_SOURCE_PROVIDER_ACCOUNT_LIST_PENDING:
      return {
        ...state,
        sourceAccountListFetching: true,
        sourceAccountListFetched: false,
        sourceAccountListError: null,
      };
    case GET_SOURCE_PROVIDER_ACCOUNT_LIST_FULFILLED:
      // console.log('GET_SOURCE_PROVIDER_ACCOUNT_LIST_FULFILLED reducer; action: ', action);
      return {
        ...state,
        sourceAccountListFetching: false,
        sourceAccountListFetched: true,
        sourceAccountListError: null,
        sourceAccountList: action.payload.data,
      };
    case GET_SOURCE_PROVIDER_ACCOUNT_LIST_REJECTED:
      return {
        ...state,
        sourceAccountListFetching: false,
        sourceAccountListFetched: false,
        sourceAccountListError: action.payload,
      };

    case ADD_SOURCE_PROVIDER_ACCOUNT_PENDING:
      return {
        ...state,
        addSourceAccountFetching: true,
        addSourceAccountFetched: false,
        addSourceAccountError: null,
      };
    case ADD_SOURCE_PROVIDER_ACCOUNT_FULFILLED:
      // console.log('ADD_SOURCE_PROVIDER_ACCOUNT_FULFILLED reducer; action: ', action);
      return {
        ...state,
        addSourceAccountFetching: false,
        addSourceAccountFetched: true,
        addSourceAccountError: null,
        addSourceAccount: action.payload.data,
      };
    case ADD_SOURCE_PROVIDER_ACCOUNT_REJECTED:
      // console.log('ADD_SOURCE_PROVIDER_ACCOUNT_REJECTED reducer; action: ', action);
      return {
        ...state,
        addSourceAccountFetching: false,
        addSourceAccountFetched: false,
        addSourceAccountError: action.payload,
      };

    case DELETE_SOURCE_PROVIDER_ACCOUNT_PENDING:
      return {
        ...state,
        deleteSourceAccountFetching: true,
        deleteSourceAccountFetched: false,
        deleteSourceAccountError: null,
      };
    case DELETE_SOURCE_PROVIDER_ACCOUNT_FULFILLED:
      // console.log('DELETE_SOURCE_PROVIDER_ACCOUNT_FULFILLED reducer; action: ', action);
      return {
        ...state,
        deleteSourceAccountFetching: false,
        deleteSourceAccountFetched: true,
        deleteSourceAccountError: null,
        deleteSourceAccount: action.payload.data,
      };
    case DELETE_SOURCE_PROVIDER_ACCOUNT_REJECTED:
      // console.log('DELETE_SOURCE_PROVIDER_ACCOUNT_REJECTED reducer; action: ', action);
      return {
        ...state,
        deleteSourceAccountFetching: false,
        deleteSourceAccountFetched: false,
        deleteSourceAccountError: action.payload,
      };
    default:
      return state;
  }
}
