import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { APPS_AND_SOURCES, SEARCH, VERSION_CONTROL } from 'store/constants/ide-apps-and-repositories-management';

function FolderIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
      <path
        d="M18 16V5H8.84571L8.34293 3.7425L7.88842 2.60571C7.77815 2.33044 7.2906 2 7.003 2H1.997C2.00157 2 2 16 2 16H18ZM10.2 3H18.009C19.109 3 20 3.893 20 4.992V16.008C20 17.108 19.102 18 18.009 18H1.991C0.891 18 0 17.107 0 16.008V2.006C0 0.898 0.896 0 1.997 0H7.003C8.106 0 9.33 0.826 9.745 1.862L10.2 3Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}

function VersionControlIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
      <path
        d="M14 8C14 6.3 12.7 5 11 5C9.3 5 8 6.3 8 8C8 9.3 8.8 10.4 10 10.8V12C10 13.1 9.1 14 8 14H5.8C5.5 13.1 4.8 12.5 3.9 12.2V12V6V5.8C5.2 5.4 6 4.3 6 3C6 1.3 4.7 0 3 0C1.3 0 0 1.3 0 3C0 4.3 0.9 5.4 2 5.8V6V12V12.2C0.8 12.6 0 13.7 0 15C0 16.7 1.3 18 3 18C4.3 18 5.4 17.2 5.8 16H8C10.2 16 12 14.2 12 12V10.8C13.2 10.4 14 9.3 14 8ZM3 2C3.6 2 4 2.4 4 3C4 3.6 3.6 4 3 4C2.4 4 2 3.6 2 3C2 2.4 2.5 2 3 2ZM3 16C2.4 16 2 15.6 2 15C2 14.4 2.4 14 3 14C3.6 14 4 14.4 4 15C4 15.6 3.6 16 3 16ZM11 9C10.4 9 10 8.6 10 8C10 7.4 10.4 7 11 7C11.6 7 12 7.4 12 8C12 8.6 11.6 9 11 9Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}

function SearchIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5ZM12.0491 13.4633C10.7873 14.4274 9.2105 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 9.2105 14.4274 10.7873 13.4633 12.0491L18 16.5858L16.5858 18L12.0491 13.4633Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}

export class AppsAndRepositoriesController extends Component {
  render() {
    const { currentState } = this.props.ideAppsAndRepositoriesManagement;
    return (
      <>
        <Menu.Item
          key={'apps-and-sources'}
          className="menu-item"
          as="a"
          active={currentState === APPS_AND_SOURCES}
          onClick={() => this.props.notifyStateChange(APPS_AND_SOURCES)}
        >
          <FolderIcon />
        </Menu.Item>
        <Menu.Item
          key={'version-control'}
          className="menu-item"
          as="a"
          active={currentState === VERSION_CONTROL}
          onClick={() => {
            if (this.props.onboardStatus.sandboxEnabled) {
              return;
            }
            this.props.notifyStateChange(VERSION_CONTROL);
          }}
        >
          <VersionControlIcon />
        </Menu.Item>
        <Menu.Item
          key={'search'}
          className="menu-item"
          as="a"
          active={currentState === SEARCH}
          onClick={() => this.props.notifyStateChange(SEARCH)}
        >
          <SearchIcon />
        </Menu.Item>
      </>
    );
  }
}
