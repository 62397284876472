import {
  CHANGE_SANDBOX_MODE,
  CLEAR_SANDBOX_STEPS,
  GET_DEMO_ACCOUNT,
  TOGGLE_SANDBOX_STEP,
} from '../constants/onboarding-v2';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';

export const changeOnboardingStatus = onboardingStatus => {
  return {
    type: onboardingStatus,
    payload: null,
  };
};

export const changeSandboxMode = mode => {
  return {
    type: CHANGE_SANDBOX_MODE,
    payload: mode,
  };
};

export const getDemoAccount = () => {
  return {
    type: GET_DEMO_ACCOUNT,
    payload: axios.post(CONFIG.serviceUrl + `/sandbox`),
  };
};

export const toggleSandboxStep = step => {
  return {
    type: TOGGLE_SANDBOX_STEP,
    payload: step,
  };
};

export const clearSandboxSteps = () => {
  return {
    type: CLEAR_SANDBOX_STEPS,
  };
};
