import { DELETE_WEBHOOK_FULFILLED, DELETE_WEBHOOK_PENDING, DELETE_WEBHOOK_REJECTED } from '../constants/webhook-delete';

const initialState = {
  deleteWebhookResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function deleteWebhook(state = initialState, action) {
  switch (action.type) {
    case DELETE_WEBHOOK_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case DELETE_WEBHOOK_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        deleteWebhookResult: action.payload.data,
      };
    case DELETE_WEBHOOK_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
