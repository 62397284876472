import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { DELETE_WEBHOOK } from '../constants/webhook-delete';

export const deleteWebhook = integrationId => {
  return {
    type: DELETE_WEBHOOK,
    payload: axios.delete(CONFIG.apiUrl + `/webhooks/${integrationId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
