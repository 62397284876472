import React, { Component } from 'react';
import { Dropdown, Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { companyRoles } from 'utils/role-utils';

class WelcomeUserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: this.props.userAccount.fullName || '',
      company: this.props.userAccount.company || '',
      companyRole: companyRoles.QA_ENGINEER,
    };
  }

  handleInputChange = e => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    this.setState({ [fieldName]: fieldValue });
  };

  handleDropdownChange = (e, { value }) => {
    this.setState({ companyRole: value });
  };

  render() {
    return (
      <Grid centered container className={'welcome-user-info-page'}>
        <GridRow className={'form-header-row'}>
          <GridColumn>
            <div className={'form-header'}>About you</div>
            <div className={'form-header-caption'}>
              Please fill the form below, before starting your Sidekick journey.{' '}
            </div>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn>
            <Form onSubmit={() => this.props.onSubmit(this.state)}>
              <Form.Input
                autoComplete={'off'}
                required
                label={'Name'}
                value={this.state.fullname}
                name="fullname"
                controller={'input'}
                placeholder={'Full Name'}
                onChange={this.handleInputChange}
              />
              <Form.Input
                autoComplete={'off'}
                label={'Company Name'}
                value={this.state.company}
                name="company"
                controller={'input'}
                placeholder={'Company Name'}
                onChange={this.handleInputChange}
              />
              <Form.Input label={'Role at company'} required>
                <Dropdown
                  fluid
                  onChange={this.handleDropdownChange}
                  defaultValue={this.state.companyRole}
                  options={Object.entries(companyRoles).map(([key, value]) => ({
                    key: key,
                    value: value,
                    text: value,
                  }))}
                />
              </Form.Input>
              <div className={'form-action-row-user-info'}>
                <div className={'button-step-info'}>Next: Create workspace</div>
                <Form.Button className={'primary'} content={'Continue'} loading={this.props.loading} />
              </div>
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

export default WelcomeUserInfo;
