import { GET_ONBOARD_STATUS, UPDATE_ONBOARD_STATUS } from '../constants/workspace-onboarding';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';

export const getOnboardStatus = workspaceId => {
  return {
    type: GET_ONBOARD_STATUS,
    payload: axios.get(CONFIG.apiUrl + `/users/me/onboard?workspaceId=${workspaceId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};

export const updateOnboardStatus = (onboardStatus, workspaceId, accessToken = auth.getAccessToken()) => {
  return {
    type: UPDATE_ONBOARD_STATUS,
    payload: axios.put(
      CONFIG.apiUrl + '/users/me/onboard',
      { onboardStatus: onboardStatus, workspaceId: workspaceId },
      {
        headers: { Authorization: 'Bearer ' + accessToken },
      },
    ),
  };
};
