import React, { Component } from 'react';
import { Menu, Popup } from 'semantic-ui-react';
import routeList from 'routes';
import { withRouter } from 'react-router-dom';
import auth from 'auth/auth-factory';
import { sidebarDecorator } from 'utils/sidebar-decorator';

class UserMenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  handleProfileClick = () => {
    this.props.history.push(routeList.settingsProfile.path);
  };

  handleWorkspacesClick = () => {
    this.props.history.push(routeList.workspaces.path);
  };

  handleWorkspaceSettingsClick = () => {
    this.props.history.push(routeList.workspace.path.replace(':workspaceId', this.props.activeWorkspaceId));
  };

  changeOpenStatus = status => {
    this.setState({ isOpen: status });
  };

  renderTrigger = nameChar => {
    return (
      <Menu.Item
        className={'settings-user menu-item ' + (this.state.isOpen ? 'active' : '')}
        id={'settings-user'}
        as="a"
        onClick={() => this.changeOpenStatus(true)}
      >
        {nameChar}
      </Menu.Item>
    );
  };
  renderContent = userName => {
    return (
      <div className="custom-profile-menu-wrapper">
        <div className="menu-title">{userName}</div>
        <div className="menu-item-button" onClick={this.handleProfileClick}>
          Profile
        </div>
        {sidebarDecorator().userMenuItems.workspaces && (
          <div className="menu-item-button" onClick={this.handleWorkspacesClick}>
            Workspaces
          </div>
        )}

        <div className="menu-item-button logout-section" onClick={() => auth.logout()}>
          Log out
        </div>
      </div>
    );
  };
  render() {
    const { userAccount } = this.props.userAccount;
    // We do not get user's fullName, use email instead!
    const { email, fullName } = userAccount || {};

    const userName = fullName && fullName.length > 0 ? fullName : email;

    let nameChar = '-';
    if (typeof userName === 'string' && userName.length > 0) {
      nameChar = userName.toUpperCase().charAt(0);
    }

    return (
      <Popup
        className={'menu-popup'}
        position={'right center'}
        open={this.state.isOpen}
        onClose={() => this.changeOpenStatus(false)}
        trigger={this.renderTrigger(nameChar)}
        content={this.renderContent(userName)}
        on={'click'}
        basic
      />
    );
  }
}

export default withRouter(UserMenuItem);
