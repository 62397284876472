import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import auth from 'auth/auth-factory';
import { identifyUsers } from './monitoringSetup';
import { CONFIG } from 'utils/config-util.js';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

import store from 'store/store';
import { getUserAccount } from 'store/actions/user-account';

import {
  AsyncCallbackPageContainer,
  AsyncErrorPageContainer,
  AsyncEventHistoryPageContainer,
  AsyncIDEPageContainer,
  AsyncLandingPageContainer,
  AsyncPlaygroundPageContainer,
  AsyncPluginWelcomePageContainer,
  AsyncSandboxPageContainer,
  AsyncSettingsProfilePageContainer,
  AsyncUnsubscribeMailPageContainer,
  AsyncWelcomePageContainer,
  AsyncWorkspacePageContainer,
  AsyncWorkspacesPageContainer,
} from 'containers';

import { LoadingPage } from 'components';

import routeList from './routes';
import 'utils/axios-interceptors.js';
import { withAuth } from './hoc';
import { roles } from './utils/role-utils';

let isUserIdentified = false;
const UserRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated()) {
          //check is Authanticated
          dispatchWithExceptionHandler(
            getUserAccount(),
            true,
            user => {
              console.log(
                'App, getUserAccount; user, store, currentUser: ',
                user,
                store.getState(),
                auth.getCurrentUser(),
              );

              if (!isUserIdentified) {
                identifyUsers(user.value.data); // TODO: this may change to userAccount!
                isUserIdentified = true;
              }
            },
            error => {
              console.log('getUserAccount error => ', error);
              // Backend returns HTTP 401 in case of an expired token but there is still a possibility
              // to refresh token if session is still alive. For other errors, HTTP status code will be
              // different hence we should logout the user
              const HTTP_UNAUTHORIZED_STATUS = 401;
              if (error && error.response && error.response.status === HTTP_UNAUTHORIZED_STATUS) {
                auth.refreshTokenOrForceLogout();
              } else {
                auth.logout();
              }
            },
          );

          return React.createElement(component, props);
        }

        return <Redirect to={routeList.callback.path} />;
      }}
    />
  );
};

const shouldRenderPage = path => {
  const excluded_pages = CONFIG?.EXCLUDED_PAGES;
  if (excluded_pages == null) {
    return true;
  }
  return !excluded_pages.includes(path);
};

class App extends Component {
  render() {
    console.log('App, render; props: ', this.props);

    const isDevEnv = CONFIG.DEV;

    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={AsyncCallbackPageContainer} />
          <Route path={routeList.callback.path} component={AsyncCallbackPageContainer} />

          {shouldRenderPage(routeList.welcome.path) && (
            <UserRoute path={routeList.welcome.path} component={AsyncWelcomePageContainer} />
          )}
          <UserRoute path={routeList.pluginWelcome.path} component={AsyncPluginWelcomePageContainer} />

          <UserRoute
            exact
            path={routeList.ide.path}
            component={withAuth(AsyncIDEPageContainer, {
              allowedRoles: [roles.ADMIN, roles.USER, roles.OWNER],
            })}
          />

          <UserRoute path={routeList.settingsProfile.path} component={AsyncSettingsProfilePageContainer} />

          <UserRoute path={routeList.eventHistory.path} component={AsyncEventHistoryPageContainer} />

          {shouldRenderPage(routeList.workspaces.path) && (
            <UserRoute exact path={routeList.workspaces.path} component={AsyncWorkspacesPageContainer} />
          )}

          {shouldRenderPage(routeList.workspace.path) && (
            <UserRoute
              path={routeList.workspace.path}
              component={withAuth(AsyncWorkspacePageContainer, {
                allowedRoles: [roles.ADMIN, roles.OWNER, roles.BILLING_ADMIN],
              })}
            />
          )}

          {/* Playground page should only be available for dev env. */}
          {isDevEnv && <UserRoute path={routeList.playground.path} component={AsyncPlaygroundPageContainer} />}

          {/* Other pages */}
          <Route exact path={routeList.landing.path} component={AsyncLandingPageContainer} />
          <Route exact path={routeList.signup.path} component={AsyncLandingPageContainer} />
          <Route exact path={routeList.loading.path} component={LoadingPage} />
          <Route exact path={routeList.errorWithCode.path} component={AsyncErrorPageContainer} />
          <Route exact path={routeList.error.path} component={AsyncErrorPageContainer} />

          <Route exact path={routeList.sandbox.path} component={AsyncSandboxPageContainer} />
          <Route exact path={routeList.unsubscribe.path} component={AsyncUnsubscribeMailPageContainer} />

          <Redirect from="*" to={routeList.callback.path} />
        </Switch>
      </div>
    );
  }
}

export default App;
