export const findActiveWorkspaceId = workspaces => {
  if (workspaces.length === 0) {
    return '';
  }
  let activeWorkspace = workspaces.find(workspace => workspace.active === true);
  if (activeWorkspace != null) {
    return activeWorkspace.workspace.id;
  }
  return workspaces[0].workspace.id;
};
