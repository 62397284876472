import {
  SA_EVENTS_FOCUS_COMPLETED,
  SA_EVENTS_FOCUS_NOTIFY,
  SA_EVENTS_FOCUS_TAB_OPEN_IF_CLOSED,
} from '../constants/sidekick-action-events-focus';

const initialState = {
  notifyStatus: '',
  sidekickAction: '',
  type: '',
  getReference: false,
};

export default function sidekickActionEventsFocus(state = initialState, action) {
  switch (action.type) {
    case SA_EVENTS_FOCUS_NOTIFY:
    case SA_EVENTS_FOCUS_TAB_OPEN_IF_CLOSED:
    case SA_EVENTS_FOCUS_COMPLETED:
      return {
        ...state,
        notifyStatus: action.type,
        sidekickAction: action.payload.sidekickAction,
        getReference: action.payload.getReference,
        type: action.payload.type,
      };
    default:
      return state;
  }
}
