import { connect } from 'react-redux';
import { OpenedTraceMaps } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export const OpenedTraceMapsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenedTraceMaps));
