import {
  UPDATE_USER_PROFILE_FULFILLED,
  UPDATE_USER_PROFILE_PENDING,
  UPDATE_USER_PROFILE_REJECTED,
  CHANGE_CURRENT_USER_INFORMATION_FULFILLED,
  CHANGE_CURRENT_USER_INFORMATION_PENDING,
  CHANGE_CURRENT_USER_INFORMATION_REJECTED,
} from 'store/constants/user-info';

const changeUserInformationInitialState = {
  updateUserInfo: {},
  updateUserInfoFetching: false,
  updateUserInfoFetched: false,
  updateUserInfoError: null,

  fetching: false,
  fetched: false,
  error: null,
};

export default function changeUserInformation(state = changeUserInformationInitialState, action) {
  switch (action.type) {
    case UPDATE_USER_PROFILE_PENDING:
      return {
        ...state,
        updateUserInfoFetching: true,
        updateUserInfoFetched: false,
        updateUserInfoError: null,
      };
    case UPDATE_USER_PROFILE_FULFILLED:
      console.log('user-info, reducer, UPDATE_USER_INFO_FULFILLED; payload: ', action.payload);
      return {
        ...state,
        updateUserInfoFetching: false,
        updateUserInfoFetched: true,
        updateUserInfoError: null,
        updateUserInfo: action.payload.data,
      };
    case UPDATE_USER_PROFILE_REJECTED:
      return {
        ...state,
        updateUserInfoFetching: false,
        updateUserInfoFetched: false,
        updateUserInfoError: action.payload,
      };
    case CHANGE_CURRENT_USER_INFORMATION_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case CHANGE_CURRENT_USER_INFORMATION_FULFILLED:
      console.log('user-info, reducer, CHANGE_CURRENT_USER_INFORMATION_FULFILLED; payload: ', action.payload);
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
      };
    case CHANGE_CURRENT_USER_INFORMATION_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
