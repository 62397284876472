import { connect } from 'react-redux';
import { AddUpdateRepoModal } from 'components';
import { withRouter } from 'react-router';
import {
  addSourceCache,
  flushSelectedGitSourceCache,
  getSourceCacheList,
  getUserRepositories,
  notifyIDEContextReset,
  selectGitSourceCache,
  updateSourceCache,
} from 'store/actions';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    gitRepoData: store.gitRepositories,
    gitBranchData: store.gitBranches,
    gitCommitData: store.gitCommits,

    sourceCache: store.sourceCache,
    sourceProviderAccount: store.sourceProviderAccount,
    activeWorkspace: store.activeWorkspace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectGitSourceCache(selectedSource) {
      dispatch(selectGitSourceCache(selectedSource));
    },
    flushSelectedGitSourceCache() {
      dispatch(flushSelectedGitSourceCache());
    },
    getSourceCacheList(workspaceId, onGetSourceCacheListSuccess, onGetSourceCacheListFail) {
      dispatchWithExceptionHandler(
        getSourceCacheList(workspaceId),
        false,
        onGetSourceCacheListSuccess,
        onGetSourceCacheListFail,
      );
    },
    addSourceCache(source, onAddSourceCacheSuccess, onAddSourceCacheFail) {
      console.log('AURMC, addSourceCache; source: ', source);
      dispatchWithExceptionHandler(addSourceCache(source), false, onAddSourceCacheSuccess, onAddSourceCacheFail);
    },
    updateSourceCache(sourceId, source, onUpdateSourceCacheSuccess, onUpdateSourceCacheFail) {
      console.log('AURMC, updateSourceCache; sourceId, source: ', sourceId, source);
      dispatchWithExceptionHandler(
        updateSourceCache(sourceId, source),
        false,
        onUpdateSourceCacheSuccess,
        onUpdateSourceCacheFail,
      );
    },

    getUserRepositories: (sourceProvider, getRepoSuccess, getRepoFail) =>
      dispatch(getUserRepositories(sourceProvider, getRepoSuccess, getRepoFail)),

    handleNotifyIDEContextReset(sourceProvider, repoOwner, repoName, branch, commit) {
      dispatch(notifyIDEContextReset(sourceProvider, repoOwner, repoName, branch, commit));
    },
  };
}

export const AddUpdateRepoModalContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUpdateRepoModal));
