import { connect } from 'react-redux';
import { SendToWebhookIcon } from 'components';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { sendToWebhook } from 'store/actions';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
  return {
    webhookList: store.webhookList,
    activeWorkspace: store.activeWorkspace,
  };
}

function mapDispatchToProps() {
  return {
    sendToWebhook(webhookIds, type, event, onSuccess, onError) {
      dispatchWithExceptionHandler(sendToWebhook(webhookIds, type, event), false, onSuccess, onError);
    },
  };
}

export const SendToWebhookIconContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SendToWebhookIcon));
