import { connect } from 'react-redux';
import { IntegrationsTab } from 'components';
import {
  createWebhook,
  deleteWebhook,
  getWebhooks,
  getWorkspace,
  setDefaultProbeWebhook,
  updateWebhook,
} from 'store/actions';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    webhookList: store.webhookList,
    activeWorkspace: store.activeWorkspace,
    createWebhookResult: store.createWebhook,
    deleteWebhookResult: store.deleteWebhook,
    updateWebhookResult: store.updateWebhook,
    setDefaultProbeWebhookResult: store.setDefaultProbeWebhook,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWorkspace(workspaceId, onGetSuccess) {
      dispatchWithExceptionHandler(getWorkspace(workspaceId), false, onGetSuccess);
    },
    getWebhooks(workspaceId) {
      dispatch(getWebhooks(workspaceId));
    },
    createIntegration(workspaceId, type, name, config, onSuccess, onError) {
      dispatchWithExceptionHandler(createWebhook(workspaceId, type, name, config), false, onSuccess, onError);
    },
    updateIntegration(workspaceId, type, name, config, id, onSuccess, onError) {
      dispatchWithExceptionHandler(updateWebhook(workspaceId, type, id, name, config), false, onSuccess, onError);
    },
    deleteIntegration(integrationId, onSuccess, onError) {
      dispatchWithExceptionHandler(deleteWebhook(integrationId), false, onSuccess, onError);
    },
    setDefaultProbeWebhook(workspaceId, probeType, integrationId, onSuccess, onError) {
      dispatchWithExceptionHandler(
        setDefaultProbeWebhook(workspaceId, probeType, integrationId),
        false,
        onSuccess,
        onError,
      );
    },
  };
}

export const IntegrationsTabContainer = connect(mapStateToProps, mapDispatchToProps)(IntegrationsTab);
