// import {pathToRegexp} from "path-to-regexp";
import pathToRegexp from 'path-to-regexp';

const routeList = {
  ide: {
    // This path is also used for github configs!!
    // Think before change!
    path: '/debug',
    title: 'Sidekick Debug',
  },
  sandbox: {
    path: '/sandbox',
    title: 'Sandbox',
  },
  eventHistory: {
    path: '/event-history',
    title: 'Event History',
  },
  settingsProfile: {
    path: '/settings/profile',
    title: 'Settings - Profile',
    tabTitle: 'Profile',
  },
  workspaces: {
    path: '/workspaces',
    title: 'Workspaces',
    tabTitle: 'Workspaces',
  },
  workspace: {
    path: '/workspace/:workspaceId',
    title: 'Workspace',
    tabTitle: 'Workspace',
  },
  pluginWelcome: {
    path: '/plugin',
    title: 'Welcome',
  },
  unsubscribe: {
    path: '/unsubscribe',
    title: 'Unsubscribe',
  },
  welcome: {
    path: '/welcome',
    title: 'Welcome to Sidekick!',
    tabTitle: 'Workspace Info',
  },
  playground: {
    path: '/playground',
  },
  landing: {
    path: '/landing',
  },
  signup: {
    path: '/signup',
  },
  callback: {
    path: '/callback',
  },
  loading: {
    path: '/loading',
  },
  errorWithCode: {
    path: '/error/:errorCode',
  },
  error: {
    path: '/error',
  },
};

export function shouldAddIsNewUserToUrl(path, isNewUser) {
  // `isNewUser` query param is used for marketing tracking.
  let redirectionURL = path;
  const isNewUserTimer = localStorage.getItem('isNewUserTimer');
  console.log('shouldAddIsNewUserToUrl; path, isNewUser, isNewUserTimer: ', path, isNewUser, isNewUserTimer);
  if (isNewUser || (isNewUserTimer && isNewUserTimer !== null)) {
    console.log('add isNewUser query param!');
    redirectionURL = path + '?isNewUser=true';
    // For webstarter we do not have any onboarding stage.
    // So in order to keep `isNewUser` param on the url,
    // we needed an external logic.
    localStorage.setItem('isNewUserTimer', true);
    setTimeout(() => {
      localStorage.removeItem('isNewUserTimer');
    }, 20000); // keep `isNewUser` query param at least 20secs.
  }

  return redirectionURL;
}

// This function is used to compute path strings which has dynamic part like `:functionName`.
// Given a pathString you need provide an object, params, which has a key for each dynamic part in the path.
export function computePath(pathString, params) {
  const parsedPathString = pathToRegexp.parse(pathString);
  let constructedPath = '';

  for (let i = 0; i < parsedPathString.length; i++) {
    let parsedPathPiece = parsedPathString[i];

    if (typeof parsedPathPiece == 'string') {
      constructedPath = constructedPath + parsedPathPiece;
    } else {
      let pathVarFromParams = params[parsedPathPiece.name];
      constructedPath = constructedPath + '/' + encodeURIComponent(pathVarFromParams);
    }
  }

  // console.log("computePath; parsedPathString, constructedPath: ", parsedPathString, constructedPath);
  return constructedPath;
}

// Compute which tab is selected for function details from the url
// export function computeFunctionDetailsTab(url) {
export function computeSelectedTabFromUrl(url) {
  const tabStringArr = url.split('/');
  const tabString = tabStringArr[tabStringArr.length - 1];

  return tabString;
}

// This function computes the selected sidebar based on the first part of the path
// defined inrouteList above.
export function computeSelectedMenuFromSidebar(url) {
  const urlStringArr = url.split('/');
  const sidebarString = urlStringArr[1]; // because 0 is '/' character

  return sidebarString;
}

export default routeList;
