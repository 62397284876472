import React, { Component } from 'react';
import { convertTimeToUnit, timeToValueUnitPair } from 'utils/time-unit-util';
import { CONFIG } from 'utils/config-util';
import { Button, Checkbox, Dropdown, Input } from 'semantic-ui-react';
import { LOG_LEVEL_OPTIONS } from 'utils/log-level-util';
import { AutocompleteInput } from '../AutocompleteInput/AutocompleteInput';

const timeUnitOptions = [
  {
    key: 'seconds',
    value: 'seconds',
    text: 'seconds',
  },
  {
    key: 'minutes',
    value: 'minutes',
    text: 'minutes',
  },
  {
    key: 'hours',
    value: 'hours',
    text: 'hours',
  },
];

export class EditSidekickActionPanel extends Component {
  constructor(props) {
    super(props);
    const expireTime = timeToValueUnitPair(
      this.props.sidekickActionToEdit ? this.props.sidekickActionToEdit.expireSecs : 1800,
    );

    const sidekickActionFields = {
      selectedSidekickActionToEdit: this.props.sidekickActionToEdit,
      selectedSidekickActionType: this.props?.sidekickActionType,
      sidekickActionCondition: this.props.sidekickActionToEdit?.conditionExpression,
      sidekickActionExpireCount: this.props.sidekickActionToEdit?.expireCount,
      sidekickActionExpireTime: this.props.sidekickActionToEdit?.expireSecs,
      sidekickActionDisabled: this.props.sidekickActionToEdit?.disabled,
      sidekickActionEnableTracing: this.props.sidekickActionToEdit?.tracingEnabled,
      sidekickActionExpireTimeUnit: expireTime.unit,
      sidekickActionExpireTimeValue: expireTime.value,
      sidekickActionLogLevel: this.props.sidekickActionToEdit?.logLevel,
      sidekickActionLogExpression: this.props.sidekickActionToEdit?.logExpression,
      sidekickActionIntegrations: this.props.sidekickActionToEdit?.webhookIds,
      sidekickActionStdOutEnabled: this.props.sidekickActionToEdit?.stdoutEnabled,
      sidekickActionPredefined: this.props.sidekickActionToEdit?.predefined,
      sidekickActionPredefinedName: this.props.sidekickActionToEdit?.probeName,
      sidekickActionTags: this.props.sidekickActionToEdit?.tags,
    };
    this.state = {
      ...sidekickActionFields,
      additionalTags: [],
    };
  }

  handleEnabledChange = () => {
    const { sidekickActionDisabled } = this.state;
    this.setState({
      sidekickActionDisabled: !sidekickActionDisabled,
    });
  };

  handlePredefinedChange = () => {
    const { sidekickActionPredefined } = this.state;
    this.setState({
      sidekickActionPredefined: !sidekickActionPredefined,
    });
  };

  handleTracingEnabledChange = () => {
    const { sidekickActionEnableTracing } = this.state;
    this.setState({
      sidekickActionEnableTracing: !sidekickActionEnableTracing,
    });
  };

  handleStdOutEnabledChange = () => {
    const { sidekickActionStdOutEnabled } = this.state;
    this.setState({
      sidekickActionStdOutEnabled: !sidekickActionStdOutEnabled,
    });
  };

  handleExpUnitChange = newUnit => {
    const { sidekickActionExpireTimeValue, sidekickActionExpireTimeUnit } = this.state;
    console.log(sidekickActionExpireTimeValue, sidekickActionExpireTimeUnit, newUnit);
    const newValue = convertTimeToUnit(sidekickActionExpireTimeValue, sidekickActionExpireTimeUnit, newUnit);
    this.setState({
      sidekickActionExpireTimeUnit: newUnit,
      sidekickActionExpireTimeValue: newValue,
    });
  };

  handleLogLevelChange = newLogLevel => {
    this.setState({
      sidekickActionLogLevel: newLogLevel,
    });
  };

  handleIntegrationChange = integrations => {
    this.setState({ sidekickActionIntegrations: integrations });
  };

  handleAddition = (e, { value }) => {
    this.setState(prevState => ({
      additionalTags: [{ tag: value }, ...prevState.additionalTags],
    }));
  };

  handleTagChange = tags => {
    this.setState({ sidekickActionTags: tags });
  };

  createIntegrationsOptions = () => {
    return this.props.webhookList.webhooks.map(webhook => {
      return { key: webhook.id, text: webhook.name, value: webhook.id };
    });
  };

  createTagOptions = () => {
    let tags = [...this.props.tags.saTags, ...this.state.additionalTags];
    return tags.map(tag => {
      return { key: tag.id, text: tag.tag, value: tag.tag };
    });
  };

  shouldRenderIntegrationsOptions = () => {
    const onPremEnabled = CONFIG?.ON_PREM;
    if (onPremEnabled) {
      return !CONFIG?.EXCLUDED_FEATURES.includes('webhook');
    }
    return true;
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  getEditSidekickActionPanel = () => {
    const {
      selectedSidekickActionToEdit,
      selectedSidekickActionType,
      sidekickActionCondition,
      sidekickActionExpireCount,
      sidekickActionDisabled,
      sidekickActionEnableTracing,
      sidekickActionExpireTimeUnit,
      sidekickActionExpireTimeValue,
      sidekickActionLogLevel,
      sidekickActionLogExpression,
      sidekickActionIntegrations,
      sidekickActionStdOutEnabled,
      sidekickActionPredefined,
      sidekickActionPredefinedName,
      sidekickActionTags,
    } = this.state;

    const handleEditDone = () => {
      const expireTime = convertTimeToUnit(sidekickActionExpireTimeValue, sidekickActionExpireTimeUnit, 'seconds');
      this.props.handleSubmit({
        ...selectedSidekickActionToEdit,
        expireCount: sidekickActionExpireCount,
        expireSecs: expireTime,
        conditionExpression: sidekickActionCondition,
        disabled: sidekickActionDisabled,
        tracingEnabled: sidekickActionEnableTracing,
        logExpression: sidekickActionLogExpression,
        logLevel: sidekickActionLogLevel,
        webhookIds: sidekickActionIntegrations,
        stdoutEnabled: sidekickActionStdOutEnabled,
        predefined: sidekickActionPredefined,
        probeName: sidekickActionPredefined ? sidekickActionPredefinedName : null,
        tags: sidekickActionPredefined ? sidekickActionTags : null,
      });
    };

    return (
      <div className={'ui inverted form trace-point-edit-form'}>
        <div className={'field'}>
          <label htmlFor={'condition'}>Condition</label>
          <AutocompleteInput
            key={'sidekickActionCondition'}
            placeholder={'e.g. firstName == “Franklin”'}
            value={sidekickActionCondition}
            onChange={(val, callback) =>
              this.setState(
                {
                  sidekickActionCondition: val,
                },
                callback,
              )
            }
            sugs={this.props.broker.variableSuggestions}
          />
        </div>
        {selectedSidekickActionType === 'logPoint' && (
          <div className={'field'}>
            <label htmlFor={'expression'}>Log Expression</label>
            <AutocompleteInput
              key={'log-expression'}
              placeholder={'Enter log Expression'}
              value={sidekickActionLogExpression}
              onChange={(val, callback) =>
                this.setState(
                  {
                    sidekickActionLogExpression: val,
                  },
                  callback,
                )
              }
              sugs={this.props.broker.variableSuggestions}
            />
          </div>
        )}

        <div className={'three fields'}>
          <div className={'field'}>
            <label htmlFor={'hit-limit'}>Hit Limit</label>
            <Input
              size={'small'}
              name={'sidekickActionExpireCount'}
              id={'hit-limit'}
              type={'number'}
              value={sidekickActionExpireCount}
              onChange={this.handleInputChange}
            />
          </div>
          <div className={'field'}>
            <label>Expiration Limit</label>
            <Input
              size={'small'}
              type={'number'}
              name={'sidekickActionExpireTimeValue'}
              value={sidekickActionExpireTimeValue}
              onChange={this.handleInputChange}
            />
          </div>
          <div className={'field'}>
            <label>&nbsp;</label>
            <Dropdown
              className={'time-unit-dropdown'}
              fluid
              selection
              options={timeUnitOptions}
              value={sidekickActionExpireTimeUnit}
              onChange={(e, data) => this.handleExpUnitChange(data.value)}
            />
          </div>
        </div>
        {selectedSidekickActionType === 'logPoint' && (
          <div className={'field'}>
            <label>Log Level</label>
            <Dropdown
              className={'time-unit-dropdown'}
              fluid
              selection
              options={LOG_LEVEL_OPTIONS}
              value={sidekickActionLogLevel}
              onChange={(e, data) => this.handleLogLevelChange(data.value)}
            />
          </div>
        )}
        {this.shouldRenderIntegrationsOptions() && (
          <div className={'field'}>
            <label>Webhook Integrations</label>
            <Dropdown
              className={'multiple-select-dropdown'}
              fluid
              selection
              multiple
              placeholder={'Select integrations'}
              options={this.createIntegrationsOptions()}
              value={sidekickActionIntegrations}
              onChange={(e, data) => this.handleIntegrationChange(data.value)}
            />
          </div>
        )}
        <div className={'field'}>
          <Checkbox label={'Enabled'} checked={!sidekickActionDisabled} onChange={this.handleEnabledChange} />
        </div>
        {selectedSidekickActionType === 'logPoint' && (
          <div className={'field'}>
            <Checkbox
              label={'StdOut enabled'}
              checked={sidekickActionStdOutEnabled}
              onChange={this.handleStdOutEnabledChange}
            />
          </div>
        )}
        {selectedSidekickActionType === 'tracePoint' && (
          <div className={'field'}>
            <Checkbox
              label={'Distributed Tracing Enabled'}
              checked={sidekickActionEnableTracing}
              onChange={this.handleTracingEnabledChange}
            />
          </div>
        )}
        <div className={'field'} style={{ marginBottom: '5px' }}>
          <Checkbox label={'Set as saved'} checked={sidekickActionPredefined} onChange={this.handlePredefinedChange} />
        </div>
        {sidekickActionPredefined && (
          <div className={'field'}>
            <label htmlFor={'predefinedName'}>
              Name<span style={{ color: 'crimson' }}>*</span>
            </label>
            <Input
              size={'small'}
              name={'sidekickActionPredefinedName'}
              id={'predefinedName'}
              value={sidekickActionPredefinedName}
              placeholder="my-action-name"
              onChange={this.handleInputChange}
            />
          </div>
        )}

        {sidekickActionPredefined && (
          <div className={'field'}>
            <label>
              Tags<span style={{ color: 'crimson' }}>*</span>
            </label>
            <Dropdown
              className={'multiple-select-dropdown tags'}
              fluid
              selection
              multiple
              allowAdditions
              search
              placeholder={'Select tags'}
              options={this.createTagOptions()}
              value={sidekickActionTags}
              onChange={(e, data) => this.handleTagChange(data.value)}
              onAddItem={this.handleAddition}
            />
          </div>
        )}
        <div className={'field'} style={{ textAlign: 'right' }}>
          <Button onClick={() => this.props.closeEditPanel()} basic content={'Cancel'} />

          <Button
            onClick={() => handleEditDone()}
            disabled={
              (sidekickActionPredefined && sidekickActionPredefinedName?.length === 0) ||
              sidekickActionTags?.length === 0
            }
            primary
            loading={this.props.loading}
            content={this.state.selectedSidekickActionToEdit.id ? 'Save' : 'Add'}
          />
        </div>
      </div>
    );
  };

  render() {
    return this.getEditSidekickActionPanel();
  }
}
