import { SOURCE_PROVIDER_PLATFORM } from "utils/source-provider-util";

export function getSourceProviderOAuthTokenSelector(store, sourceProvider) {
  console.log("selector, getSourceProviderOAuthTokenSelector; store: ", store);
  let gitSourceProviderToken = "";

  const { githubOauthToken, gitlabOauthToken } = store.sourceProviderAccount;
  const githubAccessToken = githubOauthToken.access_token;
  const gitlabAccessToken = gitlabOauthToken.access_token;

  if (githubAccessToken && sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    console.log("selector, getSourceProviderOAuthTokenSelector, if; githubAccessToken: ", githubAccessToken);
    gitSourceProviderToken = githubAccessToken;
  } else if (gitlabAccessToken && sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    console.log("selector, getSourceProviderOAuthTokenSelector, if; gitlabAccessToken: ", gitlabAccessToken);
    gitSourceProviderToken = gitlabAccessToken;
  } else {
    const { sourceAccountList } = store.sourceProviderAccount;
    console.log("selector, getSourceProviderOAuthTokenSelector, else; gitSourceProviderToken, sourceAccountList, sourceProvider: ", gitSourceProviderToken, sourceAccountList, sourceProvider);

    // Check for sourceProvider!!
    const sourceAccount = sourceAccountList.find(acc => acc.platform === sourceProvider);
    if (sourceAccountList.length > 0 && sourceAccount !== undefined) {
      gitSourceProviderToken = sourceAccount.authData;
    }
  }

  return gitSourceProviderToken;
}