import React, { Component } from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';

const WORKSPACE_INFO_STATE = {
  CREATE_WORKSPACE: 'CREATE_WORKSPACE',
  INVITED_USER: 'INVITED_USER',
};

class WelcomeWorkspaceInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workspaceInfoState: WORKSPACE_INFO_STATE.CREATE_WORKSPACE,
      workspaceName: '',
      loading: false,
    };
  }

  handleInputChange = e => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    this.setState({ [fieldName]: fieldValue });
  };

  renderWorkspaceInfo = () => {
    return (
      <Grid container centered>
        <GridRow className={'form-header-row'}>
          <GridColumn>
            <div className={'form-header'}>Create Workspace</div>
            <div className={'form-header-caption'}>
              You are not a member of any workspace. You can either check your mail box for your workspace invitations
              or create your own workspace now!
            </div>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn>
            <Form
              onSubmit={() => {
                this.props.onCreate(this.state.workspaceName);
              }}
            >
              <Form.Input
                autoComplete={'off'}
                required
                label={'Name'}
                name="workspaceName"
                controller={'input'}
                placeholder={'Workspace Name'}
                value={this.state.workspaceName}
                onChange={this.handleInputChange}
              />
              <Grid style={{ margin: 'unset' }}>
                <GridRow columns={'equal'}>
                  <div className={'form-action-row-workspace-info'}>
                    <span
                      className={'anchor-like button-step-info'}
                      onClick={() => {
                        this.setState({ workspaceInfoState: WORKSPACE_INFO_STATE.INVITED_USER });
                      }}
                    >
                      I have an invitation
                    </span>
                    <div className={'next-step'}>
                      <Form.Button className={'primary'} content={'Create'} loading={this.props.loading} />
                    </div>
                  </div>
                </GridRow>
              </Grid>
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };

  renderInvitedUser = () => {
    return (
      <Grid container>
        <GridRow className={'form-header-row'}>
          <div className={'form-header'}>Invited workspace link</div>
          <div className={'form-header-caption'}>
            Please check your inbox and click the link that we've sent to you. You can either check your mail box for
            your invitations or;
          </div>
        </GridRow>
        <GridRow>
          <GridColumn style={{ paddingLeft: '0' }}>
            <span
              className={'anchor-like button-step-info'}
              onClick={() => {
                this.setState({ workspaceInfoState: WORKSPACE_INFO_STATE.CREATE_WORKSPACE });
              }}
            >
              Create your own workspace
            </span>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };

  renderContent() {
    if (this.state.workspaceInfoState === WORKSPACE_INFO_STATE.CREATE_WORKSPACE) {
      return this.renderWorkspaceInfo();
    } else if (this.state.workspaceInfoState === WORKSPACE_INFO_STATE.INVITED_USER) {
      return this.renderInvitedUser();
    }

    return null;
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}

export default WelcomeWorkspaceInfo;
