import {
  ERROR_SNAPSHOTS_CLEAR,
  FLUSH_REFERENCE_EVENT,
  LP_ADD_WAITING,
  LP_EVENTS_CLEAR,
  PD_LP_EVENTS_CLEAR,
  PD_TP_EVENTS_CLEAR,
  SA_CHECK_WAITING,
  SET_EVENT_BEING_PROCESSED,
  TP_ADD_WAITING,
  TP_EVENTS_CLEAR,
} from 'store/constants/broker';

export function clearTracePointEvents(contextMetaData) {
  return {
    type: TP_EVENTS_CLEAR,
    payload: { contextMetaData: contextMetaData },
  };
}

export function clearPredefinedTracePointEvents(contextMetaData) {
  return {
    type: PD_TP_EVENTS_CLEAR,
    payload: { contextMetaData: contextMetaData },
  };
}

export function clearLogPointEvents(contextMetaData) {
  return {
    type: LP_EVENTS_CLEAR,
    payload: { contextMetaData: contextMetaData },
  };
}

export function clearPredefinedLogPointEvents(contextMetaData) {
  return {
    type: PD_LP_EVENTS_CLEAR,
    payload: { contextMetaData: contextMetaData },
  };
}

export function clearErrorSnapshots(contextMetaData) {
  return {
    type: ERROR_SNAPSHOTS_CLEAR,
    payload: { contextMetaData: contextMetaData },
  };
}

export function flushReferenceEvent() {
  return {
    type: FLUSH_REFERENCE_EVENT,
  };
}

export function addWaitingToSidekickAction(data) {
  const cloneData = { ...data };
  cloneData.actionTime = new Date().getTime();
  const idField = data.probeType.toLowerCase() === 'tracepoint' ? 'tracePointId' : 'logPointId';
  const actionType = data.probeType.toLowerCase() === 'tracepoint' ? TP_ADD_WAITING : LP_ADD_WAITING;
  if (typeof data[idField] === 'string') {
    //Convert Id to FileName and LineNo
    const parts = data[idField].split('::');
    cloneData.fileName = parts[0];
    cloneData.lineNo = parts[1];
  }
  return {
    type: actionType,
    payload: { ...cloneData },
  };
}

export function checkWaitingTimeout4SidekickAction() {
  return {
    type: SA_CHECK_WAITING,
    payload: {},
  };
}

export function setEventBeingProcessed(event) {
  return {
    type: SET_EVENT_BEING_PROCESSED,
    payload: event,
  };
}
