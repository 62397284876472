import React, { Component } from 'react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import { startCase } from 'lodash';

const MAX_VALUES = {
  maxFrames: 20,
  maxExpandFrames: 5,
  maxProperties: 50,
  maxParseDepth: 6,
};

export class AppEnvSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxFrames: this.props.app.config?.maxFrames || 10,
      maxExpandFrames: this.props.app.config?.maxExpandFrames || 10,
      maxProperties: this.props.app.config?.maxProperties || 50,
      maxParseDepth: this.props.app.config?.maxParseDepth || 3,
      errorCollectionEnable: this.props.app.config?.errorCollectionEnable || false,
      errorCollectionEnableCaptureFrame: this.props.app.config?.errorCollectionEnableCaptureFrame || false,
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: +event.target.value });
  };

  renderSettings = () => {
    return (
      <div className={'settings-container'}>
        {Object.entries(MAX_VALUES).map(([key, value]) => {
          return (
            <div className={'settings-row'}>
              <div className={'label'}>{`Set ${key}`}</div>
              <div className={'value'}>
                <input
                  type={'range'}
                  value={this.state[key]}
                  onChange={this.handleChange}
                  min={0}
                  max={value}
                  name={key}
                  step={'1'}
                />
                <span>{this.state[key]}</span>
              </div>
            </div>
          );
        })}
        {['errorCollectionEnable', 'errorCollectionEnableCaptureFrame'].map(field => (
          <div className={'settings-row'}>
            <div className={'label'}>{startCase(field)}* </div>
            <Checkbox
              toggle
              checked={this.state[field]}
              onChange={() => this.setState({ [field]: !this.state[field] })}
            />
          </div>
        ))}
        <div className={'caution'}>*Be aware of overhead</div>
      </div>
    );
  };

  render() {
    const { app, onClose } = this.props;
    return (
      <Modal className={'app-env-settings-modal'} open={true} onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Header>{app.name}</Modal.Header>
        <Modal.Content>{this.renderSettings()}</Modal.Content>
        <Modal.Actions>
          <Button
            className={'secondary button-step-info'}
            content={'Cancel'}
            onClick={() => this.props.onClose(false)}
          />
          <Button
            className={'secondary button-step-info'}
            content={'Reset to default'}
            onClick={() => this.props.onSaveConfig(null)}
          />
          <Button
            loading={this.props?.loading || false}
            content={'Confirm'}
            onClick={() => this.props.onSaveConfig(this.state)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
