import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { SourceGithubAccountListContainer, SourceGitlabAccountListContainer } from 'containers';

import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';

import './SourceAccountList.scss';
import URL_POOL from 'utils/url-pool';

export default class SourceAccountList extends Component {
  handleNotifyMeClick = () => {
    toast.success('We will notify when we start to support other source code management tools.', ToastConfig);
  };

  renderLocalEnvironmentSection = () => {
    return (
      <div className={'local-environment'}>
        <div className={'title'}>
          Working on
          <br /> local environment?
        </div>
        <span className={'small-text'}>
          Are you looking for local
          <br />
          folders or any other
          <br />
          provider?
        </span>
        <span className={'small-text'}>
          Check out how you can use our
          <a href={URL_POOL.SIDEKICK_INTELLIJ_PLUGIN_DOC} target={'_blank'} rel="noopener noreferrer">
            <br />
            IntelliJ IDEA
          </a>
          ,{' '}
          <a href={URL_POOL.SIDEKICK_VSCODE_PLUGIN_DOC} target={'_blank'} rel="noopener noreferrer">
            Visual Studio Code{' '}
          </a>
          <br />&
          <a href={URL_POOL.SIDEKICK_PYCHARM_PLUGIN_DOC} target={'_blank'} rel="noopener noreferrer">
            <br />
            Pycharm
          </a>
          <br />
          IDE Extensions.
        </span>
      </div>
    );
  };

  renderExperiencingIssueSection = () => {
    return (
      <div className={'local-environment'}>
        <div className={'title'}>Experiencing Issues?</div>
        <span className={'small-text'}>
          Visit our{' '}
          <a href={URL_POOL.SIDEKICK_IMPORT_CODE_DOC} target={'_blank'} rel="noopener noreferrer">
            docs
          </a>{' '}
          or reach{' '}
          <a href={URL_POOL.SIDEKICK_SUPPORT_MAIL} target={'_blank'} rel="noopener noreferrer">
            us.
          </a>
        </span>
      </div>
    );
  };

  render() {
    console.log('SourceAccountList, render; props, state: ', this.props, this.state);

    return (
      <div className="source-account-list-wrapper">
        {this.props.loading && (
          <div className="repository-management-loading-layer">
            <Icon className="icon-thundra-loading" />
          </div>
        )}
        <SourceGithubAccountListContainer />

        <SourceGitlabAccountListContainer />

        <div className="source-provider-repos-wrapper">
          <div className="source-account-wrapper">
            <div className="source-account-name">
              <Icon name="bitbucket" />
              Bitbucket
            </div>
            <Button primary className="thin-button" id="bitbucket-notify-button" onClick={this.handleNotifyMeClick}>
              Notify Me!
            </Button>
          </div>
        </div>

        {this.renderLocalEnvironmentSection()}
        {this.renderExperiencingIssueSection()}
      </div>
    );
  }
}
