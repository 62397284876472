import {
  CREATE_WEBHOOK_FULFILLED,
  CREATE_WEBHOOK_PENDING,
  CREATE_WEBHOOK_REJECTED,
} from 'store/constants/webhook-create';

const createWebhookInitialState = {
  createdWebhook: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function createWebhook(state = createWebhookInitialState, action) {
  switch (action.type) {
    case CREATE_WEBHOOK_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case CREATE_WEBHOOK_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        createdWebhook: action.payload.data,
      };
    case CREATE_WEBHOOK_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
