export const START_ONBOARDING = 'START_ONBOARDING';
export const START_ONBOARDING_COMPLETED = 'START_ONBOARDING_COMPLETED';

export const CHANGE_SANDBOX_MODE = 'CHANGE_SANDBOX_MODE';

export const GET_DEMO_ACCOUNT = 'GET_DEMO_ACCOUNT';
export const GET_DEMO_ACCOUNT_PENDING = 'GET_DEMO_ACCOUNT_PENDING';
export const GET_DEMO_ACCOUNT_FULFILLED = 'GET_DEMO_ACCOUNT_FULFILLED';
export const GET_DEMO_ACCOUNT_REJECTED = 'GET_DEMO_ACCOUNT_REJECTED';

export const TOGGLE_SANDBOX_STEP = 'TOGGLE_SANDBOX_STEP';

export const CLEAR_SANDBOX_STEPS = 'CLEAR_SANDBOX_STEPS';
