import { connect } from 'react-redux';
import { IssueManagement } from 'components';
import { withRouter } from 'react-router-dom';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import {
  createJiraIssue,
  createJiraIssueComment,
  flushEventToSend,
  flushJiraActionStates,
  getJiraAccessibleResources,
  getJiraAccessToken,
  getJiraIssueTypes,
  getJiraProjects,
  getLinearAccessToken,
} from 'store/actions';

function mapStateToProps(store) {
  return {
    issueManagementToken: store.issueManagementToken,
    issueManagementJira: store.issueManagementJira,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLinearAccessToken(code, onSuccess, onError) {
      dispatchWithExceptionHandler(getLinearAccessToken(code), false, onSuccess, onError);
    },
    getJiraAccessToken(code, onSuccess, onError) {
      dispatchWithExceptionHandler(getJiraAccessToken(code), false, onSuccess, onError);
    },
    getJiraOrganisations(token, onSuccess, onError) {
      dispatchWithExceptionHandler(getJiraAccessibleResources(token), false, onSuccess, onError);
    },
    getJiraProjects(orgId, onSuccess, onError) {
      dispatchWithExceptionHandler(getJiraProjects(orgId), false, onSuccess, onError);
    },
    getJiraIssueTypes(projectId, orgId, onSuccess, onError) {
      dispatchWithExceptionHandler(getJiraIssueTypes(projectId, orgId), false, onSuccess, onError);
    },
    createJiraIssue(orgId, issueData, onSuccess, onError) {
      dispatchWithExceptionHandler(createJiraIssue(orgId, issueData), false, onSuccess, onError);
    },
    createJiraIssueComment(orgId, issueId, commentData, onSuccess, onError) {
      dispatchWithExceptionHandler(createJiraIssueComment(orgId, issueId, commentData), false, onSuccess, onError);
    },
    flushEventToSend() {
      dispatch(flushEventToSend());
    },
    flushJiraActionStates() {
      dispatch(flushJiraActionStates());
    },
  };
}

export const IssueManagementContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(IssueManagement));
