import React, { Component } from 'react';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { BILLING_SUBSCRIPTION_CAPABILITY } from 'utils/subscription-capability';

class ActivePaymentMethod extends Component {
  render() {
    const { billingCustomer } = this.props.billingCustomer;

    return (
      <Grid className={'active-payment-method-main-grid'}>
        <GridRow>
          <GridColumn>
            <div className={'active-payment-header'}>Payment Method</div>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn>
            {billingCustomer.sourceExists ? (
              <div className={'card-info'}>
                <div className={'card-info-row'}>**** {billingCustomer.last4}</div>
                <div className={'card-info-row ellipsis-row'}>{billingCustomer.name}</div>
                <div className={'card-info-row ellipsis-row'}>{billingCustomer.addressLine1}</div>
                <div className={'card-info-row ellipsis-row'}>
                  {billingCustomer.addressCity} / {billingCustomer.addressState} / {billingCustomer.addressZip}
                </div>
              </div>
            ) : null}
          </GridColumn>
        </GridRow>
        {BILLING_SUBSCRIPTION_CAPABILITY &&
          <GridRow className={'change-payment'}>
            <GridColumn>
              <div>
                <span className={'anchor-like'} onClick={this.props.changePaymentMethod}>
                  {billingCustomer.sourceExists ? 'Change payment method' : 'Add Card'}
                </span>
              </div>
            </GridColumn>
          </GridRow>}
      </Grid>
    );
  }
}

export default ActivePaymentMethod;
