import {
  APPS_AND_SOURCES,
  COLLAPSED,
  NOTIFY_APPS_AND_REPOS_STATE_CHANGE,
  NOTIFY_APPS_AND_REPOS_STATE_CHANGE_COMPLETED,
  NOTIFY_COLLAPSE_APPS_AND_REPOS,
  NOTIFY_COLLAPSE_APPS_AND_REPOS_COMPLETED,
} from '../constants/ide-apps-and-repositories-management';

const initialState = {
  notifyStatus: '',
  currentState: APPS_AND_SOURCES,
};

export default function ideAppsAndRepositoriesManagement(state = initialState, action) {
  switch (action.type) {
    case NOTIFY_APPS_AND_REPOS_STATE_CHANGE:
      return {
        ...state,
        notifyStatus: action.type,
        currentState: action.payload,
      };
    case NOTIFY_APPS_AND_REPOS_STATE_CHANGE_COMPLETED:
    case NOTIFY_COLLAPSE_APPS_AND_REPOS_COMPLETED:
      return {
        ...state,
        notifyStatus: action.type,
      };
    case NOTIFY_COLLAPSE_APPS_AND_REPOS:
      return {
        ...state,
        notifyStatus: action.type,
        currentState: COLLAPSED,
      };
    default:
      return state;
  }
}
