import React, { Component } from 'react';
import {
  Button,
  Grid,
  GridRow,
  Icon,
  Popup,
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import { MainLayout } from 'layouts';
import routeList from 'routes';
import './WorkspacesPage.scss';
import { capitalize } from 'lodash';
import { changeActiveWorkspaceCmdObj } from 'utils/broker-commands';
import { generateUUID } from 'utils/object-util';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import { SidekickConfirmModal } from 'components';
import { findActiveWorkspaceId } from 'utils/workspace-util';
import { CreateWorkspaceModal } from './CreateWorkspaceModal';

function renderOwnerIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0V0C9.31371 0 12 2.68629 12 6V6C12 9.31371 9.31371 12 6 12V12C2.68629 12 0 9.31371 0 6V6Z"
        fill="#2CC356"
      />
      <path
        d="M10 4.75002C10 6.2688 8.76878 7.5 7.25 7.5C7.07469 7.5 6.90328 7.48341 6.73708 7.45205L6.36189 7.87414C6.32671 7.91372 6.28354 7.9454 6.23523 7.96709C6.18692 7.98879 6.13457 8 6.08161 8H5.5V8.625C5.5 8.83211 5.33211 9 5.125 9H4.5V9.625C4.5 9.83211 4.33211 10 4.125 10H2.375C2.16789 10 2 9.83211 2 9.625V8.40533C2 8.30588 2.03952 8.21048 2.10983 8.14016L4.63798 5.612C4.54856 5.34084 4.5 5.05111 4.5 4.75C4.5 3.23122 5.7312 2.00002 7.24998 2C8.77325 1.99998 10 3.22673 10 4.75002ZM7.25 4C7.25 4.41422 7.58578 4.75 8 4.75C8.41422 4.75 8.75 4.41422 8.75 4C8.75 3.58578 8.41422 3.25 8 3.25C7.58578 3.25 7.25 3.58578 7.25 4Z"
        fill="#232323"
      />
    </svg>
  );
}

class WorkspacesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createWsOpen: false,
      confirmModalOpen: false,
      confirmModalAction: undefined,
      confirmModalContent: '',
      confirmModalLoading: false,
      activePopupId: '',
    };
  }

  findActiveWorkspaceId = workspaces => {
    if (workspaces.length === 0) {
      return '';
    }
    let activeWorkspace = workspaces.find(workspace => workspace.active === true);
    if (activeWorkspace != null) {
      return activeWorkspace.workspace.id;
    }
    return workspaces[0].workspace.id;
  };

  getWorkspacesAndSetActive = action => {
    this.props.getWorkspaces(response => {
      this.props.getActiveWorkspace(findActiveWorkspaceId(response.value.data.workspaceDtos));
      toast.success(`You successfully ${action} workspace`, ToastConfig);
      this.closeModal();
    });
  };

  closeModal = () => {
    this.setState({
      confirmModalOpen: false,
      confirmModalAction: null,
      confirmModalContent: '',
      confirmModalLoading: false,
    });
  };

  renderActionItems = workspace => {
    let menuItems = [];
    !workspace.active &&
      menuItems.push(
        <div
          key={'switch_to_ws'}
          className={'popup-menu-item'}
          onClick={() => {
            this.setState({
              confirmModalOpen: true,
              confirmModalAction: () => {
                this.setState({ confirmModalLoading: true });
                const { selectedGitSourceCache } = this.props.sourceCache;
                if (selectedGitSourceCache) {
                  const { platform, owner, repository, branch, commit } = selectedGitSourceCache;
                  // This is called to reset opened source files from IDE model.
                  this.props.handleNotifyIDEContextReset(platform, owner, repository, branch, commit);
                }
                let changeActiveWorkspaceMsg = { ...changeActiveWorkspaceCmdObj };
                changeActiveWorkspaceMsg.workspaceId = workspace?.workspace?.id;
                changeActiveWorkspaceMsg.id = generateUUID();
                this.props.changeActiveWorkspace(changeActiveWorkspaceMsg);
                setTimeout(() => {
                  this.closeModal();
                }, 1000);
              },
              confirmModalContent: 'Do you want to switch workspace?',
            });
          }}
        >
          Switch to workspace
        </div>,
      );

    workspace.role !== 'OWNER' &&
      menuItems.push(
        <div
          key={'leave_ws'}
          className={'popup-menu-item'}
          onClick={() => {
            this.setState({
              confirmModalOpen: true,
              confirmModalAction: () => {
                this.setState({ confirmModalLoading: true });
                this.props.leaveWorkspace(
                  workspace?.workspace?.id,
                  () => {
                    this.getWorkspacesAndSetActive('left');
                  },
                  () => {
                    toast.error('An error occurred while leaving workspace.', ToastConfig);
                    this.closeModal();
                  },
                );
              },
              confirmModalContent: 'Do you want to leave the workspace?',
            });
          }}
        >
          Leave workspace
        </div>,
      );

    //todo delete will be implemented later
    // workspace.role === 'OWNER' &&
    //   !workspace.hasSubscription &&
    //   menuItems.push(
    //     <div
    //       key={'delete_ws'}
    //       className={'popup-menu-item'}
    //       onClick={() => {
    //         this.setState({
    //           confirmModalOpen: true,
    //           confirmModalAction: () => {
    //             this.setState({ confirmModalLoading: true });
    //             this.props.deleteWorkspace(
    //               workspace?.workspace?.id,
    //               () => {
    //                 this.getWorkspacesAndSetActive('deleted');
    //               },
    //               () => {
    //                 toast.error('An error occurred while leaving workspace.', ToastConfig);
    //                 this.closeModal();
    //               },
    //             );
    //           },
    //           confirmModalContent: 'Do you want to delete workspace?',
    //         });
    //       }}
    //     >
    //       Delete workspace
    //     </div>,
    //   );

    return menuItems;
  };

  renderActionPlaceholder = workspaceArg => {
    const workspace = workspaceArg?.workspace;
    const actionItems = this.renderActionItems(workspaceArg);
    if (actionItems.length === 0) {
      return null;
    }

    return (
      <Popup
        onOpen={() => this.setState({ activePopupId: workspace?.id })}
        onClose={() => this.setState({ activePopupId: '' })}
        className={'popup-menu'}
        basic
        position={'bottom center'}
        key={workspace?.id + '_popup'}
        content={actionItems}
        on={'click'}
        trigger={
          <Icon
            style={{ marginLeft: 'auto' }}
            className={'options interactive-icon' + (workspace.id === this.state.activePopupId ? ' active' : '')}
            size={'large'}
          />
        }
      />
    );
  };

  renderWorkspaceRow = workspace => {
    const workspaceName = workspace?.workspace?.workspaceName;
    const active = workspace?.workspace?.id === this.props.workspaceList?.activeWorkspaceId;
    const workspaceRole = workspace?.role;
    return (
      <Table.Row key={workspace?.workspace?.id}>
        <TableCell style={{ paddingRight: 0 }}>
          {active && <Icon className={'home interactive-icon'} size={'small'} />}
        </TableCell>
        <Table.Cell>
          <div className={'multi-info-cell'}>
            {this.renderNameAvatar(workspaceName)}
            {workspaceName}
            {workspaceRole === 'OWNER' && renderOwnerIcon()}
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className={'action-cell'}>
            {capitalize(workspaceRole)}
            {this.renderActionPlaceholder(workspace)}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  renderWorkspaceRows = () => {
    let workspaces = this.props.workspaceList.workspaces;
    return <TableBody>{workspaces.map(workspace => this.renderWorkspaceRow(workspace))}</TableBody>;
  };

  renderHeader = () => {
    const { fullName } = this.props.userAccount?.userAccount;
    let firstName = '';
    if (fullName != null) {
      let nameTokens = fullName.split(' ');
      firstName = nameTokens.length && capitalize(nameTokens[0]);
    }
    return (
      <div className={'workspaces-page-header-container'}>
        <div className={'workspaces-page-header'}>My Workspaces</div>
        <div className={'header-caption'}>
          Hi {firstName}, here are your workspaces. You can see your role in your workspaces and manage your workspace.
        </div>
      </div>
    );
  };

  renderNameAvatar = workspaceName => {
    let avatar = 'W';
    if (workspaceName.length > 1) {
      avatar = workspaceName.substring(0, 2).toUpperCase();
    } else {
      avatar = workspaceName.charAt(0);
    }
    return <div className={'name-avatar'}>{avatar}</div>;
  };

  onCreateWsClose = reload => {
    this.setState({ createWsOpen: false });
    reload && this.getWorkspacesAndSetActive('added');
  };

  render() {
    return (
      <MainLayout title={routeList.workspaces.title}>
        <div className="workspaces-page-wrapper">
          <Grid className="workspaces-page-main-grid" centered container>
            <GridRow className={'header-row'}>{this.renderHeader()}</GridRow>
            <GridRow className={'actions-row'}>
              <Button
                className={'small-button invite-users-button primary'}
                onClick={() => this.setState({ createWsOpen: true })}
              >
                + Add new workspace
              </Button>
            </GridRow>
            <GridRow>
              <Table celled className={'settings-table'}>
                <Table.Header>
                  <TableRow>
                    <TableHeaderCell style={{ width: '1%' }} />
                    <TableHeaderCell style={{ width: '400px' }}>Workspace name</TableHeaderCell>
                    <TableHeaderCell>My role</TableHeaderCell>
                  </TableRow>
                </Table.Header>
                {this.renderWorkspaceRows()}
              </Table>
            </GridRow>
          </Grid>
        </div>
        <SidekickConfirmModal
          modalOpen={this.state.confirmModalOpen}
          onClose={this.closeModal}
          content={this.state.confirmModalContent}
          confirmModalAction={this.state.confirmModalAction}
          loading={this.state.confirmModalLoading}
        />
        <CreateWorkspaceModal
          open={this.state.createWsOpen}
          onClose={this.onCreateWsClose}
          createWs={this.props.createWorkspace}
          loading={this.props.createWorkspaceInfo.fetching}
        />
      </MainLayout>
    );
  }
}

export default WorkspacesPage;
