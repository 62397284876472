import React, { Component } from 'react';
import { EventsFilter } from '../TracepointEvents/EventsFilter/EventsFilter';
import { SA_EVENTS_FOCUS_TAB_OPEN_IF_CLOSED } from 'store/constants/sidekick-action-events-focus';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import './LogPointEvents.scss';
import { generateUUID } from 'utils/object-util';
import { LogTable } from './LogTable';
import { abbrFileName } from 'utils/string-util';
import { findSidekickActionOfEvent, GET_REFERENCE_PURPOSE, processSidekickActionId } from 'utils/sidekick-action-utils';
import { DiffViewerModal } from '../DiffViewerModal/DiffViewerModal';
import { getReferenceEventRequestCmdObj } from 'utils/broker-commands';
import { renderNonEvents } from '../TracepointEvents/TracePointEvents';
import { EventsPanelExpander } from '../EventsPanelExpander/EventsPanelExpander';

export class LogPointEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientFilter: '',
      predefinedFilter: 'all',
      getReferencePurpose: GET_REFERENCE_PURPOSE.IDLE,
      compareEvents: [],
      modalOpen: false,
      tabStyle: null,
      getReferenceMessageId: '',
    };
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    const { notifyStatus, sidekickAction, getReference, type } = this.props.sidekickActionEventsFocus;
    const { referenceEvent } = this.props.broker;

    if (referenceEvent && referenceEvent.requestId === this.state.getReferenceMessageId) {
      if (this.state.getReferencePurpose === GET_REFERENCE_PURPOSE.COMPARE) {
        this.handleCompareIconClicked(referenceEvent.event);
        this.setState({
          modalOpen: true,
        });
      } else if (this.state.getReferencePurpose === GET_REFERENCE_PURPOSE.HIGHLIGHT) {
      }
      this.setState({ getReferencePurpose: GET_REFERENCE_PURPOSE.IDLE });
      this.props.flushReferenceEvent();
      return;
    }

    //event focus
    if (notifyStatus === SA_EVENTS_FOCUS_TAB_OPEN_IF_CLOSED && type === 'logPoint') {
      if (!sidekickAction) {
        this.props.handleSidekickActionFocusCompleted(null, null, null);
        return;
      }
      if (sidekickAction.predefined) {
        if (getReference) {
          const { predefinedLogPointEvents } = this.props.broker;
          const referenceIndex = predefinedLogPointEvents.findIndex(
            event => event.referenceEvent && processSidekickActionId(event.logPointId) === sidekickAction.id,
          );
          //reference already fetched
          if (referenceIndex > -1) {
            this.setState({
              predefinedFilter: sidekickAction.id,
            });
          } else {
            this.getReferenceEvent(sidekickAction, GET_REFERENCE_PURPOSE.HIGHLIGHT);
          }
        } else {
          this.setState({ predefinedFilter: sidekickAction.id });
        }
        this.props.handleSidekickActionFocusCompleted(null, null, null);
      } else {
        const { fileName, lineNo } = sidekickAction;
        const moduleNames = abbrFileName(fileName).split('/');
        const newClientFilter = `${moduleNames[moduleNames.length - 1]}:${lineNo}`;
        this.setState({ clientFilter: newClientFilter });
        this.props.handleSidekickActionFocusCompleted(null, null, null);
      }
    }
  }

  clearEvents = () => {
    this.props.handleLogPointEventClear();
    this.setState({ clientFilter: '' });
  };

  getReferenceEvent = (lpOfEvent, purpose) => {
    const requestId = generateUUID();
    this.setState({ getReferenceMessageId: requestId, getReferencePurpose: purpose }, () => {
      let getReferenceEventCmdObj = { ...getReferenceEventRequestCmdObj };
      if (purpose === GET_REFERENCE_PURPOSE.COMPARE) {
        const application = this.props.broker.appData.find(app => app.logPoints.find(lp => lp.id === lpOfEvent.id));
        if (application) {
          const { stage, version, name } = application;
          getReferenceEventCmdObj.applicationFilter = { stage, version, name };
        }
      }
      getReferenceEventCmdObj.probeId = lpOfEvent.id;
      getReferenceEventCmdObj.probeType = 'LOGPOINT';
      getReferenceEventCmdObj.id = requestId;
      this.props.handleBrokerSend(getReferenceEventCmdObj);
    });
  };

  getFilteredEvents = () => {
    const { logPointEvents } = this.props.broker;
    const { clientFilter } = this.state;
    if (clientFilter) {
      return logPointEvents.filter(el => {
        const { time, applicationName, traceId, lineNo } = el;
        const moduleName = abbrFileName(el.fileName);
        const moduleNameAndLineNo = moduleName + ':' + lineNo;
        const fullLine = `${time} ${applicationName} ${moduleNameAndLineNo} ${traceId}`;
        return fullLine.includes(clientFilter);
      });
    } else {
      return [...logPointEvents];
    }
  };

  handlePutReference = (putReferenceEventRequest, lpEvent) => {
    const requestId = generateUUID();
    this.props.setEventBeingProcessed({ requestId: requestId, event: lpEvent });
    this.props.handleBrokerSend({ ...putReferenceEventRequest, id: requestId });
  };

  handleRemoveReference = (removeReferenceEventRequest, lpEvent) => {
    const requestId = generateUUID();
    this.props.setEventBeingProcessed({ requestId: requestId, event: lpEvent });
    this.props.handleBrokerSend({ ...removeReferenceEventRequest, id: requestId });
  };

  handleCompare = () => {
    const { compareEvents } = this.state;
    if (compareEvents.length === 2) {
      this.setState({ modalOpen: true });
      return;
    }
    //one event selected, find its reference event
    const { predefinedLogPointEvents, logPoints } = this.props.broker;
    const tpOfEvent = findSidekickActionOfEvent(compareEvents[0].logPointId, logPoints);
    for (const event of predefinedLogPointEvents) {
      if (event.referenceEvent && processSidekickActionId(event.logPointId) === tpOfEvent.id) {
        this.handleCompareIconClicked(event);
        this.setState({ modalOpen: true });
        return;
      }
    }
    //reference not found, get reference event
    this.getReferenceEvent(tpOfEvent, GET_REFERENCE_PURPOSE.COMPARE);
  };

  getCompareButton = () => {
    const { compareEvents } = this.state;
    let disabled = true;
    let content = 'Compare';
    if (compareEvents.length === 2) {
      disabled = false;
    } else if (compareEvents.length === 1 && compareEvents[0].predefinedEvent && !compareEvents[0].referenceEvent) {
      disabled = false;
      content = 'Compare with reference';
    }

    return <Button disabled={disabled} className={'compare-button'} content={content} onClick={this.handleCompare} />;
  };

  handleCompareIconClicked = event => {
    const { compareEvents } = this.state;
    const eventIndex = compareEvents.findIndex(el => el.id === event.id);
    //remove from compare list
    if (eventIndex > -1) {
      compareEvents.splice(eventIndex, 1);
      this.setState({ compareEvents });
      return;
    } //add to compare list
    else if (compareEvents.length > 1) {
      compareEvents.pop();
    }
    compareEvents.push(event);
    this.setState({ compareEvents });
  };

  renderCompareEventsSectionHeader = () => {
    return (
      <div className={'header-group'}>
        <div className={'header'}>compare events</div>
        <Icon className="icon-tp-events-trash clickable" onClick={() => this.setState({ compareEvents: [] })} />
        {this.getCompareButton()}
      </div>
    );
  };

  renderCompareEventsSection = () => {
    const { compareEvents } = this.state;
    const { logPoints } = this.props.broker;
    return (
      <div className={'compare-events-section'}>
        {this.renderCompareEventsSectionHeader()}
        <LogTable
          handlePutReference={this.handlePutReference}
          handleRemoveReference={this.handleRemoveReference}
          handleCompareReference={this.handleCompareIconClicked}
          compareEvents={compareEvents}
          logPointEvents={compareEvents}
          logPoints={logPoints}
        />
      </div>
    );
  };

  clearPredefinedEvents = () => {
    this.props.handlePredefinedLogPointEventClear();
    this.setState({ predefinedFilter: 'all' });
  };

  renderSavedEventsSectionHeader = (predefinedOptions, eventCount) => {
    return (
      <div className={'header-group'}>
        <div className={'header'}>saved logpoint events</div>
        <div className={'event-count-label table-text'}> {`${eventCount} Events`}</div>
        <Icon className="icon-tp-events-trash" onClick={this.clearPredefinedEvents} />
        <Dropdown
          className={'predefined-tps-dropdown'}
          value={this.state.predefinedFilter}
          selection
          options={predefinedOptions}
          onChange={(e, { value }) => {
            this.setState({
              predefinedFilter: value,
            });
          }}
        />
      </div>
    );
  };

  renderSavedEventsSection = () => {
    const { predefinedLogPointEvents, logPoints, appData } = this.props.broker;
    if (predefinedLogPointEvents.length === 0) {
      return null;
    }
    const predefinedLogPoints = this.props.broker.logPoints.filter(lp => lp.predefined);
    const predefinedOptions = predefinedLogPoints.map(lp => ({ key: lp.id, text: lp.probeName, value: lp.id }));
    predefinedOptions.unshift({ key: 'all', text: 'All', value: 'all' });
    const filteredEvents = predefinedLogPointEvents.filter(
      event =>
        this.state.predefinedFilter === 'all' ||
        processSidekickActionId(event.logPointId) === this.state.predefinedFilter,
    );
    return (
      <div className={'saved-events-section'}>
        {this.renderSavedEventsSectionHeader(predefinedOptions, filteredEvents.length)}
        <LogTable
          logPointEvents={filteredEvents}
          logPoints={logPoints}
          appData={appData}
          compareEvents={this.state.compareEvents}
          handlePutReference={this.handlePutReference}
          handleRemoveReference={this.handleRemoveReference}
          handleCompareReference={this.handleCompareIconClicked}
        />
      </div>
    );
  };

  renderEventsSectionHeader = eventCount => {
    const { clientFilter } = this.state;
    return (
      <div className={'header-group'}>
        <div className={'header'}>events</div>
        <div className={'event-count-label table-text'}> {`${eventCount} Events`}</div>
        <Icon className="icon-tp-events-trash" onClick={this.clearEvents} />
        <EventsFilter
          clientFilter={clientFilter}
          setClientFilter={newClientFilter => this.setClientFilterAndResetDetails(newClientFilter)}
        />
      </div>
    );
  };

  renderEventsSection = () => {
    const { logPoints, logPointEvents } = this.props.broker;
    const filteredEvents = this.getFilteredEvents();
    if (logPointEvents.length === 0) {
      return null;
    }

    return (
      <div className={'events-section'}>
        {this.renderEventsSectionHeader(filteredEvents.length)}
        <LogTable
          logPointEvents={filteredEvents}
          logPoints={logPoints}
          compareEvents={this.state.compareEvents}
          handlePutReference={this.handlePutReference}
          handleRemoveReference={this.handleRemoveReference}
          handleCompareReference={this.handleCompareIconClicked}
        />
      </div>
    );
  };
  flushReferenceEventFields() {
    return () => {
      this.setState({
        modalOpen: false,
        getReferenceMessageId: '',
      });
    };
  }

  renderEvents = () => {
    const { logPointEvents, predefinedLogPointEvents } = this.props.broker;

    if (logPointEvents.length || predefinedLogPointEvents.length) {
      return (
        <div className={'events-container'}>
          <EventsPanelExpander position={'right'} selector={'/border/bottom/t1'} />
          {this.state.compareEvents.length > 0 && this.renderCompareEventsSection()}
          {this.renderSavedEventsSection()}
          {this.renderEventsSection()}
          {this.state.modalOpen && (
            <DiffViewerModal
              modalOpen={this.state.modalOpen}
              compareEvents={this.state.compareEvents}
              onClose={this.flushReferenceEventFields()}
            />
          )}
        </div>
      );
    } else {
      return renderNonEvents('Logpoint');
    }
  };

  render() {
    return <div className={'log-points-events-container'}>{this.renderEvents()}</div>;
  }
}
