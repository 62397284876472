import React, { Component } from 'react';
import { MonacoTree } from './MonacoTree/MonacoTree';
import { generateTreeStruct } from './VariableTreeGenerator';
import { VariableTemplate } from './VariableTemplate';
import './VariableTree.scss';

const treeConfig = {
  dataSource: {
    getId: function (tree, element) {
      return element.key;
    },

    hasChildren: function (tree, element) {
      return element.isDirectory;
    },

    getChildren: function (tree, element) {
      return Promise.resolve(element.children);
    },

    getParent: function (tree, element) {
      return Promise.resolve(element.parent);
    },
  },
  renderer: {
    getHeight: function (tree, element) {
      return 24;
    },
    renderTemplate: function (tree, templateId, container) {
      return new VariableTemplate(container);
    },
    renderElement: function (tree, element, templateId, templateData) {
      templateData.set(element);
    },
    disposeTemplate: function (tree, templateId, templateData) {
      //FileTemplate.dispose();
    },
  },

  //tree config requires a controller property but we would defer its initialisation
  //to be done by the MonacoTree component
  //controller: createController(this, this.getActions.bind(this), true),
  dnd: null,
};

export class VariableMonacoTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rootNode: null,
      treeConfig: null,
    };
  }

  componentDidMount() {
    this.setState({
      rootNode: generateTreeStruct(this.props.data, this.props.id),
      treeConfig: treeConfig,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.id !== nextProps.id) {
      //Event Changed
      this.setState({
        rootNode: generateTreeStruct(nextProps.data, nextProps.id),
        treeConfig: treeConfig,
      });
    }
  }

  /**
   * Get Action
   */
  getActions(file, event) {
    const actions = [];
    return actions;
  }

  onClickFile(file) {
    if (file.isDirectory) {
      return;
    }

    if (Date.now() - this.lastClickedTime < 500 && this.lastClickedFile === file) {
      console.log(file.name + ' double clicked');
    } else {
      console.log(file.name + ' clicked');
    }

    this.lastClickedTime = Date.now();
    this.lastClickedFile = file;
  }

  render() {
    const { rootNode, treeConfig } = this.state;
    return (
      <div className="vs-dark show-file-icons show-folder-icons variable-container-layout">
        <div className="workspaceContainer">
          {this.state.rootNode && (
            <MonacoTree
              directory={rootNode}
              treeConfig={treeConfig}
              getActions={this.getActions.bind(this)}
              onClickFile={this.onClickFile.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}
