import {
  SET_WORKSPACE_BILLING_ACCOUNT_FULFILLED,
  SET_WORKSPACE_BILLING_ACCOUNT_PENDING,
  SET_WORKSPACE_BILLING_ACCOUNT_REJECTED,
} from '../constants/workspace-set-billing-account';

const setBillingAccountInitialState = {
  setBillingAccountResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function setWorkspaceBillingAccount(state = setBillingAccountInitialState, action) {
  switch (action.type) {
    case SET_WORKSPACE_BILLING_ACCOUNT_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case SET_WORKSPACE_BILLING_ACCOUNT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        setBillingAccountResult: action.payload.data,
      };
    case SET_WORKSPACE_BILLING_ACCOUNT_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
