import store from 'store/store';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import {
  CREATE_JIRA_ISSUE_COMMENT_FULFILLED,
  CREATE_JIRA_ISSUE_COMMENT_PENDING,
  CREATE_JIRA_ISSUE_COMMENT_REJECTED,
  CREATE_JIRA_ISSUE_FULFILLED,
  CREATE_JIRA_ISSUE_PENDING,
  CREATE_JIRA_ISSUE_REJECTED,
  FLUSH_JIRA_ACTION_STATES,
  GET_JIRA_ACCESSIBLE_RESOURCES_FULFILLED,
  GET_JIRA_ACCESSIBLE_RESOURCES_PENDING,
  GET_JIRA_ACCESSIBLE_RESOURCES_REJECTED,
  GET_JIRA_ISSUE_TYPES_FULFILLED,
  GET_JIRA_ISSUE_TYPES_PENDING,
  GET_JIRA_ISSUE_TYPES_REJECTED,
  GET_JIRA_PROJECTS_FULFILLED,
  GET_JIRA_PROJECTS_PENDING,
  GET_JIRA_PROJECTS_REJECTED,
} from '../constants/issue_management_jira_actions';
import axios from 'axios';

export function getJiraAccessibleResources(jiraToken) {
  return dispatch => {
    const accessibleResourcesURL = 'https://api.atlassian.com/oauth/token/accessible-resources';
    if (jiraToken == null) {
      toast.error(`Error while getting jira token`, ToastConfig);
      return;
    }
    dispatch({ type: GET_JIRA_ACCESSIBLE_RESOURCES_PENDING });

    return axios
      .get(accessibleResourcesURL, {
        headers: { Authorization: 'Bearer ' + jiraToken },
      })
      .then(response => {
        return dispatch({ type: GET_JIRA_ACCESSIBLE_RESOURCES_FULFILLED, payload: response.data });
      })
      .catch(error => {
        return dispatch({ type: GET_JIRA_ACCESSIBLE_RESOURCES_REJECTED, payload: error });
      });
  };
}

export function getJiraProjects(organisationId) {
  return dispatch => {
    const jiraToken = store.getState().issueManagementToken.jiraToken.access_token;
    const accessibleResourcesURL = `https://api.atlassian.com/ex/jira/${organisationId}/rest/api/3/project/search`;
    if (jiraToken == null) {
      toast.error(`Error while getting jira token`, ToastConfig);
      return;
    }
    dispatch({ type: GET_JIRA_PROJECTS_PENDING });

    return axios
      .get(accessibleResourcesURL, {
        headers: { Authorization: 'Bearer ' + jiraToken },
      })
      .then(response => {
        return dispatch({ type: GET_JIRA_PROJECTS_FULFILLED, payload: response.data });
      })
      .catch(error => {
        return dispatch({ type: GET_JIRA_PROJECTS_REJECTED, payload: error });
      });
  };
}

export function getJiraIssueTypes(projectId, organisationId) {
  return dispatch => {
    const jiraToken = store.getState().issueManagementToken.jiraToken.access_token;
    const accessibleResourcesURL = `https://api.atlassian.com/ex/jira/${organisationId}/rest/api/3/issuetype/project?projectId=${projectId}`;
    if (jiraToken == null) {
      toast.error(`Error while getting jira token`, ToastConfig);
      return;
    }
    dispatch({ type: GET_JIRA_ISSUE_TYPES_PENDING });

    return axios
      .get(accessibleResourcesURL, {
        headers: { Authorization: 'Bearer ' + jiraToken },
      })
      .then(response => {
        return dispatch({ type: GET_JIRA_ISSUE_TYPES_FULFILLED, payload: response.data });
      })
      .catch(error => {
        return dispatch({ type: GET_JIRA_ISSUE_TYPES_REJECTED, payload: error });
      });
  };
}

export function createJiraIssue(organisationId, issueData) {
  return dispatch => {
    const jiraToken = store.getState().issueManagementToken.jiraToken.access_token;
    const accessibleResourcesURL = `https://api.atlassian.com/ex/jira/${organisationId}/rest/api/3/issue`;
    if (jiraToken == null) {
      toast.error(`Error while getting jira token`, ToastConfig);
      return;
    }
    dispatch({ type: CREATE_JIRA_ISSUE_PENDING });

    return axios
      .post(accessibleResourcesURL, issueData, {
        headers: { Authorization: 'Bearer ' + jiraToken },
      })
      .then(response => {
        return dispatch({ type: CREATE_JIRA_ISSUE_FULFILLED, payload: response.data });
      })
      .catch(error => {
        return dispatch({ type: CREATE_JIRA_ISSUE_REJECTED, payload: error });
      });
  };
}

export function createJiraIssueComment(organisationId, issueId, commentData) {
  return dispatch => {
    const jiraToken = store.getState().issueManagementToken.jiraToken.access_token;
    const accessibleResourcesURL = `https://api.atlassian.com/ex/jira/${organisationId}/rest/api/3/issue/${issueId}/comment`;
    if (jiraToken == null) {
      toast.error(`Error while getting jira token`, ToastConfig);
      return;
    }
    dispatch({ type: CREATE_JIRA_ISSUE_COMMENT_PENDING });

    return axios
      .post(accessibleResourcesURL, commentData, {
        headers: { Authorization: 'Bearer ' + jiraToken },
      })
      .then(response => {
        return dispatch({ type: CREATE_JIRA_ISSUE_COMMENT_FULFILLED, payload: response.data });
      })
      .catch(error => {
        return dispatch({ type: CREATE_JIRA_ISSUE_COMMENT_REJECTED, payload: error });
      });
  };
}

export function flushJiraActionStates() {
  return {
    type: FLUSH_JIRA_ACTION_STATES,
  };
}
