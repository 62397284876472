import {
  SET_ON_PREM_LICENCE_FULFILLED,
  SET_ON_PREM_LICENCE_PENDING,
  SET_ON_PREM_LICENCE_REJECTED,
} from '../constants/onprem-licencing';

const initialState = {
  setOnpremLicenceResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function setOnpremLicence(state = initialState, action) {
  switch (action.type) {
    case SET_ON_PREM_LICENCE_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case SET_ON_PREM_LICENCE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        setOnpremLicenceResult: action.payload.data,
      };
    case SET_ON_PREM_LICENCE_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
