import { GET_WEBHOOKS_FULFILLED, GET_WEBHOOKS_PENDING, GET_WEBHOOKS_REJECTED } from '../constants/webhook-list';

const initialState = {
  webhooks: [],

  fetching: false,
  fetched: false,
  error: null,
};

export default function webhookList(state = initialState, action) {
  switch (action.type) {
    case GET_WEBHOOKS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_WEBHOOKS_FULFILLED:
      let incomingWebhooks = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        webhooks: incomingWebhooks,
      };
    case GET_WEBHOOKS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
