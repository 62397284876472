import {
  NOTIFY_SOURCE_FOCUS,
  NOTIFY_SOURCE_NOT_AVAILABLE,
  NOTIFY_FIND_AND_ADD_SOURCE,
  NOTIFY_SOURCE_EXISTED,
  NOTIFY_SOURCE_SELECT,
} from '../constants/source-focus';

export const notifySourceFocus = (sourceTabIndex, lineNo = 0) => {
  return {
    type: NOTIFY_SOURCE_FOCUS,
    payload: { sourceTabIndex: sourceTabIndex, lineNo: lineNo },
  };
};

export const notifySourceNotAvailable = (sourceTabIndex, lineNo = 0) => {
  return {
    type: NOTIFY_SOURCE_NOT_AVAILABLE,
    payload: { sourceTabIndex: sourceTabIndex, lineNo: lineNo },
  };
};

export const notifyFindAndAddSource = (sourceTabIndex, lineNo = 0) => {
  return {
    type: NOTIFY_FIND_AND_ADD_SOURCE,
    payload: { sourceTabIndex: sourceTabIndex, lineNo: lineNo },
  };
};

export const notifySourceExisted = (sourceTabIndex, lineNo = 0) => {
  return {
    type: NOTIFY_SOURCE_EXISTED,
    payload: { sourceTabIndex: sourceTabIndex, lineNo: lineNo },
  };
};

export const notifySourceSelect = (sourceTabIndex, lineNo = 0) => {
  return {
    type: NOTIFY_SOURCE_SELECT,
    payload: { sourceTabIndex: sourceTabIndex, lineNo: lineNo },
  };
};
