import React, { Component } from 'react';
import { Button, GridColumn, GridRow, Icon, Modal } from 'semantic-ui-react';

class CancelPlanSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModalOpen: false,
    };
  }

  closeConfirmModal = reload => {
    reload && this.reloadActiveWorkspace();
    this.setState({ confirmModalOpen: false });
  };

  render() {
    return (
      <>
        <GridRow>
          <GridColumn className={'cancel-plan'}>
            <span className={'anchor-like'} onClick={() => this.setState({ confirmModalOpen: true })}>
              Cancel subscription
            </span>
          </GridColumn>
        </GridRow>

        <Modal
          className={'confirm-modal'}
          open={this.state.confirmModalOpen}
          onClose={() => this.closeConfirmModal(false)}
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            <Icon name="warning sign" />
            Are you sure?
          </Modal.Header>
          <Modal.Content>
            Are you sure you want to cancel your plane? You will lose Sidekick's realtime debugging features.
          </Modal.Content>
          <Modal.Actions>
            <Button
              className={'secondary button-step-info'}
              content={'Return to Billing'}
              onClick={() => this.closeConfirmModal(false)}
            />
            <Button
              loading={this.props.cancelSubscriptionResult.fetching}
              content={'Cancel subscription'}
              onClick={this.props.cancelSubscription}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default CancelPlanSection;
