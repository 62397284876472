import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import {
  SOURCE_PROVIDER_PLATFORM,
  selectCommitDataAccordingToSourceProvider,
  computeCommitTextAccordingToSourceProvider,
  computeCommitIdAccordingToSourceProvider,
} from 'utils/source-provider-util';

export class SourceRepoCommitSelector extends Component {
  componentDidMount() {
    const { selectedRepoOwnerLogin, selectedRepo, selectedRepoName, selectedRepoBranchName, gitSourceProvider } =
      this.props;

    // These are for update repo flow!?
    if (selectedRepoName && gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
      this.props.getUserRepoBranchCommits(
        gitSourceProvider,
        selectedRepoOwnerLogin,
        selectedRepoName,
        selectedRepoBranchName,
      );
    }

    if (selectedRepo && gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
      const projectId = selectedRepo.repodata.id;
      this.props.getUserRepoBranchCommits(gitSourceProvider, selectedRepoOwnerLogin, projectId, selectedRepoBranchName);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedRepoOwnerLogin, selectedRepo, selectedRepoName, selectedRepoBranchName, gitSourceProvider } =
      this.props;

    if (
      selectedRepoName !== '' &&
      selectedRepoBranchName !== '' &&
      selectedRepoOwnerLogin !== '' &&
      (prevProps.selectedRepoName !== selectedRepoName ||
        prevProps.selectedRepoBranchName !== selectedRepoBranchName ||
        prevProps.selectedRepoOwnerLogin !== selectedRepoOwnerLogin)
    ) {
      // console.log(
      //   'SRCS, CDU, getUserRepoBranchCommits; selectedRepoOwnerLogin, selectedRepoName, selectedRepoBranchName, selectedRepo: ',
      //   selectedRepoOwnerLogin,
      //   selectedRepoName,
      //   selectedRepoBranchName,
      //   selectedRepo,
      // );

      if (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
        // console.log('SRCS, CDU - GITHUB; props: ', this.props);
        this.props.getUserRepoBranchCommits(
          gitSourceProvider,
          selectedRepoOwnerLogin,
          selectedRepoName,
          selectedRepoBranchName,
        );
      }

      if (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
        // console.log('SRCS, CDU - GITLAB; props: ', this.props);

        const projectId = selectedRepo ? selectedRepo.repodata.id : '';
        this.props.getUserRepoBranchCommits(
          gitSourceProvider,
          selectedRepoOwnerLogin,
          projectId,
          selectedRepoBranchName,
        );
      }
    }
  }

  findSelectedRepoCommitFromOptions = (options, value) => {
    return options.find(commit => commit.value === value);
  };

  renderCommitSelectorDropdown = () => {
    const { gitSourceProvider, gitCommitData } = this.props;
    const { isFetching, isGitlabCommitDataFetching } = gitCommitData;

    if (
      (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB && isFetching) ||
      (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB && isGitlabCommitDataFetching)
    ) {
      return (
        <Dropdown
          placeholder="Loading available commits"
          disabled={true}
          fluid
          search
          selection
          options={[]}
          value={''}
        />
      );
    }

    const commitData = selectCommitDataAccordingToSourceProvider(gitSourceProvider, gitCommitData);
    const commitDropdownData = commitData.map(commit => {
      // console.log('renderCommitSelectorDropdown; commit: ', commit);
      const commitText = computeCommitTextAccordingToSourceProvider(gitSourceProvider, commit);
      const commitId = computeCommitIdAccordingToSourceProvider(gitSourceProvider, commit);
      return {
        key: commitId,
        value: commitId,
        text: commitText,
        commitdata: commit, // 'commitdata' should not be camel case(i.e. commitData), otherwise SUIR gives error!!
      };
    });

    let selectedRepoCommitShaValue = null;
    if (this.props.selectedRepoCommitSha) {
      selectedRepoCommitShaValue = this.props.selectedRepoCommitSha;
    }

    return (
      <Dropdown
        placeholder="Select Commit"
        disabled={!this.props.selectedRepo}
        fluid
        search
        selection
        options={commitDropdownData}
        value={selectedRepoCommitShaValue}
        onChange={(e, data) => {
          console.log('SourceRepoCommitSelector, onChange; data: ', data);
          const selectedRepoCommit = this.findSelectedRepoCommitFromOptions(commitDropdownData, data.value);
          this.props.onSelectRepoCommit(selectedRepoCommit);
        }}
      />
    );
  };

  render() {
    console.log('SourceRepoCommitSelector, render; props, state: ', this.props, this.state);

    return <div className="github-repo-commit-selector-wrapper">{this.renderCommitSelectorDropdown()}</div>;
  }
}
