export const LOG_LEVEL_OPTIONS = [
  {
    key: 'INFO',
    value: 'INFO',
    text: 'INFO',
  },
  {
    key: 'DEBUG',
    value: 'DEBUG',
    text: 'DEBUG',
  },
  {
    key: 'ERROR',
    value: 'ERROR',
    text: 'ERROR',
  },
  {
    key: 'WARN',
    value: 'WARN',
    text: 'WARN',
  },
];
