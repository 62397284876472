import {
  SEND_TO_WEBHOOK_FULFILLED,
  SEND_TO_WEBHOOK_PENDING,
  SEND_TO_WEBHOOK_REJECTED,
} from '../constants/webhook-send';

const sendToWebhookInitialState = {
  sendResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function sendToWebhook(state = sendToWebhookInitialState, action) {
  switch (action.type) {
    case SEND_TO_WEBHOOK_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case SEND_TO_WEBHOOK_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        sendResult: action.payload.data,
      };
    case SEND_TO_WEBHOOK_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
