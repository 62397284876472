import { GET_WORKSPACES_FULFILLED, GET_WORKSPACES_PENDING, GET_WORKSPACES_REJECTED } from '../constants/workspace-list';
import { findActiveWorkspaceId } from 'utils/workspace-util';

function sortWorkspaces(a, b) {
  if (a.role === 'OWNER') {
    return -1;
  }
  if (b.role === 'OWNER') {
    return 1;
  }
  return a.workspace.workspaceName.localeCompare(b.workspace.workspaceName);
}

const initialState = {
  workspaces: [],
  activeWorkspaceId: '',

  fetching: false,
  fetched: false,
  error: null,
};

export default function workspaceList(state = initialState, action) {
  switch (action.type) {
    case GET_WORKSPACES_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_WORKSPACES_FULFILLED:
      let workspaces = action.payload.data.workspaceDtos;
      workspaces.sort(sortWorkspaces);
      let activeWorkspaceId = findActiveWorkspaceId(workspaces);
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        workspaces: workspaces,
        activeWorkspaceId: activeWorkspaceId,
      };
    case GET_WORKSPACES_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
