import React, { Component } from 'react';
import { GridColumn, GridRow, Table, TableHeader, TableRow } from 'semantic-ui-react';
import { formatDate } from 'utils/date-util';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import { ToastConfig } from 'utils/toast-util';
import { toast } from 'react-toastify';
import auth from 'auth/auth-factory';
import './InvoicesSection.scss';

class InvoicesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadingInvoiceId: '',
    };
  }

  handleDownloadInvoiceClick = invoiceId => {
    const invoiceUrl =
      CONFIG.apiUrl +
      `/invoices/${invoiceId}/download?workspaceId=${this.props.activeWorkspace.workspace.workspace.id}`;
    this.setState({ downloadingInvoiceId: invoiceId }, () => {
      axios({
        url: invoiceUrl,
        method: 'GET',
        responseType: 'blob',
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      })
        .then(response => {
          setTimeout(() => {
            this.setState({ downloadingInvoiceId: '' });
          }, 1000);

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', invoiceId + '.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          // console.log("invoice download error");
          setTimeout(() => {
            this.setState({ downloadingInvoiceId: '' }, () => {
              toast.error('Invoice download error', ToastConfig);
            });
          }, 1000);
        });
    });
  };

  renderInvoiceRows = () => {
    let invoices = this.props.billingInvoices.billingInvoices.invoices;
    return invoices.map(invoice => (
      <Table.Row key={invoice.id}>
        <Table.Cell>{formatDate(invoice.created * 1000)}</Table.Cell>
        <Table.Cell>Initial Package (monthly)</Table.Cell>
        <Table.Cell>${invoice.total / 100}</Table.Cell>
        <Table.Cell>
          Paid
          <div className={'download-link'} onClick={() => this.handleDownloadInvoiceClick(invoice.id)}>
            {this.state.downloadingInvoiceId === invoice.id ? 'Downloading' : 'Download'}
          </div>
        </Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    return (
      <>
        <GridRow>
          <GridColumn>
            <div className={'small-header'}>Invoices</div>
          </GridColumn>
        </GridRow>
        <GridRow className={'invoices-table-row last-section-row'}>
          <GridColumn>
            <Table className={'settings-table'}>
              <TableHeader>
                <TableRow>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Package</Table.HeaderCell>
                  <Table.HeaderCell>Invoice Total</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </TableRow>
              </TableHeader>
              {this.renderInvoiceRows()}
            </Table>
          </GridColumn>
        </GridRow>
      </>
    );
  }
}

export default InvoicesSection;
