import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { getWorkspace, getWorkspaces } from 'store/actions';
import { DEFAULT_ALLOWED_ROLES, redirectRules } from 'utils/role-utils';
import { LoadingPage } from 'components';
import routeList from 'routes';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { CONFIG } from 'utils/config-util';

export function withAuth(Component, { allowedRoles = DEFAULT_ALLOWED_ROLES } = {}) {
  class AuthenticatedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { loading: true };
    }

    componentDidMount() {
      this.getWorkspacesAndSetActive();
    }

    getWorkspacesAndSetActive = () => {
      this.props.getWorkspaces(() => {
        this.props.getActiveWorkspace(this.props.workspaceList.activeWorkspaceId, () => {
          this.setState({ loading: false });
        });
      });
    };

    shouldRenderLoading = () => {
      return this.props.userAccount.fetching || !this.props.userAccount.fetched || this.state.loading;
    };

    shouldRedirectToWelcome = () => {
      const excludedChecks = CONFIG?.EXCLUDED_CHECKS;
      const skipWelcome = excludedChecks && excludedChecks.includes('welcome');
      if (skipWelcome) {
        return false;
      }

      let userAccount = this.props.userAccount.userAccount;
      let workspaceList = this.props.workspaceList;
      return userAccount.fullName == null || userAccount.companyRole == null || workspaceList.workspaces.length === 0;
    };

    shouldRedirectToPluginWelcome = () => {
      const PORT = localStorage.getItem('port');
      return PORT != null;
    };

    render() {
      const { workspace: activeWorkspace } = this.props.activeWorkspace;
      const redirectTo = redirectRules[activeWorkspace?.role]?.defaultRedirectionURL;
      console.log('withAuth rendering');
      if (this.shouldRenderLoading()) {
        return <LoadingPage />;
      }

      if (this.shouldRedirectToWelcome()) {
        return <Redirect to={routeList.welcome.path} />;
      }

      if (this.shouldRedirectToPluginWelcome()) {
        return <Redirect to={routeList.pluginWelcome.path} />;
      }
      if (allowedRoles.indexOf(activeWorkspace.role) === -1) {
        return <Redirect to={redirectTo} />;
      }

      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = store => {
    return {
      userAccount: store.userAccount,
      workspaceList: store.workspaceList,
      activeWorkspace: store.activeWorkspace,
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      getWorkspaces(onSuccess) {
        dispatchWithExceptionHandler(getWorkspaces(), false, onSuccess);
      },
      getActiveWorkspace(workspaceId, onSuccess) {
        dispatchWithExceptionHandler(getWorkspace(workspaceId), false, onSuccess);
      },
    };
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent));
}
