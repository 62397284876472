import React, { Component } from 'react';
import { MainLayout } from '../MainLayout';
import './ListLayout.scss';
import PropTypes from 'prop-types';

class ListLayout extends Component {
  render() {
    const leftOptionBarClass =
      this.props.leftOptionBarClass === undefined ? 'left-option-bar' : this.props.leftOptionBarClass;

    return (
      <MainLayout title={this.props.title}>
        <div className={leftOptionBarClass}>{this.props.leftPane}</div>
        <div className="list-layout-content">{this.props.children}</div>
      </MainLayout>
    );
  }
}

ListLayout.propTypes = {
  title: PropTypes.string,
};

ListLayout.defaultProps = {
  title: '',
};

export default ListLayout;
