import React, { Component } from 'react';
import { Button, Grid, GridColumn, GridRow, Icon, Modal, Popup, Table } from 'semantic-ui-react';
import { InviteUsersModal, LoadingPage } from 'components';
import { capitalize } from 'lodash';
import { ToastConfig } from 'utils/toast-util';
import { toast } from 'react-toastify';
import { CONFIG } from 'utils/config-util';

export class UsersTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteUsersModalOpen: this.props.openInviteUsersModal || false,
      confirmModalOpen: false,
      confirmModalAction: null,
      confirmModalContent: '',
      activePopupId: '',
    };
  }

  convertRole = role => {
    if (role === 'OWNER') {
      return 'Owner';
    }
    if (role === 'BILLING_USER') {
      return 'User';
    }

    return capitalize(role);
  };

  capitalizeName = name => {
    if (name === '' || name == null) {
      return 'Unknown';
    }
    let nameTokens = name.split(' ');

    return nameTokens.map(token => capitalize(token)).join(' ');
  };

  renderNameAvatar = fullName => {
    let tokens = fullName.split(' ');
    let avatar = '';
    if (tokens.length >= 2) {
      avatar = tokens[0].charAt(0) + tokens[1].charAt(0);
    } else {
      if (fullName.length > 1) {
        avatar = fullName.substr(0, 2);
      } else {
        return fullName.charAt(0);
      }
    }
    return <div className={'name-avatar'}>{avatar}</div>;
  };

  renderNameIcons = accountType => {
    let icons = [];
    if (accountType === 'OWNER') {
      icons.push(<Icon className={'user-icon owner'} bordered inverted size={'small'} name={'key'} />);
    }
    if (accountType === 'BILLING') {
      icons.push(<Icon className={'user-icon billing'} bordered inverted size={'small'} name={'credit card'} />);
    }
    return icons;
  };

  renderInvitedSettingsPopup = invited => {
    let activeWorkspaceId = this.props.activeWorkspace.workspace.workspace.id;
    return (
      <>
        <div
          className={'popup-menu-item'}
          onClick={() => {
            this.props.inviteToWorkspace(activeWorkspaceId, [invited.email], () => {
              toast.success('Invitation resend', ToastConfig);
            });
          }}
        >
          {' '}
          Resend Invitation
        </div>
      </>
    );
  };

  renderUserSettingsPopup = account => {
    let activeWorkspaceId = this.props.activeWorkspace.workspace.workspace.id;
    let menuItems = [];
    const createUpdateAction = role => () =>
      this.props.updateWorkspaceAccount(
        activeWorkspaceId,
        account.accountId,
        role,
        () => {
          this.closeConfirmModal(true);
        },
        () => {
          this.closeConfirmModal(false);
          toast.error('Could not update user', ToastConfig);
        },
      );
    const createKickAction = () => () =>
      this.props.kickWorkspaceAccount(
        activeWorkspaceId,
        account.accountId,
        () => {
          toast.success('User removed successfully.', ToastConfig);
          this.closeConfirmModal(true);
        },
        () => {
          this.closeConfirmModal(false);
          toast.error('Could not remove user', ToastConfig);
        },
      );

    let newRole = account.accountType === 'ADMIN' ? 'USER' : 'ADMIN';
    menuItems.unshift(
      <div
        className={'popup-menu-item'}
        onClick={() => {
          this.setState({
            confirmModalOpen: true,
            confirmModalAction: createUpdateAction(newRole),
            confirmModalContent: 'Do you wanna update role?',
          });
        }}
      >
        Make {capitalize(newRole)}
      </div>,
    );

    menuItems.push(
      <div
        className={'popup-menu-item'}
        onClick={() =>
          this.setState({
            confirmModalOpen: true,
            confirmModalAction: createKickAction(),
            confirmModalContent:
              'Do you wanna kick user? If he is your billing user, you might need assign new billing user.',
          })
        }
      >
        Remove
      </div>,
    );

    return menuItems;
  };

  shouldRenderActionMenu = account => {
    let me = this.props.userAccount.userAccount;
    if (account.accountType === 'OWNER') {
      return false;
    }
    if (me.id === account.accountId) {
      return false;
    }
    if (me.role === 'ADMIN' && account.accountType === 'ADMIN') {
      return false;
    }
    return true;
  };

  renderInvitedActions = invited => {
    return (
      <Popup
        onOpen={() => this.setState({ activePopupId: invited.id })}
        onClose={() => this.setState({ activePopupId: '' })}
        className={'popup-menu'}
        basic
        position={'bottom center'}
        key={invited.id}
        content={this.renderInvitedSettingsPopup(invited)}
        on={'click'}
        trigger={
          <Icon
            style={{ marginLeft: 'auto' }}
            className={'options interactive-icon' + (invited.id === this.state.activePopupId ? ' active' : '')}
            size={'large'}
          />
        }
      />
    );
  };

  renderUserActions = account => {
    if (!this.shouldRenderActionMenu(account)) {
      return null;
    }
    return (
      <Popup
        onOpen={() => this.setState({ activePopupId: account.accountId })}
        onClose={() => this.setState({ activePopupId: '' })}
        className={'popup-menu'}
        basic
        position={'bottom center'}
        key={account.accountId}
        content={this.renderUserSettingsPopup(account)}
        on={'click'}
        trigger={
          <Icon
            style={{ marginLeft: 'auto' }}
            className={'options interactive-icon' + (account.accountId === this.state.activePopupId ? ' active' : '')}
            size={'large'}
          />
        }
      />
    );
  };

  renderAccountStatus = () => {
    return (
      <>
        <Icon className={'user-icon status'} bordered inverted name={'check'} />
        <div>Active</div>
      </>
    );
  };

  renderInvitationRow = invitation => {
    return (
      <Table.Row>
        <Table.Cell className={'multi-info-cell'}>
          {this.renderNameAvatar('Unknown')}
          {this.capitalizeName('Unknown')}
        </Table.Cell>
        <Table.Cell>{invitation.email}</Table.Cell>
        <Table.Cell>User</Table.Cell>
        <Table.Cell className={'multi-info-cell'}>Invited{this.renderInvitedActions(invitation)}</Table.Cell>
      </Table.Row>
    );
  };

  renderUserRow = user => {
    return (
      <Table.Row key={user.username}>
        <Table.Cell className={'multi-info-cell'}>
          {this.renderNameAvatar(user.userInfo.fullname)}
          {this.capitalizeName(user.userInfo.fullname)}
          {this.renderNameIcons(user.account.accountType)}
        </Table.Cell>
        <Table.Cell>{user.userInfo.email}</Table.Cell>
        <Table.Cell>{this.convertRole(user.account.accountType)}</Table.Cell>
        <Table.Cell className={'multi-info-cell'}>
          {this.renderAccountStatus(user.account, user.userInfo)}
          {this.renderUserActions(user.account)}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderUsersTable = () => {
    let workspaceAccounts = this.props.activeWorkspace.workspace.workspaceAccounts;
    workspaceAccounts.sort((a, b) => {
      if (a.accountType === 'OWNER') {
        return 1;
      }
      return 0;
    });
    return (
      <Table celled className={'settings-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Mail</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Account Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {workspaceAccounts.map(user => this.renderUserRow(user))}
        {this.props.activeWorkspace.workspace.pendingInvitations.map(invitation =>
          this.renderInvitationRow(invitation),
        )}
      </Table>
    );
  };

  reloadActiveWorkspace = () => {
    let activeWorkspaceId = this.props.activeWorkspace.workspace.workspace.id;
    this.props.getWorkspace(activeWorkspaceId);
  };

  closeInviteModal = reload => {
    reload && this.reloadActiveWorkspace();
    this.setState({ inviteUsersModalOpen: false });
  };

  closeConfirmModal = reload => {
    reload && this.reloadActiveWorkspace();
    this.setState({ confirmModalOpen: false, confirmModalAction: null, confirmModalContent: '' });
  };
  render() {
    console.log('UsersTab; props: ', this.props);
    let activeWorkspaceId = this.props.activeWorkspace.workspace.workspace.id;
    if (this.props.activeWorkspace.fetching) {
      return <LoadingPage />;
    }
    const inviteButtonDisabled = CONFIG?.EXCLUDED_FEATURES && CONFIG?.EXCLUDED_FEATURES.includes('invite');
    return (
      <>
        <Grid className={'users-tab-main-grid'} centered container>
          <GridRow columns={'1'}>
            <GridColumn className={'table-header'}>
              <div className={'small-header'}>All Users</div>
              <Button
                disabled={inviteButtonDisabled}
                className={'small-button invite-users-button primary'}
                onClick={() => this.setState({ inviteUsersModalOpen: true })}
              >
                + Invite users
              </Button>
            </GridColumn>
          </GridRow>
          <GridRow className={'users-table'}>
            <GridColumn style={{ padding: 'unset' }}>{this.renderUsersTable()}</GridColumn>
          </GridRow>
        </Grid>
        <InviteUsersModal
          isOpen={this.state.inviteUsersModalOpen}
          closeModal={this.closeInviteModal}
          invitationResult={this.props.invitationResult}
          inviteToWorkspace={(emailList, onSuccess, onError) =>
            this.props.inviteToWorkspace(activeWorkspaceId, emailList, onSuccess, onError)
          }
        />
        <Modal
          open={this.state.confirmModalOpen}
          onClose={() => this.closeConfirmModal(false)}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Are you sure?</Modal.Header>
          <Modal.Content>{this.state.confirmModalContent}</Modal.Content>
          <Modal.Actions>
            <Button content={'Cancel'} onClick={() => this.closeConfirmModal(false)} />
            <Button
              loading={this.props.updateAccountResult.fetching || this.props.kickWorkspaceAccountResult.fetching}
              content={'Yes'}
              onClick={() => this.state.confirmModalAction()}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
