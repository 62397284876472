import { connect } from 'react-redux';
import { getUserRepositories } from 'store/actions';
import { SourceRepoSelector } from 'components';

const mapStateToProps = store => ({
  gitRepoData: store.gitRepositories,
});

const mapDispatchToProps = dispatch => ({
  getUserRepositories: (sourceProvider, getRepoSuccess, getRepoFail) =>
    dispatch(getUserRepositories(sourceProvider, getRepoSuccess, getRepoFail)),
});

export const SourceRepoSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(SourceRepoSelector);
