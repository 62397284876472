import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_EVENT_HISTORY, GET_PROBE_HIT_COUNT } from '../constants/event-history';

export const getEventHistory = (type, workspaceId, startDate) => {
  return {
    type: GET_EVENT_HISTORY,
    payload: axios.post(
      CONFIG.apiUrl + `/v1/eventhistory?size=100&page=0`,
      {
        type,
        workspaceId,
        withEventData: true,
        startDate,
      },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
};

export const getProbeHitCount = (fileName, lineNo, groupBy, type, workspaceId, startDate) => {
  return {
    type: GET_PROBE_HIT_COUNT,
    payload: axios.post(
      CONFIG.apiUrl + `/v1/eventhistory/count`,
      {
        fileName,
        groupBy,
        lineNo,
        workspaceId,
        type,
        startDate,
      },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
};
