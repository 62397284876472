import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import {
  FLUSH_EVENT_TO_SEND,
  GET_JIRA_ACCESS_TOKEN,
  GET_LINEAR_ACCESS_TOKEN,
  SET_EVENT_TO_SEND,
} from '../constants/issue_management_token';

export function getLinearAccessToken(code) {
  return {
    type: GET_LINEAR_ACCESS_TOKEN,
    payload: axios.get(CONFIG.apiUrl + `/oauth/linear?code=${code}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getJiraAccessToken(code) {
  return {
    type: GET_JIRA_ACCESS_TOKEN,
    payload: axios.get(CONFIG.apiUrl + `/oauth/jira?code=${code}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function setEventToSend(event) {
  return {
    type: SET_EVENT_TO_SEND,
    payload: event,
  };
}

export function flushEventToSend() {
  return {
    type: FLUSH_EVENT_TO_SEND,
  };
}
