// OAuthController
export const GET_GITHUB_ACCESS_TOKEN = 'GET_GITHUB_ACCESS_TOKEN';
export const GET_GITHUB_ACCESS_TOKEN_PENDING = 'GET_GITHUB_ACCESS_TOKEN_PENDING';
export const GET_GITHUB_ACCESS_TOKEN_FULFILLED = 'GET_GITHUB_ACCESS_TOKEN_FULFILLED';
export const GET_GITHUB_ACCESS_TOKEN_REJECTED = 'GET_GITHUB_ACCESS_TOKEN_REJECTED';

export const GET_GITLAB_ACCESS_TOKEN = 'GET_GITLAB_ACCESS_TOKEN';
export const GET_GITLAB_ACCESS_TOKEN_PENDING = 'GET_GITLAB_ACCESS_TOKEN_PENDING';
export const GET_GITLAB_ACCESS_TOKEN_FULFILLED = 'GET_GITLAB_ACCESS_TOKEN_FULFILLED';
export const GET_GITLAB_ACCESS_TOKEN_REJECTED = 'GET_GITLAB_ACCESS_TOKEN_REJECTED';

// SourceAccountController
export const GET_SOURCE_PROVIDER_ACCOUNT_LIST = 'GET_SOURCE_PROVIDER_ACCOUNT_LIST';
export const GET_SOURCE_PROVIDER_ACCOUNT_LIST_PENDING = 'GET_SOURCE_PROVIDER_ACCOUNT_LIST_PENDING';
export const GET_SOURCE_PROVIDER_ACCOUNT_LIST_FULFILLED = 'GET_SOURCE_PROVIDER_ACCOUNT_LIST_FULFILLED';
export const GET_SOURCE_PROVIDER_ACCOUNT_LIST_REJECTED = 'GET_SOURCE_PROVIDER_ACCOUNT_LIST_REJECTED';

export const ADD_SOURCE_PROVIDER_ACCOUNT = 'ADD_SOURCE_PROVIDER_ACCOUNT';
export const ADD_SOURCE_PROVIDER_ACCOUNT_PENDING = 'ADD_SOURCE_PROVIDER_ACCOUNT_PENDING';
export const ADD_SOURCE_PROVIDER_ACCOUNT_FULFILLED = 'ADD_SOURCE_PROVIDER_ACCOUNT_FULFILLED';
export const ADD_SOURCE_PROVIDER_ACCOUNT_REJECTED = 'ADD_SOURCE_PROVIDER_ACCOUNT_REJECTED';

export const UPDATE_SOURCE_PROVIDER_ACCOUNT = 'UPDATE_SOURCE_PROVIDER_ACCOUNT';
export const UPDATE_SOURCE_PROVIDER_ACCOUNT_PENDING = 'UPDATE_SOURCE_PROVIDER_ACCOUNT_PENDING';
export const UPDATE_SOURCE_PROVIDER_ACCOUNT_FULFILLED = 'UPDATE_SOURCE_PROVIDER_ACCOUNT_FULFILLED';
export const UPDATE_SOURCE_PROVIDER_ACCOUNT_REJECTED = 'UPDATE_SOURCE_PROVIDER_ACCOUNT_REJECTED';

export const DELETE_SOURCE_PROVIDER_ACCOUNT = 'DELETE_SOURCE_PROVIDER_ACCOUNT';
export const DELETE_SOURCE_PROVIDER_ACCOUNT_PENDING = 'DELETE_SOURCE_PROVIDER_ACCOUNT_PENDING';
export const DELETE_SOURCE_PROVIDER_ACCOUNT_FULFILLED = 'DELETE_SOURCE_PROVIDER_ACCOUNT_FULFILLED';
export const DELETE_SOURCE_PROVIDER_ACCOUNT_REJECTED = 'DELETE_SOURCE_PROVIDER_ACCOUNT_REJECTED';


export const DELETE_NEW_SOURCE_PROVIDER_DATA = 'DELETE_NEW_SOURCE_PROVIDER_DATA';