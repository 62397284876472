import {
  UNSUBSCRIBE_MAIL_FULFILLED,
  UNSUBSCRIBE_MAIL_PENDING,
  UNSUBSCRIBE_MAIL_REJECTED,
} from '../constants/user-unsubscribe-mail';

const unsubscribeMailInitialState = {
  unsubscribeMailResult: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function userUnsubscribeMail(state = unsubscribeMailInitialState, action) {
  switch (action.type) {
    case UNSUBSCRIBE_MAIL_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case UNSUBSCRIBE_MAIL_FULFILLED:
      console.log('user-info, reducer, UPDATE_USER_INFO_FULFILLED; payload: ', action.payload);
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        unsubscribeMailResult: action.payload.data,
      };
    case UNSUBSCRIBE_MAIL_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
