import React, { Component } from 'react';
import {
  AppManagementContainer,
  RepositoryManagementContainer,
  SourceAccountListContainer,
  SourceSearchContainer,
} from 'containers';
import { Accordion } from 'components';
import { APPS_AND_SOURCES, SEARCH, VERSION_CONTROL } from 'store/constants/ide-apps-and-repositories-management';
import './AppsAndRepositories.scss';
import { CONFIG } from 'utils/config-util';
import routeList from 'routes';
import { SOURCE_PROVIDER_PLATFORM } from 'utils/source-provider-util';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import { hasArrayElement } from 'utils/array-util';
import { clusterApps } from 'utils/application-util';

export class AppsAndRepositories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sourceTreeKey: Date.now(),
    };
  }

  componentDidMount() {
    // console.log('RepositoryManagement, CDM; props: ', this.props);
    this.props.getSourceProviderAccountList(
      this.onGetSourceProviderAccountListSuccess,
      this.onGetSourceProviderAccountListError,
    );
    this.props.getSourceListLimit();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('RepositoryManagement, CWRP; props, nextProps: ', this.props, nextProps);
    const { search } = nextProps.location;
    const urlParams = new URLSearchParams(search);

    if (urlParams.has('code')) {
      localStorage.setItem('code', urlParams.get('code'));
      localStorage.setItem('state', urlParams.get('state'));
      this.clearCodeQueryParam(nextProps);
      return;
    }

    const code = localStorage.getItem('code');
    const state = localStorage.getItem('state');

    if (![CONFIG.GITHUB_OAUTH_RANDOM_STATE, CONFIG.GITLAB_OAUTH_RANDOM_STATE].includes(state)) {
      return;
    }

    if (code && state) {
      localStorage.removeItem('code');
      localStorage.removeItem('state');
      if (state === CONFIG.GITHUB_OAUTH_RANDOM_STATE) {
        this.props.getGithubAccessToken(code, this.onGetGithubAccessTokenSuccess, this.onGetGithubAccessTokenError);
      } else if (state === CONFIG.GITLAB_OAUTH_RANDOM_STATE) {
        this.props.getGitlabAccessToken(code, this.onGetGitlabAccessTokenSuccess, this.onGetGitlabAccessTokenError);
      }
    }
  }

  clearCodeQueryParam = nextProps => {
    const { search } = nextProps.location;
    const urlParams = new URLSearchParams(search);
    if (urlParams.has('code')) {
      // This is to clear query params `code` and `state`.
      this.props.history.replace({
        pathname: routeList.ide.path,
        search: '',
      });
    }
  };

  onGetGithubAccessTokenSuccess = () => {
    console.log('getGithubAccessToken success');

    const { githubOauthToken } = this.props.sourceProviderAccount;
    const githubAccessToken = githubOauthToken.access_token;
    if (githubAccessToken) {
      console.log('githubAccessToken: ', githubAccessToken);
      // If access token is returned from OauthController,
      // then save it as a new source provider account.
      let sourceAccount = {
        platform: SOURCE_PROVIDER_PLATFORM.GITHUB,
        authMethod: 'OAUTH_TOKEN',
        authData: githubAccessToken,
      };
      this.props.addSourceProviderAccount(
        sourceAccount,
        () => {
          this.props.getSourceProviderAccountList(
            this.onGetSourceProviderAccountListSuccess,
            this.onGetSourceProviderAccountListError,
          );
          console.log('addSourceProviderAccount success; props: ', this.props);
          // Here first, call opening source account settings panel from repo management!!
        },
        () => {
          console.log('addSourceProviderAccount fail');
        },
      );
    } else {
      // sth error to get access token!
      toast.error(`Error while getting token: ${githubOauthToken.error_description}`, ToastConfig);
    }
  };

  onGetGithubAccessTokenError = () => {
    console.log('onGetGithubAccessTokenError');
  };

  onGetGitlabAccessTokenSuccess = () => {
    console.log('onGetGitlabAccessTokenSuccess');

    const { gitlabOauthToken } = this.props.sourceProviderAccount;
    const gitlabAccessToken = gitlabOauthToken.access_token;
    if (gitlabAccessToken) {
      console.log('gitlabAccessToken: ', gitlabAccessToken);
      // If access token is returned from OauthController,
      // then save it as a new source provider account.
      let sourceAccount = {
        platform: SOURCE_PROVIDER_PLATFORM.GITLAB,
        authMethod: 'OAUTH_TOKEN',
        authData: gitlabAccessToken,
      };
      this.props.addSourceProviderAccount(
        sourceAccount,
        () => {
          this.props.getSourceProviderAccountList(
            this.onGetSourceProviderAccountListSuccess,
            this.onGetSourceProviderAccountListError,
          );
          console.log('addSourceProviderAccount success; props: ', this.props);
          // Here first, call opening source account settings panel from repo management!!
        },
        () => {
          console.log('addSourceProviderAccount fail');
        },
      );
    } else {
      // sth error to get access token!
      toast.error(`Error while getting token: ${gitlabOauthToken.error_description}`, ToastConfig);
    }
  };

  onGetGitlabAccessTokenError = () => {
    console.log('onGetGitlabAccessTokenError');
  };

  onGetSourceProviderAccountListSuccess = () => {
    console.log('RM, onGetSourceProviderAccountListSuccess; props: ', this.props);
    // If there is already an account connected before,
    // then look for if there is a source cache.
    this.props.getSourceCacheList(
      this.props.activeWorkspace.workspace.workspace.id,
      this.onGetSourceCacheListSuccess,
      this.onGetSourceCacheListError,
    );
  };

  onGetSourceProviderAccountListError = () => {
    console.log('RM, onGetSourceProviderAccountListError');
  };

  onGetSourceCacheListSuccess = () => {
    // console.log('RepositoryManagement, onGetSourceCacheListSuccess; props, state: ', this.props, this.state);
    const { sourceCacheList } = this.props.sourceCache;
    if (hasArrayElement(sourceCacheList)) {
      this.props.selectGitSourceCache(sourceCacheList[0]);
    } else {
      this.props.selectGitSourceCache(null);
    }
  };

  onGetSourceCacheListError = () => {
    console.log('RepositoryManagement, getSourceCacheList error');
  };

  getTitle = () => {
    const { currentState } = this.props.ideAppsAndRepositoriesManagement;
    switch (currentState) {
      case APPS_AND_SOURCES:
        return <div className={'apps-and-repositories-title'}>Sources</div>;
      case VERSION_CONTROL:
        return <div className={'apps-and-repositories-title'}>Source Settings</div>;
      case SEARCH:
        return <div className={'apps-and-repositories-title'}>Search</div>;
      default:
        return null;
    }
  };

  getCurrentComponent = () => {
    const { currentState } = this.props.ideAppsAndRepositoriesManagement;
    const { appData: apps } = this.props.broker;
    const { selectedApps } = this.props.appsAndTracePoints;
    const appMap = clusterApps(apps);
    const appList = [];
    //eslint-disable-next-line
    for (const [key, value] of appMap.entries()) {
      appList.push(value[0]);
    }

    switch (currentState) {
      case APPS_AND_SOURCES:
        return (
          <Accordion
            panels={[
              {
                title: 'Sources',
                content: (
                  <RepositoryManagementContainer
                    key={'repository-management-container'}
                    loading={this.isDataFetchingForLoading()}
                  />
                ),
                shrink: 0,
                basis: '60%',
                key: this.state.sourceTreeKey,
              },
              {
                title: `${selectedApps.length}/${appList.length} app(s) selected`,
                content: <AppManagementContainer key={'app-management-container'} />,
                shrink: 0,
                basis: '40%',
                key: 'apps',
              },
            ]}
            openPanels={[0, 1]}
            onPanelClick={() => this.setState({ sourceTreeKey: Date.now() })}
          />
        );
      case VERSION_CONTROL:
        return <SourceAccountListContainer loading={this.isDataFetchingForLoading()} />;
      case SEARCH:
        return <SourceSearchContainer />;
      default:
        return null;
    }
  };

  isDataFetchingForLoading = () => {
    // console.log('RM, isDataFetchingForLoading; props: ', this.props);
    const { sourceProviderAccount, sourceCache, gitTreeContent } = this.props;
    const { deleteSourceAccountFetching, githubOauthTokenFetching, sourceAccountListFetching } = sourceProviderAccount;
    const { deleteSourceCacheFetching, sourceCacheListFetching, updateSourceCacheFetching } = sourceCache;
    const { isFetching: isGithubGitTreeFetching, isGitlabGitTreeFetching } = gitTreeContent;

    return (
      deleteSourceAccountFetching ||
      githubOauthTokenFetching ||
      sourceAccountListFetching ||
      deleteSourceCacheFetching ||
      sourceCacheListFetching ||
      updateSourceCacheFetching ||
      isGithubGitTreeFetching ||
      isGitlabGitTreeFetching
    );
  };

  render() {
    return (
      <div style={{ height: '100%' }} className={'apps-and-repositories-container'}>
        {this.getTitle()}
        {this.getCurrentComponent()}
      </div>
    );
  }
}
