import {
  SOURCE_TABS_SELECTION_CHANGE,
  SOURCE_TABS_SELECTION_CHANGE_REQUEST,
  LAYOUT_CHANGE,
} from '../constants/source-tabs';

export const changeSourceTabSelection = (sourceTabIndex, lineNo) => {
  return {
    type: SOURCE_TABS_SELECTION_CHANGE,
    payload: { sourceTabIndex: sourceTabIndex, lineNo: lineNo },
  };
};

export const changeSourceTabSelectionRequest = (sourceTabIndex, lineNo) => {
  return {
    type: SOURCE_TABS_SELECTION_CHANGE_REQUEST,
    payload: { sourceTabIndex: sourceTabIndex, lineNo: lineNo },
  };
};

export const changeLayout = () => {
  return {
    type: LAYOUT_CHANGE,
    payload: {},
  };
};
