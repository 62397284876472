import {
  NOTIFY_SOURCE_FOCUS,
  NOTIFY_SOURCE_NOT_AVAILABLE,
  NOTIFY_FIND_AND_ADD_SOURCE,
  NOTIFY_SOURCE_EXISTED,
  NOTIFY_SOURCE_SELECT,
} from '../constants/source-focus';

const initialState = {
  sourceTabIndex: 0,
  lineNo: 0,
  notifyStatus: '',
};

export default function sourceFocus(state = initialState, action) {
  switch (action.type) {
    case NOTIFY_SOURCE_FOCUS:
    case NOTIFY_SOURCE_NOT_AVAILABLE:
    case NOTIFY_FIND_AND_ADD_SOURCE:
    case NOTIFY_SOURCE_EXISTED:
    case NOTIFY_SOURCE_SELECT:
      return {
        ...state,
        notifyStatus: action.type,
        sourceTabIndex: action.payload.sourceTabIndex,
        lineNo: action.payload.lineNo,
      };
    default:
      return state;
  }
}
