import { connect } from 'react-redux';
import { getUserRepositoryBranches } from 'store/actions';
import { SourceRepoBranchSelector } from 'components/GitConnect/SourceRepoBranchSelector';

const mapStateToProps = store => ({
  gitBranchData: store.gitBranches,
});

const mapDispatchToProps = dispatch => ({
  getUserRepositoryBranches: (sourceProvider, owner, repo) =>
    dispatch(getUserRepositoryBranches(sourceProvider, owner, repo)),
});

export const SourceRepoBranchSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(SourceRepoBranchSelector);
