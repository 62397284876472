import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_WORKSPACE_BILLING_ACCOUNT } from '../constants/workspace-get-billing-account';

export const getWorkspaceBillingAccount = workspaceId => {
  return {
    type: GET_WORKSPACE_BILLING_ACCOUNT,
    payload: axios.get(CONFIG.apiUrl + `/workspace/${workspaceId}/accounts/billing`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
