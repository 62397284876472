import {
  CREATE_WORKSPACE_FULFILLED,
  CREATE_WORKSPACE_PENDING,
  CREATE_WORKSPACE_REJECTED,
} from 'store/constants/workspace-create';

const createWorkspaceInitialState = {
  createdWorkspace: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function createWorkspace(state = createWorkspaceInitialState, action) {
  switch (action.type) {
    case CREATE_WORKSPACE_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case CREATE_WORKSPACE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        createdWorkspace: action.payload.data,
      };
    case CREATE_WORKSPACE_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
