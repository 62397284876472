import {
  GET_GITHUB_REPOS_FETCHING,
  GET_GITHUB_REPOS_FETCHED,
  GET_GITHUB_REPOS_ERROR,

  GET_GITLAB_REPOS_FETCHING,
  GET_GITLAB_REPOS_FETCHED,
  GET_GITLAB_REPOS_ERROR,
} from '../constants/git-repositories';

const initialState = {
  repoData: [], // githubRepoData
  isFetched: false,
  isFetching: false,
  isError: false,

  gitlabRepoData: [],
  isGitlabRepoDataFetched: false,
  isGitlabRepoDataFetching: false,
  isGitlabRepoDataError: false,
};

const repoReducer = (state = initialState, action) => {
  switch (action.type) {
    // GITHUB
    case GET_GITHUB_REPOS_FETCHING:
      return Object.assign({}, state, {
        repoData: [],
        isFetched: false,
        isFetching: true,
        isError: false,
      });
    case GET_GITHUB_REPOS_FETCHED:
      console.log('reducers, users, GET_GITHUB_REPOS_FETCHED; action: ', action);

      return Object.assign({}, state, {
        repoData: [].concat(action.payload.data),
        isFetched: true,
        isFetching: false,
        isError: false,
      });
    case GET_GITHUB_REPOS_ERROR:
      return Object.assign({}, state, {
        isFetched: false,
        isError: true,
        isFetching: false,
      });

    // GITLAB
    case GET_GITLAB_REPOS_FETCHING:
      return Object.assign({}, state, {
        gitlabRepoData: [],
        isGitlabRepoDataFetched: false,
        isGitlabRepoDataFetching: true,
        isGitlabRepoDataError: false,
      });
    case GET_GITLAB_REPOS_FETCHED:
      console.log('reducers, users, GET_GITLAB_REPOS_FETCHED; action: ', action);

      return Object.assign({}, state, {
        gitlabRepoData: [].concat(action.payload.data),
        isGitlabRepoDataFetched: true,
        isGitlabRepoDataFetching: false,
        isGitlabRepoDataError: false,
      });
    case GET_GITLAB_REPOS_ERROR:
      return Object.assign({}, state, {
        isGitlabRepoDataFetched: false,
        isGitlabRepoDataError: true,
        isGitlabRepoDataFetching: false,
      });
    default:
      return state;
  }
};

export default repoReducer;
