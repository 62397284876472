import React, { Component } from 'react';
import { Button, Dropdown, GridColumn, GridRow, Icon, Modal } from 'semantic-ui-react';
import { ToastConfig } from 'utils/toast-util';
import { toast } from 'react-toastify';
import { InviteUsersModal } from 'components/InviteUsers';

class BillingUserSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModalOpen: false,
      confirmModalAction: null,
      confirmModalContent: '',
      inviteUsersModalOpen: false,
    };
  }

  closeConfirmModal = reload => {
    reload && this.reloadActiveWorkspace();
    this.setState({ confirmModalOpen: false, confirmModalAction: null, confirmModalContent: '' });
  };

  closeInviteModal = reload => {
    reload && this.props.getBillingAccount();
    this.setState({ inviteUsersModalOpen: false });
  };

  createOptions = () => {
    return this.props.userList.map(({ userInfo, account }) => ({
      key: account.id,
      text: userInfo.fullname,
      value: account.accountId,
      active: false,
      selected: false,
    }));
  };

  render() {
    return (
      <>
        <GridRow>
          <GridColumn>
            <div className={'small-header'}>Billing User</div>
            <div className={'small-header-caption'}>
              Set or invite your workspace's billing user. Billing user can manage payment method and billing plan for
              this workspace.
            </div>
          </GridColumn>
        </GridRow>
        <GridRow className={'last-section-row'}>
          <GridColumn className={'billing-user-selection'}>
            <div className={'label'}>Billing user</div>
            <Dropdown
              className={'billing-user-combo'}
              icon={null}
              search
              value={this.props.billingAccount?.workspaceBillingAccount?.account?.accountId || null}
              selectOnBlur={false}
              placeholder="Enter an user name"
              options={this.createOptions()}
              onChange={(e, { value }) =>
                this.setState({
                  confirmModalOpen: true,
                  confirmModalContent: (
                    <div className={'do-you-want-to-proceed'}>
                      <div>
                        You are about to change payment user. If there is an user whose role is defined as billing user,
                        it will be kicked automatically.
                      </div>
                      <div>Do you want to proceed?</div>
                    </div>
                  ),
                  confirmModalAction: () =>
                    this.props.setBillingAccount(
                      value,
                      () => {
                        this.setState({ confirmModalOpen: false, confirmModalAction: null });
                        this.props.getBillingAccount();
                      },
                      () => {
                        this.setState({ confirmModalOpen: false, confirmModalAction: null });
                        toast.error('Could not set', ToastConfig);
                      },
                    ),
                })
              }
            />
            <div className={'invite-button'} onClick={() => this.setState({ inviteUsersModalOpen: true })}>
              + Invite billing user
            </div>
          </GridColumn>
        </GridRow>

        <Modal
          className={'confirm-modal'}
          open={this.state.confirmModalOpen}
          onClose={() => this.closeConfirmModal(false)}
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            <Icon name="warning sign" />
            Are you sure?
          </Modal.Header>
          <Modal.Content>{this.state.confirmModalContent}</Modal.Content>
          <Modal.Actions>
            <Button
              className={'secondary button-step-info'}
              content={'Cancel'}
              onClick={() => this.closeConfirmModal(false)}
            />
            <Button
              loading={this.props.setWorkspaceBillingAccountResult.fetching}
              content={'Change'}
              onClick={() => this.state.confirmModalAction()}
            />
          </Modal.Actions>
        </Modal>
        <InviteUsersModal
          isOpen={this.state.inviteUsersModalOpen}
          closeModal={this.closeInviteModal}
          invitationResult={this.props.inviteBillingAdminResult}
          inviteToWorkspace={(emailList, onSuccess, onError) =>
            this.props.inviteBillingAdmin(emailList, onSuccess, onError)
          }
          modalDescription={'You can invite a billing user that only see billing settings.'}
          maxNumberOfEmails={1}
        />
      </>
    );
  }
}

export default BillingUserSection;
