import {
  DELETE_APP_AUTHORIZATION_FETCHING,
  DELETE_APP_AUTHORIZATION_FETCHED,
  DELETE_APP_AUTHORIZATION_ERROR,
} from '../constants/git-apps';

const initialState = {
  deleteAppData: [],
  isFetched: false,
  isFetching: false,
  isError: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_APP_AUTHORIZATION_FETCHING:
      return Object.assign({}, state, {
        deleteAppData: [],
        isFetched: false,
        isFetching: true,
        isError: false,
      });
    case DELETE_APP_AUTHORIZATION_FETCHED:
      console.log('reducers, git-apps, DELETE_APP_AUTHORIZATION_FETCHED; action: ', action);

      return Object.assign({}, state, {
        deleteAppData: [].concat(action.payload.data),
        isFetched: true,
        isFetching: false,
        isError: false,
      });
    case DELETE_APP_AUTHORIZATION_ERROR:
      return Object.assign({}, state, {
        isFetched: false,
        isError: true,
        isFetching: false,
      });
    default:
      return state;
  }
};

export default appReducer;
