const URL_POOL = {
  SIDEKICK_HOME: 'https://www.runsidekick.com/',
  TERMS_OF_SERVICES: 'https://www.runsidekick.com/termsofservices',
  SIDEKICK_DOCS: 'https://docs.runsidekick.com',
  SIDEKICK_BLOG: 'https://runsidekick.com/blog',
  SIDEKICK_JETBRAINS_PLUGIN: 'https://plugins.jetbrains.com/plugin/18566-sidekick',
  SIDEKICK_SUPPORT_MAIL: 'mailto:support@runsidekick.com',
  SIDEKICK_VSCODE_PLUGIN: 'https://marketplace.visualstudio.com/items?itemName=Sidekick.sidekick-debugger',
  SIDEKICK_TRACING_INTEGRATION: 'https://docs.runsidekick.com/integrations/tracing-integrations',

  SIDEKICK_INTELLIJ_PLUGIN_DOC: 'https://docs.runsidekick.com/plugins/intellij-idea-plugin',
  SIDEKICK_PYCHARM_PLUGIN_DOC: 'https://docs.runsidekick.com/plugins/pycharm-plugin-python',
  SIDEKICK_VSCODE_PLUGIN_DOC: 'https://docs.runsidekick.com/plugins/visual-studio-code-extension-python-and-node.js',
  SIDEKICK_WEBSTORM_PLUGIN_DOC: 'https://docs.runsidekick.com/plugins/webstorm-plugin-node.js',

  SIDEKICK_INSTALL_AGENT_DOC: ' https://docs.runsidekick.com/installation/installing-agents',
  SIDEKICK_LOG_POINT_DOC: 'https://docs.runsidekick.com/reference/log-points',
  SIDEKICK_IMPORT_CODE_DOC: ' https://docs.runsidekick.com/web-application/how-to/import-source-code',
  //TODO URLS WILL BE CHANGED
  SLACK_INVITATION: 'https://www.thundra.io/thundra-slack-invitation',

  ORARIEL_CALENDAR: 'https://thundra.orariel.com/calendar/user/ismailegilmez/t/1',
  CALENDLY_BARIS: 'https://calendly.com/bariskaya/30min',

  SUMOLOGIC_INTEGRATION: 'https://www.sumologic.com/',
  LOGGY_INTEGRATION: 'https://documentation.solarwinds.com/en/success_center/loggly/content/admin/http-endpoint.htm',
  LOGZIO_INTEGRATION: 'https://docs.logz.io/user-guide/accounts/account-region.html#available-regions',
  SLACK_INTEGRATION: 'https://slack.com/help/articles/115005265063-Incoming-webhooks-for-Slack',
  LOKI_INTEGRATION: 'https://grafana.com/docs/loki/latest/api/#push-log-entries-to-loki',
  CORALOGIX_INTEGRATION: 'https://coralogix.com/docs/coralogix-rest-api/',
};

export default URL_POOL;
