import { CHANGE_CURRENT_USER_PASSWORD } from 'store/constants/password';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';

export function changeCurrentUserPassword(email) {
  return {
    type: CHANGE_CURRENT_USER_PASSWORD,
    payload: axios.post(
      CONFIG.apiUrl + '/users/me/password',
      { email: email },
      { headers: { Authorization: 'Bearer ' + auth.getAccessToken() } },
    ),
  };
}
