import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_ON_PREM_IDE_TOKEN } from '../constants/onprem-ide-token';

export const getOnPremIdeToken = () => {
  return {
    type: GET_ON_PREM_IDE_TOKEN,
    payload: axios.get(CONFIG.apiUrl + `/onprem-user`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
