import React from 'react';
import { hasArrayElement } from 'utils/array-util';

export const Frames = ({ event }) => {
  let vDOM = 'NO FRAMES';
  if (event && hasArrayElement(event.frames)) {
    vDOM = event.frames.map(el => {
      const { methodName, lineNo, className } = el;
      const str = `${methodName}:${lineNo},${className}`;
      return <div>{str}</div>;
    });
  }

  return <div className="frames-container">{vDOM}</div>;
};
