import React, { Component } from 'react';
import {
  Button,
  Icon,
  Popup,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import './IntegrationsTab.scss';
import { IntegrationModal } from './IntegrationModal/IntegrationModal';
import { LoadingPage } from '../CallbackPage';
import Switch from 'react-switch';

export class IntegrationsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      editedIntegration: null,

      activePopupId: '',
      popupOpen: false,
    };
    this.activeWorkspaceId = this.props.activeWorkspace.workspace.workspace.id;
  }

  reloadTable = () => {
    this.props.getWebhooks(this.activeWorkspaceId);
  };

  reloadWorkspaceSettings = () => {
    this.props.getWorkspace(this.activeWorkspaceId);
  };

  componentDidMount() {
    this.reloadTable();
  }

  closeModal = reload => {
    reload && this.reloadTable();

    this.setState({ modalOpen: false, editedIntegration: null });
  };

  renderIntegrationsTable = () => {
    let integrations = this.props.webhookList.webhooks;

    return (
      <Table celled className={'settings-table'}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell style={{ width: '40%' }}>Name</TableHeaderCell>
            <TableHeaderCell style={{ width: '40%' }}>Target</TableHeaderCell>
            <TableHeaderCell>Sidekick Actions</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>{integrations.map(integration => this.renderIntegrationRow(integration))}</TableBody>
      </Table>
    );
  };

  renderActionMenuItems = integration => {
    const { defaultTracepointWebhookId, defaultLogpointWebhookId, defaultErrorSnapshotWebhookId } =
      this.props.activeWorkspace?.workspace?.workspaceSettings;

    return (
      <>
        <div key={'default-for-tp'}>
          <label className={'switch-container'}>
            <span>Default for tracepoint</span>
            <Switch
              className="react-switch"
              handleDiameter={16}
              height={16}
              width={32}
              onColor="#0073E6"
              onHandleColor="#FFFFFF"
              offColor="#2B2B2B"
              offHandleColor="#5E5E5E"
              uncheckedIcon={false}
              checkedIcon={false}
              checked={defaultTracepointWebhookId === integration.id}
              onChange={checked => {
                this.setDefaultProbeIntegration(checked, integration.id, 'TracePoint');
              }}
            />
          </label>
        </div>
        <div key={'default-for-lp'}>
          <label className={'switch-container'}>
            <span>Default for logpoint</span>
            <Switch
              className="react-switch"
              handleDiameter={16}
              height={16}
              width={32}
              onColor="#0073E6"
              onHandleColor="#FFFFFF"
              offColor="#2B2B2B"
              offHandleColor="#5E5E5E"
              uncheckedIcon={false}
              checkedIcon={false}
              checked={defaultLogpointWebhookId === integration.id}
              onChange={checked => {
                this.setDefaultProbeIntegration(checked, integration.id, 'LogPoint');
              }}
            />
          </label>
        </div>
        <div key={'default-for-es'}>
          <label className={'switch-container'}>
            <span>Default for error snapshot</span>
            <Switch
              className="react-switch"
              handleDiameter={16}
              height={16}
              width={32}
              onColor="#0073E6"
              onHandleColor="#FFFFFF"
              offColor="#2B2B2B"
              offHandleColor="#5E5E5E"
              uncheckedIcon={false}
              checkedIcon={false}
              checked={defaultErrorSnapshotWebhookId === integration.id}
              onChange={checked => {
                this.setDefaultProbeIntegration(checked, integration.id, 'ErrorSnapshot');
              }}
            />
          </label>
        </div>
        <div
          className={'popup-menu-item'}
          key={'edit-integration'}
          onClick={() => this.setState({ editedIntegration: integration, modalOpen: true })}
        >
          Edit
        </div>
        <div
          className={'popup-menu-item'}
          key={'delete-integration'}
          onClick={() => this.deleteIntegration(integration.id)}
          style={{ borderTop: '1px solid gray' }}
        >
          Delete
        </div>
      </>
    );
  };

  renderActionPlaceholder = integration => {
    return (
      <Popup
        onOpen={() => this.setState({ activePopupId: integration.id, popupOpen: true })}
        onClose={() => this.setState({ activePopupId: '', popupOpen: false })}
        open={this.state.popupOpen && integration.id === this.state.activePopupId}
        className={'popup-menu integrations-menu'}
        basic
        position={'bottom center'}
        key={integration.id + '_popup'}
        content={this.renderActionMenuItems(integration)}
        on={'click'}
        trigger={
          <Icon
            className={'options interactive-icon' + (integration.id === this.state.activePopupId ? ' active' : '')}
            size={'large'}
          />
        }
      />
    );
  };

  renderDefaultIcons = integration => {
    const { defaultTracepointWebhookId, defaultLogpointWebhookId, defaultErrorSnapshotWebhookId } =
      this.props.activeWorkspace?.workspace?.workspaceSettings;

    return (
      <div className={'default-icons-container'}>
        <Popup
          trigger={
            <div
              className={`tp-icon default-wh-icon ${defaultTracepointWebhookId === integration.id ? '' : 'hidden'}`}
            />
          }
          content={'Default for tracepoint'}
          position="top center"
        />
        <Popup
          trigger={
            <div
              className={`es-icon default-wh-icon ${defaultErrorSnapshotWebhookId === integration.id ? '' : 'hidden'}`}
            />
          }
          content={'Default for error snapshot'}
          position="top center"
        />
        <Popup
          trigger={
            <div className={`lp-icon default-wh-icon ${defaultLogpointWebhookId === integration.id ? '' : 'hidden'}`} />
          }
          content={'Default for logpoint'}
          position="top center"
        />
      </div>
    );
  };

  renderHeader = () => {
    return (
      <div className={'integrations-header'}>
        <div className={'small-header'}>All Integrations</div>
        <Button onClick={() => this.setState({ modalOpen: true })} primary className={'small-button new-integration'}>
          + Add new Integration
        </Button>
      </div>
    );
  };

  renderIntegrationRow = integration => {
    return (
      <TableRow key={integration.id}>
        <TableCell>{integration.name}</TableCell>
        <TableCell>{integration.type}</TableCell>
        <TableCell className={'actions-cell'}>
          {this.renderDefaultIcons(integration)}
          {this.renderActionPlaceholder(integration)}
        </TableCell>
      </TableRow>
    );
  };

  createIntegration = (type, name, config) => {
    this.props.createIntegration(
      this.activeWorkspaceId,
      type,
      name,
      config,
      () => this.closeModal(true),
      () => {
        this.closeModal(false);
      },
    );
  };

  updateIntegration = (type, name, config, id) => {
    this.props.updateIntegration(
      this.activeWorkspaceId,
      type,
      name,
      config,
      id,
      () => this.closeModal(true),
      () => {
        this.closeModal(false);
      },
    );
  };

  deleteIntegration = id => {
    this.props.deleteIntegration(id, () => this.reloadTable());
  };

  setDefaultProbeIntegration = (set, integrationId, probeType) => {
    const integrationIdQueryParam = set ? integrationId : null;
    this.props.setDefaultProbeWebhook(this.activeWorkspaceId, probeType, integrationIdQueryParam, () => {
      this.setState({ popupOpen: false, activePopupId: '' });
      this.reloadTable();
      this.reloadWorkspaceSettings();
    });
  };

  createModalAction = () => {
    if (this.state.editedIntegration) {
      return (type, name, config) => this.updateIntegration(type, name, config, this.state.editedIntegration.id);
    }
    return (type, name, config) => this.createIntegration(type, name, config);
  };

  render() {
    if (this.props.webhookList.fetching || this.props.deleteWebhookResult.fetching) {
      return <LoadingPage />;
    }
    return (
      <>
        <div className={'integrations-tab-root'}>
          {this.renderHeader()}
          {this.renderIntegrationsTable()}
        </div>
        {this.state.modalOpen && (
          <IntegrationModal
            modalOpen={this.state.modalOpen}
            onClose={this.closeModal}
            integration={this.state.editedIntegration}
            onSubmit={this.createModalAction()}
            onSwitchMode={() => this.setState({ editedIntegration: null })}
            loading={this.props.createWebhookResult.fetching || this.props.updateWebhookResult.fetching}
          />
        )}
      </>
    );
  }
}
