import { CREATE_WORKSPACE } from '../constants/workspace-create';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';

export const createWorkspace = workspaceInfo => {
  return {
    type: CREATE_WORKSPACE,
    payload: axios.post(CONFIG.apiUrl + '/workspaces', workspaceInfo, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
