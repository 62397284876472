import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import './InviteUser.scss';
import { validateEmail } from 'utils/email-util';

class InviteUsers extends Component {
  constructor(props) {
    super(props);
    this.maxNumberOfEmails = this.props.maxNumberOfEmails || 10;
    this.state = {
      emails: [{ email: '', erroneous: false }],
    };
  }

  isEmailsValid = () => {
    let emails = this.state.emails;
    let result = true;
    let firstValid = false;
    emails.forEach((email, index, array) => {
      let validationResult = validateEmail(email.email);
      array[index].erroneous = !validationResult;
      if (!firstValid && validationResult) {
        firstValid = true;
      }
      if (firstValid && email.email === '') {
        array[index].erroneous = false;
        return;
      }
      result = result && validationResult;
    });
    this.setState({ emails: emails });

    return result;
  };

  getEmailInput = (email, index) => {
    return (
      <Input
        className={`email-container ${email.erroneous ? ' erroneous-email' : ''}`}
        placeholder={'Enter e-mail'}
        value={email.email}
        onChange={event => this.handleEmailChange(index, event)}
        onBlur={() => this.handleInputBlur(index)}
      />
    );
  };

  addEmail = () => {
    this.setState(({ emails }) => ({ emails: [...emails, { email: '', erroneous: false }] }));
  };

  handleEmailChange = (index, event) => {
    let email = event.target.value;

    this.setState(prevState => {
      let { emails } = prevState;
      emails[index] = { email, erroneous: !validateEmail(email) };
      return { ...prevState, emails: emails };
    });
  };

  handleInputBlur = index => {
    this.setState(prevState => {
      let { emails } = prevState;
      let currentEmail = emails[index];
      emails[index] = { ...currentEmail, erroneous: !validateEmail(currentEmail.email) };
      return { ...prevState, emails: emails };
    });
  };

  removeEmail = () => {
    this.setState(({ emails }) => {
      emails.pop();
      return { emails: emails };
    });
  };
  renderEmailRows = () => {
    return this.state.emails.map((email, index) => {
      if (index !== 0 && index === this.state.emails.length - 1) {
        return (
          <div className={'new-email-row'}>
            {this.getEmailInput(email, index)}
            <span
              onClick={() => {
                this.removeEmail();
              }}
            >
              ---
            </span>
          </div>
        );
      }
      return <>{this.getEmailInput(email, index)}</>;
    });
  };

  render() {
    return (
      <div className={'invite-users-container'}>
        <div className={'email-rows'}>
          {this.renderEmailRows()}
          {this.state.emails.length < this.maxNumberOfEmails && (
            <span className={'anchor-like add-user'} onClick={this.addEmail}>
              + Add user
            </span>
          )}
        </div>
        <div className={'actions-container'}>
          {this.props.render(
            this.state.emails.map(email => email.email),
            this.isEmailsValid,
          )}
        </div>
      </div>
    );
  }
}

export default InviteUsers;
