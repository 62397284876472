import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import './EventsFilter.scss';

export class EventsFilter extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.timeout = 0;
    this.state = { filter: '', typed: false };
  }

  handleChange(e) {
    const filter = e.target.value + '';
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = 0;
    }

    this.setState({ filter: filter, typed: true });
    this.timeout = setTimeout(() => {
      this.setState({ typed: false }, this.props.setClientFilter(filter));
    }, 500);
  }

  componentWillReceiveProps(nextProps) {
    const nextClientFilter = nextProps.clientFilter;
    const currentClientFilter = this.props.clientFilter;

    if (nextClientFilter !== currentClientFilter && !this.state.typed) {
      this.setState({ filter: nextClientFilter });
    }
  }

  render() {
    return (
      <div className="event-list-search-container">
        <Input
          className="search-box"
          size="mini"
          icon="search"
          fluid={true}
          iconPosition="left"
          onChange={this.handleChange}
          placeholder={this.props?.placeholder || 'Filter by text...'}
          value={this.state.filter}
        />
      </div>
    );
  }
}
