import React, { Component } from 'react';
import { Image, Loader } from 'semantic-ui-react';
import auth from 'auth/auth-factory';
import lock from 'auth/lock-factory';
import routeList from 'routes';
import './landing-hammer.scss';
import './LandingPage.scss';

import awsLambdaDevops from 'assets/images/landing-Lambda+Devops.svg';
import gdprBadge from 'assets/images/landing-GDPR.svg';
import sidekickLogo from 'assets/images/Login-Sidekick-Logo.svg';
import URL_POOL from 'utils/url-pool';

// Keep LandingPage for auth0 consent_required error while developing @localhost!!
// LandingPage is not required/showed except local development environments.!
class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoginFormShown: true,
    };
  }

  componentWillMount() {
    console.log('SIDEKICK, LP, CWM; props: ', this.props);
    // This is added if user comes from an email-link upon invite,
    // then he needs to logout if at any tab he is already logged in.
    if (this.isForceLogoutTrue()) {
      console.log('CWM, isForceLogoutTrue');
      // auth.logout(false);
    } else if (auth.isAuthenticated()) {
      this.props.history.replace(routeList.ide.path);
    } else {
      console.log('render landing page, no redirection');
    }
  }

  componentDidMount() {
    const { pathname, search } = this.props.location;

    const params = new URLSearchParams(search);
    const onboardingSignupSource = params.get('from');

    const workspaceId = params.get('workspaceId');
    const invited = params.get('invited');

    const port = params.get('port');
    const ide = params.get('ide');

    if (invited && workspaceId) {
      localStorage.setItem('workspace-id', workspaceId);
    }

    if (port) {
      localStorage.setItem('port', port);
    }

    if (ide) {
      localStorage.setItem('ide', ide);
    }

    if (search && onboardingSignupSource) {
      // Here to put item to local storage if user came from an 3rd party integration page,
      // url should have 'from' url param.
      localStorage.setItem('onboarding-signupsource-token', onboardingSignupSource);
    }

    const initialScreen = pathname === '/signup' ? 'signUp' : 'login';
    lock.show({
      initialScreen: initialScreen,
    });

    lock.on('signin ready', () => {
      this.loginTabShown();
    });

    lock.on('signup ready', () => {
      this.signupTabShown();
    });
  }

  loginTabShown = () => {
    console.log('loginTabShown');
    this.setState({
      isLoginFormShown: true,
    });
  };
  signupTabShown = () => {
    console.log('signupTabShown');
    this.setState({
      isLoginFormShown: false,
    });
  };

  isForceLogoutTrue = () => {
    // Parse url and find if 'forceLogout' is true.
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
      url = window.location.href,
      params = {},
      match;
    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }

    return params.forceLogout === 'true';
  };

  isSignupFormTrue = () => {
    // Parse url and find if 'signupForm' is true.
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
      url = window.location.href,
      params = {},
      match;
    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }

    return params.signupForm === 'true';
  };

  render() {
    console.log('LandingPage, render; props: ', this.props, window.location.href);

    return (
      <div className="landing-page">
        <div className="landing-page-wrapper">
          <div className="thundra-logo">
            <div className="image">
              <Image src={sidekickLogo} fluid />
            </div>
          </div>

          <div className="landing-page-right-container">
            <div id="login-container" className="action-rect">
              {/* Auth0 is embbedded in here*/}
            </div>

            {this.isSignupFormTrue() && (
              <div className="landing-loader">
                <Loader active inline>
                  Welcome to Sidekick...
                </Loader>
              </div>
            )}

            <div className="certificates">
              <div className="image aws">
                <Image src={awsLambdaDevops} fluid />
              </div>
              <div className="image gdpr">
                <Image src={gdprBadge} fluid />
              </div>
            </div>

            <div className="tos-copyright">
              <a href={URL_POOL.TERMS_OF_SERVICES} rel="noopener noreferrer" target="_blank">
                Terms of Service
              </a>
              <span className="interpunct-span">•</span>
              <span>Copyright © {new Date().getFullYear()}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
