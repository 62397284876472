import { connect } from 'react-redux';
import { AppsAndRepositories } from 'components';
import {
  addSourceProviderAccount,
  getGithubAccessToken,
  getGitlabAccessToken,
  getSourceCacheList,
  getSourceListLimit,
  getSourceProviderAccountList,
  selectGitSourceCache,
} from 'store/actions';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { withRouter } from 'react-router-dom';

function mapStateToProps(store) {
  return {
    ideAppsAndRepositoriesManagement: store.ideAppsAndRepositoriesManagement,
    broker: store.broker,
    appsAndTracePoints: store.appsAndTracePoints,

    sourceProviderAccount: store.sourceProviderAccount,
    gitTreeContent: store.gitTree,

    sourceCache: store.sourceCache,
    activeWorkspace: store.activeWorkspace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGithubAccessToken(code, onGetGithubAccessTokenSuccess, onGetGithubAccessTokenFail) {
      dispatchWithExceptionHandler(
        getGithubAccessToken(code),
        false,
        onGetGithubAccessTokenSuccess,
        onGetGithubAccessTokenFail,
      );
    },
    getGitlabAccessToken(code, onGetGitlabAccessTokenSuccess, onGetGitlabAccessTokenFail) {
      dispatchWithExceptionHandler(
        getGitlabAccessToken(code),
        false,
        onGetGitlabAccessTokenSuccess,
        onGetGitlabAccessTokenFail,
      );
    },
    addSourceProviderAccount(sourceAccount, onAddSourceProviderAccountSuccess, onAddSourceProviderAccountFail) {
      console.log('RMC, addSourceProviderAccount; sourceAccount: ', sourceAccount);
      dispatchWithExceptionHandler(
        addSourceProviderAccount(sourceAccount),
        false,
        onAddSourceProviderAccountSuccess,
        onAddSourceProviderAccountFail,
      );
    },
    getSourceCacheList(workspaceId, onGetSourceCacheListSuccess, onGetSourceCacheListFail) {
      dispatchWithExceptionHandler(
        getSourceCacheList(workspaceId),
        false,
        onGetSourceCacheListSuccess,
        onGetSourceCacheListFail,
      );
    },
    getSourceListLimit(onGetSourceListLimitSuccess, onGetSourceListLimitFail) {
      dispatchWithExceptionHandler(getSourceListLimit(), false, onGetSourceListLimitSuccess, onGetSourceListLimitFail);
    },
    getSourceProviderAccountList(onGetSourceProviderAccountListSuccess, onGetSourceProviderAccountListFail) {
      dispatchWithExceptionHandler(
        getSourceProviderAccountList(),
        false,
        onGetSourceProviderAccountListSuccess,
        onGetSourceProviderAccountListFail,
      );
    },
    selectGitSourceCache(selectedSource) {
      dispatch(selectGitSourceCache(selectedSource));
    },
  };
}

export const AppsAndRepositoriesContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppsAndRepositories),
);
