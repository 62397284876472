import { UPDATE_USER_PROFILE } from 'store/constants/user-info';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';

export function updateUserProfile(profileInfo) {
  console.log('action, updateUserProfile; profileInfo: ', profileInfo);

  return {
    type: UPDATE_USER_PROFILE,
    payload: axios.patch(CONFIG.apiUrl + '/users/me/profile', profileInfo, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
