import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { send as brokerSend } from '@giantmachines/redux-websocket';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { getWorkspace, getWorkspaces, notifyIDEContextReset } from 'store/actions';
import { WorkspaceSwitcher } from 'components';

function mapStateToProps(store) {
  return {
    workspaceList: store.workspaceList,
    activeWorkspace: store.activeWorkspace,
    broker: store.broker,
    sourceCache: store.sourceCache,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeActiveWorkspace: switchWorkspaceCmdObj => {
      dispatch(brokerSend(switchWorkspaceCmdObj));
    },
    getWorkspaces(onSuccess) {
      dispatchWithExceptionHandler(getWorkspaces(), false, onSuccess);
    },
    getActiveWorkspace(workspaceId) {
      dispatch(getWorkspace(workspaceId));
    },
    handleNotifyIDEContextReset(sourceProvider, repoOwner, repoName, branch, commit) {
      dispatch(notifyIDEContextReset(sourceProvider, repoOwner, repoName, branch, commit));
    },
  };
}

export const WorkspaceSwitcherContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspaceSwitcher));
