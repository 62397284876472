import React, { Component } from 'react';
import './MainLayout.scss';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { ThundraSidebarContainer } from 'containers';
import { ToastContainer } from 'react-toastify';

class MainLayout extends Component {
  render() {
    // console.log("MainLayout; props: ", this.props);
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.title}</title>
        </Helmet>

        <div className="screen-size-message">
          <span>Sidekick is best experienced on wider screens</span>
        </div>

        <ThundraSidebarContainer>
          {this.props.children}

          <ToastContainer
            className="toast-container"
            toastClassName="toast-content"
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </ThundraSidebarContainer>
      </>
    );
  }
}

MainLayout.propTypes = {
  title: PropTypes.string,
};

MainLayout.defaultProps = {
  title: '',
};

export default MainLayout;
