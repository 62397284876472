import { connect } from 'react-redux';
import { ExportEvent } from 'components';
import { setEventToSend } from 'store/actions';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setEventToSend(event) {
      dispatch(setEventToSend(event));
    },
  };
}

export const ExportEventContainer = connect(mapStateToProps, mapDispatchToProps)(ExportEvent);
