import React, { Component } from 'react';
import './BrokerConnect.scss';
import auth from 'auth/auth-factory';
import { CONFIG } from 'utils/config-util';
import { Icon, Popup } from 'semantic-ui-react';
import { listAppCmdObj, listLogPointsCmdObj, listTracePointsCmdObj } from 'utils/broker-commands';
import { generateUUID } from 'utils/object-util';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';

export class BrokerConnect extends Component {
  componentDidMount() {
    //Connect to Broker
    if (!CONFIG?.ON_PREM) {
      const token = auth.getAccessToken();
      this.handleBrokerOpen(token);
    }
  }

  handleBrokerOpen = token => {
    const ENDPOINT = `${CONFIG.REACT_APP_BROKER_URL}${token}`;
    this.props.handleBrokerOpen(ENDPOINT);
    this.brokerPullAppsIntervalId = setInterval(this.sendListAppCommand, 3000);
  };

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    //ide token fetched first time
    if (!prevProps.onPremIdeToken?.fetched && this.props.onPremIdeToken?.fetched) {
      const token = this.props.onPremIdeToken.ideToken;
      this.handleBrokerOpen(token);
    }
  }

  componentWillUnmount() {
    clearInterval(this.brokerPullAppsIntervalId);
  }

  sendListAppCommand = () => {
    const { brokerConnectionStatus } = this.props.broker;
    if (brokerConnectionStatus) {
      this.props.handleBrokerSend({ ...listAppCmdObj, id: generateUUID() }); //HeartBit For Broker
      this.props.handleBrokerSend({ ...listTracePointsCmdObj, id: generateUUID() });
      this.props.handleBrokerSend({ ...listLogPointsCmdObj, id: generateUUID() });
      this.props.handleCheckWaitingTimeouts();
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { reconnectionTryCount } = nextProps.broker;
    if (reconnectionTryCount === 3) {
      toast.error(
        `There is a problem while connecting to the broker. Please check your internet connection. If the connection does not succeed, please reach our support team.`,
        ToastConfig,
      );
    }
  }

  render() {
    const { brokerConnectionStatus } = this.props.broker;
    const classStatus = brokerConnectionStatus ? 'connected' : 'disconnected';
    const iconClassName = brokerConnectionStatus ? 'icon-sidebar-bug-connect' : 'icon-sidebar-bug-disconnect';
    return (
      <Popup
        content={<span>{brokerConnectionStatus ? 'Broker Connected' : 'Broker Disconnected'}</span>}
        position={'right center'}
        trigger={
          <div className={`broker-connection-container ${classStatus}`}>
            <Icon className={iconClassName} />
          </div>
        }
        basic
      />
    );
  }
}
