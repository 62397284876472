import { connect } from 'react-redux';
import { RepositoryManagement } from 'components';

function mapStateToProps(store) {
  return {
    sourceCache: store.sourceCache,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const RepositoryManagementContainer = connect(mapStateToProps, mapDispatchToProps)(RepositoryManagement);
export default RepositoryManagementContainer;
