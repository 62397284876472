import React, { Component } from 'react';
import ReactMonacoTree from '@runsidekick/sidekick-monaco-tree';
import { hasArrayElement } from 'utils/array-util';
import { generateFullGitSourceURLPath } from 'utils/string-util';
import { SOURCE_PROVIDER_PLATFORM } from 'utils/source-provider-util';
import './SourceFileTree.scss';

export default class SourceFileTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataPathList: [],
      gitlabPathIdMap: null,
    };
  }

  componentDidMount() {
    const { selectedRepoOwnerLogin, selectedRepoName, selectedRepoBranchSha, gitSourceProvider } = this.props;
    console.log(
      'CDM, SourceFileTree; selectedRepoOwnerLogin, selectedRepoName, selectedRepoBranchSha: ',
      selectedRepoOwnerLogin,
      selectedRepoName,
      selectedRepoBranchSha,
    );
    this.props.getGitTree(gitSourceProvider, selectedRepoOwnerLogin, selectedRepoName, selectedRepoBranchSha, () => {
      // console.log("success = before parseGitTree");
      this.parseGitTree();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Call getGitTree if branch etc. changes.
    // console.log("SourceFileTree, CDU; prevProps, props, state: ", prevProps, this.props, this.state);

    const { selectedRepoOwnerLogin, selectedRepoName, selectedRepoBranchSha, gitSourceProvider } = this.props;

    if (
      prevProps.selectedRepoOwnerLogin !== selectedRepoOwnerLogin ||
      prevProps.selectedRepoName !== selectedRepoName ||
      prevProps.selectedRepoBranchSha !== selectedRepoBranchSha
    ) {
      // console.log("SourceFileTree, CDU, UPDATE");

      this.props.getGitTree(gitSourceProvider, selectedRepoOwnerLogin, selectedRepoName, selectedRepoBranchSha, () => {
        // console.log("SourceFileTree, CDU, getGitTree success; props, selectedRepoOwnerLogin, selectedRepoName, selectedRepoBranchSha: ",
        // 	this.props, selectedRepoOwnerLogin, selectedRepoName, selectedRepoBranchSha
        // );
        this.parseGitTree();
      });
    }
  }

  parseGitTree = () => {
    const { gitSourceProvider } = this.props;

    if (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
      this.parseGitTreeGithub();
    }
    if (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
      this.parseGitTreeGitlab();
    }
  };

  // GITLAB
  parseGitTreeGitlab = () => {
    const { selectedRepoName, gitTreeContent } = this.props;
    let gitTreeArr = gitTreeContent.gitlabGitTreeData;

    let treebeardData = {
      name: selectedRepoName,
      id: 1,
      toggled: true,
      children: [],
    };

    let gitlabPathIdMap = {};
    const gitTreeBlobsArr = gitTreeArr.filter(item => item.type === 'blob');
    let treeBeardDataChildrenArr = gitTreeBlobsArr.map(item => {
      // We need a path-id map for Gitlab to find easily the clicked file's id.
      gitlabPathIdMap[item.path] = item.id;

      return {
        name: item.path,
        path: item.path,
        sha: item.id,
        // type: blob/tree
      };
    });
    treebeardData.children = treeBeardDataChildrenArr;

    const dataPathList = treeBeardDataChildrenArr.map(el => el.path);
    // console.log("SourceFileTree - GITLAB, parseGitTree; dataPathList: ", dataPathList);

    this.setState({
      dataPathList: dataPathList,
      gitlabPathIdMap: gitlabPathIdMap,
    });
  };

  // GITHUB
  parseGitTreeGithub = () => {
    const { selectedRepoName, gitTreeContent } = this.props;
    let gitTreeArr = [];
    if (hasArrayElement(gitTreeContent.gitTreeData)) {
      gitTreeArr = gitTreeContent.gitTreeData[0].tree;
    }

    let treebeardData = {
      name: selectedRepoName,
      id: 1,
      toggled: true,
      children: [],
    };

    const gitTreeBlobsArr = gitTreeArr.filter(item => item.type === 'blob');
    let treeBeardDataChildrenArr = gitTreeBlobsArr.map(item => {
      return {
        name: item.path,
        path: item.path,
        sha: item.sha,
        // size: item.size,
        // url: item.url
        // type: blob/tree
      };
    });
    treebeardData.children = treeBeardDataChildrenArr;
    // console.log("parseGitTree; treeBeardDataChildrenArr, treebeardData: ", treeBeardDataChildrenArr, treebeardData);

    const dataPathList = treeBeardDataChildrenArr.map(el => el.path);
    // console.log("SourceFileTree, parseGitTree; dataPathList: ", dataPathList);

    this.setState({
      dataPathList: dataPathList,
    });
  };

  // Check below logs when https://thundra.fibery.io/favorites/Software_Development/Cycle-Board---My-Tasks-140#Software_Development/bug/When-multi-repos-connected-to-Sidekick,-it-cannot-browse-files-after-switching-the-another-repo-35 reproduced
  handleGitTreeFileClick = filePath => {
    const { gitlabPathIdMap } = this.state;
    const { selectedRepoOwnerLogin, selectedRepoName, selectedRepoBranchSha, gitSourceProvider } = this.props;
    console.log(
      'handleGitTreeFileClick; selectedRepoOwnerLogin, selectedRepoName, filePath, selectedRepoBranchSha: ',
      selectedRepoOwnerLogin,
      selectedRepoName,
      filePath,
      selectedRepoBranchSha,
    );

    // Github uses commit sha, Gitlab uses blob id(sha).
    let refSha = selectedRepoBranchSha;
    if (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
      refSha = gitlabPathIdMap[filePath];
    }

    this.props.getUserRepoContent(gitSourceProvider, selectedRepoOwnerLogin, selectedRepoName, filePath, refSha, () => {
      const fileFullPath = generateFullGitSourceURLPath(
        gitSourceProvider,
        selectedRepoOwnerLogin,
        selectedRepoName,
        filePath,
        refSha, // sha or id depending on source provider
      );
      console.log('SFT, handleGitTreeFileClick; fileFullPath: ', fileFullPath);

      this.props.handleSourceFocus(fileFullPath, 0);
    });
  };

  renderReactMonacoTree = dataPathList => {
    return <ReactMonacoTree filePathList={dataPathList} onFolderTreeFileClick={this.handleGitTreeFileClick} />;
  };

  render() {
    // console.log('SourceFileTree, render; props, state: ', this.props, this.state);

    return (
      <div className="github-file-tree-wrapper">
        {this.state.dataPathList.length > 0 && this.renderReactMonacoTree(this.state.dataPathList)}
      </div>
    );
  }
}
