import { SA_EDIT_NOTIFY, SA_EDIT_TAB_OPEN_IF_CLOSED } from '../constants/sidekick-action-edit';

export const notifySidekickActionEdit = sidekickAction => {
  return {
    type: SA_EDIT_NOTIFY,
    payload: { sidekickAction: sidekickAction },
  };
};

export const openSidekickActionEditTab = sidekickAction => {
  return {
    type: SA_EDIT_TAB_OPEN_IF_CLOSED,
    payload: { sidekickAction: sidekickAction },
  };
};
