import React, { Component } from 'react';
import { MainLayout } from 'layouts';
import routeList from 'routes';
import {
  IDEComplexLayoutContainer,
  IssueManagementContainer,
  OnboardingWizardContainerV2,
  SubscriptionExpiredModalContainer,
} from 'containers';
import { LoadingPage } from '../CallbackPage';
import { CONFIG } from 'utils/config-util';

export class IDEPage extends Component {
  render() {
    const onboardingDisabled = CONFIG?.ON_PREM && CONFIG?.EXCLUDED_FEATURES.includes('onboarding');
    if (this.props.activeWorkspace.fetching || !this.props.activeWorkspace.fetched) {
      return <LoadingPage />;
    }
    return (
      <MainLayout title={routeList.ide.title}>
        <IDEComplexLayoutContainer />
        {!onboardingDisabled && <OnboardingWizardContainerV2 />}
        <SubscriptionExpiredModalContainer />
        <IssueManagementContainer />
      </MainLayout>
    );
  }
}
