import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { SEND_TO_WEBHOOK } from '../constants/webhook-send';

export const sendToWebhook = (webhookIds, webhookMessageType, event) => {
  return {
    type: SEND_TO_WEBHOOK,
    payload: axios.post(
      CONFIG.apiUrl + '/webhooks/sendEvent',
      { webhookIds, webhookMessageType, event: JSON.stringify(event) },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
};
