export function isEmpty(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

//function to sort object of the keys while converting to string
export const replacer = (key, value) =>
  value instanceof Object && !(value instanceof Array)
    ? Object.keys(value)
        .sort()
        .reduce((sorted, key) => {
          sorted[key] = value[key];
          return sorted;
        }, {})
    : value;

export function generateVariables(frames) {
  let variables = [];
  for (const [key, val] of Object.entries(frames[0].variables)) {
    if (key === 'this') continue;
    variables.push(key);
    if (isObject(val['@value'])) {
      variables = variables.concat(spreadVariables(key, val['@value']));
    }
  }

  return variables;
}

function spreadVariables(prefix, variable) {
  let variables = [];

  for (const [key, val] of Object.entries(variable)) {
    variables.push(prefix + '.' + key);
    if (isObject(val['@value'])) {
      variables.concat(spreadVariables(prefix + '.' + key, val['@value']));
    }
  }
  return variables;
}

function isObject(item) {
  return typeof item === 'object' && !Array.isArray(item) && item !== null;
}
