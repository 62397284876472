export const GET_GITHUB_REPO_BRANCHES_FETCHING = 'GET_GITHUB_REPO_BRANCHES_FETCHING';
export const GET_GITHUB_REPO_BRANCHES_FETCHED = 'GET_GITHUB_REPO_BRANCHES_FETCHED';
export const GET_GITHUB_REPO_BRANCHES_ERROR = 'GET_GITHUB_REPO_BRANCHES_ERROR';

export const GET_GITLAB_REPO_BRANCHES_FETCHING = 'GET_GITLAB_REPO_BRANCHES_FETCHING';
export const GET_GITLAB_REPO_BRANCHES_FETCHED = 'GET_GITLAB_REPO_BRANCHES_FETCHED';
export const GET_GITLAB_REPO_BRANCHES_ERROR = 'GET_GITLAB_REPO_BRANCHES_ERROR';

//
export const GET_GITHUB_BRANCH_HEAD_FETCHING = 'GET_GITHUB_BRANCH_HEAD_FETCHING';
export const GET_GITHUB_BRANCH_HEAD_FETCHED = 'GET_GITHUB_BRANCH_HEAD_FETCHED';
export const GET_GITHUB_BRANCH_HEAD_ERROR = 'GET_GITHUB_BRANCH_HEAD_ERROR';

export const GET_GITLAB_BRANCH_HEAD_FETCHING = 'GET_GITLAB_BRANCH_HEAD_FETCHING';
export const GET_GITLAB_BRANCH_HEAD_FETCHED = 'GET_GITLAB_BRANCH_HEAD_FETCHED';
export const GET_GITLAB_BRANCH_HEAD_ERROR = 'GET_GITLAB_BRANCH_HEAD_ERROR';
