import {
  GET_GITHUB_GIT_TREE_FETCHING,
  GET_GITHUB_GIT_TREE_FETCHED,
  GET_GITHUB_GIT_TREE_ERROR,

  GET_GITLAB_GIT_TREE_FETCHING,
  GET_GITLAB_GIT_TREE_FETCHED,
  GET_GITLAB_GIT_TREE_ERROR,
} from '../constants/git-tree';

const initialState = {
  gitTreeData: [], // githubGitTreeData
  isFetched: false,
  isFetching: false,
  isError: false,

  gitlabGitTreeData: [],
  isGitlabGitTreeFetched: false,
  isGitlabGitTreeFetching: false,
  isGitlabGitTreeError: false,
};

const gitTreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GITHUB_GIT_TREE_FETCHING:
      return Object.assign({}, state, {
        gitTreeData: [],
        isFetched: false,
        isFetching: true,
        isError: false,
      });
    case GET_GITHUB_GIT_TREE_FETCHED:
      // console.log('reducers, users, GET_GITHUB_GIT_TREE_FETCHED; action: ', action);

      return Object.assign({}, state, {
        gitTreeData: [].concat(action.payload.data),
        isFetched: true,
        isFetching: false,
        isError: false,
      });
    case GET_GITHUB_GIT_TREE_ERROR:
      return Object.assign({}, state, {
        isFetched: false,
        isError: true,
        isFetching: false,
      });

    // GITLAB
    case GET_GITLAB_GIT_TREE_FETCHING:
      return Object.assign({}, state, {
        gitlabGitTreeData: [],
        isGitlabGitTreeFetched: false,
        isGitlabGitTreeFetching: true,
        isGitlabGitTreeError: false,
      });
    case GET_GITLAB_GIT_TREE_FETCHED:
      // console.log('reducers, users, GET_GITLAB_GIT_TREE_FETCHED; action: ', action);

      return Object.assign({}, state, {
        gitlabGitTreeData: [].concat(action.payload.data),
        isGitlabGitTreeFetched: true,
        isGitlabGitTreeFetching: false,
        isGitlabGitTreeError: false,
      });
    case GET_GITLAB_GIT_TREE_ERROR:
      return Object.assign({}, state, {
        isGitlabGitTreeFetched: false,
        isGitlabGitTreeError: true,
        isGitlabGitTreeFetching: false,
      });
    default:
      return state;
  }
};

export default gitTreeReducer;
