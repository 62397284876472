import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { CONFIG } from 'utils/config-util';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { CardModal } from './CardModal';
import { BillingUserSection } from './BillingUserSection';
import { ChangePlan } from './ChangePlan';
import InvoicesSection from './InvoicesSection/InvoicesSection';
import { PlanInfoSection } from './PlanInfoSection';
import './BillingTab.scss';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import { LoadingPage } from '../CallbackPage';
import { CancelPlanSection } from './CancelPlanSection';
import { BILLING_SUBSCRIPTION_CAPABILITY } from 'utils/subscription-capability';

const BILLING_TAB_STATE = {
  BILLING_SUMMARY: 'BILLING_SUMMARY',
  CHANGE_PLAN: 'CHANGE_PLAN',
};

export class BillingTab extends Component {
  constructor(props) {
    super(props);
    this.activeWorkspaceId = this.props.activeWorkspace.workspace.workspace.id;
    this.state = {
      isModalOpen: false,
      stripeLoaded: false,
      tabState: BILLING_TAB_STATE.BILLING_SUMMARY,
    };
  }

  componentDidMount() {
    //LOAD STRIPE IF NOT LOADED
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(CONFIG.STRIPE_API_KEY), stripeLoaded: true });
    } else {
      const stripeScript = document.createElement('script');

      stripeScript.src = 'https://js.stripe.com/v3/';
      stripeScript.async = true;

      stripeScript.onload = () => {
        this.setState({
          stripe: window.Stripe(CONFIG.STRIPE_API_KEY),
          stripeLoaded: true,
        });
      };
      document.body && document.body.appendChild(stripeScript);
    }
    // GET CUSTOMER INFO
    this.props.getPlans();
    this.props.getInvoices(this.activeWorkspaceId);
    this.props.getBillingCustomer(this.activeWorkspaceId);
    this.props.getActiveSubscription(this.activeWorkspaceId);
    this.props.getBillingAccount(this.activeWorkspaceId);
  }

  shouldRenderLoading = () => {
    return (
      this.props.billingPlans.fetching ||
      !this.props.billingPlans.fetched ||
      this.props.billingInvoices.fetching ||
      !this.props.billingInvoices.fetched ||
      this.props.billingCustomer.fetching ||
      !this.props.billingCustomer.fetched ||
      this.props.activeSubscription.fetching ||
      !this.props.activeSubscription.fetched ||
      this.props.workspaceBillingAccount.fetching ||
      !this.props.workspaceBillingAccount.fetched
    );
  };

  renderTabContent = () => {
    if (this.shouldRenderLoading()) {
      return <LoadingPage />;
    }
    switch (this.state.tabState) {
      case BILLING_TAB_STATE.BILLING_SUMMARY:
        return (
          <Grid className={'billing-tab-main-grid'}>
            <PlanInfoSection
              onChangePaymentMethodClicked={() => this.setState({ isModalOpen: true })}
              onChangePlanClicked={() => this.setState({ tabState: BILLING_TAB_STATE.CHANGE_PLAN })}
              billingCustomer={this.props.billingCustomer}
              activeSubscription={this.props.activeSubscription}
            />
            <BillingUserSection
              userList={this.props.activeWorkspace.workspace.workspaceAccounts}
              getBillingAccount={() => this.props.getBillingAccount(this.activeWorkspaceId)}
              billingAccount={this.props.workspaceBillingAccount}
              setBillingAccount={(accountId, onSuccess, onError) =>
                this.props.setBillingAccount(this.activeWorkspaceId, accountId, onSuccess, onError)
              }
              setWorkspaceBillingAccountResult={this.props.setWorkspaceBillingAccountResult}
              inviteBillingAdmin={(email, onSuccess, onError) =>
                this.props.inviteBillingAdmin(this.activeWorkspaceId, email, onSuccess, onError)
              }
              inviteBillingAdminResult={this.props.inviteBillingAdminResult}
            />
            <InvoicesSection
              billingInvoices={this.props.billingInvoices}
              activeWorkspace={this.props.activeWorkspace}
            />
            <CancelPlanSection
              cancelSubscriptionResult={this.props.cancelSubscriptionResult}
              cancelSubscription={() =>
                this.props.cancelSubscription(
                  this.activeWorkspaceId,
                  () => {
                    this.refreshSubscriptionInfo();
                    this.setState({ tabState: BILLING_TAB_STATE.BILLING_SUMMARY });
                    toast.success('You successfully canceled your subscription.', ToastConfig);
                  },
                  () => {
                    toast.error('Cancel subscription failed. Contact us!', ToastConfig);
                  },
                )
              }
            />
          </Grid>
        );
      case BILLING_TAB_STATE.CHANGE_PLAN:
        if(BILLING_SUBSCRIPTION_CAPABILITY){
          return (
            <ChangePlan
              onBackClicked={() => this.setState({ tabState: BILLING_TAB_STATE.BILLING_SUMMARY })}
              onChangePaymentMethodClicked={() => this.setState({ isModalOpen: true })}
              billingCustomer={this.props.billingCustomer}
              activeSubscription={this.props.activeSubscription}
              confirmPaymentResult={this.props.saveSubscriptionResult}
              confirmPayment={(activeApplicationLimit, userLimit) => {
                let subscriptionInfo = {
                  activeApplicationLimit,
                  userLimit,
                  workspaceId: this.activeWorkspaceId,
                  planId: this.props.billingPlans.billingPlans[0].id,
                };
                this.props.saveSubscription(
                  subscriptionInfo,
                  () => {
                    this.refreshSubscriptionInfo();
                    this.refreshInvoices();
                    this.setState({ tabState: BILLING_TAB_STATE.BILLING_SUMMARY });
                    toast.success('You successfully subscribed.', ToastConfig);
                  },
                  () => {
                    toast.error('Subscription failed. Contact us!', ToastConfig);
                  },
                );
              }}
            />
          );
        }
        else{
          return 'Subscriptions Disabled'
        }

      default:
        return null;
    }
  };

  refreshCardInfo = () => {
    this.props.getBillingCustomer(this.activeWorkspaceId);
  };

  closeCardModal = (reload = false) => {
    reload && this.refreshCardInfo();
    this.setState({ isModalOpen: false });
  };

  refreshSubscriptionInfo = () => {
    this.props.getActiveSubscription(this.activeWorkspaceId);
  };

  refreshInvoices = () => {
    this.props.getInvoices(this.activeWorkspaceId);
  };

  render() {
    console.log('BillingTab; props: ', this.props);

    return (
      <>
        {this.renderTabContent()}

        {this.state.stripeLoaded && (
          <StripeProvider stripe={this.state.stripe}>
            <Elements>
              <CardModal
                stripe={this.props.stripe}
                open={this.state.isModalOpen}
                onClose={this.closeCardModal}
                onUpsertCard={(stripeToken, onSuccess, onError) =>
                  this.props.upsertCard(this.activeWorkspaceId, stripeToken, onSuccess, onError)
                }
                upsertCardResult={this.props.upsertCardResult}
              />
            </Elements>
          </StripeProvider>
        )}
      </>
    );
  }
}
