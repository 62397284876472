import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_WORKSPACE } from '../constants/workspace-active';

export const getWorkspace = workspaceId => {
  return {
    type: GET_WORKSPACE,
    payload: axios.get(CONFIG.apiUrl + `/workspaces/${workspaceId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
