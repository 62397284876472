import { APPS_SELECTION_CHANGED } from '../constants/apps-and-tracepoints';

const initialState = {
  selectedApps: [],
};

export default function appsAndTracePoints(state = initialState, action) {
  switch (action.type) {
    case APPS_SELECTION_CHANGED:
      return { ...state, selectedApps: Array.from(new Set(action.payload)) };
    default:
      return state;
  }
}
