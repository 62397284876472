let APP_CONFIG = require('config/appConfig-' + process.env.REACT_APP_ENV + '.json');
try {
    if (window.ENV.EXTERNAL_CONFIG) {
        APP_CONFIG = window.ENV.EXTERNAL_CONFIG;
    }
} catch(e) {

}

export const CONFIG = APP_CONFIG;
