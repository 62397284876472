import React, { Component } from 'react';
import { Button, Image, Popup } from 'semantic-ui-react';
import './SendToWebhook.scss';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import routeList from 'routes';
import exportIcon from 'assets/images/export-icon.svg';

export class SendToWebhookIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      currentLoading: null,
    };
  }

  handleIntegrationsTabClick = () => {
    let { workspace: activeWorkspace } = this.props.activeWorkspace.workspace;
    let routingParams = {
      pathname: routeList.workspace.path.replace(':workspaceId', activeWorkspace.id),
      state: {
        activeTab: 'Integrations',
      },
    };
    this.props.history.push(routingParams);
  };

  afterSend = (whName, error) => () => {
    this.setState({ popupOpen: false, currentLoading: null });
    if (error) {
      toast.error(`Error while sending to the ${whName}!`, ToastConfig);
      return;
    }
    toast.success(`Sent successfully to ${whName}`, ToastConfig);
  };

  getPopupContent = () => {
    const webhookList = this.props.webhookList?.webhooks;
    if (!webhookList) {
      return;
    }

    if (webhookList.length === 0) {
      return (
        <div style={{ width: '180px' }}>
          You can define your targets from{' '}
          <span onClick={this.handleIntegrationsTabClick} className={'anchor-like'}>
            integration settings.
          </span>
        </div>
      );
    }

    return (
      <div className={'send-to-items'}>
        {webhookList.map(wh => {
          return (
            <Button
              className={'secondary'}
              loading={this.state.currentLoading === wh.name}
              onClick={() => {
                this.setState({ currentLoading: wh.name });
                this.props.sendToWebhook(
                  [wh.id],
                  this.props.type,
                  this.props.event,
                  this.afterSend(wh.name, false),
                  this.afterSend(wh.name, true),
                );
              }}
            >
              Send to {wh.name}
            </Button>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div onClick={() => this.setState({ popupOpen: true })}>
        <Popup
          content={<div>{this.getPopupContent()}</div>}
          open={this.state.popupOpen}
          on="click"
          onClose={() => this.setState({ popupOpen: false })}
          onOpen={() => this.setState({ popupOpen: true })}
          trigger={<Image src={exportIcon} />}
        />
      </div>
    );
  }
}
