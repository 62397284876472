import {
  GET_GITHUB_ACCESS_TOKEN,
  GET_GITLAB_ACCESS_TOKEN,
  GET_SOURCE_PROVIDER_ACCOUNT_LIST,
  ADD_SOURCE_PROVIDER_ACCOUNT,
  DELETE_SOURCE_PROVIDER_ACCOUNT,
  DELETE_NEW_SOURCE_PROVIDER_DATA,
} from 'store/constants/source-provider-account';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

// After adding new source provider, addSourceAccount and githubOauthToken/gitlabOauthToken stores are filled.
// Clear these after first add repo modal is opened.
export function flushNewAddedSourceProviderData() {
  return {
    type: DELETE_NEW_SOURCE_PROVIDER_DATA,
  };
}

export function getGithubAccessToken(code) {
  return {
    type: GET_GITHUB_ACCESS_TOKEN,
    payload: axios.get(CONFIG.apiUrl + `/oauth/github?code=${code}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getGitlabAccessToken(code) {
  return {
    type: GET_GITLAB_ACCESS_TOKEN,
    payload: axios.get(CONFIG.apiUrl + `/oauth/gitlab?code=${code}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getSourceProviderAccountList() {
  return {
    type: GET_SOURCE_PROVIDER_ACCOUNT_LIST,
    payload: axios.get(CONFIG.apiUrl + '/source-accounts', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

// SourceAccount {
//     private String id;
//     private String userId;
//     private String ownerAccountId;
//     @NotNull
//     private Platform platform;
//     @NotNull
//     private AuthMethod authMethod;
//     private String authData;
// }
export function addSourceProviderAccount(sourceAccount) {
  console.log('action, SPA, addSourceProviderAccount; sourceAccount: ', sourceAccount);
  return {
    type: ADD_SOURCE_PROVIDER_ACCOUNT,
    payload: axios.post(CONFIG.apiUrl + `/source-accounts`, sourceAccount, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function deleteSourceProviderAccount(sourceAccountId) {
  console.log('action, SPA, deleteSourceProviderAccount; sourceAccountId: ', sourceAccountId);
  return {
    type: DELETE_SOURCE_PROVIDER_ACCOUNT,
    payload: axios.delete(CONFIG.apiUrl + `/source-accounts/${sourceAccountId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
