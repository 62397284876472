import {
  GET_GITHUB_BRANCH_HEAD_FETCHING,
  GET_GITHUB_BRANCH_HEAD_FETCHED,
  GET_GITHUB_BRANCH_HEAD_ERROR,
  GET_GITHUB_REPO_BRANCHES_FETCHING,
  GET_GITHUB_REPO_BRANCHES_FETCHED,
  GET_GITHUB_REPO_BRANCHES_ERROR,

  GET_GITLAB_BRANCH_HEAD_FETCHING,
  GET_GITLAB_BRANCH_HEAD_FETCHED,
  GET_GITLAB_BRANCH_HEAD_ERROR,
  GET_GITLAB_REPO_BRANCHES_FETCHING,
  GET_GITLAB_REPO_BRANCHES_FETCHED,
  GET_GITLAB_REPO_BRANCHES_ERROR,
} from '../constants/git-branches';

const initialState = {
  // GITHUB
  branchHead: null, // githubBranchHead, get branch head
  isBranchHeadFetched: false,
  isBranchHeadFetching: false,
  isBranchHeadError: false,

  branchData: [], // githubBranchData, branch list
  isFetched: false, // isGithubBranchDataFetched
  isFetching: false, // isGithubBranchDataFetcing
  isError: false, // isGithubBranchDataError

  // GITLAB
  gitlabBranchHead: null, // get branch head
  isGitlabBranchHeadFetched: false,
  isGitlabBranchHeadFetching: false,
  isGitlabBranchHeadError: false,

  gitlabBranchData: [], // branch list
  isGitlabBranchDataFetched: false,
  isGitlabBranchDataFetching: false,
  isGitlabBranchDataError: false,

};

const branchReducer = (state = initialState, action) => {
  switch (action.type) {
    // GITHUB
    case GET_GITHUB_BRANCH_HEAD_FETCHING:
      return Object.assign({}, state, {
        branchHead: null,
        isBranchHeadFetched: false,
        isBranchHeadFetching: true,
        isBranchHeadError: false,
      });
    case GET_GITHUB_BRANCH_HEAD_FETCHED:
      console.log('reducers, users, GET_GITHUB_BRANCH_HEAD_FETCHED; action: ', action);
      return Object.assign({}, state, {
        branchHead: action.payload.data,
        isBranchHeadFetched: true,
        isBranchHeadFetching: false,
        isBranchHeadError: false,
      });
    case GET_GITHUB_BRANCH_HEAD_ERROR:
      return Object.assign({}, state, {
        isBranchHeadFetched: false,
        isBranchHeadError: true,
        isBranchHeadFetching: false,
      });

    case GET_GITHUB_REPO_BRANCHES_FETCHING:
      return Object.assign({}, state, {
        branchData: [],
        isFetched: false,
        isFetching: true,
        isError: false,
      });
    case GET_GITHUB_REPO_BRANCHES_FETCHED:
      console.log('reducers, users, GET_GITHUB_REPO_BRANCHES_FETCHED; action: ', action);
      return Object.assign({}, state, {
        branchData: [].concat(action.payload.data),
        isFetched: true,
        isFetching: false,
        isError: false,
      });
    case GET_GITHUB_REPO_BRANCHES_ERROR:
      return Object.assign({}, state, {
        isFetched: false,
        isError: true,
        isFetching: false,
      });

    // GITLAB
    case GET_GITLAB_BRANCH_HEAD_FETCHING:
      return Object.assign({}, state, {
        gitlabBranchHead: null,
        isGitlabBranchHeadFetched: false,
        isGitlabBranchHeadFetching: true,
        isGitlabBranchHeadError: false,
      });
    case GET_GITLAB_BRANCH_HEAD_FETCHED:
      console.log('reducers, users, GET_GITLAB_BRANCH_HEAD_FETCHED; action: ', action);
      return Object.assign({}, state, {
        gitlabBranchHead: action.payload.data,
        isGitlabBranchHeadFetched: true,
        isGitlabBranchHeadFetching: false,
        isGitlabBranchHeadError: false,
      });
    case GET_GITLAB_BRANCH_HEAD_ERROR:
      return Object.assign({}, state, {
        isGitlabBranchHeadFetched: false,
        isGitlabBranchHeadError: true,
        isGitlabBranchHeadFetching: false,
      });

    case GET_GITLAB_REPO_BRANCHES_FETCHING:
      return Object.assign({}, state, {
        gitlabBranchData: [],
        isGitlabBranchDataFetched: false,
        isGitlabBranchDataFetching: true,
        isGitlabBranchDataError: false,
      });
    case GET_GITLAB_REPO_BRANCHES_FETCHED:
      console.log('reducers, users, GET_GITLAB_REPO_BRANCHES_FETCHED; action: ', action);
      return Object.assign({}, state, {
        gitlabBranchData: [].concat(action.payload.data),
        isGitlabBranchDataFetched: true,
        isGitlabBranchDataFetching: false,
        isGitlabBranchDataError: false,
      });
    case GET_GITLAB_REPO_BRANCHES_ERROR:
      return Object.assign({}, state, {
        isGitlabBranchDataFetched: false,
        isGitlabBranchDataError: true,
        isGitlabBranchDataFetching: false,
      });

    default:
      return state;
  }
};

export default branchReducer;
