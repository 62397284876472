import { GET_INVOICES_FULFILLED, GET_INVOICES_PENDING, GET_INVOICES_REJECTED } from '../constants/billing-invoices';

const initialState = {
  billingInvoices: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function billingInvoices(state = initialState, action) {
  switch (action.type) {
    case GET_INVOICES_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_INVOICES_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        billingInvoices: action.payload.data,
      };
    case GET_INVOICES_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
