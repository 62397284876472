import { connect } from 'react-redux';
import { AppsAndRepositoriesController } from 'components';
import { notifyAppsAndRepositoriesStateChange } from 'store/actions';

function mapStateToProps(store) {
  return {
    ideAppsAndRepositoriesManagement: store.ideAppsAndRepositoriesManagement,
    onboardStatus: store.onboardingV2,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notifyStateChange(newState) {
      dispatch(notifyAppsAndRepositoriesStateChange(newState));
    },
  };
}

export const AppsAndRepositoriesControllerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppsAndRepositoriesController);
