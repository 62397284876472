import React, { Component } from 'react';
import routeList from 'routes';

import './error.scss';

function svgMap() {
  return (
    <div className="svg-map-container">
      <svg width="234" height="193" viewBox="0 0 234 193" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M79 -2.36112e-05L1.43373e-05 29L0 193L79 163L79 -2.36112e-05Z"
          fill="#EBECF0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M79 163L157 192L157 29L79 -3.05176e-05L79 163Z"
          fill="#C1C7D0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M234 -2.3786e-05L157 29L157 192L234 164L234 -2.3786e-05Z"
          fill="#EBECF0"
        />
        <path
          d="M193.393 60.5303C193.03 59.3477 193.044 58.0362 193.433 56.5958C193.818 55.143 194.617 53.795 195.829 52.5517C197.042 51.3084 198.619 50.3891 200.561 49.7937C202.366 49.2403 204.062 49.0879 205.65 49.3365C207.234 49.5727 208.576 50.1489 209.674 51.0652C210.784 51.9777 211.545 53.1062 211.958 54.4507C212.282 55.5089 212.348 56.5031 212.157 57.4334C211.978 58.3599 211.679 59.2073 211.261 59.9756C210.852 60.7276 210.091 62.023 208.98 63.8619C208.675 64.3775 208.438 64.8248 208.268 65.2038C208.106 65.5664 208.002 65.8913 207.954 66.1785C207.902 66.4532 207.877 66.7264 207.878 66.9983C207.888 67.2539 207.919 67.7077 207.969 68.3597C208.129 69.7269 207.587 70.6013 206.342 70.983C205.695 71.1815 205.082 71.1378 204.504 70.8519C203.939 70.5622 203.528 70.0003 203.272 69.1662C202.952 68.1205 202.837 67.1684 202.928 66.3097C203.015 65.4387 203.238 64.6348 203.598 63.898C203.954 63.1488 204.464 62.2365 205.128 61.1612C205.711 60.2198 206.124 59.5214 206.366 59.0659C206.616 58.5942 206.793 58.1043 206.895 57.596C207.011 57.084 206.99 56.5728 206.834 56.0624C206.529 55.0664 205.897 54.3406 204.941 53.8849C203.996 53.4254 202.939 53.375 201.769 53.7338C200.399 54.1536 199.498 54.8113 199.064 55.7069C198.627 56.5901 198.39 57.7726 198.354 59.2545C198.362 60.791 197.775 61.7405 196.592 62.1031C195.895 62.3168 195.229 62.2553 194.595 61.9187C193.969 61.5657 193.569 61.103 193.393 60.5303ZM208.78 78.2026C208.021 78.4355 207.28 78.3969 206.559 78.0869C205.846 77.7607 205.354 77.1557 205.083 76.2718C204.842 75.4875 204.914 74.7438 205.298 74.0405C205.681 73.3373 206.272 72.8636 207.068 72.6193C207.853 72.3789 208.594 72.4442 209.294 72.8154C209.993 73.1866 210.463 73.7644 210.704 74.5486C210.971 75.4201 210.901 76.1906 210.493 76.8603C210.086 77.53 209.514 77.9775 208.78 78.2026Z"
          fill="#1E1E20"
        />
        <path
          d="M5.30933 90.4599C17.4928 74.7319 28.7174 76.3573 38.9832 95.3362C58.6378 133.909 91.9904 102.353 103.576 80.4202C115.161 58.4873 133.947 57.2741 146.532 95.3362C159.117 133.398 193.674 155.826 207.542 121.554C210.238 114.893 207.542 100.415 207.542 95.3362C207.542 91.9504 207.542 88.5943 207.542 85.2677"
          stroke="#1E1E20"
          stroke-width="5.6"
          stroke-linecap="round"
          stroke-dasharray="4.2 9.8"
        />
      </svg>
    </div>
  );
}

function svgRoadFlag() {
  return (
    <div className="svg-map-container">
      <svg width="150" height="169" viewBox="0 0 150 169" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M74.9959 0C76.7948 0.00212271 78.5193 0.71615 79.7905 1.98523C81.0617 3.25431 81.7758 4.97464 81.7758 6.76833V158.916H68.2081V6.76833C68.2081 4.97326 68.9232 3.25171 70.1962 1.9824C71.4692 0.71309 73.1957 0 74.9959 0V0Z"
          fill="#C1C7D0"
          fill-opacity="0.5"
        />
        <path
          d="M74.8273 153.036C93.7344 153.007 112.323 157.89 128.761 167.206C128.944 167.311 129.088 167.474 129.169 167.669C129.251 167.863 129.266 168.08 129.212 168.284C129.158 168.488 129.038 168.669 128.871 168.798C128.704 168.928 128.499 168.999 128.287 169H21.3758C21.1641 168.999 20.9588 168.928 20.7915 168.798C20.6243 168.669 20.5045 168.488 20.4506 168.284C20.3966 168.08 20.4116 167.863 20.4932 167.669C20.5747 167.474 20.7184 167.311 20.9019 167.206C37.3373 157.892 55.9229 153.008 74.8273 153.036Z"
          fill="#FAFBFC"
        />
        <path
          d="M18.3298 83.4387L125.169 83.4387C125.608 83.4412 126.042 83.5335 126.444 83.7098C126.846 83.8862 127.208 84.1428 127.506 84.464L149.139 107.693C149.692 108.285 150 109.065 150 109.875C150 110.685 149.692 111.465 149.139 112.058L127.506 135.287C127.206 135.61 126.842 135.867 126.437 136.044C126.032 136.22 125.594 136.311 125.153 136.312L18.3138 136.312C17.2485 136.312 16.2269 135.89 15.4737 135.139C14.7204 134.388 14.2973 133.369 14.2973 132.307V87.4516C14.2962 86.9237 14.3998 86.4007 14.6022 85.9128C14.8046 85.425 15.1017 84.9818 15.4765 84.6089C15.8513 84.2359 16.2963 83.9405 16.786 83.7397C17.2757 83.5389 17.8003 83.4366 18.3298 83.4387Z"
          fill="#B3BAC5"
        />
        <path
          d="M24.8474 20.7376L132.49 20.7376C133.342 20.7376 134.159 21.0752 134.762 21.676C135.364 22.2769 135.703 23.0918 135.703 23.9415V70.3987C135.703 71.2485 135.364 72.0634 134.762 72.6642C134.159 73.2651 133.342 73.6027 132.49 73.6027L24.8474 73.6027C24.4055 73.6023 23.9684 73.5111 23.5634 73.3347C23.1584 73.1583 22.7943 72.9005 22.4938 72.5774L0.860931 49.3488C0.307587 48.7559 -3.05176e-05 47.976 -3.05176e-05 47.1661C-3.05176e-05 46.3562 0.307587 45.5763 0.860931 44.9834L22.4938 21.7549C22.795 21.4332 23.1594 21.1769 23.5644 21.0019C23.9693 20.8269 24.4061 20.7369 24.8474 20.7376Z"
          fill="#FAFBFC"
        />
        <path
          d="M72.9235 60.7627L58.8096 47.186L64.513 41.499L72.9235 49.3807L90.5158 32.0073L96.2192 37.6943L72.9235 60.7627Z"
          fill="#1E1E20"
          stroke="#1E1E20"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M88.5155 100.323L82.8121 94.6365L73.2127 104.208L63.6052 94.6365L57.9018 100.323L67.5012 109.903L57.9018 119.475L63.6052 125.162L73.2127 115.59L82.8121 125.162L88.5155 119.475L78.9161 109.903L88.5155 100.323Z"
          stroke="#1E1E20"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

class ErrorPage extends Component {
  handleGoBackClick = e => {
    const { errorCode } = this.props.match.params;
    if (errorCode && errorCode === 'forcelogout') {
      // TODO: handle forcelogout and error cases?
      this.props.history.replace(routeList.landing.path);
    }
    else if (this.props && this.props.history) {
      this.props.history.goBack();
    } else {
      window.location.reload(true); //Async Page Loading page url not change /team or /trace /
    }
  };

  handleCloseClick = e => {
    //Close Not Worked . Chrome Not Support it..
    //https://stackoverflow.com/questions/19761241/window-close-and-self-close-do-not-close-the-window-in-chrome
    window.close();
  };

  renderServerError = () => {
    return (
      <div className="error-page-wrapper">
        <div className="error-page-content">
          <div className="error-pic">{svgMap()}</div>
          <div className="error-title">
            <span>Oh no, wrong path!</span>
          </div>
          <div className="error-subtitle">
            <div>Looks like we are having an issue.</div>
          </div>
          <div className="error-go-back-button" onClick={this.handleGoBackClick}>
            Go Back
          </div>
        </div>
      </div>
    );
  };

  renderDublicateTabError = () => {
    return (
      <div className="error-page-wrapper">
        <div className="error-page-content">
          <div className="error-pic">{svgRoadFlag()}</div>
          <div className="error-title">
            <span>Sorry, multiple tabs :(</span>
          </div>
          <div className="error-subtitle">
            <div>Sidekick is open in another window or tab.</div>
            <div>Please use previous tab to continue your debugging session.</div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { errorCode } = this.props.match.params;
    if (errorCode && errorCode === 'duplicatedtab') {
      return this.renderDublicateTabError();
    } else {
      return this.renderServerError();
    }
  }
}

export default ErrorPage;
