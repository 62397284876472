export class VariableTemplate {
  constructor(container) {
    this.variableNodeLabel = document.createElement('div');
    this.variableNodeLabel.className = 'monaco-node-label';
    this.variableNodeLabel.style.display = 'flex';
    this.variableNodeLabel.style.alignItems = 'center';

    container.appendChild(this.variableNodeLabel);

    //Container
    this.fieldIcon = document.createElement('i');
    this.fieldIcon.className = 'icon-variables';
    this.fieldIcon.style.marginRight = '5px';

    this.labelDescriptionContainer = document.createElement('div');
    this.labelDescriptionContainer.className = 'monaco-node-label-description-container';
    this.labelDescriptionContainer.style.overflow = 'hidden';
    this.labelDescriptionContainer.style.textOverflow = 'ellipsis';
    this.labelDescriptionContainer.style.whiteSpace = 'nowrap';

    this.variableNodeLabel.appendChild(this.fieldIcon);
    this.variableNodeLabel.appendChild(this.labelDescriptionContainer);

    //labelDescriptionContainer
    this.label = document.createElement('a');
    this.label.className = 'label-name';
    this.labelDescriptionContainer.appendChild(this.label);

    this.description = document.createElement('span');
    this.description.className = 'label-description';
    this.labelDescriptionContainer.appendChild(this.description);
  }

  dispose() {
    // TODO dispose resources?
  }

  set(variableDetail) {
    this.label.innerHTML = variableDetail.name;
    this.variableNodeLabel.title = variableDetail.path;

    if (!variableDetail.isDirectory) {
      this.fieldIcon.classList.remove('icon-variables-tree-parent');
      this.fieldIcon.classList.add('icon-variables-tree-field');
    } else {
      this.fieldIcon.classList.remove('icon-variables-tree-field');
      this.fieldIcon.classList.add('icon-variables-tree-parent');
    }
  }
}
