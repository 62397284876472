import {
  GET_WORKSPACE_BILLING_ACCOUNT_FULFILLED,
  GET_WORKSPACE_BILLING_ACCOUNT_PENDING,
  GET_WORKSPACE_BILLING_ACCOUNT_REJECTED,
} from '../constants/workspace-get-billing-account';

const getWorkspaceBillingAccountInitialState = {
  workspaceBillingAccount: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function workspaceBillingAccount(state = getWorkspaceBillingAccountInitialState, action) {
  switch (action.type) {
    case GET_WORKSPACE_BILLING_ACCOUNT_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case GET_WORKSPACE_BILLING_ACCOUNT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        workspaceBillingAccount: action.payload.data,
      };
    case GET_WORKSPACE_BILLING_ACCOUNT_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
