import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import {
  computeSourceRepositoryNameAccordingToSourceProvider,
  computeSourceRepositoryOwnerAccordingToSourceProvider,
  selectRepoDataAccordingToSourceProvider,
  SOURCE_PROVIDER_PLATFORM,
} from 'utils/source-provider-util';

export class SourceRepoSelector extends Component {
  componentDidMount() {
    console.log('SRS, CDM; props: ', this.props);
    const { gitSourceProvider } = this.props;
    this.props.getUserRepositories(gitSourceProvider);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("SRS, CDU; prevProps, props: ", prevProps, this.props);
  }

  findSelectedRepoFromOptions = (options, value) => {
    return options.find(repo => repo.value === value);
  };

  renderRepoSelectorDropdown = () => {
    const { gitSourceProvider, gitRepoData } = this.props;
    const { isFetching, isGitlabRepoDataFetching } = gitRepoData;

    if (
      (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB && isFetching) ||
      (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB && isGitlabRepoDataFetching)
    ) {
      return (
        <Dropdown
          placeholder="Loading available repositories"
          disabled={true}
          fluid
          search
          selection
          options={[]}
          value={''}
        />
      );
    }

    // Get repoData according to source provider.
    const repoData = selectRepoDataAccordingToSourceProvider(gitSourceProvider, gitRepoData);
    // console.log("renderRepoSelectorDropdown; repoData: ", repoData);

    const repoDropdownData = repoData.map(repo => {
      const repoName = computeSourceRepositoryNameAccordingToSourceProvider(gitSourceProvider, repo);
      const repoOwner = computeSourceRepositoryOwnerAccordingToSourceProvider(gitSourceProvider, repo);
      return {
        key: repo.id,
        value: repo.id,
        text: `${repoName}, Owner:${repoOwner}`,
        repodata: repo, // 'repodata' should not be camel case, otherwise SUIR gives error!!
      };
    });

    let selectedRepoValue = null;
    if (this.props.selectedRepo) {
      selectedRepoValue = this.props.selectedRepo.value;
    }

    return (
      <Dropdown
        placeholder="Select Repo"
        fluid
        search
        selection
        disabled={this.props.isRepoDropdownDisabled}
        options={repoDropdownData}
        value={selectedRepoValue}
        onChange={(e, data) => {
          // console.log("SRS, dropdown, onChange; e, data: ", e, data);
          const selectedRepo = this.findSelectedRepoFromOptions(repoDropdownData, data.value);
          this.props.onSelectRepo(selectedRepo);
        }}
      />
    );
  };

  render() {
    console.log('SourceRepoSelector, render; props, state: ', this.props, this.state);

    return <div className="github-repo-selector-wrapper">{this.renderRepoSelectorDropdown()}</div>;
  }
}
