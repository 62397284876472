import React, { Component } from 'react';
import axios from 'axios';
import auth from 'auth/auth-factory';
import { LoadingPage } from '../CallbackPage';
import URL_POOL from 'utils/url-pool';
import { Button, Icon, Image } from 'semantic-ui-react';
import vscodeConnected from 'assets/images/vscode-connected.svg';
import ideaConnected from 'assets/images/idea-connected.svg';
import pycharmConnected from 'assets/images/pycharm-connected.svg';
import { CONFIG } from 'utils/config-util';
import routes from 'routes';

const IDE_MAP = {
  idea: 'idea',
  vscode: 'vscode',
  pycharm: 'pycharm',
};

class PluginWelcomePage extends Component {
  constructor(props) {
    super(props);
    this.PORT = localStorage.getItem('port');
    this.IDE = localStorage.getItem('ide');
    localStorage.removeItem('port');
    localStorage.removeItem('ide');
    this.state = {
      loading: false,
      ide: IDE_MAP[this.IDE] || IDE_MAP.idea,
    };
  }
  componentDidMount() {
    const { userAccount } = this.props.userAccount;

    if (this.PORT) {
      axios
        .post(`http://localhost:${this.PORT}/auth/sidekick`, {
          email: userAccount.email,
          access_token: auth.getAccessToken(),
        })
        .then(res => {
          this.setState({ loading: false });
          console.log('Success token: ', res);
        })
        .catch(err => {
          console.log('Error token: ', err);
        });
    }
  }

  renderConnectedSVG = () => {
    switch (this.state.ide) {
      case IDE_MAP.idea:
        return <Image src={ideaConnected} />;
      case IDE_MAP.vscode:
        return <Image src={vscodeConnected} />;
      case IDE_MAP.pycharm:
        return <Image src={pycharmConnected} />;
      default:
        return null;
    }
  };

  render() {
    if (this.state.loading) {
      return <LoadingPage />;
    }

    let isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    if (isSafari) {
      const loginLink =
        CONFIG.thundraSidekickAppUrl + `/landing?port=${this.PORT || ''}&ide=${IDE_MAP[this.IDE] || ''}`;
      return (
        <div className="error-page-wrapper">
          <div className="error-page-content">
            <div className="error-pic">
              <Icon color={'yellow'} size="massive" name="warning sign" />
            </div>
            <div className="error-title">
              <span>Authentication failed</span>
            </div>
            <div className="error-subtitle">
              <div>
                Oops! Seems like you are using a browser that is not compatible with our extension login flow. Please
                visit the url below using a modern browser like Mozilla Firefox, Google Chrome etc.
              </div>
              <div>
                <a href={loginLink} target={'_blank'} rel="noopener noreferrer">
                  {loginLink}
                </a>
              </div>
              <div>
                <a href={URL_POOL.SIDEKICK_DOCS} target={'_blank'} rel="noopener noreferrer">
                  Check out our docs.
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="error-page-wrapper">
        <div className="error-page-content">
          <div className="error-pic">{this.renderConnectedSVG()}</div>
          <div className="error-title">
            <span>Authentication successful</span>
          </div>
          <div className="error-subtitle">
            <div>You can return to your IDE.</div>
            <div>
              <a href={URL_POOL.SIDEKICK_DOCS} target={'_blank'} rel="noopener noreferrer">
                Check out our docs.
              </a>
            </div>
          </div>
          <Button
            className={'secondary button-step-info'}
            content={'Back to home'}
            onClick={() => this.props.history.push(routes.ide.path)}
          />
        </div>
      </div>
    );
  }
}

export default PluginWelcomePage;
