import {
  FLUSH_JIRA_ACTION_STATES,
  GET_JIRA_ACCESSIBLE_RESOURCES_FULFILLED,
  GET_JIRA_ACCESSIBLE_RESOURCES_PENDING,
  GET_JIRA_ACCESSIBLE_RESOURCES_REJECTED,
  GET_JIRA_ISSUE_TYPES_FULFILLED,
  GET_JIRA_ISSUE_TYPES_PENDING,
  GET_JIRA_ISSUE_TYPES_REJECTED,
  GET_JIRA_PROJECTS_FULFILLED,
  GET_JIRA_PROJECTS_PENDING,
  GET_JIRA_PROJECTS_REJECTED,
} from '../constants/issue_management_jira_actions';

const initialState = {
  organisations: null,
  organisationsFetching: false,
  organisationsFetched: false,
  organisationsError: null,

  projects: null,
  projectsFetching: false,
  projectsFetched: false,
  projectsError: null,

  issueTypes: null,
  issueTypesFetching: false,
  issueTypesFetched: false,
  issueTypesError: null,
};

export default function issueManagementJiraActions(state = initialState, action) {
  switch (action.type) {
    case GET_JIRA_ACCESSIBLE_RESOURCES_PENDING:
      return {
        ...state,
        organisationsFetching: true,
        organisationsFetched: false,
        organisationsError: null,
      };
    case GET_JIRA_ACCESSIBLE_RESOURCES_FULFILLED:
      return {
        ...state,
        organisationsFetching: false,
        organisationsFetched: true,
        organisationsError: null,
        organisations: action.payload,
      };
    case GET_JIRA_ACCESSIBLE_RESOURCES_REJECTED:
      return {
        ...state,
        organisationsFetching: false,
        organisationsFetched: false,
        organisationsError: action.payload,
      };
    case GET_JIRA_PROJECTS_PENDING:
      return {
        ...state,
        projectsFetching: true,
        projectsFetched: false,
        projectsError: null,
      };
    case GET_JIRA_PROJECTS_FULFILLED:
      return {
        ...state,
        projectsFetching: false,
        projectsFetched: true,
        projectsError: null,
        projects: action.payload.values,
      };
    case GET_JIRA_PROJECTS_REJECTED:
      return {
        ...state,
        projectsFetching: false,
        projectsFetched: false,
        projectsError: action.payload,
      };
    case GET_JIRA_ISSUE_TYPES_PENDING:
      return {
        ...state,
        issueTypesFetching: true,
        issueTypesFetched: false,
        issueTypesError: null,
      };
    case GET_JIRA_ISSUE_TYPES_FULFILLED:
      return {
        ...state,
        issueTypesFetching: false,
        issueTypesFetched: true,
        issueTypesError: null,
        issueTypes: action.payload,
      };
    case GET_JIRA_ISSUE_TYPES_REJECTED:
      return {
        ...state,
        issueTypesFetching: false,
        issueTypesFetched: false,
        issueTypesError: action.payload,
      };

    case FLUSH_JIRA_ACTION_STATES:
      return { ...initialState };
    default:
      return state;
  }
}
