import { connect } from 'react-redux';
import { SourceSearch } from 'components';
import { withRouter } from 'react-router';
import { getUserRepoContent, notifySourceFocus } from 'store/actions';

function mapStateToProps(store) {
  return {
    gitTreeContent: store.gitTree,
    gitRepoContent: store.gitRepoContent,
    sourceCache: store.sourceCache,
    ideContext: store.ideContext,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserRepoContent(sourceProvider, owner, repo, path, ref, successFunc, errorFunc) {
      dispatch(getUserRepoContent(sourceProvider, owner, repo, path, ref, successFunc, errorFunc));
    },
    handleSourceFocus(sourceTabIndex, lineNo) {
      dispatch(notifySourceFocus(sourceTabIndex, lineNo));
    },
  };
}

const SourceSearchContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SourceSearch));
export default SourceSearchContainer;
