export const SOURCE_PROVIDER_PLATFORM = {
  GITHUB: 'GITHUB',
  GITLAB: 'GITLAB',
  BITBUCKET: 'BITBUCKET',
};

export const MODAL_STATE = {
  ADD_REPO: 'add_repo',
  UPDATE_BRANCH_COMMIT: 'update_branch_commit',
  CLOSED: false, // CLOSED state value should be false or a falsy value e.g. ''!
};

export function isSourceProviderConnected(sourceAccountList, sourceProvider) {
  return sourceAccountList.find(source => source.platform === sourceProvider);
}

export function findSourcesWithProvider(sourceCacheList, sourceProvider) {
  return sourceCacheList.filter(source => source.platform === sourceProvider);
}

export function isRepoCachedPreviously(sourceCacheList, repoName, sourceProvider) {
  console.log(
    'ADD, isRepoCachedPreviously; sourceCacheList, repoName, sourceProvider: ',
    sourceCacheList,
    repoName,
    sourceProvider,
  );
  const sourceList = findSourcesWithProvider(sourceCacheList, sourceProvider);
  console.log('ADD, isRepoCachedPreviously; sourceList: ', sourceList);
  return sourceList.find(source => source.repository === repoName);
}

export function clearRepoTracepointsAndEvents(sourceCacheList, repoName, sourceProvider, handleNotifyIDEContextReset) {
  const updatedRepo = isRepoCachedPreviously(sourceCacheList, repoName, sourceProvider);
  console.log('clearRepoTracepointsAndEvents; repoName, updatedRepo: ', repoName, updatedRepo);
  if (updatedRepo) {
    const { platform, owner, repository, branch, commit } = updatedRepo;
    // This is called to reset opened source files from IDE model.
    handleNotifyIDEContextReset(platform, owner, repository, branch, commit);
  }
}

// TODO: Add bitbucket here later!
export function selectRepoDataAccordingToSourceProvider(sourceProvider, gitRepoData) {
  // console.log("selectRepoDataAccordingToSourceProvider; gitRepoData, sourceProvider: ", gitRepoData, sourceProvider);

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    return gitRepoData.repoData;
  } else if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    return gitRepoData.gitlabRepoData;
  } else {
    return 'no repo data found!';
  }
}

export function selectBranchDataAccordingToSourceProvider(sourceProvider, gitBranchData) {
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    return gitBranchData.branchData;
  } else if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    return gitBranchData.gitlabBranchData;
  } else {
    return 'no branch data found!';
  }
}

export function selectCommitDataAccordingToSourceProvider(sourceProvider, gitCommitData) {
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    return gitCommitData.commitData;
  } else if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    return gitCommitData.gitlabCommitData;
  } else {
    return 'no commit data found!';
  }
}

export function computeRepoOwnerNameAccordingToSourceProvider(sourceProvider, repoData) {
  // console.log(
  //   'OWNER, computeRepoOwnerNameAccordingToSourceProvider; sourceProvider, repoData: ',
  //   sourceProvider,
  //   repoData,
  // );

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    return repoData.owner.login;
  } else if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    if (repoData.owner) {
      // For personal projects gitlab has owner.username instead of owner.login of github!
      return repoData.owner.username;
    } else if (repoData.namespace) {
      return repoData.namespace.name;
    } else {
      return 'default-owner-name';
    }
  } else {
    return 'no owner!';
  }
}

export function computeCommitTextAccordingToSourceProvider(sourceProvider, commitData) {
  // console.log("computeCommitTextAccordingToSourceProvider; sourceProvider, commitData: ", sourceProvider, commitData);

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    const commitText = `${commitData.sha.substring(0, 7)} - ${commitData.commit.message.substring(0, 60)} ...`;
    return commitText;
  } else if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    const commitText = `${commitData.id.substring(0, 7)} - ${commitData.message.substring(0, 60)} ...`;
    return commitText;
  } else {
    return 'no commit text!';
  }
}

export function computeCommitIdAccordingToSourceProvider(sourceProvider, commitData) {
  // console.log("computeCommitIdAccordingToSourceProvider; sourceProvider, commitData: ", sourceProvider, commitData);

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    const commitId = commitData.sha;
    return commitId;
  } else if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    const commitId = commitData.id;
    return commitId;
  } else {
    return 'no commit id/sha!';
  }
}

export function computeBranchHeadCommitIdAccordingToSourceProvider(sourceProvider, gitBranchData) {
  console.log(
    'computeBranchHeadCommitIdAccordingToSourceProvider; sourceProvider, gitBranchData: ',
    sourceProvider,
    gitBranchData,
  );

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    const commitId = gitBranchData.branchHead.commit.sha;
    return commitId;
  } else if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    const commitId = gitBranchData.gitlabBranchHead.commit.id;
    return commitId;
  } else {
    return 'no commit id/sha!';
  }
}

export function computeSourceRepositoryNameAccordingToSourceProvider(sourceProvider, repoData) {
  // console.log(
  //   'computeSourceRepositoryNameAccordingToSourceProvider; sourceProvider, repoData: ',
  //   sourceProvider,
  //   repoData,
  // );

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    return repoData.name;
  } else if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    return `${repoData.name}~${repoData.id}`; // Use findGitlabProjectIdFromRepoName to get projectId
  } else {
    return 'no repo name!';
  }
}

export function computeSourceRepositoryOwnerAccordingToSourceProvider(sourceProvider, repoData) {
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    return repoData?.owner?.login;
  } else if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    return repoData?.owner?.username; // Use findGitlabProjectIdFromRepoName to get projectId
  } else {
    return 'no owner name!';
  }
}

export function findGitlabProjectIdFromRepoName(gitlabSourceCacheRepoName) {
  const repoNameSplitArr = gitlabSourceCacheRepoName.split('~');
  return repoNameSplitArr[repoNameSplitArr.length - 1];
}
