import { GET_TRACEMAP_PENDING, GET_TRACEMAP_FULFILLED, GET_TRACEMAP_REJECTED } from 'store/constants/tracemap';

const initialState = {
  edges: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function tracemap(state = initialState, action) {
  switch (action.type) {
    case GET_TRACEMAP_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_TRACEMAP_FULFILLED:
      return { ...state, fetching: false, fetched: true, error: null, graph: action.payload.data };
    case GET_TRACEMAP_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    default:
      return state;
  }
}
