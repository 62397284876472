import { connect } from 'react-redux';
import { TraceMapTopology } from 'components';
import { withRouter } from 'react-router';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { getTraceMap } from 'store/actions';

function mapStateToProps(store) {
  return {
    tracemap: store.tracemap,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTraceMap(traceId, onGetTraceMapSuccess, onGetTraceMapError) {
      dispatchWithExceptionHandler(getTraceMap(traceId), false, onGetTraceMapSuccess, onGetTraceMapError);
    },
  };
}

export const TraceMapTopologyContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TraceMapTopology));
