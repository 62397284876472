import { GET_WORKSPACE_FULFILLED, GET_WORKSPACE_PENDING, GET_WORKSPACE_REJECTED } from '../constants/workspace-active';

const initialState = {
  workspace: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function activeWorkspace(state = initialState, action) {
  switch (action.type) {
    case GET_WORKSPACE_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_WORKSPACE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        workspace: action.payload.data,
      };
    case GET_WORKSPACE_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
