import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { SET_WORKSPACE_BILLING_ACCOUNT } from '../constants/workspace-set-billing-account';

export const setWorkspaceBillingAccount = (workspaceId, accountId) => {
  return {
    type: SET_WORKSPACE_BILLING_ACCOUNT,
    payload: axios.post(CONFIG.apiUrl + `/workspace/${workspaceId}/accounts/billing/${accountId}`, null, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
