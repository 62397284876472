import React from 'react';
import { formatTime } from 'utils/date-util';

export const Metadata = ({ event }) => {
  if (event) {
    const {
      time,
      id,
      hostName,
      applicationName,
      applicationInstanceId,
      client,
      sendAck,
      traceId,
      transactionId,
      spanId,
      className,
      lineNo,
    } = event;

    return (
      <div className="metadata-container">
        <div>Time:{formatTime(time)}</div>
        <div>ID:{id}</div>
        <div>Host name:{hostName}</div>
        <div>App name:{applicationName}</div>
        <div>App Instance Id:{applicationInstanceId}</div>
        <div>Client:{client}</div>
        <div>Send ack:{sendAck}</div>
        <div>Trace ID:{traceId}</div>
        <div>Transaction ID:{transactionId}</div>
        <div>Span ID:{spanId}</div>
        <div>ClassName:{className}</div>
        <div>LineNo:{lineNo}</div>
      </div>
    );
  } else {
    return <div className="metadata-container">NO EVENT</div>;
  }
};
