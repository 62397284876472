import {
  GET_GITHUB_BRANCH_COMMITS_FETCHING,
  GET_GITHUB_BRANCH_COMMITS_FETCHED,
  GET_GITHUB_BRANCH_COMMITS_ERROR,

  GET_GITLAB_BRANCH_COMMITS_FETCHING,
  GET_GITLAB_BRANCH_COMMITS_FETCHED,
  GET_GITLAB_BRANCH_COMMITS_ERROR,
} from '../constants/git-commits';
import { getSourceProviderOAuthTokenSelector } from 'store/selectors';
import { SOURCE_PROVIDER_PLATFORM } from 'utils/source-provider-util';
import store from 'store/store';

import axios from 'axios';
import { Octokit } from '@octokit/rest';

export const getUserRepoBranchCommits = (
  sourceProvider,
  ownerLogin,
  repoName,
  branchName,
  successFunction,
  errFunction,
) => {

  console.log(
    'actionss, getUserRepoBranchCommits; sourceProvider, ownerLogin, repoName, branchName: ',
    sourceProvider,
    ownerLogin,
    repoName,
    branchName,
  );

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    return getUserRepoBranchCommitsGithub(ownerLogin, repoName, branchName, successFunction, errFunction);
  }

  // For GITLAB, repoName passes projectId to this action and ownerLogin is not used!
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    console.log(
      'GITLAB, ACTIONS, getUserRepoBranchCommits; sourceProvider, projectId, branchName: ',
      sourceProvider,
      repoName,
      branchName,
    );
    const projectId = repoName;
    return getUserRepoBranchCommitsGitlab(projectId, branchName, successFunction, errFunction);
  }
};


// GITLAB
// Ref doc => https://docs.gitlab.com/ee/api/commits.html#list-repository-commits
export const getUserRepoBranchCommitsGitlab = (projectId, branchName, successFunction, errFunction) => {
  console.log('GITLAB, ACTIONS, getUserRepoBranchCommitsGitlab; projectId, branchName: ', projectId, branchName);
  const gitlabPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITLAB);

  // url encode branchName!?
  const encodedBranchName = encodeURIComponent(branchName);
  const listRepoBranchCommitsUrl = `https://gitlab.com/api/v4/projects/${projectId}/repository/commits?ref_name=${encodedBranchName}&per_page=20`;
  return dispatch => {
    dispatch(getGitlabUserRepoBranchCommitsStarted());

    axios
      .get(listRepoBranchCommitsUrl, {
        headers: { Authorization: 'Bearer ' + gitlabPAT },
      })
      .then(res => {
        dispatch(getGitlabUserRepoBranchCommitsSuccess(res.data));
        console.log('getGitlabUserRepoBranchCommitsSuccess - gitlab, res: ', res);
        if (successFunction) {
          successFunction();
        }
      })
      .catch(err => {
        dispatch(getGitlabUserRepoBranchCommitsFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });
  };
};

const getGitlabUserRepoBranchCommitsStarted = () => ({
  type: GET_GITLAB_BRANCH_COMMITS_FETCHING,
});

const getGitlabUserRepoBranchCommitsSuccess = data => {
  // console.log("action, users, getGitlabUserRepoBranchCommitsSuccess; data: ", data);
  return {
    type: GET_GITLAB_BRANCH_COMMITS_FETCHED,
    payload: {
      data,
    },
  };
};

const getGitlabUserRepoBranchCommitsFailure = error => {
  // console.log("action, users, getGitlabUserRepoBranchCommitsFailure; error: ", error);
  return {
    type: GET_GITLAB_BRANCH_COMMITS_ERROR,
    payload: {
      error,
    },
  };
};



// GITHUB
export const getUserRepoBranchCommitsGithub = (ownerLogin, repoName, branchName, successFunction, errFunction) => {
  const githubPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITHUB);
  const octokit = new Octokit({
    auth: githubPAT,
  });

  return dispatch => {
    dispatch(getGithubUserRepoBranchCommitsStarted());

    octokit.repos
      .listCommits({
        owner: ownerLogin,
        repo: repoName,
        sha: branchName, // branch name or branch sha as `sha` param.
      })
      .then(res => {
        dispatch(getGithubUserRepoBranchCommitsSuccess(res.data));
        // console.log("getGithubUserRepoBranchCommitsSuccess, res: ", res);

        if (successFunction) {
          // console.log("SUCCESS");
          successFunction();
        }
      })
      .catch(err => {
        dispatch(getGithubUserRepoBranchCommitsFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });
  };
};

const getGithubUserRepoBranchCommitsStarted = () => ({
  type: GET_GITHUB_BRANCH_COMMITS_FETCHING,
});

const getGithubUserRepoBranchCommitsSuccess = data => {
  // console.log("action, users, getGithubUserRepoBranchCommitsSuccess; data: ", data);
  return {
    type: GET_GITHUB_BRANCH_COMMITS_FETCHED,
    payload: {
      data,
    },
  };
};

const getGithubUserRepoBranchCommitsFailure = error => {
  // console.log("action, users, getGithubUserRepoBranchCommitsFailure; error: ", error);
  return {
    type: GET_GITHUB_BRANCH_COMMITS_ERROR,
    payload: {
      error,
    },
  };
};
