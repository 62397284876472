import React, { Component } from 'react';
import auth from 'auth/auth-factory';
import routeList from 'routes';

import './CallbackPage.scss';

class CallbackPage extends Component {
  componentDidMount() {
    console.log('SIDEKICK, CallbackPage, CDM; props: ', this.props);
    const isAuthenticated = auth.isAuthenticated();

    if (!!this.props.history) {
      if (isAuthenticated) {
        console.log('SIDEKICK, CallbackPage, replace to IDE page; isAuthenticated: ', isAuthenticated);
        this.props.history.replace(routeList.ide.path);
      } else {
        console.log('SIDEKICK, CallbackPage, render landing page and no redirection');
        this.props.history.replace(routeList.landing.path);
      }
    }
  }

  render() {
    console.log('CallbackPage, render; props: ', this.props);

    return (
      <div className="loading-view">
        <div className="loading-bg-artwork">
          <div className="loading-bg-image" />
        </div>
        <div className="loading-real">
          <div className="thundra-logo" />
          <div className="loading-message">PREPARING TO FLY</div>
          <div className="loading-lines">
            <div className="load-base">
              <div className="load-in" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallbackPage;
