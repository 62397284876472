import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { UPDATE_WORKSPACE } from '../constants/workspace-update';

export const updateWorkspace = (workspaceId, workspaceInfo) => {
  return {
    type: UPDATE_WORKSPACE,
    payload: axios.put(CONFIG.apiUrl + `/workspaces/${workspaceId}`, workspaceInfo, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
