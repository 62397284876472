import history from '../history.js';
import store from 'store/store';

export default function dispatchWithExceptionHandler(action, redirectOnError, onSuccess, onError) {
  const actionType = action.type;
  // console.log("dispatchWithExceptionHandler; action: ", action);
  store
    .dispatch(action)
    .then(response => {
      // console.log("dispatchWithExceptionHandler, then; action: ", action, response);
      if (onSuccess !== undefined) {
        onSuccess(response);
      }
    })
    .catch(err => {
      // console.log("dispatchWithExceptionHandler, err; action: ", action, err);
      if (!onError && redirectOnError && 500 <= err.response.status <= 599) {
        history.push('/error');
      }

      if (onError !== undefined) {
        onError(err);
      }

      //TODO: instead of this dispatch we can log this error
      store.dispatch({ type: actionType + '_REJECTED', payload: err });
    });
}
