import {
  RESET_SELECTED_GIT_REPO_CONTENT,

  GET_GITHUB_REPO_CONTENT_FETCHING,
  GET_GITHUB_REPO_CONTENT_FETCHED,
  GET_GITHUB_REPO_CONTENT_ERROR,

  GET_GITLAB_REPO_CONTENT_FETCHING,
  GET_GITLAB_REPO_CONTENT_FETCHED,
  GET_GITLAB_REPO_CONTENT_ERROR,
} from '../constants/git-repo-content';

const initialState = {
  selectedRepoContent: [],

  repoContent: null,
  isFetched: false,
  isFetching: false,
  isError: false,

  gitlabRepoContent: null,
  isGitlabRepoContentFetched: false,
  isGitlabRepoContentFetching: false,
  isGitlabRepoContentError: false,
};

const addNewSelectedRepoContent = (selectedRepoContentArr, newRepoContent) => {
  // console.log("addNewSelectedRepoContent; selectedRepoContentArr, newRepoContent: ", selectedRepoContentArr, newRepoContent);
  let populatedSelectedRepoContent = selectedRepoContentArr;
  // Prevent adding duplicate file contents to 'selectedRepoContent' arr!
  if (!selectedRepoContentArr.find(el => el.sha === newRepoContent.sha)) {
    populatedSelectedRepoContent.push(newRepoContent);
  }
  // console.log("addNewSelectedRepoContent; populatedSelectedRepoContent: ", populatedSelectedRepoContent);
  return populatedSelectedRepoContent;
}

const repoContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SELECTED_GIT_REPO_CONTENT:
      return {
        selectedRepoContent: [], // TODO: use this for source explorer selected repo!!
        repoContent: null,
        isFetched: false,
        isFetching: false,
        isError: false,
      };

    // GITHUB
    case GET_GITHUB_REPO_CONTENT_FETCHING:
      return Object.assign({}, state, {
        selectedRepoContent: state.selectedRepoContent,
        repoContent: null,
        isFetched: false,
        isFetching: true,
        isError: false,
      });
    case GET_GITHUB_REPO_CONTENT_FETCHED:
      console.log('reducers, users, GET_GITHUB_REPO_CONTENT_FETCHED; action: ', action);

      return Object.assign({}, state, {
        // selectedRepoContent: state.selectedRepoContent.concat(action.payload.data),
        selectedRepoContent: addNewSelectedRepoContent(state.selectedRepoContent, action.payload.data),
        repoContent: action.payload.data,
        isFetched: true,
        isFetching: false,
        isError: false,
      });
    case GET_GITHUB_REPO_CONTENT_ERROR:
      return Object.assign({}, state, {
        isFetched: false,
        isError: true,
        isFetching: false,
      });

    // GITLAB
    case GET_GITLAB_REPO_CONTENT_FETCHING:
      return Object.assign({}, state, {
        selectedRepoContent: state.selectedRepoContent,
        gitlabRepoContent: null,
        isGitlabRepoContentFetched: false,
        isGitlabRepoContentFetching: true,
        isGitlabRepoContentError: false,
      });
    case GET_GITLAB_REPO_CONTENT_FETCHED:
      console.log('reducers, users, GET_GITLAB_REPO_CONTENT_FETCHED; action: ', action);

      return Object.assign({}, state, {
        // selectedRepoContent: state.selectedRepoContent.concat(action.payload.data),
        selectedRepoContent: addNewSelectedRepoContent(state.selectedRepoContent, action.payload.data),
        gitlabRepoContent: action.payload.data,
        isGitlabRepoContentFetched: true,
        isGitlabRepoContentFetching: false,
        isGitlabRepoContentError: false,
      });
    case GET_GITLAB_REPO_CONTENT_ERROR:
      return Object.assign({}, state, {
        isGitlabRepoContentFetched: false,
        isGitlabRepoContentError: true,
        isGitlabRepoContentFetching: false,
      });

    default:
      return state;
  }
};

export default repoContentReducer;
