import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { INVITE_TO_WORKSPACE } from '../constants/workspace-invite';

export const inviteToWorkspace = (workspaceId, emailList) => {
  return {
    type: INVITE_TO_WORKSPACE,
    payload: axios.post(CONFIG.apiUrl + `/workspaces/${workspaceId}/invite`, emailList, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
