import {
  FLUSH_EVENT_TO_SEND,
  GET_JIRA_ACCESS_TOKEN_FULFILLED,
  GET_JIRA_ACCESS_TOKEN_PENDING,
  GET_JIRA_ACCESS_TOKEN_REJECTED,
  GET_LINEAR_ACCESS_TOKEN_FULFILLED,
  GET_LINEAR_ACCESS_TOKEN_PENDING,
  GET_LINEAR_ACCESS_TOKEN_REJECTED,
  SET_EVENT_TO_SEND,
} from '../constants/issue_management_token';

const initialState = {
  linearToken: {},
  linearTokenFetchTime: null,
  linearTokenFetching: false,
  linearTokenFetched: false,
  linearTokenError: null,

  jiraToken: {},
  jiraTokenFetchTime: null,
  jiraTokenFetching: false,
  jiraTokenFetched: false,
  jiraTokenError: null,

  eventToSend: null,
};

export default function issueManagementToken(state = initialState, action) {
  switch (action.type) {
    case GET_LINEAR_ACCESS_TOKEN_PENDING:
      return {
        ...state,
        linearTokenFetching: true,
        linearTokenFetched: false,
        linearTokenError: null,
        linearTokenFetchTime: null,
      };
    case GET_LINEAR_ACCESS_TOKEN_FULFILLED:
      return {
        ...state,
        linearTokenFetching: false,
        linearTokenFetched: true,
        linearTokenError: null,
        linearToken: action.payload.data,
        linearTokenFetchTime: new Date(),
      };
    case GET_LINEAR_ACCESS_TOKEN_REJECTED:
      return {
        ...state,
        linearTokenFetching: false,
        linearTokenFetched: false,
        linearTokenError: action.payload,
        linearTokenFetchTime: null,
      };
    case GET_JIRA_ACCESS_TOKEN_PENDING:
      return {
        ...state,
        jiraTokenFetching: true,
        jiraTokenFetched: false,
        jiraTokenError: null,
        jiraTokenFetchTime: null,
      };
    case GET_JIRA_ACCESS_TOKEN_FULFILLED:
      return {
        ...state,
        jiraTokenFetching: false,
        jiraTokenFetched: true,
        jiraTokenError: null,
        jiraToken: action.payload.data,
        jiraTokenFetchTime: new Date(),
      };
    case GET_JIRA_ACCESS_TOKEN_REJECTED:
      return {
        ...state,
        jiraTokenFetching: false,
        jiraTokenFetched: false,
        jiraTokenError: action.payload,
        jiraTokenFetchTime: null,
      };
    case SET_EVENT_TO_SEND:
      return {
        ...state,
        eventToSend: action.payload,
      };
    case FLUSH_EVENT_TO_SEND:
      return {
        ...state,
        eventToSend: null,
      };
    default:
      return state;
  }
}
