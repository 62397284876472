import * as FullStory from '@fullstory/browser';

// `devMode` envs are not recorded by FullStory!
// If you want to see sessions of a spesific env on FullStory and Heap,
// then just comment out for that env to make it out of dev mode,
// then see the captured session at FullStory.
const isDevMode =
  process.env.REACT_APP_ENV === 'demo' ||
  process.env.REACT_APP_ENV === 'dev' || // localhost
  process.env.REACT_APP_ENV === 'lab' ||
  process.env.REACT_APP_ENV === 'staging';

export function initFullStory() {
  // console.log("init FS, isDevMode:", isDevMode);

  //FS - thundra: B9XEX
  FullStory.init({
    orgId: 'B9XEX',
    devMode: isDevMode,
  });
}

export function initHeap() {
  // heap - dev: 1815275075
  // heap - prod: 838919937

  if (window.heap && window.heap.load) {
    if (isDevMode) {
      // console.log("init HEAP dev isDevMode:", isDevMode);
      window.heap.load('1815275075'); // heap dev proj.
    } else {
      // prod
      // console.log("init HEAP prod isDevMode:", isDevMode);
      window.heap.load('838919937'); // heap prod proj.
    }
  }
}

// No CUSTOMER obj as param here!
export function identifyUsers(userData) {
  // We keep window.datalLayer for 'userDataReady' event which is used by Intercom via GTM.
  window.dataLayer = window.dataLayer || [];
  // profile.metadata can be undefined when user invited and login on local environment
  // profile.metedata fetched when working lab, staging, prod
  window.dataLayer.push({
    event: 'userDataReady',
    userId: userData.userId,
    displayName: userData.fullName || '',
    email: userData.email || '',
    organization: 'SidekickNoOrganization',
    role: userData.role || '',
  });

  // Heap should not be added via GTM, it creates a problem; 'react heap push is not a function'
  // `userId` changes when user changes organization, so it is better to user `originalUserId` for
  // user identification.
  if (window.heap && window.heap.identify && window.heap.addUserProperties) {
    window.heap.identify(`${userData.email}`);

    window.heap.addUserProperties({
      ...userData,
      email: userData.email || 'no email',
      devEnv: process.env.REACT_APP_ENV,
    });
  }

  FullStory.identify(userData.userId, {
    // displayName: userData.fullName || "no name",
    displayName: userData.email || 'no displayName',
    userName: userData.fullName || 'no user fullName',
    email: userData.email || 'no email',
    organization: 'SidekickNoOrganization',
    role: userData.role || 'no role',
    devEnv: process.env.REACT_APP_ENV,
  });
}
