import React, { Component } from 'react';
import { Form, Input, Grid, GridColumn, GridRow, Icon } from 'semantic-ui-react';
import './WorkspaceTab.scss';

class WorkspaceTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workspaceName: this.props.workspace.workspace.workspaceName,
      showCopyLabel: false,
      apmUrl: this.props.workspace?.workspaceSettings?.apmBaseUrl
    };
  }

  handleChange = e => {
    const fieldValue = e.target.value;
    this.setState({ workspaceName: fieldValue.replace(/\s/g, '') });
  };

  handleApmUrlChange = e => {
    const fieldValue = e.target.value;
    this.setState({ apmUrl: fieldValue ? fieldValue : null });
  };

  renderPackageInfo = () => {
    return (
      <>
        <div className={'small-header'}>Summary</div>
        <Grid className={'package-info-grid'}>
          <GridRow columns={'3'}>
            <GridColumn width={'4'}>
              <div>
                <Icon name={'setting'} />
                Users:
              </div>
              <div>4</div>
            </GridColumn>
            <GridColumn width={'4'}>
              <div>
                <Icon name={'setting'} />
                Apps
              </div>
              <div>4</div>
            </GridColumn>
            <GridColumn width={'4'}>
              <div>
                <Icon name={'setting'} />
                Tracepoints
              </div>
              <div>400</div>
            </GridColumn>
          </GridRow>
        </Grid>
      </>
    );
  };

  copyClicked = () => {
    navigator.clipboard.writeText(this.props.workspace.apiKey);
    this.setState({ showCopyLabel: true });
    setTimeout(() => this.setState({ showCopyLabel: false }), 3000);
  };

  renderWorkspaceSettings = () => {
    return (
      <Grid>
        <GridRow style={{ paddingBottom: 'unset' }}>
          <GridColumn>
            <div className={'small-header'}>About Workspace</div>
          </GridColumn>
        </GridRow>
        <GridRow style={{ paddingTop: 'unset' }}>
          <GridColumn width={'8'}>
            <Form
              onSubmit={() =>
                this.props.onSubmit({
                  workspaceName: this.state.workspaceName,
                  workspaceDescription: '',
                }, this.state.apmUrl)
              }
            >
              <Form.Input
                autoComplete={'off'}
                label={'Name'}
                name={'name'}
                controller={'input'}
                placeholder={'Workspace Name'}
                value={this.state.workspaceName}
                onChange={this.handleChange}
                required
              />

              <Form.Input
                autoComplete={'off'}
                label={'Custom APM Url'}
                name={'apmUrl'}
                controller={'input'}
                placeholder={'Workspace APM Url - default: Thundra APM'}
                value={this.state.apmUrl || ''}
                onChange={this.handleApmUrlChange}
              />

              <Form.Button
                disabled={
                  this.state.workspaceName === this.props.workspace.workspace.workspaceName &&
                  this.state.apmUrl === this.props.workspace?.workspaceSettings?.apmBaseUrl
                }
                content={'Save Changes'}
              />
              <div className={'api-key-container'}>
                <div className={'label'}>API Key</div>
                <div className={'api-key'}>
                  {this.props.workspace.apiKey}
                  <span>
                    {this.state.showCopyLabel ? (
                      <span>Copied!</span>
                    ) : (
                      <i onClick={this.copyClicked} className={'clickable copy icon'} />
                    )}
                  </span>
                </div>
              </div>
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };

  render() {
    console.log('WorkspaceTab; props: ', this.props);

    return (
      <Grid className={'workspace-tab-main-grid'}>
        {/*<GridRow className={'package-info-row'}>*/}
        {/*  <GridColumn>{this.renderPackageInfo()}</GridColumn>*/}
        {/*</GridRow>*/}
        <GridRow>
          <GridColumn>{this.renderWorkspaceSettings()}</GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

export default WorkspaceTab;
