import {
  DELETE_WORKSPACE_FULFILLED,
  DELETE_WORKSPACE_PENDING,
  DELETE_WORKSPACE_REJECTED,
} from 'store/constants/workspace-delete';

const deleteWorkspaceInitialState = {
  deleteWorkspaceResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function deleteWorkspace(state = deleteWorkspaceInitialState, action) {
  switch (action.type) {
    case DELETE_WORKSPACE_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case DELETE_WORKSPACE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        deleteWorkspaceResult: action.payload.data,
      };
    case DELETE_WORKSPACE_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
