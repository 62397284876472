import React, { Component } from 'react';
import { Button, Form, TextArea } from 'semantic-ui-react';

export default class OnpremLicencingTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      licenceKey: '',
      userTokens: '',
    };
  }

  submitLicence = () => {
    const { licenceKey, userTokens } = this.state;
    // eslint-disable-next-line
    let trimmedLicenceKey = licenceKey.replaceAll(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, '').replaceAll(' ', '');
    // eslint-disable-next-line
    let trimmedUserTokens = userTokens.replaceAll(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, '').replaceAll(' ', '');
    let userTokkensArray = trimmedUserTokens.split('"').filter(token => token !== ',' && token !== '');

    this.props.setOnpremLicence(
      trimmedLicenceKey,
      userTokkensArray,
      () => null,
      () => null,
    );
  };

  render() {
    return (
      <>
        <div className={'workspace-page-wrapper'}>
          <div className={'small-header'}>Licence your onprem product.</div>
          <Form>
            <label style={{ marginBottom: '5px' }}>Licence Key</label>
            <TextArea
              spellCheck={false}
              style={{ minHeight: 200 }}
              onChange={e => this.setState({ licenceKey: e.target.value })}
              placeholder="Enter your licence"
            />
            <label style={{ marginBottom: '5px' }}>User tokens</label>
            <TextArea
              spellCheck={false}
              style={{ minHeight: 300 }}
              onChange={e => this.setState({ userTokens: e.target.value })}
              placeholder="Enter your users"
            />
            <Button style={{ marginTop: '5px' }} onClick={this.submitLicence} content={'Submit'} />
          </Form>
        </div>
      </>
    );
  }
}
