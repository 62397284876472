import {
  GET_GITHUB_BRANCH_COMMITS_FETCHING,
  GET_GITHUB_BRANCH_COMMITS_FETCHED,
  GET_GITHUB_BRANCH_COMMITS_ERROR,

  GET_GITLAB_BRANCH_COMMITS_FETCHING,
  GET_GITLAB_BRANCH_COMMITS_FETCHED,
  GET_GITLAB_BRANCH_COMMITS_ERROR,
} from '../constants/git-commits';

const initialState = {
  commitData: [], // githubCommitData
  isFetched: false,
  isFetching: false,
  isError: false,

  gitlabCommitData: [],
  isGitlabCommitDataFetched: false,
  isGitlabCommitDataFetching: false,
  isGitlabCommitDataError: false,
};

const commitReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GITHUB_BRANCH_COMMITS_FETCHING:
      return Object.assign({}, state, {
        commitData: [],
        isFetched: false,
        isFetching: true,
        isError: false,
      });
    case GET_GITHUB_BRANCH_COMMITS_FETCHED:
      console.log('reducers, users, GET_GITHUB_BRANCH_COMMITS_FETCHED; action: ', action);
      return Object.assign({}, state, {
        commitData: [].concat(action.payload.data),
        isFetched: true,
        isFetching: false,
        isError: false,
      });
    case GET_GITHUB_BRANCH_COMMITS_ERROR:
      return Object.assign({}, state, {
        isFetched: false,
        isError: true,
        isFetching: false,
      });

    // Gitlab
    case GET_GITLAB_BRANCH_COMMITS_FETCHING:
      return Object.assign({}, state, {
        gitlabCommitData: [],
        isGitlabCommitDataFetched: false,
        isGitlabCommitDataFetching: true,
        isGitlabCommitDataError: false,
      });
    case GET_GITLAB_BRANCH_COMMITS_FETCHED:
      console.log('reducers, users, GET_GITLAB_BRANCH_COMMITS_FETCHED; action: ', action);
      return Object.assign({}, state, {
        gitlabCommitData: [].concat(action.payload.data),
        isGitlabCommitDataFetched: true,
        isGitlabCommitDataFetching: false,
        isGitlabCommitDataError: false,
      });
    case GET_GITLAB_BRANCH_COMMITS_ERROR:
      return Object.assign({}, state, {
        isGitlabCommitDataFetched: false,
        isGitlabCommitDataError: true,
        isGitlabCommitDataFetching: false,
      });
    default:
      return state;
  }
};

export default commitReducer;
