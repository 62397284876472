import {
  GET_GITHUB_GIT_TREE_FETCHING,
  GET_GITHUB_GIT_TREE_FETCHED,
  GET_GITHUB_GIT_TREE_ERROR,

  GET_GITLAB_GIT_TREE_FETCHING,
  GET_GITLAB_GIT_TREE_FETCHED,
  GET_GITLAB_GIT_TREE_ERROR,
} from '../constants/git-tree';

import { getSourceProviderOAuthTokenSelector } from 'store/selectors';
import store from 'store/store';
import { SOURCE_PROVIDER_PLATFORM, findGitlabProjectIdFromRepoName } from 'utils/source-provider-util';

import axios from 'axios';
import { Octokit } from '@octokit/rest';

export const getGitTree = (sourceProvider, ownerLogin, repoName, treeSha, successFunction, errFunction) => {
  console.log('==> getGitTree');
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    console.log('GITHUB ==> getGitTree');
    return getGitTreeGithub(ownerLogin, repoName, treeSha, successFunction, errFunction);
  }

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    console.log('GITLAB ==> getGitTree');
    return getGitTreeGitlab(ownerLogin, repoName, treeSha, successFunction, errFunction);
  }
};

// GITLAB
// Ref doc => https://docs.gitlab.com/ee/api/repositories.html#list-repository-tree
export const getGitTreeGitlab = (ownerLogin, repoName, treeSha, successFunction, errFunction) => {
  console.log('GITLAB, ACTIONS, getGitTreeGitlab; ownerLogin, repoName, treeSha: ', ownerLogin, repoName, treeSha);
  const gitlabPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITLAB);
  const projectId = findGitlabProjectIdFromRepoName(repoName);
  // paginated tree url sample =>
  //    `https://gitlab.com/api/v4/projects/${projectId}/repository/tree?ref=${treeSha}&recursive=true&per_page=100&page=1`;
  const getRepoTreeUrl = `https://gitlab.com/api/v4/projects/${projectId}/repository/tree?ref=${treeSha}&recursive=true&per_page=100`;
  console.log('getGitTreeGitlab; getRepoTreeUrl: ', getRepoTreeUrl);

  return dispatch => {
    dispatch(getGitlabGitTreeStarted());

    fetchPaginatedRepoTree(dispatch, getRepoTreeUrl, gitlabPAT, 1, [], successFunction, errFunction);
  };
};

// Sample res.header for pagination
// x-next-page: ""
// x-page: "36"
// x-per-page: "100"
// x-prev-page: ""
// x-total: "26"

// x-next-page: "2"
// x-page: "1"
// x-per-page: "100"
// x-prev-page: ""
// x-total: "3523"
const fetchPaginatedRepoTree = (
  dispatch,
  repoTreeUrl,
  gitlabPAT,
  pageNum = 1,
  repoTreeData = [],
  successFunction,
  errFunction,
) => {
  const getRepoTreeUrl = `${repoTreeUrl}&page=${pageNum}`;
  // console.log('fetchPaginatedRepoTree; repoTreeUrl: ', repoTreeUrl);
  // console.log('fetchPaginatedRepoTree; gitlabPAT,pageNum,repoTreeData: ', gitlabPAT, pageNum, repoTreeData);
  return axios
    .get(getRepoTreeUrl, {
      headers: { Authorization: 'Bearer ' + gitlabPAT },
    })
    .then(res => {
      const resHeaders = res.headers;
      const resData = res.data;
      const mergedData = [...repoTreeData, ...resData];

      // console.log('fetchPaginatedRepoTree - axios.then, resHeaders, resData: ', resHeaders, resData);
      if (resHeaders['x-next-page'] === '') {
        // console.log('fetchPaginatedRepoTree, getGitlabGitTreeSuccess; mergedData: ', mergedData);
        dispatch(getGitlabGitTreeSuccess(mergedData));
        if (successFunction) {
          successFunction();
        }
      } else {
        pageNum++;

        return fetchPaginatedRepoTree(
          dispatch,
          repoTreeUrl,
          gitlabPAT,
          pageNum,
          mergedData,
          successFunction,
          errFunction,
        );
      }
    })
    .catch(err => {
      dispatch(getGitlabGitTreeFailure(err.message));
      if (errFunction) {
        errFunction();
      }
    });
};

const getGitlabGitTreeStarted = () => ({
  type: GET_GITLAB_GIT_TREE_FETCHING,
});

const getGitlabGitTreeSuccess = data => {
  // console.log("action, users, getGitlabGitTreeSuccess; data: ", data);
  return {
    type: GET_GITLAB_GIT_TREE_FETCHED,
    payload: {
      data,
    },
  };
};

const getGitlabGitTreeFailure = error => {
  // console.log("action, users, getGitlabGitTreeFailure; error: ", error);
  return {
    type: GET_GITLAB_GIT_TREE_ERROR,
    payload: {
      error,
    },
  };
};

// GITHUB
// export const getGitTree = (ownerLogin, repoName, treeSha, successFunction, errFunction) => {
export const getGitTreeGithub = (ownerLogin, repoName, treeSha, successFunction, errFunction) => {
  // console.log("actions, git-tree; ownerLogin, repoName, treeSha: ", ownerLogin, repoName, treeSha);
  // const githubPAT = getSourceProviderOAuthTokenSelector(store.getState());
  const githubPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITHUB);
  const octokit = new Octokit({
    auth: githubPAT,
  });

  return dispatch => {
    dispatch(getGitTreeStarted());

    octokit.git
      .getTree({
        owner: ownerLogin,
        repo: repoName,
        tree_sha: treeSha,
        recursive: 1,
      })
      .then(res => {
        dispatch(getGitTreeSuccess(res.data));
        // console.log("getGitTree, res: ", res);

        if (successFunction) {
          // console.log("SUCCESS");
          successFunction();
        }
      })
      .catch(err => {
        dispatch(getGitTreeFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });
  };
};

const getGitTreeStarted = () => ({
  type: GET_GITHUB_GIT_TREE_FETCHING,
});

const getGitTreeSuccess = data => {
  // console.log("action, users, getGitTreeSuccess; data: ", data);
  return {
    type: GET_GITHUB_GIT_TREE_FETCHED,
    payload: {
      data,
    },
  };
};

const getGitTreeFailure = error => {
  // console.log("action, users, getGitTreeFailure; error: ", error);
  return {
    type: GET_GITHUB_GIT_TREE_ERROR,
    payload: {
      error,
    },
  };
};
