/**
 * Axios Interceptors added for encodeURI https://github.com/axios/axios
 */

import axios from 'axios';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.url = encodeURI(config.url);
    return config;
  },
  function (err) {
    // Do something with request error
    return Promise.reject(err);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  },
);
