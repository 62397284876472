export const findSidekickActionOfEvent = (saId, sidekickActions) => {
  let id = processSidekickActionId(saId);
  return sidekickActions?.find(el => el.id === id);
};

export const processSidekickActionId = saId => {
  if (saId == null) return;
  let id = saId;
  if (saId.startsWith('Tracepoint:')) {
    id = saId.substr(11);
  } else if (saId.startsWith('Logpoint:')) {
    id = saId.substr(9);
  }
  return id;
};

export const GET_REFERENCE_PURPOSE = {
  IDLE: 'IDLE',
  COMPARE: 'COMPARE',
  HIGHLIGHT: 'HIGHLIGHT',
};
