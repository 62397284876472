import {
  CHANGE_SANDBOX_MODE,
  CLEAR_SANDBOX_STEPS,
  GET_DEMO_ACCOUNT_FULFILLED,
  GET_DEMO_ACCOUNT_PENDING,
  GET_DEMO_ACCOUNT_REJECTED,
  START_ONBOARDING,
  START_ONBOARDING_COMPLETED,
  TOGGLE_SANDBOX_STEP,
} from '../constants/onboarding-v2';

const initialState = {
  startOnboardingStatus: START_ONBOARDING_COMPLETED,

  sandboxEnabled: false,

  sandboxSteps: [
    { name: 'Select App', completed: true, tourId: 365341 },
    { name: 'Put tracepoint', completed: false, tourId: 351612 },
    { name: 'See collected events', completed: false, tourId: 365343 },
  ],
  demoAccount: {},
  demoAccountFetching: false,
  demoAccountFetched: false,
  demoAccountError: null,
};

export default function onboardingV2(state = initialState, action) {
  switch (action.type) {
    case START_ONBOARDING:
    case START_ONBOARDING_COMPLETED:
      return {
        ...state,
        startOnboardingStatus: action.type,
      };

    case TOGGLE_SANDBOX_STEP: {
      const stepId = action.payload;
      const newSteps = state.sandboxSteps.map(step => ({ ...step }));
      const selectedStep = newSteps.find(step => step.tourId === stepId);
      if (selectedStep) {
        selectedStep.completed = true;
      }
      return { ...state, sandboxSteps: newSteps };
    }
    case CLEAR_SANDBOX_STEPS: {
      const newSteps = state.sandboxSteps.map((step, index) => ({ ...step, completed: index === 0 }));
      return { ...state, sandboxSteps: newSteps };
    }
    case CHANGE_SANDBOX_MODE: {
      const sandboxEnabled = action.payload;
      const newState = { ...state, sandboxEnabled: sandboxEnabled };
      if (sandboxEnabled === false) {
        newState.demoAccountFetching = false;
        newState.demoAccountFetched = false;
        newState.demoAccountError = null;
        newState.demoAccount = null;
      }
      return newState;
    }
    case GET_DEMO_ACCOUNT_PENDING:
      return { ...state, demoAccountFetching: true, demoAccountFetched: false, demoAccountError: null };
    case GET_DEMO_ACCOUNT_FULFILLED:
      return {
        ...state,
        demoAccountFetching: false,
        demoAccountFetched: true,
        demoAccountError: null,
        demoAccount: action.payload.data,
      };
    case GET_DEMO_ACCOUNT_REJECTED:
      return { ...state, demoAccountFetching: false, demoAccountFetched: false, demoAccountError: action.payload };
    default:
      return state;
  }
}
