import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

export class SidekickConfirmModal extends Component {
  render() {
    return (
      <Modal
        className={'confirm-modal'}
        open={this.props.modalOpen}
        onClose={() => this.props.onClose(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Icon name="warning sign" />
          Are you sure?
        </Modal.Header>
        <Modal.Content>{this.props.content}</Modal.Content>
        <Modal.Actions>
          <Button
            className={'secondary button-step-info'}
            content={'Cancel'}
            onClick={() => this.props.onClose(false)}
          />
          <Button
            loading={this.props?.loading || false}
            content={'Confirm'}
            onClick={() => this.props.confirmModalAction()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
