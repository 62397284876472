import React, { Component } from 'react';
import { Button, Grid, GridColumn, GridRow, Popup } from 'semantic-ui-react';
import { ActivePaymentMethod } from '../PlanInfoSection';
import './ChangePlan.scss';
import { ToastConfig } from 'utils/toast-util';
import { toast } from 'react-toastify';

const BASE_INSTANCES = 5;
const BASE_USERS = 10;
export const BASE_PRICE = 29;
class ChangePlan extends Component {
  constructor(props) {
    super(props);
    const { activeSubscription } = this.props.activeSubscription;

    this.state = {
      instances: activeSubscription.applicationLimit || BASE_INSTANCES,
      users: activeSubscription.userLimit || BASE_USERS,
    };
  }

  handleChange = event => {
    if (event.target.value === '') {
      this.setState({ [event.target.name]: 0 });
      return;
    }
    if (!event.target.value.match(/^[0-9]+$/)) {
      return;
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  getSlider = (name, min) => {
    return (
      <>
        <div className={'input-container'}>
          <input
            type={'range'}
            min={min}
            max={'100'}
            name={name}
            value={this.state[name]}
            onChange={this.handleChange}
            step={'1'}
          />
        </div>
        <div className={'input-container increment'}>
          <input
            type="number"
            disabled={true}
            min={min}
            max="100"
            name={name}
            value={this.state[name]}
            onChange={this.handleChange}
          />
        </div>
      </>
    );
  };

  calculateTotal = () => {
    const { activeSubscription } = this.props.activeSubscription;
    return activeSubscription.nextInvoiceTotal ? '$' + activeSubscription.nextInvoiceTotal / 100 : '$0';
  };

  confirmButtonDisabled = total => {
    const { activeSubscription } = this.props.activeSubscription;

    if (activeSubscription.nextInvoiceTotal == null) {
      return false;
    }
    if (BASE_PRICE + total === BASE_PRICE) {
      return true;
    }
  };

  render() {
    let total = (this.state.users - BASE_USERS) * 2 + (this.state.instances - BASE_INSTANCES) * 5;

    return (
      <>
        <Grid className={'change-plan-main-grid'}>
          <GridRow>
            <GridColumn className={'change-plan-header'}>
              <Popup
                className={'tooltip'}
                on={'hover'}
                content={'Back to'}
                trigger={
                  <div className={'back-to'} onClick={this.props.onBackClicked}>
                    {'<'}
                  </div>
                }
                position={'bottom center'}
              />

              <div className={'small-header'}>Change Plan</div>
            </GridColumn>
          </GridRow>
          <GridRow className={'plan-summary-row'}>
            <GridColumn className={'header-column'}>
              <div className={'header'}>Sidekick Base</div>
              <div className={'header-caption'}>{this.calculateTotal()} monthly</div>
            </GridColumn>
            <GridColumn className={'content-column small-header-caption'}>
              Manage your own plan to fit your scale. Your first 5 instances and 10 users are free with our base plan.
            </GridColumn>
            <GridColumn className={'price-column'}>
              <div className={'header'}>{this.calculateTotal()}</div>
              <div className={'price-caption'}>per month</div>
            </GridColumn>
          </GridRow>
          <GridRow className={'instance-row'}>
            <GridColumn className={'header-column'}>
              <div className={'header'}>Instances</div>
              <div className={'header-caption'}>$5 per instance</div>
            </GridColumn>
            <GridColumn className={'content-column'}>{this.getSlider('instances', BASE_INSTANCES)}</GridColumn>
            <GridColumn className={'price-column'}>
              <div className={'header'}>${(this.state.instances - 5) * 5}</div>
              <div className={'price-caption'}>{this.state.instances} instances</div>
            </GridColumn>
          </GridRow>
          <GridRow className={'user-row'}>
            <GridColumn className={'header-column'}>
              <div className={'header'}>Users</div>
              <div className={'header-caption'}>$2 per user</div>
            </GridColumn>
            <GridColumn className={'content-column'}>{this.getSlider('users', BASE_USERS)}</GridColumn>
            <GridColumn className={'price-column'}>
              <div className={'header'}>${(this.state.users - 10) * 2}</div>
              <div className={'price-caption'}>{this.state.users} users</div>
            </GridColumn>
          </GridRow>
          <GridRow className={'payment-row'} columns={'2'}>
            <GridColumn className={'active-payment-method-column'}>
              <div className={'active-payment-method-container'}>
                <ActivePaymentMethod
                  changePaymentMethod={this.props.onChangePaymentMethodClicked}
                  billingCustomer={this.props.billingCustomer}
                />
              </div>
            </GridColumn>
            <GridColumn floated={'right'} className={'price-calc-column'}>
              <div className={'calculator'}>
                <div className={'calculator-row'}>
                  <div className={'header'}>Total per month</div>
                  <div className={'header'}>${29 + total}</div>
                </div>
                <div className={'calculator-row'}>
                  <div className={'header-caption'}>
                    The charges are calculated based on the cost per day. New charges related to your plan change will
                    be calculated according to the number of days passed from the start of the month.
                  </div>
                </div>
              </div>
              <div className={'action-buttons'}>
                <Button
                  className={'wide-button'}
                  secondary
                  content={'Return to Billing'}
                  onClick={this.props.onBackClicked}
                />
                <Button
                  disabled={this.confirmButtonDisabled(total)}
                  className={'wide-button confirm'}
                  content={'Change & confirm purchase'}
                  loading={this.props.confirmPaymentResult.fetching}
                  onClick={() => {
                    if (this.props.billingCustomer.billingCustomer == null) {
                      toast.error('No card found. Please add card from left.', ToastConfig);
                      return;
                    }
                    this.props.confirmPayment(this.state.instances, this.state.users);
                  }}
                />
              </div>
            </GridColumn>
          </GridRow>
        </Grid>
      </>
    );
  }
}

export default ChangePlan;
