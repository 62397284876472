import {
  GET_ACTIVE_SUBSCRIPTION_FULFILLED,
  GET_ACTIVE_SUBSCRIPTION_PENDING,
  GET_ACTIVE_SUBSCRIPTION_REJECTED,
} from '../constants/billing-active-subscription';

const initialState = {
  activeSubscription: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function activeSubscription(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_SUBSCRIPTION_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_ACTIVE_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        activeSubscription: action.payload.data,
      };
    case GET_ACTIVE_SUBSCRIPTION_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
