import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { SET_DEFAULT_PROBE_WEBHOOK } from '../constants/workspace-set-default-probe-webhook';

export const setDefaultProbeWebhook = (workspaceId, probeType, integrationId) => {
  let urlSuffix = integrationId == null ? '' : `?webhookId=${integrationId}`;
  return {
    type: SET_DEFAULT_PROBE_WEBHOOK,
    payload: axios.post(CONFIG.apiUrl + `/workspaces/${workspaceId}/default${probeType}Webhook` + urlSuffix, null, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
