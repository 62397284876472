import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_SA_TAGS } from '../constants/sidekick-action-tag-list';

export const getSidekickActionTags = workspaceId => {
  return {
    type: GET_SA_TAGS,
    payload: axios.get(CONFIG.apiUrl + `/probetags?workspaceId=${workspaceId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
