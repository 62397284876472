import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { KICK_WORKSPACE_ACCOUNT } from '../constants/workspace-kick-account';

export const kickWorkspaceAccount = (workspaceId, accountId) => {
  return {
    type: KICK_WORKSPACE_ACCOUNT,
    payload: axios.delete(CONFIG.apiUrl + `/workspace/${workspaceId}/accounts/${accountId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
