import {
  GET_BILLING_CUSTOMER_FULFILLED,
  GET_BILLING_CUSTOMER_PENDING,
  GET_BILLING_CUSTOMER_REJECTED,
} from '../constants/billing-customer';

const initialState = {
  billingCustomer: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function billingCustomer(state = initialState, action) {
  switch (action.type) {
    case GET_BILLING_CUSTOMER_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_BILLING_CUSTOMER_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        billingCustomer: action.payload.data,
      };
    case GET_BILLING_CUSTOMER_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
