import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import { SET_ON_PREM_LICENCE } from '../constants/onprem-licencing';
import auth from 'auth/auth-factory';

export const setOnpremLicence = (licenseKey, userTokens) => {
  return {
    type: SET_ON_PREM_LICENCE,
    payload: axios.post(
      CONFIG.serviceUrl + `/license`,
      { licenseKey, userTokens },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
};
