import { connect } from 'react-redux';
import {
  connect as brokerConnect,
  disconnect as brokerDisconnect,
  send as brokerSend,
} from '@giantmachines/redux-websocket';

import { checkWaitingTimeout4SidekickAction } from 'store/actions';

import { BrokerConnect } from 'components';

function mapStateToProps(store) {
  return {
    broker: store.broker,
    onPremIdeToken: store.onPremIdeToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleBrokerOpen(url) {
      dispatch(brokerConnect(url));
    },
    handleBrokerClose() {
      dispatch(brokerDisconnect());
    },
    handleBrokerSend(data) {
      dispatch(brokerSend(data));
    },
    handleCheckWaitingTimeouts() {
      dispatch(checkWaitingTimeout4SidekickAction());
    },
  };
}

export const BrokerConnectContainer = connect(mapStateToProps, mapDispatchToProps)(BrokerConnect);
