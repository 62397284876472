import { connect } from 'react-redux';
import { TracePointEvents } from 'components';
import {
  clearPredefinedTracePointEvents,
  clearTracePointEvents,
  completedSidekickActionEventsFocus,
  flushReferenceEvent,
  setEventBeingProcessed,
} from 'store/actions';
import { send as brokerSend } from '@giantmachines/redux-websocket';
import { generateUUID } from 'utils/object-util';

function mapStateToProps(store) {
  return {
    broker: store.broker,
    sidekickActionEventsFocus: store.sidekickActionEventsFocus,
    activeWorkspace: store.activeWorkspace
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleTracePointEventFocusCompleted(sidekickAction, getReference, type) {
      dispatch(completedSidekickActionEventsFocus(sidekickAction, getReference, type));
    },
    handleTracePointEventClear() {
      dispatch(clearTracePointEvents());
    },
    handlePredefinedTracePointEventClear() {
      dispatch(clearPredefinedTracePointEvents());
    },
    handleBrokerSend(data) {
      data.id = data?.id || generateUUID();
      dispatch(brokerSend(data));
    },
    setEventBeingProcessed(event) {
      dispatch(setEventBeingProcessed(event));
    },
    flushReferenceEvent() {
      dispatch(flushReferenceEvent());
    },
  };
}

export const TracePointEventsContainer = connect(mapStateToProps, mapDispatchToProps)(TracePointEvents);
