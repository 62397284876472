import React, { Component } from 'react';
import cytoscape from 'cytoscape';
import panzoom from 'cytoscape-panzoom';
import popper from 'cytoscape-popper';
import spread from 'cytoscape-spread';
import coseBilkent from 'cytoscape-cose-bilkent';

//Css Layouts
import './TraceMapTopology.scss';
import './cy-panzoom.scss';

import 'font-awesome/css/font-awesome.min.css';
import style from './cy-stylesheet';
import { ArrayOfResourceClasses } from 'assets/constants/cy-stylesheet-common';
import { breadfirstLayout, coseBilkentLayout } from './cy-layout';

//Utils
import { toFixed } from 'utils/math';

cytoscape.use(panzoom);
cytoscape.use(spread);
cytoscape.use(coseBilkent);
cytoscape.use(popper);

const SPLIT_NODE_ID_CODE = '$!$';
const ITS_APP = 'its_app';
const edgesStatic = [
  {
    requestId: null,
    spanIds: ['66921c45-f93d-4a74-a904-4adf571b75e5'],
    invocationIds: [
      {
        invocationId: '35056671-bdb4-4ca2-96ca-f21d22201ef3',
        startTimestamp: 1613108000725,
        erroneous: false,
      },
    ],
    transactionIds: [
      {
        transactionId: '15aaff01-7c5b-4758-a374-37a6aa23faf0',
        startTimestamp: 1613108000725,
        erroneous: false,
      },
    ],
    sourceVertex: {
      invocationIds: null,
      transactionIds: null,
      resourceType: 'HTTP',
      resourceName: '172.31.9.224/healthcheck',
      resourceDisplayName: '/healthcheck',
      avgDuration: 1,
      count: 0,
      color: null,
      app: false,
    },
    targetVertex: {
      invocationIds: [
        {
          invocationId: '35056671-bdb4-4ca2-96ca-f21d22201ef3',
          startTimestamp: 1613108000725,
          erroneous: false,
        },
      ],
      transactionIds: [
        {
          transactionId: '15aaff01-7c5b-4758-a374-37a6aa23faf0',
          startTimestamp: 1613108000725,
          erroneous: false,
        },
      ],
      resourceType: 'Express',
      resourceName: 'MediumClone',
      resourceDisplayName: 'MediumClone',
      avgDuration: 1,
      count: 0,
      color: null,
      app: true,
    },
    count: 1,
    totalDuration: -1,
    averageDuration: -1,
    errorCount: 0,
    delay: 0,
  },
];

export class TraceMapTopology extends Component {
  constructor(props) {
    super(props);

    //Locals
    this.cy = undefined;
    this.slider = undefined;
    this.fitEnabled = { status: true, zoomLevel: null, panLevel: null };

    //States
    this.state = {
      detailsIsVisible: false,
      edgeLabelShow: true,
      nodeLabelShow: true,
      sidebarDragX: 0,
    };

    //Bindings
    this.calculateEdgeClassNameByHealth = this.calculateEdgeClassNameByHealth.bind(this);
    this.calculateNodeLabel = this.calculateNodeLabel.bind(this);
    this.calculateEdgeLabel = this.calculateEdgeLabel.bind(this);
    this.fixForClassName = this.fixForClassName.bind(this);
    this.generateNodeId = this.generateNodeId.bind(this);
    this.renderTopology = this.renderTopology.bind(this);
  }

  componentDidMount() {
    this.renderTopology();
  }

  isLambdaOrApp(vertx) {
    if (vertx) {
      const resourceType = vertx.resourceType;
      if (resourceType && typeof resourceType.toLowerCase === 'function') {
        const lResourceType = resourceType.toLowerCase();
        if (lResourceType === 'aws-lambda') {
          return true;
        }
      }

      const app = vertx.app;
      if (app === true) {
        return true;
      }
      return false;
    }
  }

  isIdAnApp(id) {
    if (id && typeof id === 'string') {
      if (id.includes(ITS_APP)) {
        return true;
      }
    }
    return false;
  }

  fixForClassName(vertex) {
    let className = vertex.resourceType.toLowerCase();
    if (!ArrayOfResourceClasses.includes(className)) {
      className = 'unknown';
    }
    return className;
  }

  calculateNodeLabel(edge, vertx) {
    if (!this.state.nodeLabelShow) {
      return '';
    }

    if (this.isLambdaOrApp(vertx)) {
      const duration = vertx.avgDuration === 0 ? '' : toFixed(vertx.avgDuration, 0) + ' ms';
      return vertx.resourceDisplayName + '\n' + duration;
    } else {
      return vertx.resourceDisplayName + '\n' + vertx.resourceType.replace('AWS-', '');
    }
  }

  getResourceTypeFromNodeLabel(nodeData) {
    if (nodeData && nodeData.label) {
      const parts = nodeData.label.split('\n');
      if (parts.length === 2) {
        return parts[1];
      }
    }
    return 'not_available_invoc';
  }

  calculateEdgeLabel(edge) {
    if (!this.state.edgeLabelShow) {
      return '';
    }

    let countLabel = '';
    if (edge.count === 1) countLabel = edge.count + ' time';
    if (edge.count > 1) countLabel = edge.count + ' times';

    if (this.isLambdaOrApp(edge.sourceVertex)) {
      let durationLabel = toFixed(edge.averageDuration, 0) + ' ms';

      let arrLabel = [];
      if (countLabel !== '') arrLabel.push(countLabel);
      if (durationLabel !== '') arrLabel.push(durationLabel);
      return arrLabel.join(' / ');
    } else {
      let latencyLabel = '';
      if (edge.delay > 0) {
        latencyLabel = toFixed(edge.delay, 0) + ' ms delay';
      }

      let arrLabel = [];
      if (countLabel !== '') arrLabel.push(countLabel);
      if (latencyLabel !== '') arrLabel.push(latencyLabel);
      return arrLabel.join(' / ');
    }
  }

  calculateEdgeClassNameByHealth(edge) {
    let healthObj = { health: '-', className: 'edge' };
    healthObj.className = 'edgeHealthUpper90';
    if (edge && edge.errorCount > 0) {
      healthObj.className = 'edgeHealthSmaller50';
    }

    return healthObj;
  }

  generateNodeId(edge, vertx) {
    let arr = [vertx.resourceName, vertx.resourceType];
    if (this.isLambdaOrApp(vertx)) {
      arr = [vertx.resourceName, vertx.resourceType, edge.applicationId, ITS_APP];
    }

    const id = arr.join(SPLIT_NODE_ID_CODE);
    return id;
  }

  isEmptyResource(vrtx) {
    return vrtx.resourceType === 'empty';
  }

  renderTopology() {
    const graphElement = document.getElementById('tracemapContainer');
    if (!graphElement) {
      return;
    }

    if (!this.fitEnabled.status && this.cy !== undefined) {
      this.fitEnabled.panLevel = this.cy.pan();
      this.fitEnabled.zoomLevel = this.cy.zoom();
    }

    let elements = [];

    let nodes = {};
    let edges = edgesStatic;

    for (let edge of edges) {
      if (edge.sourceVertex && !this.isEmptyResource(edge.sourceVertex)) {
        const sourceNodeClassName = this.fixForClassName(edge.sourceVertex);
        const sourceNodeId = this.generateNodeId(edge, edge.sourceVertex);
        // console.log("SOURCE; edge, sourceNodeId: ", edge, sourceNodeId);

        let sourceClasses = [sourceNodeClassName, 'normal'];
        if (edge.sourceVertex.color) {
          if (edge.sourceVertex.color === 'red') sourceClasses = [sourceNodeClassName, 'errored'];
          if (edge.sourceVertex.color === 'yellow') sourceClasses = [sourceNodeClassName, 'warning'];
        }
        nodes[sourceNodeId] = {
          data: {
            id: sourceNodeId,
            edge: edge,
            label: this.calculateNodeLabel(edge, edge.sourceVertex),
          },
          classes: sourceClasses,
        };
      }

      if (edge.targetVertex && !this.isEmptyResource(edge.targetVertex)) {
        const targetNodeClassName = this.fixForClassName(edge.targetVertex);
        const targetNodeId = this.generateNodeId(edge, edge.targetVertex);
        // console.log("TARGET; edge, targetNodeId: ", edge, targetNodeId);

        let targetClasses = [targetNodeClassName, 'normal'];
        if (edge.targetVertex.color) {
          if (edge.targetVertex.color === 'red') targetClasses = [targetNodeClassName, 'errored'];
          if (edge.targetVertex.color === 'yellow') targetClasses = [targetNodeClassName, 'warning'];
        }

        nodes[targetNodeId] = {
          data: {
            id: targetNodeId,
            edge: edge,
            label: this.calculateNodeLabel(edge, edge.targetVertex),
          },
          classes: targetClasses,
        };
      }
    }

    if (this.state.detailsIsVisible) {
      const { detailsData } = this.state;

      if (detailsData.source && detailsData.target) {
        nodes[detailsData.source].classes[1] = 'selected';
        nodes[detailsData.target].classes[1] = 'selected';
      } else if (this.isIdAnApp(detailsData.id)) {
        nodes[detailsData.id].classes[1] = 'selected-lambda';
      } else {
        nodes[detailsData.id].classes[1] = 'selected-resource';
      }
    }

    elements = Object.values(nodes);
    console.log('TMT; elements, nodes, edges: ', elements, nodes, edges);

    for (let i = 0; i < edges.length; i++) {
      let edge = edges[i];
      edge.index = i;

      if (edge.sourceVertex && edge.targetVertex) {
        if (!this.isEmptyResource(edge.targetVertex)) {
          const sourceNodeId = this.generateNodeId(edge, edge.sourceVertex);
          const targetNodeId = this.generateNodeId(edge, edge.targetVertex);

          const healthObj = this.calculateEdgeClassNameByHealth(edge);
          let edgeClasses = [healthObj.className];
          if (this.state.detailsIsVisible) {
            const { detailsData } = this.state;
            if (
              detailsData.edge.index === i &&
              detailsData.source === sourceNodeId &&
              detailsData.target === targetNodeId
            ) {
              edgeClasses = ['edge-selected'];
            }
          }

          // This creates edges.
          elements.push({
            data: {
              source: sourceNodeId,
              target: targetNodeId,
              edge: edge,
              label: this.calculateEdgeLabel(edge),
            },
            classes: edgeClasses,
          });
        }
      }
    }

    const configID = {
      container: graphElement,
      elements,
      style,
      zoomingEnabled: true,
      minZoom: 0.3,
      maxZoom: 2,
      userZoomingEnabled: true,
      boxSelectionEnabled: true,
      autounselectify: true,
      layout: breadfirstLayout,
    };

    const cy = cytoscape(configID);

    const defaults = {
      zoomFactor: 0.05,
      zoomDelay: 45,
      minZoom: 0.3,
      maxZoom: 2,
      fitPadding: 0,
    };

    cy.layout(coseBilkentLayout).run();
    cy.panzoom(defaults);

    console.log('render tracemap:' + JSON.stringify(this.fitEnabled));

    if (this.fitEnabled.status) {
      setTimeout(() => {
        this.fitEnabled.status = false;
        this.cy.fit();
      }, 1000);
    } else {
      cy.pan(this.fitEnabled.panLevel);
      cy.zoom(this.fitEnabled.zoomLevel);
    }

    this.cy = cy;
  }

  render() {
    console.log('TMT; props, state: ', this.props, this.state);
    return (
      <div className="tracemap-and-details-container">
        <div className="tracemap-container" style={{ width: '100%' }}>
          <figure id={'tracemapContainer'} />
        </div>
      </div>
    );
  }
}
