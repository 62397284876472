import React, { Component } from 'react';
import { compareTextAndNumber } from 'utils/string-util';
import './SidekickActionsTag.scss';

export class SidekickActionsTag extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getTags();
  }

  render() {
    const { saTags } = this.props?.saTagList;
    return (
      <div className={'sa-tags-container'}>
        <div className={'tags-header'}>Tags</div>
        <div className={'tags-list'}>
          {saTags
            .sort((a, b) => compareTextAndNumber(a.tag, b.tag))
            .map(tag => {
              return (
                <div key={tag.tag} className={'tag-item'} onClick={() => this.props.focusTag(tag.tag)}>
                  <div className={'tag-info'}>{tag.tag}</div>
                  <div className={'tag-actions'}>
                    <i
                      className={'tracepoint-action icon icon-apps-and-tps-enable-tp'}
                      onClick={e => {
                        e.stopPropagation();
                        this.props.enableTag(tag.tag);
                      }}
                    />
                    <i
                      className={'tracepoint-action icon icon-apps-and-tps-disable-tp'}
                      onClick={e => {
                        e.stopPropagation();
                        this.props.disableTag(tag.tag);
                      }}
                    />
                    <i 
                      className={'tracepoint-action icon icon-apps-and-tps-remove-tp'}
                      onClick={e => {
                        e.stopPropagation();
                        this.props.deleteTag(tag.tag);
                      }}
                    />

                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
