import {
  GET_GITHUB_REPO_BRANCHES_FETCHING,
  GET_GITHUB_REPO_BRANCHES_FETCHED,
  GET_GITHUB_REPO_BRANCHES_ERROR,
  GET_GITLAB_REPO_BRANCHES_FETCHING,
  GET_GITLAB_REPO_BRANCHES_FETCHED,
  GET_GITLAB_REPO_BRANCHES_ERROR,

  GET_GITHUB_BRANCH_HEAD_FETCHING,
  GET_GITHUB_BRANCH_HEAD_FETCHED,
  GET_GITHUB_BRANCH_HEAD_ERROR,
  GET_GITLAB_BRANCH_HEAD_FETCHING,
  GET_GITLAB_BRANCH_HEAD_FETCHED,
  GET_GITLAB_BRANCH_HEAD_ERROR,
} from '../constants/git-branches';
import { getSourceProviderOAuthTokenSelector } from 'store/selectors';
import { SOURCE_PROVIDER_PLATFORM, findGitlabProjectIdFromRepoName } from 'utils/source-provider-util';
import store from 'store/store';

import axios from 'axios';
import { Octokit } from '@octokit/rest';

export const getUserRepositoryBranches = (sourceProvider, ownerLogin, repoName, successFunction, errFunction) => {
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    return getUserRepositoryBranchesGithub(ownerLogin, repoName, successFunction, errFunction);
  }

  // For GITLAB, repoName passes projectId to this action and ownerLogin is not used!
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    console.log(
      'getUserRepositoryBranches - gitlab; sourceProvider, ownerLogin, repoName: ',
      sourceProvider,
      ownerLogin,
      repoName,
    );
    const projectId = repoName;
    return getUserRepositoryBranchesGitlab(projectId, successFunction, errFunction);
  }
};

// GITLAB
// Ref doc => https://docs.gitlab.com/ee/api/branches.html#list-repository-branches
export const getUserRepositoryBranchesGitlab = (projectId, successFunction, errFunction) => {
  console.log('getUserRepositoryBranches - gitlab, action, users; projectId: ', projectId);
  const gitlabPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITLAB);

  const listRepoBranchesUrl = `https://gitlab.com/api/v4/projects/${projectId}/repository/branches?per_page=50`;
  return dispatch => {
    dispatch(getGitlabUserRepositoryBranchesStarted());

    axios
      .get(listRepoBranchesUrl, {
        headers: { Authorization: 'Bearer ' + gitlabPAT },
      })
      .then(res => {
        dispatch(getGitlabUserRepositoryBranchesSuccess(res.data));
        console.log('getUserRepositoryBranches - gitlab, res: ', res);
        if (successFunction) {
          successFunction();
        }
      })
      .catch(err => {
        dispatch(getGitlabUserRepositoryBranchesFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });
  };
};

const getGitlabUserRepositoryBranchesStarted = () => ({
  type: GET_GITLAB_REPO_BRANCHES_FETCHING,
});

const getGitlabUserRepositoryBranchesSuccess = data => {
  // console.log("action, users, getGitlabUserRepositoryBranchesSuccess; data: ", data);
  return {
    type: GET_GITLAB_REPO_BRANCHES_FETCHED,
    payload: {
      data,
    },
  };
};

const getGitlabUserRepositoryBranchesFailure = error => {
  // console.log("action, users, getGitlabUserRepositoryBranchesFailure; error: ", error);
  return {
    type: GET_GITLAB_REPO_BRANCHES_ERROR,
    payload: {
      error,
    },
  };
};

// GITHUB
export const getUserRepositoryBranchesGithub = (ownerLogin, repoName, successFunction, errFunction) => {
  // console.log("actions, getUserRepositoryBranchesGithub; ownerLogin, repoName: ", ownerLogin, repoName);
  const githubPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITHUB);
  const octokit = new Octokit({
    auth: githubPAT,
  });

  return dispatch => {
    dispatch(getUserRepositoryBranchesStarted());

    octokit.repos
      .listBranches({
        owner: ownerLogin,
        repo: repoName,
      })
      .then(res => {
        dispatch(getUserRepositoryBranchesSuccess(res.data));
        // console.log("getUserRepositoryBranches, res: ", res);
        if (successFunction) {
          console.log('SUCCESS');
          // successFunction(response);
          successFunction();
        }
      })
      .catch(err => {
        dispatch(getUserRepositoryBranchesFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });
  };
};

const getUserRepositoryBranchesStarted = () => ({
  type: GET_GITHUB_REPO_BRANCHES_FETCHING,
});

const getUserRepositoryBranchesSuccess = data => {
  // console.log("action, users, getUserRepositoryBranchesSuccess; data: ", data);
  return {
    type: GET_GITHUB_REPO_BRANCHES_FETCHED,
    payload: {
      data,
    },
  };
};

const getUserRepositoryBranchesFailure = error => {
  // console.log("action, users, getUserRepositoryBranchesFailure; error: ", error);
  return {
    type: GET_GITHUB_REPO_BRANCHES_ERROR,
    payload: {
      error,
    },
  };
};

/////////////////////////////////////////////////////////
// Get single branch info in order to fetch last commit of the branch!
/////////////////////////////////////////////////////////

export const getUserRepositoryBranchHead = (
  sourceProvider,
  ownerLogin,
  repoName,
  branchName,
  successFunction,
  errFunction,
) => {
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    return getUserRepositoryBranchHeadGithub(ownerLogin, repoName, branchName, successFunction, errFunction);
  }

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    console.log(
      'getUserRepositoryBranchHead - gitlab; sourceProvider, ownerLogin, repoName, branchName: ',
      sourceProvider,
      ownerLogin,
      repoName,
      branchName,
    );
    return getUserRepositoryBranchHeadGitlab(repoName, branchName, successFunction, errFunction);
  }
};



// GITLAB
// Ref doc => https://docs.gitlab.com/ee/api/branches.html#get-single-repository-branch
export const getUserRepositoryBranchHeadGitlab = (repoName, branchName, successFunction, errFunction) => {
  console.log('GITLAB, ACTIONS, getUserRepositoryBranchHeadGitlab; repoName, branchName: ', repoName, branchName);
  const gitlabPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITLAB);
  const projectId = findGitlabProjectIdFromRepoName(repoName);
  const encodedBranchName = encodeURIComponent(branchName);

  const getRepoBranchUrl = `https://gitlab.com/api/v4/projects/${projectId}/repository/branches/${encodedBranchName}`;
  return dispatch => {
    dispatch(getGitlabUserRepositoryBranchHeadStarted());

    axios
      .get(getRepoBranchUrl, {
        headers: { Authorization: 'Bearer ' + gitlabPAT },
      })
      .then(res => {
        dispatch(getGitlabUserRepositoryBranchHeadSuccess(res.data));
        console.log('getGitlabUserRepositoryBranchHeadSuccess - gitlab, res: ', res);
        if (successFunction) {
          successFunction();
        }
      })
      .catch(err => {
        dispatch(getGitlabUserRepositoryBranchHeadFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });
  };
};

const getGitlabUserRepositoryBranchHeadStarted = () => ({
  type: GET_GITLAB_BRANCH_HEAD_FETCHING,
});

const getGitlabUserRepositoryBranchHeadSuccess = data => {
  // console.log("action, users, getGitlabUserRepositoryBranchHeadSuccess; data: ", data);
  return {
    type: GET_GITLAB_BRANCH_HEAD_FETCHED,
    payload: {
      data,
    },
  };
};

const getGitlabUserRepositoryBranchHeadFailure = error => {
  // console.log("action, users, getGitlabUserRepositoryBranchHeadFailure; error: ", error);
  return {
    type: GET_GITLAB_BRANCH_HEAD_ERROR,
    payload: {
      error,
    },
  };
};




// GITHUB
export const getUserRepositoryBranchHeadGithub = (ownerLogin, repoName, branchName, successFunction, errFunction) => {
  console.log('actions, getUserRepositoryBranchHead; ownerLogin, repoName: ', ownerLogin, repoName, branchName);
  const githubPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITHUB);
  console.log('getUserRepositoryBranchHead; githubPAT: ', githubPAT);
  const octokit = new Octokit({
    auth: githubPAT,
  });

  return dispatch => {
    dispatch(getUserRepositoryBranchHeadStarted());

    octokit.repos
      .getBranch({
        owner: ownerLogin,
        repo: repoName,
        branch: branchName,
      })
      .then(res => {
        dispatch(getUserRepositoryBranchHeadSuccess(res.data));
        console.log('getUserRepositoryBranchHeadSuccess, res: ', res);
        if (successFunction) {
          // console.log("SUCCESS");
          successFunction();
        }
      })
      .catch(err => {
        dispatch(getUserRepositoryBranchHeadFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });
  };
};

const getUserRepositoryBranchHeadStarted = () => ({
  type: GET_GITHUB_BRANCH_HEAD_FETCHING,
});

const getUserRepositoryBranchHeadSuccess = data => {
  // console.log("action, users, getUserRepositoryBranchHeadSuccess; data: ", data);
  return {
    type: GET_GITHUB_BRANCH_HEAD_FETCHED,
    payload: {
      data,
    },
  };
};

const getUserRepositoryBranchHeadFailure = error => {
  // console.log("action, users, getUserRepositoryBranchHeadFailure; error: ", error);
  return {
    type: GET_GITHUB_BRANCH_HEAD_ERROR,
    payload: {
      error,
    },
  };
};
