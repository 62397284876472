import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThundraSidebar } from 'components';
import { changeOnboardingStatus, getActiveSubscription } from 'store/actions';
import { START_ONBOARDING } from 'store/constants/onboarding-v2';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    userAccount: store.userAccount,
    activeWorkspaceId: store.workspaceList.activeWorkspaceId,
    activeWorkspace: store.activeWorkspace,
    activeSubscription: store.activeSubscription,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startOnboarding() {
      dispatch(changeOnboardingStatus(START_ONBOARDING));
    },
    getActiveSubscription(workspaceId, onSuccess, onError) {
      dispatchWithExceptionHandler(getActiveSubscription(workspaceId), false, onSuccess, onError);
    },
  };
}

export const ThundraSidebarContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ThundraSidebar));
