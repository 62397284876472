import routeList from '../routes';

export const companyRoles = {
  QA_ENGINEER: 'QA Engineer',
  SUPPORT_ENGINEER: 'Support Engineer',
  CUSTOMER_SUCCESS_ENGINEER: 'Customer Success Engineer',
  BACKEND_DEVELOPER: 'Backend Developer',
  TECHNICAL_LEAD: 'Technical Lead',
  Other: 'Other',
};

export const roles = {
  BILLING_ADMIN: 'BILLING_ADMIN', // billing admin sadece billing tabi
  ADMIN: 'ADMIN', //billing yetkisine sahipse
  OWNER: 'OWNER',
  USER: 'USER', //billing yetkisine sahipse
};

export const redirectRules = {
  [roles.BILLING_ADMIN]: {
    defaultRedirectionURL: routeList.workspace.path,
  },
  [roles.USER]: {
    defaultRedirectionURL: routeList.ide.path,
  },
  [roles.ADMIN]: {
    defaultRedirectionURL: routeList.workspace.path,
  },
  [roles.OWNER]: {
    defaultRedirectionURL: routeList.workspace.path,
  },
};

export const DEFAULT_ALLOWED_ROLES = [roles.OWNER];
