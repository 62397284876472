import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { CopyBox } from '../CopyBox/CopyBox';

export class InstructionsModal extends Component {
  constructor(props) {
    super(props);
  }

  renderSteps = () => {
    const { framework } = this.props;

    return (
      <div className={'steps'}>
        <div className={'main subtitle'}>Fork the repository</div>
        <div className={'step'}>
          <CopyBox value={framework?.repo} />
        </div>
        <div className={'main subtitle'}>Follow the steps below</div>

        {framework?.steps.map((step, i) => {
          return (
            <div className={'step'}>
              <div className={'title-container'}>
                <div className={'index'}>{i + 1}</div>
                <div className={'subtitle'}>{step.title}</div>
              </div>
              <div className={'caption'}>{step.caption}</div>
              {step.action && (
                <div className={'action'}>
                  <CopyBox value={step.action} />
                </div>
              )}
              {step.gif && (
                <div className={'gif'}>
                  <iframe
                    width="100%"
                    height="292.6"
                    src={step.gif}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { framework } = this.props;
    return (
      <Modal
        className={'instructions-modal'}
        open={this.props.modalOpen}
        onClose={() => this.props.onClose()}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{`Quickstart ${framework?.name} example`}</Modal.Header>
        <Modal.Content>{this.renderSteps()}</Modal.Content>
        <Modal.Actions>
          <Button content={'Ok'} onClick={() => this.props.onClose()} />
        </Modal.Actions>
      </Modal>
    );
  }
}
