import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { SOURCE_PROVIDER_PLATFORM, selectBranchDataAccordingToSourceProvider } from 'utils/source-provider-util';

export class SourceRepoBranchSelector extends Component {
  componentDidMount() {
    const { selectedRepo, selectedRepoOwnerLogin, selectedRepoName, gitSourceProvider } = this.props;
    if (selectedRepoName && gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
      this.props.getUserRepositoryBranches(gitSourceProvider, selectedRepoOwnerLogin, selectedRepoName);
    }
    if (selectedRepo && gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
      const projectId = selectedRepo.repodata.id;
      this.props.getUserRepositoryBranches(gitSourceProvider, selectedRepoOwnerLogin, projectId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedRepo, selectedRepoOwnerLogin, selectedRepoName, gitSourceProvider } = this.props;
    // console.log("SRBS, CDU; props, prevProps: ", this.props, prevProps);

    // We want to update when selectedRepoName and selectedRepoOwnerLogin is real values(not empty string),
    // otherwise we get error when getting repository branches because these values are used by the apis!
    if (
      selectedRepoName !== '' &&
      selectedRepoOwnerLogin !== '' &&
      (prevProps.selectedRepoName !== selectedRepoName || prevProps.selectedRepoOwnerLogin !== selectedRepoOwnerLogin)
    ) {
      // console.log("SRBS, CDU, getUserRepositoryBranches; selectedRepoOwnerLogin, selectedRepoName: ", selectedRepoOwnerLogin, selectedRepoName);
      if (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
        this.props.getUserRepositoryBranches(gitSourceProvider, selectedRepoOwnerLogin, selectedRepoName);
      }

      if (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
        const projectId = selectedRepo ? selectedRepo.repodata.id : '';
        this.props.getUserRepositoryBranches(gitSourceProvider, selectedRepoOwnerLogin, projectId);
      }
    }
  }

  findSelectedRepoBranchFromOptions = (options, value) => {
    return options.find(branch => branch.value === value);
  };

  renderBranchSelectorDropdown = () => {
    const { gitSourceProvider, selectedRepo, gitBranchData } = this.props;
    const { isFetching, isGitlabBranchDataFetching } = gitBranchData;

    if (
      (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB && isFetching) ||
      (gitSourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB && isGitlabBranchDataFetching)
    ) {
      return (
        <Dropdown
          placeholder="Loading available branches"
          disabled={true}
          fluid
          search
          selection
          options={[]}
          value={''}
        />
      );
    }

    // Get branchData according to source provider.
    const branchData = selectBranchDataAccordingToSourceProvider(gitSourceProvider, gitBranchData);
    const branchDropdownData = branchData.map(branch => {
      return {
        key: branch.name,
        value: branch.name,
        text: branch.name,
        branchdata: branch, // Here 'branchdata' should not be camel case, otherwise SUIR gives error!!
      };
    });

    let selectedRepoBranchValue = null;
    if (this.props.selectedRepoBranchName) {
      selectedRepoBranchValue = this.props.selectedRepoBranchName;
    }

    return (
      <Dropdown
        placeholder="Select Branch"
        disabled={!selectedRepo}
        fluid
        search
        selection
        options={branchDropdownData}
        value={selectedRepoBranchValue}
        onChange={(e, data) => {
          const selectedRepoBranch = this.findSelectedRepoBranchFromOptions(branchDropdownData, data.value);
          this.props.onSelectRepoBranch(selectedRepoBranch);
        }}
      />
    );
  };

  render() {
    console.log('SourceRepoBranchSelector, render; props, state: ', this.props, this.state);

    return <div className="github-repo-branch-selector-wrapper">{this.renderBranchSelectorDropdown()}</div>;
  }
}
