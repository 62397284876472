import React, { Component } from 'react';
import { Icon, Menu, Popup } from 'semantic-ui-react';
import routeList from 'routes';
import { withRouter } from 'react-router-dom';
import { sidebarDecorator } from 'utils/sidebar-decorator';

class SettingsMenuItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  changeOpenStatus = status => {
    this.setState({ isOpen: status });
  };

  handleMenuItemClick = state => {
    let { workspace: activeWorkspace } = this.props.activeWorkspace.workspace;
    let routingParams = {
      pathname: routeList.workspace.path.replace(':workspaceId', activeWorkspace.id),
      state: state,
    };
    this.props.history.push(routingParams);
    this.setState({ isOpen: false });
  };

  renderContent = workspaceName => {
    let { workspace } = this.props.activeWorkspace;
    const decorator = sidebarDecorator(workspace.role, workspace.billingAccount, workspace?.workspace);

    return (
      <div className="custom-profile-menu-wrapper">
        <div className="menu-title">{workspaceName}</div>
        <div className={'menu-section-title'}>Manage Workspace</div>
        {decorator.settingsMenuItems.workspaceSettings && (
          <div
            className={'menu-item-button'}
            onClick={() =>
              this.handleMenuItemClick({
                activeTab: 'Workspace',
              })
            }
          >
            Workspace Settings
          </div>
        )}
        {decorator.settingsMenuItems.integrations && (
          <div
            className={'menu-item-button new'}
            onClick={() =>
              this.handleMenuItemClick({
                activeTab: 'Integrations',
              })
            }
          >
            Integrations
            <div className={'new-label'}>New</div>
          </div>
        )}
        {decorator.settingsMenuItems.billing && (
          <div
            className={'menu-item-button'}
            onClick={() =>
              this.handleMenuItemClick({
                activeTab: 'Billing',
              })
            }
          >
            Billing
          </div>
        )}
        {decorator.settingsMenuItems.inviteToWorkspace && (
          <>
            <div className={'menu-section-title'}> Manage Users</div>
            <div
              className={'menu-item-button'}
              onClick={() =>
                this.handleMenuItemClick({
                  activeTab: 'Users',
                  openInviteUsersModal: true,
                })
              }
            >
              Invite to Workspace
            </div>
          </>
        )}{' '}
      </div>
    );
  };

  renderTrigger = () => {
    return (
      <Menu.Item
        className={'menu-item settings-menu ' + (this.state.isOpen ? 'active' : '')}
        as="a"
        onClick={() => this.changeOpenStatus(true)}
      >
        <Icon style={{ fontSize: '18px' }} name={'setting'} />
      </Menu.Item>
    );
  };

  render() {
    let { workspace: activeWorkspace } = this.props.activeWorkspace.workspace;
    return (
      <Popup
        className={'menu-popup'}
        position={'right center'}
        open={this.state.isOpen}
        onClose={() => this.changeOpenStatus(false)}
        trigger={this.renderTrigger()}
        content={this.renderContent(activeWorkspace.workspaceName)}
        on={'click'}
        basic
      />
    );
  }
}

export default withRouter(SettingsMenuItem);
