import cytoscape from 'cytoscape';
import { addIcons2Style } from 'assets/constants/cy-stylesheet-common';

const style = cytoscape
  .stylesheet()
  .selector('node')
  .css({
    height: 60,
    width: 60,
    shape: 'round-rectangle',
    label: 'data(label)',
    'background-fit': 'contain',
    'background-color': '#fff',
    'text-valign': 'bottom',
    'text-halign': 'center',
    'text-wrap': 'wrap',
    'border-color': '#ccc',
    'font-size': 14,
    'border-width': 1,
    'border-opacity': 0.5,
    color: '#272727',
  })
  .selector('.selected')
  .css({
    'border-width': 2,
    'border-opacity': 1,
    'border-color': '#736DEE',
  })
  .selector('.selected-lambda')
  .css({
    'border-color': '#736DEE',
    'border-width': 2,
    'border-opacity': 1,
    'border-style': 'solid',
  })
  .selector('.selected-resource')
  .css({
    'border-color': '#736DEE',
    'border-width': 2,
    'border-opacity': 1,
    'border-style': 'solid',
  })
  .selector('.normal')
  .css({
    'border-width': 2,
    'border-opacity': 1,
    'border-color': '#FFFFFF',
  })
  .selector('.errored')
  .css({
    'border-width': 2,
    'border-opacity': 1,
    'border-color': 'red',
    'border-style': 'dashed',
  })
  .selector('.warning')
  .css({
    'border-width': 2,
    'border-opacity': 1,
    'border-color': '#E1C010',
    'border-style': 'dashed',
  })
  .selector('.hidden')
  .css({
    opacity: '0.1',
  })
  .selector('edge')
  .css({
    label: 'data(label)',
    'curve-style': 'bezier',
    width: 4,
    'font-size': 12,
    color: 'grey',
    'target-arrow-shape': 'triangle',
    'line-color': '#B4B6C8',
    'target-arrow-color': '#B4B6C8',
    'text-wrap': 'wrap',
  })
  .selector('.edge-selected')
  .css({
    'curve-style': 'bezier',
    width: 4,
    'line-color': '#736DEE',
    'target-arrow-shape': 'triangle',
    'target-arrow-color': '#736DEE',
  })
  .selector('.edgeHealthUpper90')
  .css({
    label: 'data(label)',
    'curve-style': 'bezier',
    width: 4,
    'font-size': 12,
    color: 'grey',
    'target-arrow-shape': 'triangle',
    'line-color': '#3C871B',
    'target-arrow-color': '#3C871B',
    'text-wrap': 'wrap',
  })
  .selector('.edgeHealth7090')
  .css({
    label: 'data(label)',
    'curve-style': 'bezier',
    width: 4,
    'font-size': 12,
    color: 'grey',
    'target-arrow-shape': 'triangle',
    'line-color': '#E1C010',
    'target-arrow-color': '#E1C010',
    'text-wrap': 'wrap',
  })
  .selector('.edgeHealth5070')
  .css({
    label: 'data(label)',
    'curve-style': 'bezier',
    width: 4,
    'font-size': 12,
    color: 'grey',
    'target-arrow-shape': 'triangle',
    'line-color': '#F78528',
    'target-arrow-color': '#F78528',
    'text-wrap': 'wrap',
  })
  .selector('.edgeHealthSmaller50')
  .css({
    'line-color': '#E31E12',
    'target-arrow-color': '#E31E12',
  });

addIcons2Style(style, true); //Old Icon Definition
addIcons2Style(style); //Icon Definition

export default style;
