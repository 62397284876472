import {
  OPEN_SOURCES,
  SOURCES_OPENED,
  EVENTS_OPENED,
  OPEN_EVENTS,
  APPS_AND_TPS_OPENED,
  OPEN_APPS_AND_TPS,
} from '../constants/ide-toggle-border-tabs';

const initialState = {
  status: '',
};

export default function ideToggleBorderTabs(state = initialState, action) {
  switch (action.type) {
    case SOURCES_OPENED:
    case OPEN_SOURCES:
    case EVENTS_OPENED:
    case OPEN_EVENTS:
    case APPS_AND_TPS_OPENED:
    case OPEN_APPS_AND_TPS:
      return {
        ...state,
        status: action.type,
      };
    default:
      return state;
  }
}
