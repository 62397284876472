import { GET_WORKSPACES } from '../constants/workspace-list';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';

export const getWorkspaces = () => {
  return {
    type: GET_WORKSPACES,
    payload: axios.get(CONFIG.apiUrl + '/workspaces', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
