import React, { Component } from 'react';
import { Accordion, Checkbox } from 'semantic-ui-react';
import {
  abbrFileName,
  generateFullGitSourceURLPath,
  generatePathPrefixFromContextMetaData,
  generateSourceMetaDataFromEntityId,
} from 'utils/string-util';

import {
  deleteProbeTagRequestCmdObj,
  delLogPointCmdObj,
  delTracePointCmdObj,
  disableLogPointCmdObj,
  disableTracePointCmdObj,
  enableLogPointCmdObj,
  enableTracePointCmdObj,
  putLogPointCmdObj,
  putTracePointCmdObj,
  removeBatchTracePointCmdObj,
  removeReferenceEventRequestCmdObj,
  updateLogPointCmdObj,
  updateTracePointCmdObj,
} from 'utils/broker-commands';

import { IDE_CONTEXT_RESET_SUCCEEDED } from 'store/constants/ide-context';

import './SidekickActions.scss';
import { SA_EDIT_NOTIFY } from 'store/constants/sidekick-action-edit';
import { generateUUID } from 'utils/object-util';
import { SidekickActionsTable } from './SidekickActionsTable';
import { CONFIG } from 'utils/config-util';
import { EditSidekickActionPanel } from './EditSidekickActionPanel';
import URL_POOL from 'utils/url-pool';
import { EventsFilter } from '../TracepointEvents/EventsFilter/EventsFilter';
import { SidekickActionsTag } from '../SidekickActionsTag/SidekickActionsTag';
import { eqSet } from 'utils/set-utils';

const SA_STATE = {
  SA: 'SA',
  TAGS: 'TAGS',
};

export class SidekickActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editSidekickAction: false,
      selectedSidekickActionToEdit: undefined,
      selectedSidekickActionType: 'tracePoint',
      showPredefinedTP: true,
      showPredefinedLP: true,
      saState: SA_STATE.SA,
      lpFilter: '',
      tpFilter: '',
      selectedTP: new Set(),
      selectedLP: new Set(),
    };
  }

  componentDidMount() {
    const activeWorkspaceId = this.props.activeWorkspace.workspace?.workspace?.id;
    this.props.getSATags(activeWorkspaceId);
    const webhookDisabled = CONFIG?.ON_PREM && CONFIG?.EXCLUDED_FEATURES.includes('webhook');
    !webhookDisabled && this.props.getWebhooks(activeWorkspaceId);
  }

  handleSelectSA = (saType, saId) => {
    let stateKey = `selected${saType}`;
    const oldCheckedSA = this.state[stateKey];
    if (oldCheckedSA.has(saId)) {
      oldCheckedSA.delete(saId);
    } else {
      oldCheckedSA.add(saId);
    }
    this.setState({ [stateKey]: new Set(oldCheckedSA) });
  };

  renderSidekickActionsPlaceholder = actionType => {
    const sidekickActionType = actionType === 'logPoint' ? 'Logpoint' : 'Tracepoint';
    const sidekickActionPutText =
      actionType === 'logPoint' ? 'right click the gutter next to that line.' : 'click the gutter next to that line.';
    return (
      <div className={'apps-and-tps-placeholder'}>
        <h2 className="ui inverted center aligned small icon header">
          <i className="icon-apps-and-tps-set-trace-points icon" />
          <div className="content">Set your first {sidekickActionType}!</div>
        </h2>
        <div className={'description'}>
          <ul>
            <li>
              Place the pointer at an executable line of code where you want to put {sidekickActionType} and{' '}
              {sidekickActionPutText}
            </li>
            <li>
              If you are having a trouble with setting a Tracepoint, please{' '}
              <a href={URL_POOL.SIDEKICK_DOCS} target={'_blank'} rel="noopener noreferrer">
                check out our docs
              </a>{' '}
              or reach the support team.
            </li>
          </ul>
        </div>
      </div>
    );
  };

  closeEditPanelAfterEditCompleted = nextProps => {
    //Edit TP Screen Opened
    const { fileName, lineNo } = this.state.selectedSidekickActionToEdit;
    const { selectedSidekickActionType } = this.state;
    if (selectedSidekickActionType === 'tracePoint') {
      //Waiting Current
      const allTracePointWaitingCurrent = this.props.broker.tracePointsWaiting;
      const tpWaitingCurrent = allTracePointWaitingCurrent.find(el => {
        // TODO: Change it later
        // eslint-disable-next-line
        return el && el.fileName === fileName && el.lineNo == lineNo;
      });

      //WaitingNext
      const allTracePointWaitingNext = nextProps.broker.tracePointsWaiting;
      const tpWaitingNext = allTracePointWaitingNext.find(el => {
        // TODO: Change it later
        // eslint-disable-next-line
        return el && el.fileName === fileName && el.lineNo == lineNo;
      });

      if (tpWaitingCurrent && !tpWaitingNext) {
        this.toggleEditSidekickAction(false);
      }
    } else if (selectedSidekickActionType === 'logPoint') {
      //Waiting Current
      const allLogPointWaitingCurrent = this.props.broker.logPointsWaiting;
      const lpWaitingCurrent = allLogPointWaitingCurrent.find(el => {
        // TODO: Change it later
        // eslint-disable-next-line
        return el && el.fileName === fileName && el.lineNo == lineNo;
      });

      //WaitingNext
      const allLogPointWaitingNext = nextProps.broker.logPointsWaiting;
      const lpWaitingNext = allLogPointWaitingNext.find(el => {
        // TODO: Change it later
        // eslint-disable-next-line
        return el && el.fileName === fileName && el.lineNo == lineNo;
      });

      if (lpWaitingCurrent && !lpWaitingNext) {
        this.toggleEditSidekickAction(false);
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    const { notifyStatus, sidekickAction } = nextProps.sidekickActionEdit;

    if (notifyStatus === SA_EDIT_NOTIFY) {
      this.toggleEditSidekickAction(true, sidekickAction, sidekickAction.type);
    }

    //==============================================================================
    //IDE CONTEXT
    //==============================================================================

    const { resetStatus } = this.props.ideContext;
    const { resetStatus: newResetStatus, contextMetaData } = nextProps.ideContext;

    if (resetStatus !== newResetStatus) {
      switch (newResetStatus) {
        case IDE_CONTEXT_RESET_SUCCEEDED: //AFTER CONTEXT RESET TP RESET STARTED
          const delBatchObj = { ...removeBatchTracePointCmdObj, id: generateUUID() };
          const { tracePoints } = this.props.broker;
          const tpIdArr = tracePoints.map(el => el.id);
          const pathPrefix = generatePathPrefixFromContextMetaData(contextMetaData);
          const tpIdArrFiltered = tpIdArr.filter(el => el.includes(pathPrefix));

          if (tpIdArrFiltered.length > 0) {
            delBatchObj.tracePointIds = tpIdArrFiltered;
            this.props.handleBrokerSend(delBatchObj);
          }
          this.props.handleSucceedIDETPContextReset(contextMetaData);
          break;
        default:
          break;
      }
    }

    if (this.state.editSidekickAction) {
      this.closeEditPanelAfterEditCompleted(nextProps);
    }
  }

  handleFilterEvents = (sidekickAction, type) => {
    this.props.handleSidekickActionEventsFocus(sidekickAction, false, type);
  };

  handleFocus = tracePoint => {
    const metaData = generateSourceMetaDataFromEntityId(tracePoint.fileName);
    const { sourceProvider, owner, repo, sourcePath, sha } = metaData;
    console.log('AATP, handleFocus; tracePoint, props, metaData: ', tracePoint, this.props, metaData);

    this.props.getUserRepoContent(sourceProvider, owner, repo, sourcePath, sha, () => {
      const fileFullPath = generateFullGitSourceURLPath(sourceProvider, owner, repo, sourcePath, sha);
      this.props.handleSourceFocus(fileFullPath, tracePoint.lineNo);
    });
  };

  handleClearTracePoints = () => {
    const delBatchObj = { ...removeBatchTracePointCmdObj, id: generateUUID() };
    const { tracePoints } = this.props.broker;
    const tpIdArr = tracePoints.map(el => el.id);
    delBatchObj.tracePointIds = tpIdArr;
    this.props.handleBrokerSend(delBatchObj);
  };

  flushSelectedSaSet = actionType => {
    let setKey = actionType === 'tracePoint' ? 'selectedTP' : 'selectedLP';
    this.setState({ [setKey]: new Set() });
  };

  handleBatchEnable = (saSet, actionType) => {
    const { tracePoints, logPoints } = this.props.broker;
    this.flushSelectedSaSet(actionType);
    let saList =
      actionType === 'tracePoint'
        ? tracePoints.filter(tp => saSet.has(tp.id))
        : logPoints.filter(tp => saSet.has(tp.id));
    saList.forEach(sa => this.handleEnableDisable(sa, true, actionType));
  };

  handleBatchDisable = (saSet, actionType) => {
    const { tracePoints, logPoints } = this.props.broker;
    this.flushSelectedSaSet(actionType);
    let saList =
      actionType === 'tracePoint'
        ? tracePoints.filter(tp => saSet.has(tp.id))
        : logPoints.filter(tp => saSet.has(tp.id));
    saList.forEach(sa => this.handleEnableDisable(sa, false, actionType));
  };

  handleBatchDelete = (saSet, actionType) => {
    const { tracePoints, logPoints } = this.props.broker;
    this.flushSelectedSaSet(actionType);
    let saList =
      actionType === 'tracePoint'
        ? tracePoints.filter(tp => saSet.has(tp.id))
        : logPoints.filter(tp => saSet.has(tp.id));
    saList.forEach(sa => this.handleDelete(sa, actionType));
  };

  handleDelete = (sidekickAction, actionType) => {
    let command = null;
    if (actionType === 'logPoint') {
      command = { ...delLogPointCmdObj, id: generateUUID() };
      command.logPointId = sidekickAction.id;
    } else if (actionType === 'tracePoint') {
      command = { ...delTracePointCmdObj, id: generateUUID() };
      command.tracePointId = sidekickAction.id;
    }

    command && this.props.handleBrokerSend({ ...command, probeType: actionType });
  };
  handleRemoveReferenceEvent = (sidekickAction, actionType) => {
    let command = { ...removeReferenceEventRequestCmdObj };
    const requestId = generateUUID();
    command.probeType = actionType.toUpperCase();
    command.probeId = sidekickAction.id;
    command.id = requestId;
    this.props.setEventBeingProcessed({
      requestId: requestId,
      event: { [actionType + 'Id']: sidekickAction.id },
      name: actionType === 'tracePoint' ? 'TracePointSnapshotEvent' : 'LogPointEvent',
    });
    this.props.handleBrokerSend({ ...command });
  };
  handleSeeReferenceEvent = (sidekickAction, type) => {
    this.props.handleSidekickActionEventsFocus(sidekickAction, true, type);
  };
  handleEnableDisable = (sidekickAction, disabled, actionType) => {
    let command = null;
    if (actionType === 'logPoint') {
      command = disabled
        ? { ...enableLogPointCmdObj, id: generateUUID() }
        : { ...disableLogPointCmdObj, id: generateUUID() };
      command.logPointId = sidekickAction.id;
    } else if (actionType === 'tracePoint') {
      command = disabled
        ? { ...enableTracePointCmdObj, id: generateUUID() }
        : { ...disableTracePointCmdObj, id: generateUUID() };
      command.tracePointId = sidekickAction.id;
    }

    command && this.props.handleBrokerSend({ ...command, probeType: actionType });
  };

  updateProbeTags = () => {
    const activeWorkspaceId = this.props.activeWorkspace.workspace?.workspace?.id;
    activeWorkspaceId && this.props.getSATags(activeWorkspaceId);
  };

  handleDeleteTag = tag => {
    const command = { ...deleteProbeTagRequestCmdObj, probeType: 'tracePoint', tag: tag };
    this.props.handleBrokerSend(command);

    console.log('tag has been deleted: ', tag);
    this.updateProbeTags();
  };

  handleSubmitEditSidekickAction = sidekickAction => {
    //first time add
    let command = {};
    if (sidekickAction.id == null) {
      command = { ...this.state.selectedSidekickActionToEdit };
      command.disable = this.state.selectedSidekickActionToEdit.disabled;
    }

    command.expireSecs = sidekickAction.expireSecs;
    command.expireCount = sidekickAction.expireCount;
    command.conditionExpression = sidekickAction.conditionExpression;
    command.disable = sidekickAction.disabled;
    command.webhookIds = sidekickAction.webhookIds;
    command.type = 'Request';
    command.persist = true;
    command.predefined = sidekickAction.predefined;
    command.probeName = sidekickAction.probeName;
    command.tags = sidekickAction.tags;

    const selectedType = this.state.selectedSidekickActionType;
    if (selectedType === 'tracePoint') {
      command.name = sidekickAction.id != null ? updateTracePointCmdObj.name : putTracePointCmdObj.name;
      command.tracePointId = sidekickAction.id;
      command.enableTracing = sidekickAction.tracingEnabled;
    } else if (selectedType === 'logPoint') {
      command.name = sidekickAction.id != null ? updateLogPointCmdObj.name : putLogPointCmdObj.name;
      command.logPointId = sidekickAction.id;
      command.logLevel = sidekickAction.logLevel;
      command.logExpression = sidekickAction.logExpression;
      command.stdoutEnabled = sidekickAction.stdoutEnabled;
    }

    command && this.props.handleBrokerSend({ ...command, probeType: selectedType });
    this.updateProbeTags();
  };

  decideWaitingStatus = (fileName, lineNo) => {
    const { selectedSidekickActionType } = this.state;
    if (selectedSidekickActionType === 'tracePoint') {
      const allTracePointWaiting = this.props.broker.tracePointsWaiting;
      const tracePointsWaiting = allTracePointWaiting.find(el => {
        // TODO: Change it later
        // eslint-disable-next-line
        return el && el.fileName === fileName && el.lineNo == lineNo;
      });
      return tracePointsWaiting && tracePointsWaiting.name === 'UpdateTracePointRequest';
    } else if (selectedSidekickActionType === 'logPoint') {
      const allLogPointWaiting = this.props.broker.logPointsWaiting;
      const logPointsWaiting = allLogPointWaiting.find(el => {
        // TODO: Change it later
        // eslint-disable-next-line
        return el && el.fileName === fileName && el.lineNo == lineNo;
      });
      return logPointsWaiting && logPointsWaiting.name === 'UpdateLogPointRequest';
    }
  };
  toggleEditSidekickAction = (edit, sidekickAction, sidekickActionType) => {
    if (edit === false) {
      this.setState({
        editSidekickAction: false,
        selectedSidekickActionToEdit: undefined,
        selectedSidekickActionType: undefined,
      });
      return;
    }
    if (this.findSelectedSidekickActionToEdit(sidekickAction, sidekickActionType) == null) {
      return;
    }
    this.setState({
      editSidekickAction: edit,
      selectedSidekickActionToEdit: sidekickAction,
      selectedSidekickActionType: sidekickActionType,
    });
  };

  findSelectedSidekickActionToEdit = (selectedSidekickActionToEdit, selectedSidekickActionType) => {
    //edit panel opened for add.
    if (selectedSidekickActionToEdit.id == null) {
      return true;
    }
    const { tracePoints, logPoints } = this.props.broker;
    if (selectedSidekickActionType === 'tracePoint') {
      return tracePoints.find(tp => tp.id === selectedSidekickActionToEdit.id);
    } else if (selectedSidekickActionType === 'logPoint') {
      return logPoints.find(lp => lp.id === selectedSidekickActionToEdit.id);
    }
  };

  findSelectedSidekickActionToEditConnectedStatus = () => {
    const { selectedSidekickActionType, selectedSidekickActionToEdit } = this.state;
    const { appData } = this.props.broker;
    if (selectedSidekickActionType === 'tracePoint') {
      return appData.filter(app => app.tracePoints.find(tp => tp.id === selectedSidekickActionToEdit.id)).length;
    } else if (selectedSidekickActionType === 'logPoint') {
      return appData.filter(app => app.logPoints.find(lp => lp.id === selectedSidekickActionToEdit.id)).length;
    }
  };

  filterActions = (sidekickActions, clientFilter, predefined) => {
    if (clientFilter.length > 0) {
      return sidekickActions.filter(sa => {
        let filterString = `${sa.fileName}:${sa.lineNo}`;
        if (predefined) {
          sa.tags.forEach(tag => (filterString += `:${tag}`));
        }
        return filterString.includes(clientFilter);
      });
    }
    return sidekickActions;
  };

  filterActionsWithTag = (sidekickActions, filterTag) => {
    if (filterTag.length > 0) {
      return sidekickActions.filter(sa => {
        return sa?.tags?.some(tag => tag === filterTag);
      });
    }
    return sidekickActions;
  };

  getEditSidekickActionPanel = () => {
    const { selectedSidekickActionToEdit, selectedSidekickActionType } = this.state;

    const { disabled, fileName, lineNo } = selectedSidekickActionToEdit || {};
    let statusIcon;
    statusIcon = selectedSidekickActionType === 'tracePoint' ? 'tracepoint-status ' : 'logpoint-status ';
    if (disabled) {
      statusIcon += 'disabled ';
    }
    if (this.findSelectedSidekickActionToEditConnectedStatus()) {
      statusIcon += 'app-connected ';
    }
    return {
      key: 'edit-sidekick-action-panel',
      title: {
        content: (
          <>
            <span>
              {statusIcon && (
                <>
                  <span className={statusIcon} /> &nbsp;
                </>
              )}
              {selectedSidekickActionToEdit ? abbrFileName(fileName) + ':' + lineNo : 'Edit Tracepoints'}
            </span>
            <span
              className={'apps-edit-icon anchor-like'}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                this.toggleEditSidekickAction(false);
              }}
            >
              <i className={'icon-apps-and-tps-close icon'} />
            </span>
          </>
        ),
      },
      content: {
        content: (
          <EditSidekickActionPanel
            sidekickActionToEdit={this.state.selectedSidekickActionToEdit}
            sidekickActionType={this.state.selectedSidekickActionType}
            broker={this.props.broker}
            webhookList={this.props.webhookList}
            tags={this.props.saTagList}
            closeEditPanel={() => this.toggleEditSidekickAction(false)}
            handleSubmit={editConfig => this.handleSubmitEditSidekickAction(editConfig)}
            loading={this.decideWaitingStatus(fileName, lineNo)}
          />
        ),
      },
    };
  };
  getLogPointPanel = () => {
    const logPoints = this.props.broker.logPoints.filter(lp => !lp.predefined);
    const predefinedLogPoints = this.props.broker.logPoints.filter(lp => lp.predefined);
    let filteredLP = this.filterActions(logPoints, this.state.lpFilter, false);
    let filteredPLP = this.filterActions(predefinedLogPoints, this.state.lpFilter, true);
    let lpSet = new Set();
    let plpSet = new Set();
    filteredLP.forEach(lp => lpSet.add(lp.id));
    filteredPLP.forEach(lp => plpSet.add(lp.id));
    let selectAllText = '';

    if (this.state.selectedLP.size === 0) {
      selectAllText = 'Select all logpoints';
    } else if (this.state.selectedLP.size === new Set([...lpSet, ...plpSet]).size) {
      selectAllText = 'All logpoints are selected';
    } else {
      selectAllText = `${this.state.selectedLP.size} logpoints are selected.`;
    }

    if (logPoints.length === 0) {
      if (predefinedLogPoints.length === 0) {
        return this.renderSidekickActionsPlaceholder('logPoint');
      } else if (!this.state.showPredefinedLP) {
        return this.renderSidekickActionsPlaceholder('logPoint');
      }
    }
    return (
      <>
        <EventsFilter
          clientFilter={this.state.lpFilter}
          setClientFilter={newClientFilter => this.setState({ lpFilter: newClientFilter })}
          placeholder="Search by app name or tags"
        />
        <div className={'selected-sa-section'}>
          <Checkbox
            checked={eqSet(new Set([...lpSet, ...plpSet]), this.state.selectedLP)}
            onChange={(event, data) => {
              if (data.checked === false) {
                this.setState({ selectedLP: new Set() });
              } else {
                this.setState({ selectedLP: new Set([...lpSet, ...plpSet]) });
              }
            }}
          />
          <div>{selectAllText}</div>
          <div className={'actions'}>
            <i
              className={'tracepoint-action icon icon-apps-and-tps-enable-tp'}
              onClick={() => this.handleBatchEnable(this.state.selectedLP, 'logPoint')}
            />
            <i
              className={'tracepoint-action icon icon-apps-and-tps-disable-tp'}
              onClick={() => this.handleBatchDisable(this.state.selectedLP, 'logPoint')}
            />
            <i
              className={'tracepoint-action icon icon-apps-and-tps-remove-tp'}
              onClick={() => this.handleBatchDelete(this.state.selectedLP, 'logPoint')}
            />
          </div>
        </div>
        {this.state.showPredefinedLP && (
          <SidekickActionsTable
            predefined={true}
            actionType={'logPoint'}
            appData={this.props.broker.appData}
            events={this.props.broker.predefinedLogPointEvents}
            sidekickActions={filteredPLP}
            sidekickActionsWaiting={this.props.broker.logPointsWaiting}
            handleFilterEvents={this.handleFilterEvents}
            handleFocus={this.handleFocus}
            handleEnableDisable={this.handleEnableDisable}
            handleDelete={this.handleDelete}
            handleEdit={this.toggleEditSidekickAction}
            handleRemoveReferenceEvent={this.handleRemoveReferenceEvent}
            handleSeeReferenceEvent={this.handleSeeReferenceEvent}
            handleCheck={this.handleSelectSA}
            selectedSA={this.state.selectedLP}
          />
        )}
        <SidekickActionsTable
          predefined={false}
          actionType={'logPoint'}
          appData={this.props.broker.appData}
          events={this.props.broker.logPointEvents}
          sidekickActions={filteredLP}
          sidekickActionsWaiting={this.props.broker.logPointsWaiting}
          handleFilterEvents={this.handleFilterEvents}
          handleFocus={this.handleFocus}
          handleEnableDisable={this.handleEnableDisable}
          handleDelete={this.handleDelete}
          handleEdit={this.toggleEditSidekickAction}
          handleCheck={this.handleSelectSA}
          selectedSA={this.state.selectedLP}
        />
      </>
    );
  };

  getTracePointPanel = () => {
    const tracePoints = this.props.broker.tracePoints.filter(lp => !lp.predefined);
    const predefinedTracePoints = this.props.broker.tracePoints.filter(lp => lp.predefined);
    let filteredTP = this.filterActions(tracePoints, this.state.tpFilter, false);
    let filteredPTP = this.filterActions(predefinedTracePoints, this.state.tpFilter, true);
    let tpSet = new Set();
    let ptpSet = new Set();
    filteredTP.forEach(tp => tpSet.add(tp.id));
    filteredPTP.forEach(tp => ptpSet.add(tp.id));
    if (tracePoints.length === 0) {
      if (predefinedTracePoints.length === 0) {
        return this.renderSidekickActionsPlaceholder('tracePoint');
      } else if (!this.state.showPredefinedTP) {
        return this.renderSidekickActionsPlaceholder('tracePoint');
      }
    }

    let selectAllText = '';

    if (this.state.selectedTP.size === 0) {
      selectAllText = 'Select all tracepoints';
    } else if (this.state.selectedTP.size === new Set([...tpSet, ...ptpSet]).size) {
      selectAllText = 'All tracepoints are selected';
    } else {
      selectAllText = `${this.state.selectedTP.size} tracepoints are selected.`;
    }

    return (
      <>
        <EventsFilter
          clientFilter={this.state.tpFilter}
          setClientFilter={newClientFilter => this.setState({ tpFilter: newClientFilter })}
          placeholder="Search by app name or tags"
        />
        <div className={'selected-sa-section'}>
          <Checkbox
            checked={eqSet(new Set([...tpSet, ...ptpSet]), this.state.selectedTP)}
            onChange={(event, data) => {
              if (data.checked === false) {
                this.setState({ selectedTP: new Set() });
              } else {
                this.setState({ selectedTP: new Set([...tpSet, ...ptpSet]) });
              }
            }}
          />
          <div>{selectAllText}</div>
          <div className={'actions'}>
            <i
              className={'tracepoint-action icon icon-apps-and-tps-enable-tp'}
              onClick={() => this.handleBatchEnable(this.state.selectedTP, 'tracePoint')}
            />
            <i
              className={'tracepoint-action icon icon-apps-and-tps-disable-tp'}
              onClick={() => this.handleBatchDisable(this.state.selectedTP, 'tracePoint')}
            />
            <i
              className={'tracepoint-action icon icon-apps-and-tps-remove-tp'}
              onClick={() => this.handleBatchDelete(this.state.selectedTP, 'tracePoint')}
            />
          </div>
        </div>
        {this.state.showPredefinedTP && (
          <SidekickActionsTable
            predefined={true}
            actionType={'tracePoint'}
            appData={this.props.broker.appData}
            events={this.props.broker.predefinedTracepointEvents}
            sidekickActions={filteredPTP}
            sidekickActionsWaiting={this.props.broker.tracePointsWaiting}
            handleFilterEvents={this.handleFilterEvents}
            handleFocus={this.handleFocus}
            handleEnableDisable={this.handleEnableDisable}
            handleDelete={this.handleDelete}
            handleEdit={this.toggleEditSidekickAction}
            handleRemoveReferenceEvent={this.handleRemoveReferenceEvent}
            handleSeeReferenceEvent={this.handleSeeReferenceEvent}
            handleCheck={this.handleSelectSA}
            selectedSA={this.state.selectedTP}
          />
        )}
        <SidekickActionsTable
          predefined={false}
          actionType={'tracePoint'}
          appData={this.props.broker.appData}
          events={this.props.broker.tracePointEvents}
          sidekickActions={filteredTP}
          sidekickActionsWaiting={this.props.broker.tracePointsWaiting}
          handleFilterEvents={this.handleFilterEvents}
          handleFocus={this.handleFocus}
          handleEnableDisable={this.handleEnableDisable}
          handleDelete={this.handleDelete}
          handleEdit={this.toggleEditSidekickAction}
          handleCheck={this.handleSelectSA}
          selectedSA={this.state.selectedTP}
        />
      </>
    );
  };

  getPanelHeader = sidekickAction => {
    const headerTitle = sidekickAction === 'tracePoint' ? 'Tracepoints' : 'Logpoints';
    const stateKey = sidekickAction === 'tracePoint' ? 'showPredefinedTP' : 'showPredefinedLP';
    return (
      <div className={'accordion-title'}>
        <span>{headerTitle}</span>
        <Checkbox
          toggle
          checked={this.state[stateKey]}
          onClick={(e, data) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState({ [stateKey]: data.checked });
          }}
        />
        <span>Show saved</span>
      </div>
    );
  };

  renderHeader = () => {
    const { saState } = this.state;

    return (
      <div className="apps-and-tracepoints-header">
        <div
          className={'tab' + (saState === SA_STATE.SA ? ' active' : '')}
          onClick={() => this.setState({ saState: SA_STATE.SA })}
        >
          Sidekick Actions
        </div>
        <div
          className={'tab' + (saState === SA_STATE.TAGS ? ' active' : '')}
          onClick={() => this.setState({ saState: SA_STATE.TAGS })}
        >
          Tags
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { editSidekickAction, saState } = this.state;
    switch (saState) {
      case SA_STATE.SA:
        return (
          <>
            <Accordion
              panels={
                editSidekickAction
                  ? [this.getEditSidekickActionPanel()]
                  : [
                      {
                        key: 'tracepoints',
                        title: {
                          content: this.getPanelHeader('tracePoint'),
                        },
                        content: {
                          content: this.getTracePointPanel(),
                        },
                      },
                      {
                        key: 'logpoints',
                        title: {
                          content: this.getPanelHeader('logPoint'),
                        },
                        content: {
                          content: this.getLogPointPanel(),
                        },
                      },
                    ]
              }
              defaultActiveIndex={[0, 1]}
              exclusive={false}
              fluid
            />
          </>
        );
      case SA_STATE.TAGS:
        return (
          <SidekickActionsTag
            saTagList={this.props.saTagList}
            focusTag={tag =>
              this.setState({ saState: SA_STATE.SA }, () => this.setState({ lpFilter: tag, tpFilter: tag }))
            }
            enableTag={tag => {
              this.setState({ saState: SA_STATE.SA });
              let tpIds = new Set();
              let lpIds = new Set();
              this.filterActionsWithTag(this.props.broker.tracePoints, tag).forEach(tp => tpIds.add(tp.id));
              this.filterActionsWithTag(this.props.broker.logPoints, tag).forEach(lp => lpIds.add(lp.id));

              this.handleBatchEnable(tpIds, 'tracePoint');
              this.handleBatchEnable(lpIds, 'tracePoint');
            }}
            disableTag={tag => {
              this.setState({ saState: SA_STATE.SA });
              let tpIds = new Set();
              let lpIds = new Set();
              this.filterActionsWithTag(this.props.broker.tracePoints, tag).forEach(tp => tpIds.add(tp.id));
              this.filterActionsWithTag(this.props.broker.logPoints, tag).forEach(lp => lpIds.add(lp.id));

              this.handleBatchDisable(tpIds, 'tracePoint');
              this.handleBatchDisable(lpIds, 'tracePoint');
            }}
            deleteTag={this.handleDeleteTag}
            getTags={() => this.updateProbeTags()}
          />
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="apps-and-tracepoints-container">
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}
