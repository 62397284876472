import React, { Component } from 'react';
import { SourceEditor } from './CodeMirror/SourceEditor';
import {
  delLogPointCmdObj,
  delTracePointCmdObj,
  disableLogPointCmdObj,
  disableTracePointCmdObj,
  enableLogPointCmdObj,
  enableTracePointCmdObj,
  putLogPointCmdObj,
  putTracePointCmdObj,
} from 'utils/broker-commands';
import { getSourceCode, getSourceHashCode } from 'utils/source-files';
import { getLiveAppSelection, parseKey } from 'utils/application-util';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';

export class ThundraSourceEditor extends Component {
  getSelectedAppFilter = () => {
    const { selectedApps } = this.props.appsAndTracePoints;
    const { appData } = this.props.broker;
    return getLiveAppSelection(appData, selectedApps).map(app => parseKey(app));
  };

  createPutSidekickActionCommand = action => {
    const applicationFilters = this.getSelectedAppFilter();
    if (!applicationFilters.length) {
      const cloneConfig = { ...ToastConfig };
      cloneConfig.onClose = () => {
        this.props.handleOpenAppsAndTracePointsTab(); //When Close Open and Glow AppsAndTracepoints Tab.
      };
      toast.error('At least 1 app should be selected.', cloneConfig);
      return null;
    }
    let command = action.payloadType === 'TP' ? { ...putTracePointCmdObj } : { ...putLogPointCmdObj };
    const { defaultTracepointWebhookId, defaultLogpointWebhookId } =
      this.props.activeWorkspace?.workspace?.workspaceSettings;
    command.fileHash = getSourceHashCode(this.props.sourcePath);
    command.fileName = this.props.sourcePath;
    command.lineNo = action.lineNumber;
    command.applicationFilters = applicationFilters;
    command.probeType = action.payloadType === 'TP' ? 'tracePoint' : 'logPoint';
    command.disable = action.disabled;
    command.conditionExpression = action.conditionExpression;
    if (defaultTracepointWebhookId != null && action.payloadType === 'TP') {
      command.webhookIds = [defaultTracepointWebhookId];
    } else if (defaultLogpointWebhookId != null && action.payloadType === 'LP') {
      command.webhookIds = [defaultLogpointWebhookId];
    }
    if (action.payloadType === 'LP') {
      command.logExpression = action.logExpression;
      command.logLevel = action.logLevel;
    }

    return command;
  };

  handleAddSidekickAction = action => {
    const command = this.createPutSidekickActionCommand(action);

    command && this.props.handleBrokerSend(command);
  };

  handleRemoveSidekickAction = action => {
    let command = null;
    if (action.payloadType === 'TP') {
      command = { ...delTracePointCmdObj };
      command.tracePointId = action.payload.id;
    } else if (action.payloadType === 'LP') {
      command = { ...delLogPointCmdObj };
      command.logPointId = action.payload.id;
    }

    this.props.handleBrokerSend({ ...command, probeType: action.payloadType === 'TP' ? 'tracePoint' : 'logPoint' });
  };

  handleEnableDisableSidekickAction = (action, enable) => {
    let command = null;
    if (action.payloadType === 'TP') {
      command = enable ? { ...enableTracePointCmdObj } : { ...disableTracePointCmdObj };
      command.tracePointId = action.payload.id;
    } else if (action.payloadType === 'LP') {
      command = enable ? { ...enableLogPointCmdObj } : { ...disableLogPointCmdObj };
      command.logPointId = action.payload.id;
    }

    this.props.handleBrokerSend({ ...command, probeType: action.payloadType === 'TP' ? 'tracePoint' : 'logPoint' });
  };

  handleSeeEvents = action => {
    const sidekickActionType = action.payloadType === 'TP' ? 'tracePoint' : 'logPoint';
    this.props.handleSidekickActionEventsFocus(action.payload, false, sidekickActionType);
  };

  handleEdit = action => {
    const sidekickActionType = action.payloadType === 'TP' ? 'tracePoint' : 'logPoint';
    this.props.handleSidekickActionEdit({ ...action.payload, type: sidekickActionType });
  };

  handleDetailedAdd = action => {
    const sidekickActionType = action.payloadType === 'TP' ? 'tracePoint' : 'logPoint';
    const applicationFilters = this.getSelectedAppFilter();
    const { defaultTracepointWebhookId, defaultLogpointWebhookId } =
      this.props.activeWorkspace?.workspace?.workspaceSettings;
    const sidekickAction = {
      lineNo: action.lineNumber,
      fileHash: getSourceHashCode(this.props.sourcePath),
      fileName: this.props.sourcePath,
      disabled: action.disabled,
      applicationFilters: applicationFilters,
      probeType: sidekickActionType,
      expireSecs: 1800,
      expireCount: 50,
      conditionExpression: action.conditionExpression,
    };
    if (defaultTracepointWebhookId != null && action.payloadType === 'TP') {
      sidekickAction.webhookIds = [defaultTracepointWebhookId];
    } else if (defaultLogpointWebhookId != null && action.payloadType === 'LP') {
      sidekickAction.webhookIds = [defaultLogpointWebhookId];
    }
    if (sidekickActionType === 'tracePoint') {
    } else if (sidekickActionType === 'logPoint') {
      sidekickAction.logExpression = action.logExpression;
      sidekickAction.logLevel = action.logLevel;
    }
    this.props.handleSidekickActionEdit({ ...sidekickAction, type: sidekickActionType });
  };

  createGutterAction = lineNumber => {
    const { tracePoints } = this.props.broker;
    const { logPoints } = this.props.broker;
    let command = null;
    const matchingTracePoint = tracePoints.find(
      tp => tp.lineNo === lineNumber && tp.fileName === this.props.sourcePath,
    );
    const matchingLogPoint = logPoints.find(lp => lp.lineNo === lineNumber && lp.fileName === this.props.sourcePath);

    //both log point and tracepoint or only tracepoint presents on the gutter, remove tracepoint
    if ((matchingTracePoint && matchingLogPoint) || matchingTracePoint) {
      command = { ...delTracePointCmdObj };
      command.tracePointId = matchingTracePoint.id;
      command.probeType = 'tracePoint';
    }

    //logpoint presents in gutter, remove it
    if (matchingLogPoint) {
      command = { ...delLogPointCmdObj };
      command.logPointId = matchingLogPoint.id;
      command.probeType = 'logPoint';
    }

    //gutter is empty, add tracepoint
    if (matchingTracePoint == null && matchingLogPoint == null) {
      command = this.createPutSidekickActionCommand({ lineNumber, payloadType: 'TP' });
    }

    return command;
  };

  handleGutterClick = lineNumber => {
    if (this.props.onboarding.sandboxEnabled) {
      if (lineNumber !== 38) {
        return;
      }
      this.props.toggleSandboxStep(365343);
      window.Intercom('startTour', 365343);
    }
    let brokerCommand = this.createGutterAction(lineNumber);
    if (brokerCommand) {
      this.props.handleBrokerSend(brokerCommand);
    }
  };

  handleContextClick = action => {
    switch (action.actionType) {
      case 'ADD':
        this.handleAddSidekickAction(action);
        break;
      case 'REMOVE':
        this.handleRemoveSidekickAction(action);
        break;
      case 'ENABLE':
        this.handleEnableDisableSidekickAction(action, true);
        break;
      case 'DISABLE':
        this.handleEnableDisableSidekickAction(action, false);
        break;
      case 'EVENTS':
        this.handleSeeEvents(action);
        break;
      case 'EDIT':
        this.handleEdit(action);
        break;
      case 'DETAILED_ADD':
        this.handleDetailedAdd(action);
        break;
      default:
        break;
    }
  };

  render() {
    const { sourcePath } = this.props;
    const {
      sourceTabIndex,
      lineNo,
      reason: sourceTabChangeReason,
      sourceTabChangeCount,
      layoutUpdateCount,
    } = this.props.sourceTabs; //Active SourceTabs and ActiveLineNo
    const { appData, variableSuggestions } = this.props.broker;

    const sourceCode = getSourceCode(sourcePath);
    const sourceHashCode = getSourceHashCode(sourcePath);
    const allTracePoint = this.props.broker.tracePoints;
    const allLogPoints = this.props.broker.logPoints;

    const connectStatusMap = { tracePoints: {}, logPoints: {} };

    const tracePointsFiltered = allTracePoint.filter(el => el && el.fileName === sourcePath);
    const logPointsFiltered = allLogPoints.filter(el => el && el.fileName === sourcePath);

    tracePointsFiltered.forEach(el => {
      const application = appData.find(app => app.tracePoints.find(tp => tp.id === el.id));
      connectStatusMap.tracePoints[el.lineNo] = !!application;
    });

    logPointsFiltered.forEach(el => {
      const application = appData.find(app => app.logPoints.find(lp => lp.id === el.id));
      connectStatusMap.logPoints[el.lineNo] = !!application;
    });

    //WAITINGS BEFORE BROKER RESPONSE....
    const allTracePointWaiting = this.props.broker.tracePointsWaiting;
    const tracePointsWaitingFiltered = allTracePointWaiting.filter(el => {
      // console.log(el.filename, sourcePath);
      return el && el.fileName === sourcePath;
    });

    const allLogPointWaiting = this.props.broker.logPointsWaiting;
    const logPointsWaitingFiltered = allLogPointWaiting.filter(el => {
      // console.log(el.filename, sourcePath);
      return el && el.fileName === sourcePath;
    });

    //console.log('WaitingArrCount' + tracePointsWaitingFiltered.length);

    const lineNo4ThisTab = sourcePath === sourceTabIndex ? lineNo : 0;
    return (
      <SourceEditor
        connectStatusMap={connectStatusMap}
        tracePointsWaiting={tracePointsWaitingFiltered}
        tracePoints={tracePointsFiltered}
        logPoints={logPointsFiltered}
        logPointsWaiting={logPointsWaitingFiltered}
        sourceTabIndex={sourceTabIndex}
        sourceTabChangeReason={sourceTabChangeReason}
        sourceTabChangeCount={sourceTabChangeCount}
        sourceCode={sourceCode}
        sourceHashCode={sourceHashCode}
        id={'thundra-source-editor'}
        handleGutterClick={this.handleGutterClick}
        handleContextClick={this.handleContextClick}
        lineNo={lineNo4ThisTab}
        layoutUpdateCount={layoutUpdateCount}
        sugs={variableSuggestions}
      />
    );
  }
}
