import React, { Component } from 'react';
import './ThundraSourceWelcome.scss';
import sidekickBugMiddle from 'assets/icons/bug-middle-big.svg';
import ideaLogoSVG from 'assets/images/idea-logo.svg';
import vsCodeLogoSVG from 'assets/images/vscode-logo.svg';
import pcLogoSVG from 'assets/images/pc-logo-svg.svg';
import wsLogoSVG from 'assets/images/ws-logo-svg.svg';
import URL_POOL from 'utils/url-pool';
import { CONFIG } from 'utils/config-util';
import { Icon } from 'semantic-ui-react';
import { QuickStart } from '../QuickStart/QuickStart';

export class ThundraSourceWelcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedInfoText: '',
      showApiKey: false,
      showIdeToken: false,
    };
  }

  handleOpenLink = link => {
    window.open(link, '_blank');
  };

  componentDidMount() {
    if (CONFIG?.ON_PREM) {
      this.props.getOnPremIdeToken();
    }
  }

  handleOpenSourcesTab = e => {
    e.preventDefault();
    this.props.handleOpenSourcesTab();
  };

  handleOpenAppsAndTracePointsTab = e => {
    e.preventDefault();
    this.props.handleOpenAppsAndTracePointsTab();
  };

  handleOpenEventsTab = e => {
    e.preventDefault();
    this.props.handleOpenEventsTab();
  };

  handleShowKey = keyType => {
    this.setState({ [keyType]: true });
    setTimeout(() => this.setState({ [keyType]: false }), 5000);
  };
  render() {
    const { apiKey } = this.props.activeWorkspace.workspace;
    const ideToken = this.props.onPremIdeToken?.ideToken;

    const copyValue = value => {
      navigator.clipboard.writeText(value);
      this.setState({
        copiedInfoText: value,
      });
      setTimeout(() => {
        this.setState({
          copiedInfoText: '',
        });
      }, 2000);
    };

    return (
      <div className="thundra-source-welcome-container">
        <div className="container">
          <div className={'section'}>
            <div className="bird">
              <img src={sidekickBugMiddle} alt="" />
            </div>
            <div className="title">Sidekick</div>
          </div>
          {!this.props.onboardStatus.sandboxEnabled && (
            <div className={'section'}>
              <div className="subtitle">Your API Key</div>
              <div className={'highlight-box'}>
                <div className={'highlight-box-content'}>
                  {this.state.showApiKey ? apiKey : [...apiKey].map(() => '*')}
                </div>
                <Icon name={'eye'} className={'clickable'} onClick={() => this.handleShowKey('showApiKey')} />
                {this.state.copiedInfoText === apiKey ? (
                  <span className={'right-item'}>Copied!</span>
                ) : (
                  <i onClick={() => copyValue(apiKey)} className={'right-item clickable copy icon'} />
                )}
              </div>
            </div>
          )}
          {this.props.onPremIdeToken?.fetched && (
            <div className={'section'}>
              <div className="subtitle">Your IDE Token</div>
              <div className={'highlight-box'}>
                <div className={'highlight-box-content'}>
                  {this.state.showIdeToken ? ideToken : [...ideToken].map(() => '*')}
                </div>
                <Icon name={'eye'} className={'clickable'} onClick={() => this.handleShowKey('showIdeToken')} />
                {this.state.copiedInfoText === ideToken ? (
                  <span className={'right-item'}>Copied!</span>
                ) : (
                  <i onClick={() => copyValue(ideToken)} className={'right-item clickable copy icon'} />
                )}
              </div>
            </div>
          )}
          <div className={'section'}>
            <div className={'subtitle'}>Start</div>
            <ul className={'list'}>
              <li>
                Add source from the{' '}
                <span className={'anchor-like'} onClick={this.handleOpenSourcesTab}>
                  Sources panel
                </span>{' '}
                on the left.
              </li>
              <li>
                Connect your apps and set Tracepoints from the{' '}
                <span className={'anchor-like'} onClick={this.handleOpenAppsAndTracePointsTab}>
                  Apps & Tracepoints panel
                </span>{' '}
                on the right.
              </li>
              <li>
                See all valuable data retrieved from your Tracepoints from the{' '}
                <span className={'anchor-like'} onClick={this.handleOpenEventsTab}>
                  Events panel
                </span>{' '}
                on the bottom.
              </li>
            </ul>
          </div>
          <div className={'section'}>
            <div className={'subtitle'}>Quickstart Examples</div>
            <div className={'list'}>
              Sidekick supports different types of languages, platforms, web frameworks and applications. Select your
              platform from the list and display the basic instructions to help you use Sidekick effortlessly!
            </div>
            <QuickStart apiKey={apiKey} />
          </div>
          <div className={'section'}>
            <div className={'subtitle'}>Resources</div>
            <ul className={'list'}>
              <li>
                <a href={URL_POOL.SIDEKICK_DOCS} target={'_blank'} rel="noopener noreferrer">
                  Product Documentation
                </a>
              </li>
              <li>
                <a href={URL_POOL.SLACK_INVITATION} target={'_blank'} rel="noopener noreferrer">
                  Join our Slack community
                </a>
              </li>
              <li>
                <a href={URL_POOL.ORARIEL_CALENDAR} target={'_blank'} rel="noopener noreferrer">
                  Book a time for a dedicated onboarding session
                </a>
              </li>
              <li>
                <a href={URL_POOL.SIDEKICK_BLOG} target={'_blank'} rel="noopener noreferrer">
                  Check our Blog
                </a>
              </li>
            </ul>
          </div>
          <div className={'section'}>
            <div className={'subtitle'}>IDE Integrations</div>
            <div className={'ide-integrations'}>
              <div className={'ide-item'} onClick={() => this.handleOpenLink(URL_POOL.SIDEKICK_VSCODE_PLUGIN_DOC)}>
                <img src={vsCodeLogoSVG} alt={'vscode-logo'} />
                <div className={'ide'}>VS Code</div>
              </div>
              <div className={'ide-item'} onClick={() => this.handleOpenLink(URL_POOL.SIDEKICK_INTELLIJ_PLUGIN_DOC)}>
                <img src={ideaLogoSVG} alt={'idea-logo'} />
                <div className={'ide'}>IntelliJ IDEA</div>
              </div>
              <div className={'ide-item'} onClick={() => this.handleOpenLink(URL_POOL.SIDEKICK_PYCHARM_PLUGIN_DOC)}>
                <img src={pcLogoSVG} alt={'pycharm-logo'} />
                <div className={'ide'}>PyCharm</div>
              </div>
              <div className={'ide-item'} onClick={() => this.handleOpenLink(URL_POOL.SIDEKICK_WEBSTORM_PLUGIN_DOC)}>
                <img src={wsLogoSVG} alt={'webstorm-logo'} />
                <div className={'ide'}>WebStorm</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
