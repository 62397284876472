import { hasArrayElement } from './array-util';

export function parseKey(key) {
  return JSON.parse(atob(key));
}

export function generateKey(application) {
  return btoa(JSON.stringify(getFilters(application)));
}

function formatCustomTagsForBroker(customTags) {
  if (hasArrayElement(customTags)) {
    const newObj = {};
    customTags.forEach(customTag => {
      const { tagName, tagValue } = customTag;
      newObj[tagName] = tagValue;
    });
    return newObj;
  }
  return {};
}

export function findConfigByApp(app, configs) {
  let config = configs.find(config => {
    return generateKey(config.applicationFilter) === app.key;
  });
  //no config set
  if (config == null) {
    return { detached: false };
  }
  let appConfig = config?.config ? config.config : {};
  appConfig.detached = config?.detached;

  return appConfig;
}

export function getFilters(application) {
  return {
    name: application.name,
    version: application.version,
    stage: application.stage,
    customTags: formatCustomTagsForBroker(application.customTags),
  };
}

/**
 * Filter connected applications from app selection
 * @param appData
 * @param selectedApps
 */
export function getLiveAppSelection(appData, selectedApps) {
  return selectedApps.filter(selectedAppKey => appData.find(app => app.key === selectedAppKey));
}

export const clusterApps = apps => {
  let appMap = new Map();

  for (const app of apps) {
    if (appMap.has(app.key)) {
      appMap.set(app.key, [...appMap.get(app.key), app]);
    } else {
      appMap.set(app.key, [app]);
    }
  }

  return appMap;
};
