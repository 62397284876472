import React, { Component } from 'react';
import { Button, Icon, Image, Table, TableCell } from 'semantic-ui-react';

import './EventTable.scss';
import { formatTime } from 'utils/date-util';
import { abbrFileName } from 'utils/string-util';
import URL_POOL from 'utils/url-pool';
import { putReferenceEventRequestCmdObj, removeReferenceEventRequestCmdObj } from 'utils/broker-commands';
import compareSelectedIcon from 'assets/images/compare-selected.svg';
import compareIcon from 'assets/images/compare.svg';

import saveReferenceEventIcon from 'assets/images/save-reference-event.svg';
import savedReferenceEventIcon from 'assets/images/saved-reference-event.svg';
import { findSidekickActionOfEvent } from 'utils/sidekick-action-utils';
import { getApmUrlWithTraceId } from 'utils/trace-util';

export class EventsTable extends Component {
  putReferenceEvent = (tpEvent, tpId) => {
    const putReferenceEventRequest = { ...putReferenceEventRequestCmdObj };
    const application = this.props.appData.find(app => app.instanceId === tpEvent.applicationInstanceId);
    if (application) {
      const { stage, version, name, customTags } = application;
      let flattenedCustomTags = {};
      customTags.forEach(({ tagName, tagValue }) => (flattenedCustomTags[tagName] = tagValue));
      putReferenceEventRequest.applicationFilter = { stage, version, name, customTags: flattenedCustomTags };
    }
    putReferenceEventRequest.probeId = tpId;
    putReferenceEventRequest.probeType = 'TRACEPOINT';
    putReferenceEventRequest.event = JSON.stringify(tpEvent);
    this.props.handlePutReference(putReferenceEventRequest, tpEvent);
  };

  removeReferenceEvent = (tpEvent, tpId) => {
    const removeReferenceEventRequest = { ...removeReferenceEventRequestCmdObj };
    const application = this.props.appData.find(app => app.instanceId === tpEvent.applicationInstanceId);
    if (application) {
      const { stage, version, name } = application;
      removeReferenceEventRequest.applicationFilter = { stage, version, name };
    }
    removeReferenceEventRequest.probeType = 'TRACEPOINT';
    removeReferenceEventRequest.probeId = tpId;
    this.props.handleRemoveReference(removeReferenceEventRequest, tpEvent);
  };

  handleCompareReference = tpEvent => {
    this.props.handleCompareReference(tpEvent);
  };

  onEventTableTraceIdClick = (e, traceUrl) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(traceUrl, '_blank');
  };

  getRows = () => {
    const { events, onRowClick, onTraceFilterClick, highlightedRowIndex, tracepoints } = this.props;
    const rows = events.map((el, index) => {
      let { time, applicationName, id: traceId, lineNo } = el;
      if (time == null) {
        time = el.createdAt;
      }
      if (applicationName == null) {
        applicationName = el.applicationFilter.name;
      }

      const moduleName = abbrFileName(el.fileName);
      const moduleNameAndLineNo = moduleName + ':' + lineNo;
      const key = `row-${index}`;
      let className = '';
      if (index % 2 === 0) className += ' even';
      else className += ' odd';
      if (index === highlightedRowIndex) className += ' highlighted';
      else className += ' normal';
      const tracepointOfEvent = findSidekickActionOfEvent(el.tracePointId, tracepoints);

      return (
        <Table.Row className={className} key={key} onClick={e => onRowClick(e, index)}>
          <Table.Cell>{formatTime(time)}</Table.Cell>
          <Table.Cell>{applicationName}</Table.Cell>
          <Table.Cell>{moduleNameAndLineNo}</Table.Cell>
          <Table.Cell style={{ display: 'flex' }}>
            {traceId ? (
              <>
                <div className="trace-id-container" onClick={e => onTraceFilterClick(e, traceId)}>
                  <span className="trace-id">{traceId}</span>
                  <Icon className="tbl-icon icon-tp-events-filter" />
                </div>
                <span className="tbl-link">
                  <span
                    className={'anchor-like'}
                    onClick={e => {
                      this.onEventTableTraceIdClick(e, getApmUrlWithTraceId(traceId, this.props.apmBaseUrl));
                    }}
                  >
                    TraceMap <Icon className="tbl-icon icon-tp-events-external" />
                  </span>
                </span>
              </>
            ) : (
              <span className="tbl-link">
                <a className={'anchor-like'} href="https://docs.runsidekick.com/integrations/thundra-apm-tracing-integration">
                  Enable Tracing <Icon className="tbl-icon icon-tp-events-external" />
                </a>
              </span>
            )}
          </Table.Cell>
          {this.props.childCells &&
            this.props.childCells.length > 0 &&
            this.props.childCells.map((cell, index) => <TableCell key={index}>{cell.component}</TableCell>)}
          <TableCell>
            <div className={'predefined-actions'}>
              {tracepointOfEvent?.predefined && (
                <Image
                  src={el.referenceEvent ? savedReferenceEventIcon : saveReferenceEventIcon}
                  onClick={() =>
                    el.referenceEvent
                      ? this.removeReferenceEvent(el, tracepointOfEvent.id)
                      : this.putReferenceEvent(el, tracepointOfEvent.id)
                  }
                />
              )}
              {this.props.compareEvents != null ? (
                <Image
                  src={
                    this.props.compareEvents?.findIndex(event => event.id === el.id) > -1
                      ? compareSelectedIcon
                      : compareIcon
                  }
                  onClick={() => {
                    this.handleCompareReference(el);
                  }}
                />
              ) : null}
            </div>
          </TableCell>
        </Table.Row>
      );
    });
    return rows;
  };

  render() {
    return (
      <Table celled className="trace-point-event-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: '25%' }}>Time</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '20%' }}>App Name</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '20%' }}>Class</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '20%' }}>Trace</Table.HeaderCell>
            {this.props.childCells &&
              this.props.childCells.length > 0 &&
              this.props.childCells.map((cell, index) => (
                <Table.HeaderCell key={index}>{cell.header}</Table.HeaderCell>
              ))}
            <Table.HeaderCell style={{ width: '10%' }} />
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.getRows()}</Table.Body>
      </Table>
    );
  }
}
