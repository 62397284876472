import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { START_ONBOARDING } from 'store/constants/onboarding-v2';
import { VideoListItem } from './VideoListItem';
import './OnboardingWizardV2.scss';
import routeList from 'routes';
import { Accordion } from 'components';
import welcome_art from 'assets/images/welcome_art.png';

const MODAL_STATE = {
  WELCOME: 'WELCOME',
  VIDEO_LIST: 'VIDEO_LIST',
};

const VIDEO_LIST = {
  tracepoint: [
    { name: 'Add tracepoint', id: 'DfhUIFH' },
    { name: 'Observe tracepoint events', id: 'fbToHWr' },
    { name: 'Enable/disable tracepoint', id: 'aNyJWxA' },
    { name: 'Edit tracepoint', id: '4ezt4id' },
    { name: 'Delete tracepoint', id: 'QrMFKwy' },
  ],
  logpoint: [
    { name: 'Add logpoint', id: 'U2rQWiR' },
    { name: 'See logs', id: 'YZntQfW' },
    { name: 'Edit logpoint', id: 'W0lNSLY' },
    { name: 'Delete logpoint', id: 'vGCjNPk' },
  ],
};

const GETTING_STARTED_ID = 'https://www.youtube.com/embed/6t0GVFAJqyE';
export class OnboardingWizardV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalState: MODAL_STATE.WELCOME,
      modalOpen: false,
      videoId: GETTING_STARTED_ID,
      skipButtonLoading: false,
    };
  }

  getOnboardStatus = () => {
    if (this.props.onboardingStatus.sandboxEnabled) {
      return;
    }
    this.props.getOnboardStatus(this.props.activeWorkspace?.workspace?.workspace?.id, response => {
      const status = response?.value?.data?.status;
      if (status === 'SKIPPED' || status === 'PASSED') {
        this.setState({ modalOpen: false });
      } else if (status === 'CONTINUE') {
        this.setState({ modalOpen: true });
      }
    });
  };

  updateOnboardStatus = status => {
    if (
      this.props.workspaceOnboardingStatus.onboardStatus.status === 'SKIPPED' ||
      this.props.workspaceOnboardingStatus.onboardStatus.status === 'PASSED'
    ) {
      this.setState({ modalOpen: false });
      return;
    }
    this.setState({ skipButtonLoading: true });
    this.props.updateOnboardStatus(
      status,
      this.props.activeWorkspace?.workspace?.workspace?.id,
      () => {
        this.getOnboardStatus();
        this.setState({ skipButtonLoading: false });
      },
      () => {
        this.setState({ skipButtonLoading: false });
      },
    );
  };

  componentDidMount() {
    this.getOnboardStatus();
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    const { startOnboardingStatus: pStartOnboardingStatus } = prevProps.onboardingStatus;
    const { startOnboardingStatus: nStartOnboardingStatus } = this.props.onboardingStatus;

    //Works like state machine.
    if (pStartOnboardingStatus !== nStartOnboardingStatus && nStartOnboardingStatus === START_ONBOARDING) {
      this.setState({ modalOpen: true, modalState: MODAL_STATE.VIDEO_LIST });
      this.props.completeStartOnboarding();
    }
  }

  getModelContent = () => {
    switch (this.state.modalState) {
      case MODAL_STATE.WELCOME:
        return (
          <div className={'onboarding-welcome'}>
            <img style={{ width: '50%' }} src={welcome_art} alt={'welcome.png'} />
            <div style={{ textAlign: 'center' }} className={'modal-caption'}>
              Sidekick is a Real-Time Application Debugger that helps you to troubleshoot problems where they happen,
              when they happen, without stopping, rebuilding, or redeploying applications. Check out our tutorials for a
              smoother on-boarding experience!
            </div>
          </div>
        );
      case MODAL_STATE.VIDEO_LIST:
        return (
          <>
            <div className={'modal-caption'}>Learn how to debug by choosing the videos below</div>
            <div className={'onboarding-panel'}>
              <div className={'video-list'}>
                <VideoListItem
                  name={'Getting started'}
                  id={'6t0GVFAJqyE'}
                  key={'6t0GVFAJqyE'}
                  onClick={id => this.setState({ videoId: `https://www.youtube.com/embed/${id}` })}
                  active={this.state.videoId === GETTING_STARTED_ID}
                />
                <Accordion
                  panels={[
                    {
                      title: 'How to Tracepoint',
                      content: (
                        <div className={'video-list-panel'}>
                          {VIDEO_LIST.tracepoint.map(video => (
                            <VideoListItem
                              name={video.name}
                              id={video.id}
                              key={video.id}
                              onClick={id => this.setState({ videoId: `https://cdn.iframe.ly/${id}` })}
                              active={this.state.videoId === `https://cdn.iframe.ly/${video.id}`}
                            />
                          ))}
                        </div>
                      ),
                      shrink: 1,
                      basis: '50%',
                    },
                    {
                      title: 'How to Logpoint',
                      content: (
                        <div className={'video-list-panel'}>
                          {VIDEO_LIST.logpoint.map(video => (
                            <VideoListItem
                              name={video.name}
                              id={video.id}
                              key={video.id}
                              onClick={id => this.setState({ videoId: `https://cdn.iframe.ly/${id}` })}
                              active={this.state.videoId === `https://cdn.iframe.ly/${video.id}`}
                            />
                          ))}
                        </div>
                      ),
                      shrink: 1,
                      basis: '50%',
                    },
                  ]}
                  openPanels={[0]}
                />
              </div>
              <div className={'video-container'}>
                <div className={'loading'}>Loading...</div>
                <iframe
                  width="520"
                  height="292.6"
                  src={this.state.videoId}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  getModalActions = () => {
    let closeActionContent = 'Skip tutorial';
    if (
      this.props.workspaceOnboardingStatus.onboardStatus.status === 'SKIPPED' ||
      this.props.workspaceOnboardingStatus.onboardStatus.status === 'PASSED'
    ) {
      closeActionContent = 'Close tutorial';
    }
    switch (this.state.modalState) {
      case MODAL_STATE.WELCOME:
        return (
          <>
            <Button
              secondary
              className={'button-step-info'}
              content={closeActionContent}
              loading={this.state.skipButtonLoading}
              onClick={() => this.updateOnboardStatus('SKIPPED')}
            />
            <Button
              onClick={() => this.setState({ modalState: MODAL_STATE.VIDEO_LIST })}
              primary
              content={'Learn how in 2 minutes!'}
            />
          </>
        );
      case MODAL_STATE.VIDEO_LIST:
        return (
          <>
            <Button
              secondary
              className={'button-step-info'}
              content={closeActionContent}
              loading={this.state.skipButtonLoading}
              onClick={() => this.updateOnboardStatus('SKIPPED')}
            />
            <Button
              onClick={() => this.props.history.push(routeList.sandbox.path)}
              primary
              content={'To the Sandbox'}
            />
          </>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <Modal
        open={this.state.modalOpen}
        style={{ width: this.state.modalState === MODAL_STATE.WELCOME ? '640px' : '824px' }}
        className={'onboarding-modal'}
      >
        <Modal.Header>Welcome to Sidekick</Modal.Header>
        <Modal.Content>{this.getModelContent()}</Modal.Content>
        <Modal.Actions>{this.getModalActions()}</Modal.Actions>
      </Modal>
    );
  }
}
