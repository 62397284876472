import React, { Component } from 'react';
import { Image, Table, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { abbrFileName } from 'utils/string-util';
import { formatTime } from 'utils/date-util';
import { findSidekickActionOfEvent } from 'utils/sidekick-action-utils';
import savedReferenceEventIcon from 'assets/images/saved-reference-event.svg';
import saveReferenceEventIcon from 'assets/images/save-reference-event.svg';
import compareSelectedIcon from 'assets/images/compare-selected.svg';
import compareIcon from 'assets/images/compare.svg';
import { putReferenceEventRequestCmdObj, removeReferenceEventRequestCmdObj } from 'utils/broker-commands';
import { ExportEventContainer } from 'containers';

export class LogTable extends Component {
  putReferenceEvent = (lpEvent, lpId) => {
    const putReferenceEventRequest = { ...putReferenceEventRequestCmdObj };
    const application = this.props.appData.find(app => app.instanceId === lpEvent.applicationInstanceId);
    if (application) {
      const { stage, version, name, customTags } = application;
      let flattenedCustomTags = {};
      customTags.forEach(({ tagName, tagValue }) => (flattenedCustomTags[tagName] = tagValue));
      putReferenceEventRequest.applicationFilter = { stage, version, name, customTags: flattenedCustomTags };
    }
    putReferenceEventRequest.probeId = lpId;
    putReferenceEventRequest.probeType = 'LOGPOINT';
    putReferenceEventRequest.event = JSON.stringify(lpEvent);
    this.props.handlePutReference(putReferenceEventRequest, lpEvent);
  };

  removeReferenceEvent = (lpEvent, lpId) => {
    const removeReferenceEventRequest = { ...removeReferenceEventRequestCmdObj };
    const application = this.props.appData.find(app => app.instanceId === lpEvent.applicationInstanceId);
    if (application) {
      const { stage, version, name } = application;
      removeReferenceEventRequest.applicationFilter = { stage, version, name };
    }
    removeReferenceEventRequest.probeType = 'LOGPOINT';
    removeReferenceEventRequest.probeId = lpId;
    this.props.handleRemoveReference(removeReferenceEventRequest, lpEvent);
  };

  handleCompareReference = tpEvent => {
    this.props.handleCompareReference(tpEvent);
  };

  renderLogPointEventsTableRows = () => {
    let { logPointEvents, logPoints } = this.props;

    return logPointEvents.map((lpEvent, index) => {
      const logpointOfEvent = findSidekickActionOfEvent(lpEvent.logPointId, logPoints);
      let { time } = lpEvent;
      if (time == null) {
        time = lpEvent.createdAt;
      }
      return (
        <Table.Row
          className={index % 2 === 0 ? 'even' : 'odd'}
          key={index}
          onDoubleClick={e => {
            this.props.onRowDoubleClick && this.props.onRowDoubleClick(e, index);
          }}
          onClick={e => {
            this.props.onRowClick && this.props.onRowClick(e, index);
          }}
        >
          <TableCell>{formatTime(time)}</TableCell>
          <TableCell>{`${abbrFileName(lpEvent.fileName)}:${lpEvent.lineNo}`}</TableCell>
          <TableCell>{lpEvent.logMessage}</TableCell>
          {this.props.childCells &&
            this.props.childCells.length > 0 &&
            this.props.childCells.map((cell, index) => <TableCell key={index}>{cell.component}</TableCell>)}
          <TableCell>
            <div className={'predefined-actions'}>
              {logpointOfEvent?.predefined && (
                <Image
                  src={lpEvent.referenceEvent ? savedReferenceEventIcon : saveReferenceEventIcon}
                  onClick={() =>
                    lpEvent.referenceEvent
                      ? this.removeReferenceEvent(lpEvent, logpointOfEvent.id)
                      : this.putReferenceEvent(lpEvent, logpointOfEvent.id)
                  }
                />
              )}
              {this.props.compareEvents && (
                <>
                  <Image
                    src={
                      this.props.compareEvents.findIndex(event => event.id === lpEvent.id) > -1
                        ? compareSelectedIcon
                        : compareIcon
                    }
                    onClick={() => {
                      this.handleCompareReference(lpEvent);
                    }}
                  />
                  <ExportEventContainer type={1} event={lpEvent} noStyle={true} />
                </>
              )}
            </div>
          </TableCell>
        </Table.Row>
      );
    });
  };

  render() {
    return (
      <div className={'table-section'}>
        <Table className={'trace-point-event-table'}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Time</TableHeaderCell>
              <TableHeaderCell>Class</TableHeaderCell>
              <TableHeaderCell>Log Message</TableHeaderCell>
              {this.props.childCells &&
                this.props.childCells.length > 0 &&
                this.props.childCells.map(cell => <Table.HeaderCell>{cell.header}</Table.HeaderCell>)}
              <TableHeaderCell style={{ width: '10%' }} />
            </TableRow>
          </TableHeader>
          {this.renderLogPointEventsTableRows()}
        </Table>
      </div>
    );
  }
}
