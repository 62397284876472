import {
  GET_ONBOARD_STATUS_FULFILLED,
  GET_ONBOARD_STATUS_PENDING,
  GET_ONBOARD_STATUS_REJECTED,
} from 'store/constants/workspace-onboarding';

const initialState = {
  onboardStatus: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function workspaceOnboardingStatus(state = initialState, action) {
  switch (action.type) {
    case GET_ONBOARD_STATUS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_ONBOARD_STATUS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        onboardStatus: action.payload.data,
      };
    case GET_ONBOARD_STATUS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    default:
      return state;
  }
}
