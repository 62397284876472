import { connect } from 'react-redux';
import { SourceAccountList } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const SourceAccountListContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SourceAccountList));
export default SourceAccountListContainer;
