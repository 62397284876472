import React, { Component } from 'react';
import { Button, Dropdown, Input, Modal } from 'semantic-ui-react';
import { LinearClient } from '@linear/sdk';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';

export class LinearIntegrationClient extends Component {
  constructor(props) {
    super(props);
    this.linearClient = null;
    this.state = {
      teams: [],
      teamsLoading: true,
      selectedTeam: null,
      title: null,
      issueCreateLoading: false,
    };
  }

  handleTeamChangeChange = (e, { value }) => {
    this.setState({ selectedTeam: value });
  };
  createTeamsOptions = () => {
    return this.state.teams.map(team => ({
      key: team.id,
      text: team.name,
      value: team.id,
    }));
  };

  async componentDidMount() {
    this.props.getAccessToken(
      this.props.code,
      async () => {
        this.linearClient = new LinearClient({
          accessToken: this.props.issueManagementToken.linearToken.access_token,
        });
        const teams = await this.linearClient.teams();
        this.setState({ teamsLoading: false, teams: teams.nodes });
      },
      () => {
        this.setState({ teamsLoading: false });
        toast.error('Error while getting Linear Token!', ToastConfig);
      },
    );
  }

  createIssue = async () => {
    const { selectedTeam } = this.state;
    if (selectedTeam) {
      this.setState({ issueCreateLoading: true });
      this.linearClient
        .issueCreate({ teamId: selectedTeam, title: this.state.title })
        .then(res => {
          this.linearClient
            .commentCreate({
              issueId: res._issue.id,
              body: JSON.stringify(this.props.issueManagementToken.eventToSend.frames),
            })
            .then(() => {
              toast.success('You successfully created issue on Linear!', ToastConfig);
              this.props.closeModal();
            })
            .catch(() => {
              this.props.closeModal();
              toast.error('Error happened while creating comment!', ToastConfig);
            });
        })
        .catch(() => {
          this.props.closeModal();
          toast.error('Error happened while creating issue!', ToastConfig);
        });
    }
  };

  render() {
    return (
      <Modal closeOnDimmerClick={false} open={true}>
        <Modal.Header>Create Jira Issue</Modal.Header>
        <Modal.Content>
          <div className={'field'}>
            <div className={'label'}>Organisations</div>
            <Dropdown
              value={this.state.selectedTeam}
              placeholder={'Select Team'}
              loading={this.state.teamsLoading}
              onChange={this.handleTeamChangeChange}
              options={this.createTeamsOptions(this.state.team)}
            />
          </div>
          <div className={'field'}>
            <div className={'label'}>Issue Title</div>
            <Input
              disabled={this.state.teamsLoading}
              value={this.state.title}
              onChange={e => this.setState({ title: e.target.value })}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button className={'secondary button-step-info'} content={'Cancel'} onClick={this.props.closeModal} />
          <Button
            loading={this.state.issueCreateLoading}
            content={'Confirm'}
            onClick={this.createIssue}
            disabled={this.state.teamsLoading || this.state.title?.length === 0 || this.state.selectedTeam == null}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
