import { connect } from 'react-redux';
import { LogPointEvents } from 'components';
import {
  clearLogPointEvents,
  clearPredefinedLogPointEvents,
  completedSidekickActionEventsFocus,
  flushReferenceEvent,
  setEventBeingProcessed,
} from 'store/actions';
import { generateUUID } from 'utils/object-util';
import { send as brokerSend } from '@giantmachines/redux-websocket/';

function mapStateToProps(store) {
  return {
    broker: store.broker,
    sidekickActionEventsFocus: store.sidekickActionEventsFocus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleSidekickActionFocusCompleted(fileName, lineNo) {
      dispatch(completedSidekickActionEventsFocus(fileName, lineNo));
    },
    handleLogPointEventClear() {
      dispatch(clearLogPointEvents());
    },
    handlePredefinedLogPointEventClear() {
      dispatch(clearPredefinedLogPointEvents());
    },
    handleBrokerSend(data) {
      data.id = data?.id || generateUUID();
      dispatch(brokerSend(data));
    },
    setEventBeingProcessed(event) {
      dispatch(setEventBeingProcessed(event));
    },
    flushReferenceEvent() {
      dispatch(flushReferenceEvent());
    },
  };
}

export const LogPointEventsContainer = connect(mapStateToProps, mapDispatchToProps)(LogPointEvents);
