import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

import { SourceExplorerContainer } from 'containers';
import { hasArrayElement } from 'utils/array-util';
import curvedUpArrow from 'assets/images/curved-up-arrow.svg';
import './RepositoryManagement.scss';
import URL_POOL from 'utils/url-pool';

export default class RepositoryManagement extends Component {
  renderNoSourcePlaceholder = () => {
    return (
      <div className="no-source-wrapper">
        <div className="arrow-pointer">
          <img src={curvedUpArrow} alt="" />
        </div>
        <div className="folder-icon">
          <i className="icon-apps-and-tps-select-applications icon" />
        </div>
        <div className="no-source-text">
          <span className="title">Source File</span>
          <span className="text">Please connect sources from a source code management tool of your choice. </span>
          <a href={URL_POOL.SIDEKICK_DOCS} target={'_blank'} rel={'noopener noreferrer'}>
            {' '}
            Check out our docs for more.
          </a>
        </div>
      </div>
    );
  };

  render() {
    const { sourceCacheList } = this.props.sourceCache;
    return (
      <div style={{ minHeight: '100%' }} className="repository-management-wrapper">
        {this.props.loading && (
          <div className="repository-management-loading-layer">
            <Icon className="icon-thundra-loading" />
          </div>
        )}
        {hasArrayElement(sourceCacheList) ? <SourceExplorerContainer /> : this.renderNoSourcePlaceholder()}
      </div>
    );
  }
}
