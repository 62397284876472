import { UPDATE_WEBHOOK_FULFILLED, UPDATE_WEBHOOK_PENDING, UPDATE_WEBHOOK_REJECTED } from '../constants/webhook-update';

const updatedWebhookInitialState = {
  updatedWebhook: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function updateWebhook(state = updatedWebhookInitialState, action) {
  switch (action.type) {
    case UPDATE_WEBHOOK_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case UPDATE_WEBHOOK_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        updatedWebhook: action.payload.data,
      };
    case UPDATE_WEBHOOK_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
