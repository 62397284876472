import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { DELETE_WORKSPACE } from '../constants/workspace-delete';

export const deleteWorkspace = workspaceId => {
  return {
    type: DELETE_WORKSPACE,
    payload: axios.delete(CONFIG.apiUrl + `/workspaces/${workspaceId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
