import React from 'react';
import { connect } from 'react-redux';
import { completeInvitation } from 'store/actions';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import routeList from 'routes';
import { withRouter } from 'react-router-dom';

export function withInvitation(Component) {
  class ComponentWithInvitation extends React.Component {
    componentDidMount() {
      let workspaceId = localStorage.getItem('workspace-id');
      if (workspaceId) {
        localStorage.removeItem('workspace-id');
        this.props.completeInvitation(workspaceId, () => {
          toast.success('You successfully joined workspace!', ToastConfig);
          this.props.history.push(routeList.ide.path);
        });
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = store => {
    return {
      completeInvitationResult: store.workspaceCompleteInvitation,
    };
  };

  const mapDispatchToProps = () => {
    return {
      completeInvitation(workspaceId, onSuccess, onError) {
        dispatchWithExceptionHandler(completeInvitation(workspaceId), false, onSuccess, onError);
      },
    };
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(ComponentWithInvitation));
}
