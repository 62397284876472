import React, { Component } from 'react';
import { Button, Message, Modal } from 'semantic-ui-react';
import { injectStripe } from 'react-stripe-elements';
import { CreditCardForm } from '../CreditCardForm';

import './CardModal.scss';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';

class CardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenError: null,
      cardNumberError: null,
      cardExpiryError: null,
      cardCVCError: null,
      postalCodeError: null,
      name: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      isButtonsDisabled: false,
    };
  }

  handleInputChange = e => {
    const fieldName = e.target.name;
    const value = e.target.value;
    // console.log("CardModal, handleInputChange; fieldName, value: ", fieldName, value);
    this.setState({
      [fieldName]: value,
    });
  };

  handleCardNumberChange = change => {
    // console.log("CardModal, handleCardNumberChange; change: ", change);
    this.setState({
      cardNumberError: change.error ? change.error.message : null,
    });
  };

  handleCardExpiryChange = change => {
    // console.log("CardModal, handleCardExpiryChange; change: ", change);
    this.setState({
      cardExpiryError: change.error ? change.error.message : null,
    });
  };

  handleCardCVCChange = change => {
    // console.log("CardModal, handleCardCVCChange; change: ", change);
    this.setState({
      cardCVCError: change.error ? change.error.message : null,
    });
  };

  handlePostalCodeChange = change => {
    // console.log("CardModal, handlePostalCodeChange; change: ", change);
    this.setState({
      postalCodeError: change.error ? change.error.message : null,
      zip: change.value ? change.value : '',
    });
  };

  handleSaveCard = () => {
    console.log('handleSaveCard; props: ', this.props);
    this.setState({
      isButtonsDisabled: true,
    });

    if (this.props.stripe) {
      this.props.stripe
        .createToken({
          name: this.state.name,
          address_line1: this.state.address,
          address_city: this.state.city,
          address_state: this.state.state,
          address_country: this.state.country,
          address_zip: this.state.zip,
        })
        .then(result => {
          if (result.error) {
            console.log('ERROR-createToken; result: ', result);
            this.setState({
              tokenError: result.error.message,
              isButtonsDisabled: false,
            });
          } else {
            console.log('WIN-createToken; result: ', result);
            this.props.onUpsertCard(result.token.id, this.handleSaveCardSuccess, this.handleSaveCardError);
          }
        });
    }
  };

  handleSaveCardSuccess = () => {
    console.log('handleSaveCardSuccess, WIN-upsert; props: ', this.props);
    this.setState({
      isButtonsDisabled: false,
    });
    this.props.onClose(true);

    toast.success('Updated successfully', ToastConfig);
  };

  handleSaveCardError = () => {
    // console.log("handleSaveCardError; props: ", this.props);

    this.setState({
      isButtonsDisabled: false,
    });
    this.props.onClose(false);

    const { error } = this.props.upsertCardResult;

    toast.error(`Your card information update failed; ${error.response.data}`, ToastConfig);
  };

  handleCardModalClose = () => {
    // Do not close while stripe creates token
    if (!this.props.upsertCardResult.fetching) {
      this.props.onClose(false);

      // Clear any errors upon close
      this.setState({
        tokenError: null,
        cardNumberError: null,
        cardExpiryError: null,
        cardCVCError: null,
        postalCodeError: null,
      });
    }
  };

  render() {
    // console.log("CardModal, render; props, state: ", this.props, this.state);

    return (
      <Modal open={this.props.open} onClose={this.handleCardModalClose}>
        <Modal.Header>Add Credit Card</Modal.Header>

        <Modal.Content>
          {!!this.state.tokenError && (
            <Message negative>
              <p>{this.state.tokenError}</p>
            </Message>
          )}
          <CreditCardForm
            onInputChange={this.handleInputChange}
            onCardNumberChange={this.handleCardNumberChange}
            onCardExpiryChange={this.handleCardExpiryChange}
            onCardCVCChange={this.handleCardCVCChange}
            onPostalCodeChange={this.handlePostalCodeChange}
            cardNumberError={this.state.cardNumberError}
            cardExpiryError={this.state.cardExpiryError}
            cardCVCError={this.state.cardCVCError}
            postalCodeError={this.state.postalCodeError}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button
            className="reject-button"
            onClick={this.handleCardModalClose}
            content="Cancel"
            disabled={this.state.isButtonsDisabled}
          />

          <Button
            className="accept-button"
            onClick={this.handleSaveCard}
            content="Save"
            // loading={this.props.cardSource.fetching}
            disabled={this.state.isButtonsDisabled}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

// export default CardModal;
export default injectStripe(CardModal);
