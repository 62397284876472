import { SOURCE_PROVIDER_PLATFORM } from './source-provider-util';

export function abbrClassName(str) {
  if (str) {
    const arr = str.split('.');
    const abbArr = arr.map(el => el[0]);
    const length = abbArr.length;
    if (length > 0) abbArr[length - 1] = arr[length - 1];
    return abbArr.join('.');
  }
  return str;
}

export function abbrFileName(str) {
  if (str) {
    const arr = str.split('/');
    const lastElement = arr[arr.length - 1];
    if (typeof lastElement === 'string' && lastElement.indexOf('?ref=') > 0) {
      //Pattern like Owner.java?ref=07a86dd57b8817fb873a9a7bd48d85a318079c04
      const arrFileParts = lastElement.split('?ref=');
      return arrFileParts[0];
    } else {
      return lastElement;
    }
  }
  return str;
}

export function generatePathPrefixFromContextMetaData(contextMetaData) {
  // SourceProvider use after BitBucket and GitLab
  const { sourceProvider, repoOwner, repoName } = contextMetaData;
  let pathPrefix = ``;
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    pathPrefix = `https://api.github.com/repos/${repoOwner}/${repoName}`;
  }
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    pathPrefix = `gitlab.com/repos/${repoOwner}/${repoName}`;
  }

  return pathPrefix;
}

export function generateSourceMetaDataFromEntityId(entityId) {
  // const metaData = {};
  const result = entityId.split('repos/', 2);
  const sourceProviderStr = result[0];
  let sourceProvider = '';
  if (sourceProviderStr.includes('github')) {
    sourceProvider = SOURCE_PROVIDER_PLATFORM.GITHUB;
  }
  if (sourceProviderStr.includes('gitlab')) {
    sourceProvider = SOURCE_PROVIDER_PLATFORM.GITLAB;
  }

  const ownerRepoSourceShaPart = result[1];

  const result2 = ownerRepoSourceShaPart.split('/contents/', 2);
  const ownerRepoPart = result2[0];
  const sourceShaPart = result2[1];

  const result3 = ownerRepoPart.split('/', 2);
  const owner = result3[0];
  const repo = result3[1];

  const result4 = sourceShaPart.split('?ref=', 2);
  const sourcePath = result4[0];
  const sha = result4[1];

  return { sourceProvider, owner, repo, sourcePath, sha };
}

export function generateFullGitSourceURLPath(
  sourceProvider,
  selectedRepoOwnerLogin,
  selectedRepoName,
  filePath,
  selectedRepoBranchSha,
) {
  let fileFullPath = ``;
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    fileFullPath = `https://api.github.com/repos/${selectedRepoOwnerLogin}/${selectedRepoName}/contents/${filePath}?ref=${selectedRepoBranchSha}`;
  }

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    fileFullPath = `gitlab.com/repos/${selectedRepoOwnerLogin}/${selectedRepoName}/contents/${filePath}?ref=${selectedRepoBranchSha}`;
  }

  return fileFullPath;
}

export function compareTextAndNumber(str1, str2) {
  const text1 = str1.replace(/\d+/g, '');
  const text2 = str2.replace(/\d+/g, '');
  if (text1 === text2) {
    const number1 = parseInt(str1.replace(/\D+/g, ''), 10) || 0;
    const number2 = parseInt(str2.replace(/\D+/g, ''), 10) || 0;
    return number1 - number2;
  }
  return text1.localeCompare(text2);
}

// TODO: Change it later
// eslint-disable-next-line
String.prototype.replaceAll = function (search, replacement) {
  const target = this;
  return target.split(search).join(replacement);
};
