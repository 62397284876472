import {
  CANCEL_ACTIVE_SUBSCRIPTION_FULFILLED,
  CANCEL_ACTIVE_SUBSCRIPTION_PENDING,
  CANCEL_ACTIVE_SUBSCRIPTION_REJECTED,
} from '../constants/billing-cancel-subscription';

const initialState = {
  cancelSubscriptionResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function cancelSubscription(state = initialState, action) {
  switch (action.type) {
    case CANCEL_ACTIVE_SUBSCRIPTION_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case CANCEL_ACTIVE_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        cancelSubscriptionResult: action.payload.data,
      };
    case CANCEL_ACTIVE_SUBSCRIPTION_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
