import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { capitalize } from 'lodash/string';

export class Frameworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'JAVA',
    };
  }

  renderTabs = () => {
    return (
      <Menu pointing secondary>
        {Object.entries(this.props.quickStartData).map(([key, val]) => {
          return (
            <Menu.Item
              key={key}
              className={key.toLowerCase()}
              name={capitalize(key)}
              active={this.state?.activeTab === key}
              onClick={() => this.setState({ activeTab: key })}
            />
          );
        })}
      </Menu>
    );
  };

  renderFrameworks = () => {
    const { activeTab } = this.state;
    return (
      <div className={'frameworks'}>
        {Object.entries(this.props.quickStartData[activeTab]).map(([key, value]) => {
          return (
            <div className={'framework'} key={key} onClick={() => this.props.onFrameworkClicked(value)}>
              <img src={value.iconURL} alt="new" />
              <div className={'label'}>{value.name.split(' ')[0]}</div>
              <div className={'label'}>{value.name.split(' ').slice(1).join(' ')}</div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div className={'frameworks-container'}>
        {this.renderTabs()}
        {this.renderFrameworks()}
      </div>
    );
  }
}
