import React, { Component } from 'react';
import './Accordion.scss';
import { Icon } from 'semantic-ui-react';

export class Accordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openPanels: this.props.openPanels || [0],
    };
  }

  togglePanel = panelIndex => {
    const openPanels = [...this.state.openPanels];
    const arrayIndex = openPanels.indexOf(panelIndex);
    if (arrayIndex > -1) {
      // only splice array when item is found
      openPanels.splice(arrayIndex, 1);
    } else {
      openPanels.push(panelIndex);
    }
    this.setState({ openPanels: openPanels });
    this.props.onPanelClick && this.props.onPanelClick();
  };

  render() {
    return (
      <div className={'sidekick-accordion'}>
        {this.props.panels.map((panel, index) => {
          const panelOpen = this.state.openPanels.includes(index);
          return (
            <>
              <div
                key={`${index}-title`}
                className={'title' + (panelOpen ? ' open' : '')}
                onClick={() => this.togglePanel(index)}
              >
                <Icon key={'dropdown'} name={'dropdown'} />
                {panel.title}
              </div>
              <div
                key={panel.key}
                style={{
                  flexShrink: panel?.shrink !== undefined ? panel.shrink : 1,
                  flexBasis: panel?.basis || 'auto',
                  height: panel?.basis || 'auto',
                }}
                className={'panel' + (panelOpen ? ' open' : '')}
              >
                {panel.content}
              </div>
            </>
          );
        })}
      </div>
    );
  }
}
