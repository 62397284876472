import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_BILLING_CUSTOMER } from 'store/constants/billing-customer';

export const getBillingCustomer = workspaceId => {
  return {
    type: GET_BILLING_CUSTOMER,
    payload: axios.get(CONFIG.apiUrl + `/customer-info/customer?workspaceId=${workspaceId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
