import React, { Component } from 'react';
import './WelcomeLayout.scss';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { Image } from 'semantic-ui-react';
import sidekickLogo from 'assets/images/Login-Sidekick-Logo.svg';

class WelcomeLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserMenuOpen: false,
    };
  }

  handleUserMenuOpen = () => {
    this.setState({
      isUserMenuOpen: true,
    });
  };

  handleUserMenuClose = () => {
    this.setState({
      isUserMenuOpen: false,
    });
  };

  render() {
    // console.log("MainLayout; props: ", this.props);
    return (
      <div className={'welcome-page-container'}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.title}</title>
        </Helmet>
        <div className={'welcome-page-header'}>
          <Image src={sidekickLogo} />
        </div>
        <div className={'welcome-page-content'}>{this.props.children}</div>

        <ToastContainer
          className="toast-container"
          toastClassName="toast-content"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default WelcomeLayout;
