import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { SAVE_SUBSCRIPTION } from 'store/constants/billing-save-subscription';

export const saveSubscription = billingInfo => {
  return {
    type: SAVE_SUBSCRIPTION,
    payload: axios.post(CONFIG.apiUrl + '/subscription', billingInfo, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
