import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_WEBHOOKS } from '../constants/webhook-list';

export const getWebhooks = workspaceId => {
  return {
    type: GET_WEBHOOKS,
    payload: axios.get(CONFIG.apiUrl + `/webhooks?workspaceId=${workspaceId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
