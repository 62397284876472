import React, { Component } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { INTEGRATION_CONFIG, logoMap } from 'utils/integration-util';
import { capitalize } from 'lodash';

export class IntegrationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validateFields: false,
      name: this.props?.integration?.name || '',
      integrationType: this.props?.integration?.type || 'SLACK',
      modalMode: this.props?.integration?.id ? 'EDIT' : 'ADD',
    };
  }

  switchMode = newType => {
    this.props.onSwitchMode();
    this.setState({ modalMode: 'ADD', name: '', integrationType: newType });
  };

  handleChange = e => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;

    this.setState({ [fieldName]: fieldValue });
  };

  renderFields = () => {
    return (
      <Form>
        <Form.Input
          key={'name'}
          autoComplete={'off'}
          label={'Name'}
          value={this.state.name}
          placeholder={'Enter name'}
          name={'name'}
          required
          className={this.state.validateFields && !this.state.name ? 'erroneous' : ''}
          onChange={this.handleChange}
        />
        {Object.entries(INTEGRATION_CONFIG[this.state.integrationType]).map(([key, val]) => {
          return (
            <>
              <Form.Input
                key={key}
                autoComplete={'off'}
                label={capitalize(key)}
                value={this.state[key]}
                placeholder={
                  this.state.modalMode === 'EDIT' ? '******' : val?.placeholder || `Enter ${key.toLocaleLowerCase()}`
                }
                name={key}
                required={val.required}
                className={this.state.validateFields && val.required && !this.state[key] ? 'erroneous' : ''}
                onChange={this.handleChange}
              />
              {val?.caption && <div>{val?.caption}</div>}
            </>
          );
        })}
      </Form>
    );
  };

  areFieldsValid = () => {
    let result = true;
    Object.entries(INTEGRATION_CONFIG[this.state.integrationType]).forEach(([key, val]) => {
      if (val.required && !this.state[key]) {
        result = false;
      }
    });
    result = result && !!this.state.name;

    if (result === false) {
      this.setState({ validateFields: true });
      setTimeout(() => {
        this.setState({ validateFields: false });
      }, 3000);
    }
    return result;
  };

  createConfig = () => {
    let webHookConfig = {};
    Object.entries(INTEGRATION_CONFIG[this.state.integrationType]).forEach(([key, val]) => {
      webHookConfig[key] = this.state[key];
    });

    return webHookConfig;
  };

  renderSettings = () => {
    return (
      <>
        <div className={'settings-header'}>{logoMap[this.state.integrationType].logo(32)}</div>
        <div className={'modal-caption'}>{logoMap[this.state.integrationType].caption}</div>
        {this.renderFields()}
      </>
    );
  };

  renderIntegrations = () => {
    return Object.keys(INTEGRATION_CONFIG).map(integration => {
      return (
        <div
          key={integration}
          onClick={() => {
            this.switchMode(integration);
          }}
          className={'integration-item' + (this.state.integrationType === integration ? ' active' : '')}
        >
          {logoMap[integration].logo(20)}
        </div>
      );
    });
  };

  render() {
    return (
      <Modal
        className={'integration-modal'}
        open={this.props.modalOpen}
        onClose={() => this.props.onClose(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{capitalize(this.state.modalMode)} integration</Modal.Header>
        <Modal.Content>
          <div className={'modal-caption'}>Select an integration and create</div>
          <div className={'integration-panel'}>
            <div className={'integrations-list'}>{this.renderIntegrations()}</div>
            <div className={'integration-settings'}>{this.renderSettings()}</div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={'secondary button-step-info'}
            content={'Cancel'}
            onClick={() => this.props.onClose(false)}
          />
          <Button
            loading={this.props?.loading || false}
            content={this.state.modalMode === 'EDIT' ? 'Update' : 'Add'}
            onClick={() => {
              const fieldsValid = this.areFieldsValid();
              if (!fieldsValid) {
                return;
              }
              const webhookConfig = this.createConfig();
              this.props.onSubmit(this.state.integrationType, this.state.name, webhookConfig);
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
