import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import routeList from 'routes';
import URL_POOL from 'utils/url-pool';
import { CONFIG } from 'utils/config-util';

export class SubscriptionExpiredModal extends Component {
  render() {
    const activeWorkspace = this.props.activeWorkspace?.workspace;
    const { workspaces } = this.props.workspaceList;
    let parentWs = activeWorkspace?.workspace.parentWorkspaceId
      ? workspaces.find(parentWs => parentWs.workspace.id === activeWorkspace?.workspace.parentWorkspaceId)
      : null;
    let open = parentWs != null ? !parentWs.hasSubscription : !activeWorkspace?.hasSubscription;
    if (this.props.onboardingStatus.sandboxEnabled || CONFIG?.ON_PREM) {
      open = false;
    }
    return (
      <Modal className={'confirm-modal'} open={open} closeOnDimmerClick={false}>
        <Modal.Header>
          <Icon name="warning sign" />
          Subscription Expired
        </Modal.Header>
        <Modal.Content>
          <div style={{ marginBottom: '10px' }}>
            This workspaces's subscription has expired. Please update billing settings or inform your Billing Admin.
          </div>
          <div style={{ marginBottom: '10px' }}>
            If you have other workspaces, you can head to an active workspace to continue using Sidekick.
          </div>
          <div style={{ marginBottom: '10px' }}>
            For any problem or inquiry, you can reach us at :{' '}
            <a href={'mailto:support@runsidekick.com'} target={'_blank'} rel="noopener noreferrer">
              support@runsidekick.com
            </a>
          </div>
          <div>
            Check out our{' '}
            <a href={URL_POOL.SIDEKICK_BLOG} target={'_blank'} rel="noopener noreferrer">
              blogs
            </a>{' '}
            to discover what more can you achieve with Sidekick.
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={'secondary button-step-info'}
            content={'Change Workspace'}
            onClick={() => this.props.history.push(routeList.workspaces.path)}
          />
          <Button
            loading={this.props?.loading || false}
            content={'To the Billing Page'}
            onClick={() => {
              let routingParams = {
                pathname: routeList.workspace.path.replace(':workspaceId', activeWorkspace?.workspace.id),
                state: {
                  activeTab: 'Billing',
                },
              };
              this.props.history.push(routingParams);
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
