import {
  GET_GITHUB_REPOS_ERROR,
  GET_GITHUB_REPOS_FETCHED,
  GET_GITHUB_REPOS_FETCHING,
  GET_GITLAB_REPOS_ERROR,
  GET_GITLAB_REPOS_FETCHED,
  GET_GITLAB_REPOS_FETCHING,
} from '../constants/git-repositories';
import { getSourceProviderOAuthTokenSelector } from 'store/selectors';
import store from 'store/store';
import { SOURCE_PROVIDER_PLATFORM } from 'utils/source-provider-util';

import axios from 'axios';
import { Octokit } from '@octokit/rest';

// Decide git api actions according to source provider!
export const getUserRepositories = (sourceProvider, successFunction, errFunction) => {
  console.log("==> getUserRepositories");
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    console.log("GITHUB ==> getUserRepositories");
    return getUserRepositoriesGithub(successFunction, errFunction);
  }

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    console.log("GITLAB ==> getUserRepositories");
    return getUserRepositoriesGitlab(successFunction, errFunction);
  }
}

// GITLAB
export const getUserRepositoriesGitlab = (successFunction, errFunction) => {
  console.log("action, users, getUserRepositoriesGitlab");
  const gitlabPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITLAB);
  console.log("getUserRepositoriesGitlab; gitlabPAT: ", gitlabPAT);

  return dispatch => {
    dispatch(getGitlabUserRepositoriesStarted());

    // This is to prevent fetching when gitlabPAT is not available.
    // A similar mechanism works for github due to octokit implementation.
    // Otherwise we do not fetch repolist after the initial connection with the GitLab!
    if (gitlabPAT === "") {
      dispatch(getGitlabUserRepositoriesFailure("NO_AUTH_TOKEN"));
      if (errFunction) {
        errFunction();
      }
      return;
    }

    // Ref doc => https://docs.gitlab.com/ee/api/projects.html#list-user-projects
    const listUserProjectsUrl = `https://gitlab.com/api/v4/projects?membership=true&archived=false`;
    axios.get(listUserProjectsUrl, {
      headers: { Authorization: 'Bearer ' + gitlabPAT }
    }).then(res => {
      console.log("getUserRepositoriesGitlab, listUserProjectsUrl; res: ", res);
      dispatch(getGitlabUserRepositoriesSuccess(res.data));
      if (successFunction) {
        successFunction();
      }
    }).catch(err => {
      console.log("getUserRepositoriesGitlab, listUserProjectsUrl; err: ", err);
      dispatch(getGitlabUserRepositoriesFailure(err.message));
      if (errFunction) {
        errFunction();
      }
    });
  }

}

const getGitlabUserRepositoriesStarted = () => ({
  type: GET_GITLAB_REPOS_FETCHING
});

const getGitlabUserRepositoriesSuccess = (data) => {
  // console.log("action, users, getGitlabUserRepositoriesSuccess; data: ", data);
  return ({
    type: GET_GITLAB_REPOS_FETCHED,
    payload: {
      data
    }
  });
}

const getGitlabUserRepositoriesFailure = error => {
  // console.log("action, users, getGitlabUserRepositoriesFailure; error: ", error);
  return ({
    type: GET_GITLAB_REPOS_ERROR,
    payload: {
      error
    }
  });
}







// GITHUB
// Ref. doc: https://octokit.github.io/rest.js/v18#repos-list-for-authenticated-user
export const getUserRepositoriesGithub = (successFunction, errFunction) => {
  console.log("action, users, getUserRepositories: ");
  const githubPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITHUB);
  console.log("getUserRepositoriesGithub; githubPAT: ", githubPAT);
  const octokit = new Octokit({
    auth: githubPAT,
  });

  return dispatch => {
    dispatch(getGithubUserRepositoriesStarted());

    octokit.repos.listForAuthenticatedUser({
      affiliation: "owner, collaborator, organization_member", //?
      sort: "updated",
      per_page: 100,
    })
      .then(res => {
        dispatch(getGithubUserRepositoriesSuccess(res.data));
        console.log("getUserRepositories, res: ", res);

        if (successFunction) {
          // console.log("SUCCESS");
          successFunction();
        }
      })
      .catch(err => {
        console.log("ERR, getUserRepositories; err: ", err);
        dispatch(getGithubUserRepositoriesFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });

  };
};


const getGithubUserRepositoriesStarted = () => ({
  type: GET_GITHUB_REPOS_FETCHING
});

const getGithubUserRepositoriesSuccess = (data) => {
  // console.log("action, users, getGithubUserRepositoriesSuccess; data: ", data);
  return ({
    type: GET_GITHUB_REPOS_FETCHED,
    payload: {
      data
    }
  });
}

const getGithubUserRepositoriesFailure = error => {
  // console.log("action, users, getGithubUserRepositoriesFailure; error: ", error);
  return ({
    type: GET_GITHUB_REPOS_ERROR,
    payload: {
      error
    }
  });
}
