import React, { Component } from 'react';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import { AddUpdateRepoModalContainer } from 'containers';

import { Tooltip } from 'components';

import {
  clearRepoTracepointsAndEvents,
  findSourcesWithProvider,
  isSourceProviderConnected,
  MODAL_STATE,
  SOURCE_PROVIDER_PLATFORM,
} from 'utils/source-provider-util';
import { hasArrayElement } from 'utils/array-util';
import { isEmpty } from 'utils/object-util';
import { CONFIG } from 'utils/config-util.js';

import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';

import './SourceAccountList.scss';

export class SourceGitlabAccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverRepoId: '',
      isSourceRepoModalOpen: MODAL_STATE.CLOSED, // manage source modal state.
      isDisconnectRepoModalOpen: false,
      selectedUpdateSource: null,
    };
  }

  componentDidMount() {
    // console.log('CDM, SourceGitlabAccountList; props: ', this.props);
    this.props.getSourceProviderAccountList(
      this.onGetSourceProviderAccountListSuccess,
      this.onGetSourceProviderAccountListError,
    );

    this.props.getUserRepositories(SOURCE_PROVIDER_PLATFORM.GITLAB);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { sourceAccountList } = this.props.sourceProviderAccount;
    const sourceAccount = sourceAccountList.find(acc => acc.platform === SOURCE_PROVIDER_PLATFORM.GITLAB);
    // Open add source modal right after source provider initial connection.
    if (
      !isEmpty(this.props.sourceProviderAccount.addSourceAccount) &&
      !isEmpty(this.props.sourceProviderAccount.gitlabOauthToken) &&
      sourceAccount
    ) {
      console.log('NEW SOURCE PROVIDER ADDED - GITLAB; props: ', this.props);
      this.handleSourceModalOpen(MODAL_STATE.ADD_REPO);

      // Clear source provider add data from store, to prevent modal keep opening by itself.
      this.props.flushNewAddedSourceProviderData();
    }
  }

  onGetSourceProviderAccountListSuccess = () => {
    // What if user revoked access from source provider, then authData becomes obsolete!?
    console.log('SourceGitlabAccountList, onGetSourceProviderAccountListSuccess; props: ', this.props);

    this.props.getSourceCacheList(
      this.props.activeWorkspace.workspace.workspace.id,
      this.onGetSourceCacheListSuccess,
      this.onGetSourceCacheListError,
    );
  };

  onGetSourceProviderAccountListError = () => {
    console.log('SourceGitlabAccountList, onGetSourceProviderAccountListError');
  };

  onGetSourceCacheListSuccess = () => {
    console.log('SourceGitlabAccountList, onGetSourceCacheListSuccess; props, state: ', this.props, this.state);
  };

  onGetSourceCacheListError = () => {
    console.log('getSourceCacheList error');
  };

  ////////////////////////////////
  // Source Provider actions title
  renderGitlabSourceConnector = () => {
    return (
      <div className="source-account-wrapper">
        <div className="source-account-name">
          <Icon name="gitlab" />
          GitLab
        </div>
        {this.renderGitlabSourceProviderActions()}
      </div>
    );
  };

  renderGitlabSourceProviderActions = () => {
    const { sourceAccountList } = this.props.sourceProviderAccount;
    const connectedGitlabSource = isSourceProviderConnected(sourceAccountList, SOURCE_PROVIDER_PLATFORM.GITLAB);

    if (connectedGitlabSource) {
      return (
        <div className="source-provider-options">
          <Tooltip content={<span>Add repository from GitLab</span>}>
            <Icon
              name="plus"
              onClick={() => {
                console.log('Add repository from Gitlab clicked!');
                this.handleSourceModalOpen(MODAL_STATE.ADD_REPO);
              }}
            />
          </Tooltip>
          <Popup
            className="source-provider-ellipsis-popup"
            on="click"
            basic
            position="bottom left"
            trigger={<Icon name="ellipsis horizontal" />}
          >
            <div className="source-provider-popup-button" onClick={this.handleDisconnectGitlabSourceModalOpen}>
              Disconnect
            </div>
          </Popup>
        </div>
      );
    } else {
      return (
        <Button primary className="thin-button" onClick={this.handleConnectGitlabClick}>
          Connect
        </Button>
      );
    }
  };

  handleConnectGitlabClick = () => {
    const { sourceAccountList } = this.props.sourceProviderAccount;
    const connectedGitlabSource = isSourceProviderConnected(sourceAccountList, SOURCE_PROVIDER_PLATFORM.GITLAB);
    console.log('handleConnectGitlabClick; props, connectedGitlabSource: ', this.props, connectedGitlabSource);

    // Check for if selected source account is already connected.
    if (!connectedGitlabSource) {
      // First time gitlab connection.
      const clientId = CONFIG.GITLAB_CLIENT_ID;
      const randomState = CONFIG.GITLAB_OAUTH_RANDOM_STATE; // this needs to be a reasonable random str.
      const scope = CONFIG.GITLAB_OAUTH_SCOPE; //i.e scope=read_user+profile
      const redirectURI = `${CONFIG.thundraSidekickAppUrl}/debug`;
      // https://gitlab.com/oauth/authorize?client_id=8949b99d19b4d90476b9d33539a8335336ad7fcd173d2170510e79581d8412b4&redirect_uri=http://localhost:3005/debug&response_type=code&state=gitlab-dev-state&scope=api
      const url = `https://gitlab.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectURI}&response_type=code&state=${randomState}&scope=${scope}`;
      window.location.assign(url);
    }
  };

  handleSourceModalOpen = modalState => {
    console.log('handleSourceModalOpen; modalState: ', modalState);

    if (modalState === MODAL_STATE.ADD_REPO) {
      this.setState({
        isSourceRepoModalOpen: modalState,
        selectedUpdateSource: null,
      });
    }

    if (modalState === MODAL_STATE.UPDATE_BRANCH_COMMIT) {
      const { mouseOverRepoId } = this.state;
      const { sourceCacheList } = this.props.sourceCache;
      const selectedSourceCache = sourceCacheList.find(source => source.id === mouseOverRepoId);

      this.setState({
        isSourceRepoModalOpen: modalState,
        selectedUpdateSource: selectedSourceCache,
      });
    }
  };

  handleSourceModalClose = () => {
    console.log('handleSourceModalClose');

    this.setState({
      isSourceRepoModalOpen: MODAL_STATE.CLOSED,
    });
  };

  /////////////////////////////
  // Source provider repo list.
  renderNoRepositoryPlacehodler = () => {
    return (
      <div className="no-repository-wrapper">
        <div className="no-repo-text">
          <span className="title">No repository</span>
          <span className="text">
            <span
              className={'anchor-like'}
              onClick={() => {
                console.log('add a repo clicked.');
                this.handleSourceModalOpen(MODAL_STATE.ADD_REPO);
              }}
            >
              Add a repository
            </span>
            <span> from your source. You can add multiple repositories.</span>
          </span>
        </div>
      </div>
    );
  };

  renderTooltipContent = (repoName, branchName, commitSHA) => {
    return (
      <span>
        <span>{`Repository: ${repoName}`}</span>
        <br />
        <span>{`Branch: ${branchName}`}</span>
        <br />
        <span>{`Commit SHA: ${commitSHA.substring(0, 7)}`}</span>
      </span>
    );
  };

  renderGitlabSourceRepoList = () => {
    const { mouseOverRepoId } = this.state;
    const { sourceCacheList } = this.props.sourceCache;
    const sourceRepoList = findSourcesWithProvider(sourceCacheList, SOURCE_PROVIDER_PLATFORM.GITLAB);
    console.log('renderGitlabSourceRepoList; sourceRepoList: ', sourceRepoList);

    const { sourceAccountList } = this.props.sourceProviderAccount;
    const connectedGitlabSource = isSourceProviderConnected(sourceAccountList, SOURCE_PROVIDER_PLATFORM.GITLAB);

    if (!hasArrayElement(sourceRepoList) && connectedGitlabSource) {
      return this.renderNoRepositoryPlacehodler();
    }

    return (
      <div className="repo-list-wrapper">
        {sourceRepoList.map(repo => {
          // We can not clean id state onMouseLeave, because it conflicts with
          // change branch popup!!
          return (
            <div
              key={`${repo.id}`}
              className="repo-list-item"
              onMouseOver={() => this.setState({ mouseOverRepoId: repo.id })}
              onMouseLeave={() => this.setState({ mouseOverRepoId: '' })}
            >
              <Tooltip content={this.renderTooltipContent(repo.repository, repo.branch, repo.commit)}>
                <div className="repo-list-item-text">
                  <span>
                    {repo.owner}/{repo.repository}/{repo.branch}
                  </span>
                </div>
              </Tooltip>
              {mouseOverRepoId === repo.id && (
                <div className="repo-list-item-actions">
                  <Tooltip content={<span>Remove repository</span>}>
                    <Icon name="trash" onClick={() => this.handleSourceCacheRepoDelete(repo)} />
                  </Tooltip>
                  <Popup
                    className="source-provider-ellipsis-popup"
                    on="click"
                    basic
                    position="bottom left"
                    trigger={<Icon name="ellipsis horizontal" />}
                    onUnmount={() => this.setState({ mouseOverRepoId: '' })}
                  >
                    <div
                      className="source-provider-popup-button"
                      onClick={() => {
                        console.log('change branch&commit clicked');
                        this.handleSourceModalOpen(MODAL_STATE.UPDATE_BRANCH_COMMIT);
                      }}
                    >
                      Change branch & commit
                    </div>
                  </Popup>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  /////////////////////
  // DELETE REPO MODAL
  handleSourceCacheRepoDelete = ({ id: sourceId, repository }) => {
    // console.log("handleSourceCacheRepoDelete; repo: ", repo);
    const { id: workspaceId } = this.props.activeWorkspace.workspace.workpace;
    this.props.deleteSourceCache(
      sourceId,
      workspaceId,
      () => this.onDeleteSourceCacheSuccess(repository),
      this.onDeleteSourceCacheError,
    );

    const { selectedGitSourceCache, sourceCacheList } = this.props.sourceCache;
    // If deleted source is selected source for source explorer, then also flush it.
    if (selectedGitSourceCache && repository === selectedGitSourceCache.repository) {
      // flushSelectedGitSourceCache, deletes selected source cache from store!
      this.props.flushSelectedGitSourceCache();
    }
    const { handleNotifyIDEContextReset } = this.props;
    clearRepoTracepointsAndEvents(
      sourceCacheList,
      repository,
      SOURCE_PROVIDER_PLATFORM.GITLAB,
      handleNotifyIDEContextReset,
    );
  };

  onDeleteSourceCacheSuccess = repository => {
    console.log('onDeleteSourceCacheSuccess; props: ', this.props);
    this.props.getSourceCacheList(
      this.props.activeWorkspace.workspace.workspace.id,
      this.onGetSourceCacheListSuccess,
      this.onGetSourceCacheListError,
    );
    toast.success(`We succesfully delete your repository; ${repository}`, ToastConfig);
  };

  onDeleteSourceCacheError = () => {
    console.log('onDeleteSourceCacheError');
  };

  //////////////////////////////
  // Disconnect source provider - GITLAB
  handleDisconnectGitlabSourceModalOpen = () => {
    this.setState({
      isDisconnectRepoModalOpen: true,
    });
  };

  handleDisconnectGitlabSourceModalClose = () => {
    this.setState({
      isDisconnectRepoModalOpen: false,
    });
  };

  renderDisconnectGitlabSourceModal = () => {
    const { deleteSourceAccountFetching, sourceAccountListFetching } = this.props.sourceProviderAccount;

    const { isDisconnectRepoModalOpen } = this.state;
    const disconnectSourceText =
      'You will not continue debugging your GitLab repositories. Tracepoints, events will be lost after disconnection. Do you want to continue?';
    return (
      <Modal className="disconnect-source-repo-modal" open={isDisconnectRepoModalOpen}>
        <Modal.Content>
          <div className="disconnect-source-content">
            <div className="disconnect-source-title">
              <span>
                <Icon color="yellow" name="warning sign" />
              </span>
              <span>Warning</span>
            </div>
            <div className="disconnect-source-text">
              <span>{disconnectSourceText}</span>
            </div>
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={this.handleDisconnectGitlabSourceModalClose}>
            Cancel
          </Button>
          <Button
            primary
            onClick={this.handleContinueDisconnectSourceClick}
            loading={deleteSourceAccountFetching || sourceAccountListFetching}
            disabled={deleteSourceAccountFetching || sourceAccountListFetching}
          >
            Let's continue
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  handleContinueDisconnectSourceClick = () => {
    const { sourceAccountList } = this.props.sourceProviderAccount;
    const connectedGitlabSource = isSourceProviderConnected(sourceAccountList, SOURCE_PROVIDER_PLATFORM.GITLAB);

    console.log(
      'Gitlab disconnect clicked!; connectedGitlabSource, sourceAccountList: ',
      connectedGitlabSource,
      sourceAccountList,
    );

    if (connectedGitlabSource) {
      this.props.deleteSourceProviderAccount(
        connectedGitlabSource.id,
        this.onDeleteSourceProviderAccountSuccess,
        this.onDeleteSourceProviderAccountError,
      );
    }
  };

  onDeleteSourceProviderAccountSuccess = () => {
    console.log('onDeleteSourceProviderAccountSuccess; props, state: ', this.props, this.state);
    // Clear selected source cache, source provider disconnected then no need for selected source cahce!
    this.props.flushSelectedGitSourceCache();

    this.props.getSourceProviderAccountList(
      this.onGetSourceProviderAccountListSuccess,
      this.onGetSourceProviderAccountListError,
    );

    // Gitlab app revoke is not possible
    // https://stackoverflow.com/questions/51438620/gitlab-api-to-revoke-authorized-applications
    toast.error(
      `It is failed to delete your GitLab OAuth App for Sidekick. You may want to delete it from your gitlab.com account using GitLab Profile>Settings>Applications before connecting Sidekick again!`,
      ToastConfig,
    );

    this.handleDisconnectGitlabSourceModalClose();
  };

  onDeleteSourceProviderAccountError = () => {
    console.log('onDeleteSourceProviderAccountError');
  };

  render() {
    console.log('SourceGitlabAccountList, render; props, state: ', this.props, this.state);
    const { isSourceRepoModalOpen, selectedUpdateSource } = this.state;
    const isAddUpdateModalOpen = isSourceRepoModalOpen !== MODAL_STATE.CLOSED;

    return (
      <div className="source-provider-repos-wrapper">
        {this.renderGitlabSourceConnector()}
        {this.renderGitlabSourceRepoList()}

        {isAddUpdateModalOpen && (
          <AddUpdateRepoModalContainer
            isModalAddOrUpdate={isSourceRepoModalOpen}
            selectedSourceCacheForModal={selectedUpdateSource}
            gitSourceProvider={SOURCE_PROVIDER_PLATFORM.GITLAB}
            isOpen={isAddUpdateModalOpen}
            onClose={this.handleSourceModalClose}
          />
        )}
        {this.renderDisconnectGitlabSourceModal()}
      </div>
    );
  }
}
