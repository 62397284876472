import {
  LEAVE_WORKSPACE_FULFILLED,
  LEAVE_WORKSPACE_PENDING,
  LEAVE_WORKSPACE_REJECTED,
} from '../constants/workspace-leave';

const leaveWorkspaceInitialState = {
  leaveWorkspaceResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function leaveWorkspace(state = leaveWorkspaceInitialState, action) {
  switch (action.type) {
    case LEAVE_WORKSPACE_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case LEAVE_WORKSPACE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        leaveWorkspaceResult: action.payload.data,
      };
    case LEAVE_WORKSPACE_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
