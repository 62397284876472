import { connect } from 'react-redux';
import { ThundraSourceEditor } from 'components';
import {
  addWaitingToSidekickAction,
  notifySidekickActionEdit,
  notifySidekickActionEventsFocus,
  openAppsAndTracePointsTab,
  toggleSandboxStep,
} from 'store/actions';

import { send as brokerSend } from '@giantmachines/redux-websocket';
import { generateUUID } from 'utils/object-util';

function mapStateToProps(store) {
  return {
    broker: store.broker,
    appsAndTracePoints: store.appsAndTracePoints,
    sourceTabs: store.sourceTabs,
    onboarding: store.onboardingV2,
    activeWorkspace: store.activeWorkspace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleBrokerSend(data) {
      data.id = generateUUID();
      dispatch(addWaitingToSidekickAction({ ...data }));
      dispatch(brokerSend(data));
    },
    toggleSandboxStep(stepId) {
      dispatch(toggleSandboxStep(stepId));
    },
    handleOpenAppsAndTracePointsTab() {
      dispatch(openAppsAndTracePointsTab());
    },
    handleSidekickActionEdit(sidekickAction) {
      dispatch(notifySidekickActionEdit(sidekickAction));
    },
    handleSidekickActionEventsFocus(sidekickAction, getReference, type) {
      dispatch(notifySidekickActionEventsFocus(sidekickAction, getReference, type));
    },
  };
}

export const ThundraSourceEditorContainer = connect(mapStateToProps, mapDispatchToProps)(ThundraSourceEditor);
