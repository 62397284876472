import React, { Component } from 'react';
import { Frameworks } from './Frameworks';
import './QuickStart.scss';
import { InstructionsModal } from './Instructions';
import axios from 'axios';
import { LoadingPage } from '../CallbackPage';

export class QuickStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFramework: null,
      quickStartData: null,
      loading: true,
    };
  }

  componentDidMount() {
    axios.get('https://runsidekick.github.io/sidekick-deployment-examples/data/examples.json').then(res => {
      const data = res.data;
      Object.entries(data).forEach(([key, val]) => {
        let languageObject = data[key];
        Object.entries(languageObject).forEach(([lkey, lvalue]) => {
          languageObject[lkey].steps.forEach(
            step => (step.action = step.action.replace('<YOUR-SIDEKICK-API-KEY>', this.props.apiKey)),
          );
        });
      });
      this.setState({
        quickStartData: res.data,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ position: 'relative', height: '300px' }}>
          <LoadingPage />
        </div>
      );
    }
    const { selectedFramework } = this.state;
    return (
      <div className={'quick-start-container'}>
        <Frameworks
          onFrameworkClicked={framework => this.setState({ selectedFramework: framework })}
          quickStartData={this.state.quickStartData}
        />
        <InstructionsModal
          modalOpen={selectedFramework !== null}
          framework={this.state.selectedFramework}
          onClose={() => this.setState({ selectedFramework: null })}
        />
      </div>
    );
  }
}
