import { connect } from 'react-redux';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { getWorkspace, inviteToWorkspace, kickWorkspaceAccount, updateWorkspaceAccount } from 'store/actions';
import { UsersTab } from 'components';

function mapStateToProps(store) {
  return {
    activeWorkspace: store.activeWorkspace,
    userAccount: store.userAccount,
    invitationResult: store.inviteToWorkspace,
    updateAccountResult: store.updateWorkspaceAccount,
    kickWorkspaceAccountResult: store.kickWorkspaceAccount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWorkspace(workspaceId) {
      dispatch(getWorkspace(workspaceId));
    },
    inviteToWorkspace(workspaceId, emailList, onSuccess, onError) {
      dispatchWithExceptionHandler(inviteToWorkspace(workspaceId, emailList), false, onSuccess, onError);
    },
    kickWorkspaceAccount(workspaceId, accountId, onSuccess, onError) {
      dispatchWithExceptionHandler(kickWorkspaceAccount(workspaceId, accountId), false, onSuccess, onError);
    },
    updateWorkspaceAccount(workspaceId, accountId, role, onSuccess, onError) {
      dispatchWithExceptionHandler(updateWorkspaceAccount(workspaceId, accountId, role), false, onSuccess, onError);
    },
  };
}

export const UsersTabContainer = connect(mapStateToProps, mapDispatchToProps)(UsersTab);
