import { connect } from 'react-redux';
import { ErrorSnapshots } from 'components';
import { clearErrorSnapshots } from 'store/actions';

function mapStateToProps(store) {
  return {
    broker: store.broker,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleErrorSnapshotsClear() {
      dispatch(clearErrorSnapshots());
    },
  };
}

export const ErrorSnapshotsContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorSnapshots);
