import {
  GET_USER_ACCOUNT_FULFILLED,
  GET_USER_ACCOUNT_PENDING,
  GET_USER_ACCOUNT_REJECTED,
} from 'store/constants/user-account';

const initialState = {
  userAccount: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function userAccount(state = initialState, action) {
  switch (action.type) {
    case GET_USER_ACCOUNT_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_USER_ACCOUNT_FULFILLED:
      console.log('GET USER ACCOUNT FULFILLED REDUCER');
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        userAccount: action.payload.data,
      };
    case GET_USER_ACCOUNT_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    default:
      return state;
  }
}
