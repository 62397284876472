import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { SAVE_WORKSPACE_SETTINGS } from 'store/constants/workspace-settings-save';

export const saveWorkspaceSettings = (workspaceId, workspaceSettingsInfo) => {
  return {
    type: SAVE_WORKSPACE_SETTINGS,
    payload: axios.put(CONFIG.apiUrl + `/workspaces/${workspaceId}/settings`, workspaceSettingsInfo, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};