import {
  ADD_ONBOARDING_SOURCE_CACHE,
  ADD_SOURCE_CACHE,
  DELETE_SOURCE_CACHE,
  FLUSH_SELECTED_GIT_SOURCE_CACHE,
  FLUSH_SOURCE_CACHE_LIST,
  GET_SOURCE_CACHE_LIST,
  GET_SOURCE_LIST_LIMIT,
  REMOVE_ONBOARDING_SOURCE_CACHE,
  SELECT_GIT_SOURCE_CACHE,
  UPDATE_SOURCE_CACHE,
} from 'store/constants/source-cache';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

// selectedSource: {
// branch,
// commit,
// createdAt,
// id,
// owner,
// platform,
// repository,
// sourceAccountId,
// }

export const selectGitSourceCache = selectedSource => {
  // console.log('actions, selectGitSourceCache; selectedSource: ', selectedSource);
  return {
    type: SELECT_GIT_SOURCE_CACHE,
    payload: selectedSource,
  };
};

export const flushSelectedGitSourceCache = () => {
  console.log('actions, flushSelectedGitSourceCache');
  return {
    type: FLUSH_SELECTED_GIT_SOURCE_CACHE,
  };
};

export const flushSelectedGitSourceCacheList = () => {
  console.log('actions, flushSelectedGitSourceCache');
  return {
    type: FLUSH_SOURCE_CACHE_LIST,
  };
};

export const getSourceListLimit = () => {
  // console.log('actions, GET_SOURCE_LIST_LIMIT');
  return {
    type: GET_SOURCE_LIST_LIMIT,
    payload: axios.get(CONFIG.apiUrl + '/sources/sourcelimit', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};

export function getSourceCacheList(workspaceId) {
  return {
    type: GET_SOURCE_CACHE_LIST,
    payload: axios.get(CONFIG.apiUrl + `/sources?workspaceId=${workspaceId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

// Source {
// private String id;
// @NotNull
// private String sourceAccountId;
// @NotNull
// private Platform platform;
// @NotNull
// private String owner;
// @NotNull
// private String repository;
// private String branch;
// private String commit;
// @JsonSerialize(using = LocalDateTimeSerializer.class)
// @JsonDeserialize(using = LocalDateTimeDeserializer.class)
// private LocalDateTime createdAt;
// }
export function addSourceCache(source) {
  // console.log('action, SC, addSourceCache; source: ', source);
  return {
    type: ADD_SOURCE_CACHE,
    payload: axios.post(CONFIG.apiUrl + `/sources`, source, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function addOnboardingSourceCache(onboardingSource) {
  console.log('action, SC, addOnboardingSourceCache; onboardingSource: ', onboardingSource);
  return {
    type: ADD_ONBOARDING_SOURCE_CACHE,
    payload: onboardingSource,
  };
}

export function deleteOnboardingSourceCache(sourceId) {
  console.log('action, SC, deleteOnboardingSourceCache; sourceId: ', sourceId);

  return {
    type: REMOVE_ONBOARDING_SOURCE_CACHE,
    payload: new Promise((resolve, reject) => {
      resolve();
    }),
  };
}

export function updateSourceCache(sourceId, source) {
  // console.log('action, SC, updateSourceCache; sourceId, source: ', sourceId, source);
  return {
    type: UPDATE_SOURCE_CACHE,
    payload: axios.put(CONFIG.apiUrl + `/sources/${sourceId}`, source, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function deleteSourceCache(sourceId, workspaceId) {
  console.log('action, SC, deleteSourceCache; source: ', sourceId);
  return {
    type: DELETE_SOURCE_CACHE,
    payload: axios.delete(CONFIG.apiUrl + `/sources/${sourceId}/${workspaceId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
