import { connect } from 'react-redux';
import { AppManagement } from 'components';
import { selectApps } from 'store/actions';
import { send as brokerSend } from '@giantmachines/redux-websocket';

function mapStateToProps(store) {
  return {
    onboardingStatus: store.onboardingV2,
    broker: store.broker,
    appsAndTracePoints: store.appsAndTracePoints,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleBrokerSend(data) {
      dispatch(brokerSend(data));
    },
    handleSelectApps(selectedApps) {
      dispatch(selectApps(selectedApps));
    },
  };
}

export const AppManagementContainer = connect(mapStateToProps, mapDispatchToProps)(AppManagement);
