import {
  SAVE_SUBSCRIPTION_FULFILLED,
  SAVE_SUBSCRIPTION_PENDING,
  SAVE_SUBSCRIPTION_REJECTED,
} from '../constants/billing-save-subscription';

const initialState = {
  saveSubscriptionResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function saveSubscription(state = initialState, action) {
  switch (action.type) {
    case SAVE_SUBSCRIPTION_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case SAVE_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        saveSubscriptionResult: action.payload.data,
      };
    case SAVE_SUBSCRIPTION_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
