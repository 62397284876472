import React, { Component } from 'react';
import { AsyncIDEPageContainer } from 'containers';
import { LoadingPage } from '../CallbackPage';
import { generateFullGitSourceURLPath, generateSourceMetaDataFromEntityId } from 'utils/string-util';
import './SandboxPage.scss';
import { SandboxMenu } from './SandboxMenu';
import { findActiveWorkspaceId } from 'utils/workspace-util';
import routeList from 'routes';
import { SidekickConfirmModal } from '../SidekickConfirmModal/SidekickConfirmModal';

export const ONBOARDING_CONFIG = {
  sourceId: 'onboarding-sample-id',
  sourceAccountId: 'onboarding-sample-source-account-id',
  sourcePlatform: 'GITHUB',
  sourceOwner: 'runsidekick',
  sourceRepository: 'sidekick-demo-todo-app-java',
  sourceBranch: 'master',
  sourceCommitSHA: '3b44b13e54fe0f7390c85201b2d0fd2a25327736',
  fileFullPath:
    'https://api.github.com/repos/runsidekick/sidekick-demo-todo-app-java/contents/src/main/java/com/runsidekick/todo/service/TodoServiceImpl.java?ref=3b44b13e54fe0f7390c85201b2d0fd2a25327736',
  lineNo: 27,
  applicationKey:
    'eyJuYW1lIjoic2lkZWtpY2stZGVtby10b2RvLWFwcC1qYXZhIiwidmVyc2lvbiI6IjEuMC4wIiwic3RhZ2UiOiJvbmJvYXJkaW5nIiwiY3VzdG9tVGFncyI6e319',
};

class SandboxPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      confirmModalOpen: false,
    };
    this.onOpen();
  }

  onOpen = () => {
    this.resetIdeContext();
    this.addPublicRepo();
    this.props.changeSandboxMode(true);

    window.addEventListener('beforeunload', this.onClose);

    this.props.getDemoAccount(() => {
      this.storeAuthentication();
      this.props.getUserAccount();
      this.getWorkspacesAndSetActive();
    });
  };

  onClose = () => {
    const userAccess = localStorage.getItem('userAccess');
    const userId = localStorage.getItem('userId');
    userAccess && localStorage.setItem('idToken', userId);
    userId && localStorage.setItem('accessToken', userAccess);
    localStorage.removeItem('userId');
    localStorage.removeItem('userAccess');
    localStorage.removeItem('workspaceId');
    this.resetIdeContext();
    this.props.clearSandboxSteps();
    this.props.changeSandboxMode(false);
    this.props.getUserAccount();
    this.props.handleSelectApps([]);
    window.removeEventListener('beforeunload', this.onUnload);
  };

  getWorkspacesAndSetActive = () => {
    this.props.getWorkspaces(response => {
      this.props.getActiveWorkspace(findActiveWorkspaceId(response.value.data.workspaceDtos), () => {
        this.setState({ loading: false });
      });
    });
  };
  addPublicRepo = () => {
    //Redux Send Message To FileExplorer Load Public Repo
    console.log('addPublicRepo');
    const source = {
      id: ONBOARDING_CONFIG.sourceId,
      sourceAccountId: ONBOARDING_CONFIG.sourceAccountId,
      platform: ONBOARDING_CONFIG.sourcePlatform,
      owner: ONBOARDING_CONFIG.sourceOwner,
      repository: ONBOARDING_CONFIG.sourceRepository,
      branch: ONBOARDING_CONFIG.sourceBranch,
      commit: ONBOARDING_CONFIG.sourceCommitSHA,
    };

    // Here add onboarding repo to sourceCacheList store.
    this.props.addOnboardingSourceCache(source);
  };

  sourceFocus = () => {
    const { fileFullPath: fileName, lineNo } = ONBOARDING_CONFIG;

    const metaData = generateSourceMetaDataFromEntityId(fileName);
    const { sourceProvider, owner, repo, sourcePath, sha } = metaData;

    this.props.getUserRepoContent(sourceProvider, owner, repo, sourcePath, sha, () => {
      const fileFullPath = generateFullGitSourceURLPath(sourceProvider, owner, repo, sourcePath, sha);
      this.props.handleSourceFocus(fileFullPath, lineNo);
    });
  };

  componentWillUnmount() {
    this.onClose();
  }
  startFirstTour() {
    window.Intercom('startTour', 365341);
  }
  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (prevState.loading !== this.state.loading) {
      setTimeout(() => {
        this.props.handleSelectApps([ONBOARDING_CONFIG.applicationKey]);
        this.startFirstTour();
      }, 4000);

      setTimeout(this.sourceFocus, 4000);
      setTimeout(this.props.openTracepointEvents, 4000);
    }
  }

  storeAuthentication = () => {
    const { accessToken, idToken } = this.props.onboarding.demoAccount;
    const activeWorkspaceId = this.props.activeWorkspace.workspace?.workspace?.id;

    const userAccess = localStorage.getItem('userAccess');
    const userId = localStorage.getItem('userId');
    const workspaceId = localStorage.getItem('workspaceId');

    if (workspaceId == null && activeWorkspaceId) {
      localStorage.setItem('workspaceId', activeWorkspaceId);
    }

    if (userAccess == null && userId == null) {
      localStorage.setItem('userAccess', localStorage.getItem('accessToken'));
      localStorage.setItem('userId', localStorage.getItem('idToken'));
    }
    localStorage.setItem('idToken', idToken);
    localStorage.setItem('accessToken', accessToken);
  };

  resetIdeContext = () => {
    this.props.flushSelectedGitSourceCacheList();
    this.props.flushSelectedGitSourceCache();
    this.props.handleUserRepoContentReset();
    localStorage.removeItem('IDEModel');
  };

  confirmModalAction = () => {
    const workspaceId = localStorage.getItem('workspaceId');
    const status = this.props.workspaceOnboardingStatus.onboardStatus.status;
    const accessToken = localStorage.getItem('userAccess');
    if (status !== 'PASSED' && workspaceId != null) {
      this.props.updateOnboardStatus('PASSED', workspaceId, accessToken);
    }
    this.props.history.push(routeList.ide.path);
    this.onClose();
  };

  getLeaveTutorialModal = () => {
    let { tracePointEvents } = this.props.broker;

    let content =
      tracePointEvents.length === 0
        ? "You haven't collected any events, are you sure you want to leave?"
        : 'You are leaving the tutorial, are you sure?';
    return (
      <SidekickConfirmModal
        modalOpen={this.state.confirmModalOpen}
        onClose={() => this.setState({ confirmModalOpen: false })}
        content={content}
        confirmModalAction={this.confirmModalAction}
      />
    );
  };

  render() {
    if (this.state.loading) {
      return <LoadingPage caption={'Creating a demo account for you. This may take 2-3 minutes.'} />;
    }
    return (
      <div className={'sandbox-page-container'}>
        <SandboxMenu
          steps={this.props.onboarding.sandboxSteps}
          toggleStep={stepId => {
            this.props.toggleSandboxStep(stepId);
            window.Intercom('startTour', stepId);
          }}
          startDebuggingAction={() => {
            this.setState({ confirmModalOpen: true });
          }}
        />
        {this.getLeaveTutorialModal()}
        <AsyncIDEPageContainer />
      </div>
    );
  }
}

export default SandboxPage;
