import React, { Component } from 'react';
import routeList from 'routes';
import { LinearIntegrationClient } from './LinearIntegrationClient';
import { CONFIG } from 'utils/config-util';
import { JiraIntegrationClient } from './JiraIntegrationClient';

export class IssueManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasIntegration: false,
      integrationConfig: null,
    };
  }

  componentDidMount() {
    const { search } = this.props.location;
    const urlParams = new URLSearchParams(search);

    if (urlParams.has('code')) {
      localStorage.setItem('code', urlParams.get('code'));
      localStorage.setItem('state', urlParams.get('state'));
      this.props.history.replace({ pathname: routeList.ide.path });
      return;
    }
    const code = localStorage.getItem('code');
    const state = localStorage.getItem('state');
    if (code && state) {
      if (![CONFIG.ISSUE_MANAGEMENT.LINEAR.RANDOM_STATE, CONFIG.ISSUE_MANAGEMENT.JIRA.RANDOM_STATE].includes(state)) {
        return;
      }
      localStorage.removeItem('code');
      localStorage.removeItem('state');
      this.setState({
        hasIntegration: true,
        integrationConfig: { code, state },
      });
    }
  }

  closeModal = () => {
    this.props.flushEventToSend();
    this.setState({ hasIntegration: false, integrationConfig: null });
  };

  render() {
    const { hasIntegration, integrationConfig } = this.state;
    if (!hasIntegration) {
      return null;
    }
    return (
      <>
        {CONFIG.ISSUE_MANAGEMENT.LINEAR.RANDOM_STATE === integrationConfig.state && (
          <LinearIntegrationClient
            code={integrationConfig.code}
            getAccessToken={this.props.getLinearAccessToken}
            issueManagementToken={this.props.issueManagementToken}
            closeModal={this.closeModal}
          />
        )}
        {CONFIG.ISSUE_MANAGEMENT.JIRA.RANDOM_STATE === integrationConfig.state && (
          <JiraIntegrationClient
            flushJiraActions={this.props.flushJiraActionStates}
            code={integrationConfig.code}
            getAccessToken={this.props.getJiraAccessToken}
            issueManagementToken={this.props.issueManagementToken}
            createJiraIssue={this.props.createJiraIssue}
            createJiraIssueComment={this.props.createJiraIssueComment}
            getJiraOrganisations={this.props.getJiraOrganisations}
            getJiraProjects={this.props.getJiraProjects}
            getJiraIssueTypes={this.props.getJiraIssueTypes}
            issueManagementJira={this.props.issueManagementJira}
            closeModal={this.closeModal}
          />
        )}
      </>
    );
  }
}
