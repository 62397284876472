import { GET_TRACEMAP } from 'store/constants/tracemap';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';

export function getTraceMap(traceId) {
  let params = '';

  if (traceId !== undefined) {
    if (params.length === 0) params += '?';
    else params += '&';
    params += 'traceId=' + traceId;
  }

  return {
    type: GET_TRACEMAP,
    payload: axios.get(CONFIG.apiUrl + '/traces/map' + params, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
