import React, { Component } from 'react';
import { LoadingPage } from '../CallbackPage';

export class UnsubscribeMailPage extends Component {
  constructor(props) {
    super(props);
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const email = params.get('email');
    this.state = {
      message: '',
    };

    this.props.unsubscribeMail(
      token,
      email,
      () => this.setState({ message: 'Unsubscribe successfully.' }),
      () => this.setState({ message: 'An error occurred, Please contact us.' }),
    );
  }

  render() {
    if (this.props.unsubscribeMailResult.fetching) {
      return <LoadingPage />;
    }

    return (
      <div className="error-page-wrapper">
        <div className="error-page-content">
          {/*<div className="error-pic">{this.renderConnectedSVG()}</div>*/}
          <div className="error-title">
            <span>{this.state.message}</span>
          </div>
        </div>
      </div>
    );
  }
}
