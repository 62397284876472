import { sha256text } from 'utils/hash-util';
import { generatePathPrefixFromContextMetaData } from './string-util';

let sourceFiles = [];

export function setSourceFiles(sourceGitFiles) {
  // console.log("source-files, setSourceFiles; sourceGitFiles: ", sourceGitFiles);

  sourceFiles = [];
  const sourceCheckMap = {}; //Check File in source-files

  sourceGitFiles.forEach(el => {
    const sourceBase64 = el.content;
    if (typeof sourceBase64 === 'string') {
      const checkedSource = sourceBase64.replace(/\s/g, ''); //Invalid chars Check https://stackoverflow.com/questions/9786508/javascript-atob-returning-string-contains-an-invalid-character
      const sourceString = atob(checkedSource);
      const sourceSha256Hex = sha256text(sourceString);

      // Here we differentiate opened source files according to el.sha values.
      // It was using sourceSha256Hex before but that fails when 2 same files exists in different repos!
      if (!sourceCheckMap[el.sha]) {
        sourceCheckMap[el.sha] = true;
        sourceFiles.push({
          sourceCode: sourceString,
          sourceSha256Hex: sourceSha256Hex,
          sourcePath: el.url,
          name: el.name,
        });
      }
    }
  });
}

export function filterSourceFilesWithContextMetaData(contextMetaData) {
  const pathPrefix = generatePathPrefixFromContextMetaData(contextMetaData);
  const filtered = sourceFiles.filter(el => !el.sourcePath.includes(pathPrefix));
  return filtered;
}

export function getSourceId(index) {
  // console.log("source-file, getSourceId; index, sourceFiles: ", index, sourceFiles);
  if (index < sourceFiles.length) {
    const sourceNode = sourceFiles[index];
    const id = `src#${sourceNode.sourcePath}`;
    return id;
  } else {
    //Problem with SourceFiles
    const id = `src#NotFound-${Math.random()}`;
    return id;
  }
}

export function getSourceName(index) {
  if (index < sourceFiles.length) {
    const sourceNode = sourceFiles[index];
    if (sourceNode) {
      return sourceNode.name;
    }
  }
  return 'Unknown';
}

export function getSourceCode(sourcePath) {
  const sourceNode = sourceFiles.find(el => el.sourcePath === sourcePath);
  if (sourceNode) {
    return sourceNode.sourceCode;
  }
  return '';
}

export function getSourceHashCode(sourcePath) {
  const sourceNode = sourceFiles.find(el => el.sourcePath === sourcePath);
  if (sourceNode) {
    return sourceNode.sourceSha256Hex;
  }
  return '';
}

export function getClassName(sourcePath) {
  if (sourcePath.includes('.java')) {
    const arr = sourcePath.replace('.java', '').split('java/');
    if (arr.length > 1) {
      const secondPart = arr[1];
      const className = secondPart.replaceAll('/', '.');
      return className;
    }
  }
  return sourcePath;
}

export function getSourcePath(className) {
  const sourceNode = sourceFiles.find(el => getClassName(el.sourcePath) === className);
  if (sourceNode) {
    return sourceNode.sourcePath;
  }
  return null;
}

export function getSourceIndex(sourcePath) {
  console.log("source-files, getSourceIndex; sourcePath, sourceFiles: ", sourcePath, sourceFiles);
  const sourceIndex = sourceFiles.findIndex(el => el.sourcePath === sourcePath);
  // Check below logs when https://thundra.fibery.io/favorites/Software_Development/Cycle-Board---My-Tasks-140#Software_Development/bug/When-multi-repos-connected-to-Sidekick,-it-cannot-browse-files-after-switching-the-another-repo-35 reproduced
  if (sourceIndex < 0) {
    console.error('Index is out of bounds!', sourcePath);
  }
  return sourceIndex;
}
