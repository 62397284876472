import { connect } from 'react-redux';
import { getUserRepoBranchCommits } from 'store/actions';
import { SourceRepoCommitSelector } from 'components/GitConnect/SourceRepoCommitSelector';

const mapStateToProps = store => ({
  gitCommitData: store.gitCommits,
});

const mapDispatchToProps = dispatch => ({
  getUserRepoBranchCommits: (sourceProvider, owner, repo, branch) =>
    dispatch(getUserRepoBranchCommits(sourceProvider, owner, repo, branch)),
});

export const SourceRepoCommitSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(SourceRepoCommitSelector);
