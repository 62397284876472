import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { CONFIG } from 'utils/config-util';

export class SandboxMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sandboxStepsCollapsed: false,
      adblockWarningCollapsed: false,
    };
  }
  renderSteps = () => {
    return this.props.steps.map(step => {
      return (
        <div className={'step'} onClick={() => this.props.toggleStep(step.tourId)}>
          {step.completed ? <Icon name={'check circle'} /> : <Icon name={'circle outline'} />}
          <div className={'subtitle-s5'}>{step.name}</div>
        </div>
      );
    });
  };

  calculateCompletedSteps = () => {
    const steps = [...this.props.steps];
    const completed = steps.filter(step => step.completed);
    return `${completed.length}/${steps.length} Completed`;
  };
  render() {
    return (
      <div className={'sandbox-menu-container'}>
        <div className={'sandbox-menu'}>
          <div className={'menu-header'}>
            Sidekick Sandbox
            <Icon
              key={'dropdown'}
              name={'dropdown'}
              className={this.state.sandboxStepsCollapsed ? ' collapsed' : ''}
              onClick={() =>
                this.setState(prevState => ({
                  sandboxStepsCollapsed: !prevState.sandboxStepsCollapsed,
                }))
              }
            />
          </div>
          {!this.state.sandboxStepsCollapsed && (
            <div>
              <div className={'step-info subtitle-s5'}>
                {this.calculateCompletedSteps()}
                <Button
                  className={'secondary open-app-button'}
                  onClick={() => {
                    window.open(CONFIG.TODO_APP_URL, '_blank');
                  }}
                  content={'Open app'}
                />
              </div>
              <div className={'menu-steps'}>{this.renderSteps()}</div>
              <div className={'menu-caption subtitle-s5'}>Find your own way and try Sidekick with your data! </div>
              <Button className={'get-started-button'} onClick={this.props.startDebuggingAction}>
                Complete Tutorial
              </Button>
            </div>
          )}
        </div>
        <div className={'adblock-warning-guide'}>
          <div className={'menu-header'}>
            Alternative tour
            <Icon
              key={'dropdown'}
              name={'dropdown'}
              className={this.state.adblockWarningCollapsed ? ' collapsed' : ''}
              onClick={() =>
                this.setState(prevState => ({
                  adblockWarningCollapsed: !prevState.adblockWarningCollapsed,
                }))
              }
            />
          </div>
          {!this.state.adblockWarningCollapsed && (
            <>
              <div className={'subtitle-s5'} style={{ marginBottom: '3px' }}>
                Some browser settings & extensions might prevent our product tour from starting. You can either try
                again after disabling your ad blocker or continue using the deck below.
              </div>
              <iframe
                width="100%"
                height="200px"
                src="https://pitch.com/embed/dbee5d01-3d4b-4a2a-9a2c-92bb2db0cf6e"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
