import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/mode/javascript/javascript';
import './SourceEditor.scss';
import { hasArrayElement } from 'utils/array-util';
import { Popup } from 'semantic-ui-react';

import 'codemirror/addon/selection/active-line';
import { PutSidekickActionPopup } from '../PutSidekickActionPopup';

function getElementsByClassName(className) {
  return window.document.getElementsByClassName(className);
}

function makeMarker(lineNo, disabled, connected, waiting, type) {
  let iconClass = disabled ? 'icon-gutter-tracepoint-disable' : 'icon-gutter-tracepoint-enable';
  iconClass = connected ? iconClass : 'icon-gutter-tracepoint-disconnected';
  iconClass = waiting ? 'icon-gutter-tracepoint-waiting' : iconClass;
  let color = type === '#8D80DC';
  if (type === 'LP') iconClass = iconClass.replace('tracepoint', 'logpoint');
  if (type === 'BOTH') iconClass = 'icon-gutter-both';
  const marker = document.createElement('div');
  marker.className = 'thundra-sidekick-tracepoint';
  marker.style.color = color;
  marker.dataset.lineNo = lineNo;
  marker.style.paddingTop = '2px';
  marker.style.marginRight = '1px';
  marker.style.fontSize = '10px';
  marker.innerHTML = `<i class="${iconClass}"/>`;
  return marker;
}

function refreshLayout() {
  window.setTimeout(function () {
    const arrElements = getElementsByClassName('CodeMirror');
    for (let i = 0; i < arrElements.length; i++) {
      const el = arrElements[i];
      if (el && el.CodeMirror) {
        el.CodeMirror.refresh();
      }
    }
  }, 0);
}

function createContextFromEvent(e) {
  const left = e.clientX;
  const top = e.clientY;
  const right = left + 1;
  const bottom = top + 1;

  return {
    getBoundingClientRect: () => ({
      left,
      top,
      right,
      bottom,

      height: 0,
      width: 0,
    }),
  };
}

export class SourceEditor extends Component {
  constructor(props) {
    super(props);
    this._editor = null;
    this.contextRef = React.createRef();
    this.state = {
      contextLineNo: -1,
      contextOpen: false,
      contextOpenCounter: 0,
      putSidekickActionMenuOpen: false,
      contextLineNumber: -1,
      sidekickActionType: 'TP',
    };
  }

  componentDidMount() {
    const { tracePoints, tracePointsWaiting, logPoints, logPointsWaiting, connectStatusMap } = this.props;
    this.markUpdateGutters(tracePoints, tracePointsWaiting, logPoints, logPointsWaiting, connectStatusMap);
  }

  sidekickActionUpdateCheck = (cSidekickActions, nSidekickActions) => {
    const cSidekickActionLineNo = cSidekickActions.map(el => el.lineNo);
    const nSidekickActionLineNo = nSidekickActions.map(el => el.lineNo);

    const addedMarkers = nSidekickActionLineNo.filter(el => !cSidekickActionLineNo.includes(el));
    const deletedMarkers = cSidekickActionLineNo.filter(el => !nSidekickActionLineNo.includes(el));

    if (hasArrayElement(addedMarkers) || hasArrayElement(deletedMarkers)) {
      return true;
    }
    return false;
  };

  checkSidekickActionsWaiting = (cSidekickActionsWaiting, nSidekickActionsWaiting) => {
    const cSidekickActionsWaitingLineNo = cSidekickActionsWaiting.map(el => el.lineNo);
    const nSidekickActionsWaitingLineNo = nSidekickActionsWaiting.map(el => el.lineNo);

    const addedWMarkers = nSidekickActionsWaitingLineNo.filter(el => !cSidekickActionsWaitingLineNo.includes(el));
    const deletedWMarkers = cSidekickActionsWaitingLineNo.filter(el => !nSidekickActionsWaitingLineNo.includes(el));

    if (hasArrayElement(addedWMarkers) || hasArrayElement(deletedWMarkers)) {
      return true;
    }
    return false;
  };

  checkSidekickActionEnabledDisabled = (cSidekickActions, nSidekickActions) => {
    const cMarkersMap = {};
    let stateChange = false;
    cSidekickActions.forEach(el => (cMarkersMap[el.lineNo] = el));
    nSidekickActions.forEach(el => {
      const oldSidekickAction = cMarkersMap[el.lineNo];
      if (oldSidekickAction && oldSidekickAction.disabled !== el.disabled) {
        stateChange = true;
      }
    });
    return stateChange;
  };

  checkSidekickActionConnectedDisconnected = (cStatusMap, nStatusMap) => {
    for (const key in nStatusMap) {
      const newStatus = nStatusMap[key];
      const currentStatus = cStatusMap[key];
      if (newStatus !== currentStatus) {
        return true;
      }
    }
    return false;
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.lineNo !== nextProps.lineNo) {
      const lineNo = nextProps.lineNo > 0 ? nextProps.lineNo - 1 : 0;
      if (this._editor) this._editor.setCursor(lineNo);
    } else if (this.props.sourceTabChangeCount !== nextProps.sourceTabChangeCount) {
      if (this._editor) this._editor.setCursor(0);
    }

    if (this.props.layoutUpdateCount !== nextProps.layoutUpdateCount) {
      //Layout Update
      refreshLayout();
    }

    const {
      connectStatusMap: cStatusMap,
      tracePoints: cTracePoints,
      tracePointsWaiting: cTracePointsWaiting,
      logPointsWaiting: cLogPointsWaiting,
      logPoints: cLogPoints,
    } = this.props;
    const {
      connectStatusMap: nStatusMap,
      tracePoints: nTracePoints,
      tracePointsWaiting: nTracePointsWaiting,
      logPointsWaiting: nLogPointsWaiting,
      logPoints: nLogPoints,
    } = nextProps;

    if (this.props.sourceTabIndex !== nextProps.sourceTabIndex) {
      this.markUpdateGutters(nTracePoints, nTracePointsWaiting, nLogPoints, nLogPointsWaiting, nStatusMap);
      return;
    }

    //Tracepoint Gutter Panel Update Check
    if (
      this.sidekickActionUpdateCheck(cTracePoints, nTracePoints) ||
      this.sidekickActionUpdateCheck(cLogPoints, nLogPoints)
    ) {
      this.markUpdateGutters(nTracePoints, nTracePointsWaiting, nLogPoints, nLogPointsWaiting, nStatusMap);
      return;
    }

    //Check Tracepoint Disabled/Enabled
    let anyMarkersStateChange = false;
    anyMarkersStateChange =
      anyMarkersStateChange || this.checkSidekickActionEnabledDisabled(cTracePoints, nTracePoints);
    anyMarkersStateChange = anyMarkersStateChange || this.checkSidekickActionEnabledDisabled(cLogPoints, nLogPoints);

    //Check Tracepoint Connected/DisConnected
    anyMarkersStateChange =
      anyMarkersStateChange ||
      this.checkSidekickActionConnectedDisconnected(cStatusMap.tracePoints, nStatusMap.tracePoints);
    anyMarkersStateChange =
      anyMarkersStateChange ||
      this.checkSidekickActionConnectedDisconnected(cStatusMap.logPoints, nStatusMap.logPoints);

    if (anyMarkersStateChange) {
      this.markUpdateGutters(nTracePoints, nTracePointsWaiting, nLogPoints, nLogPointsWaiting, nStatusMap);
      return;
    }

    if (
      this.checkSidekickActionsWaiting(cTracePointsWaiting, nTracePointsWaiting) ||
      this.checkSidekickActionsWaiting(cLogPointsWaiting, nLogPointsWaiting)
    ) {
      this.markUpdateGutters(nTracePoints, nTracePointsWaiting, nLogPoints, nLogPointsWaiting, nStatusMap);
    }
  }

  handleContentChange = () => {
    //TODO Content Change Maybe fire...
  };

  clearGutter = () => {
    const doc = this._editor.doc;
    const arrElements = getElementsByClassName('thundra-sidekick-tracepoint'); //Return not Arr, return HTMLCollection and its live
    const currentLineNos = [];
    for (let el of arrElements) {
      const lineNo = parseInt(el.dataset.lineNo);
      currentLineNos.push(lineNo);
    }

    //This Steps Needed For HTMLCollections Live And DOM Deletion has side effects on For of...
    const lineCount = doc.lineCount();
    currentLineNos.forEach(lineNo => {
      if (lineNo > 0 && lineNo <= lineCount) {
        doc.cm.setGutterMarker(lineNo - 1, 'breakpoints', null);
      }
    });
  };

  markUpdateGutters = (tracePoints, tracePointsWaiting, logPoints, logPointsWaiting, connectStatusMap) => {
    this.clearGutter();
    this.markUpdateGutter(tracePoints, connectStatusMap.tracePoints, 'TP');
    this.markUpdateGutter(logPoints, connectStatusMap.logPoints, 'LP');

    const commonLines = tracePoints.filter(tp => logPoints.some(lp => tp.lineNo === lp.lineNo));
    this.markUpdateGutter(commonLines, {}, 'BOTH');

    this.handleLoadingStateSidekickActions(tracePointsWaiting, connectStatusMap.tracePoints, 'TP');
    this.handleLoadingStateSidekickActions(logPointsWaiting, connectStatusMap.logPoints, 'LP');
  };

  handleLoadingStateSidekickActions = (sidekickActionsWaiting, statusMap, type) => {
    const doc = this._editor.doc;

    sidekickActionsWaiting.forEach(el => {
      const lineCount = doc.lineCount();
      if (el.lineNo > 0 && el.lineNo <= lineCount) {
        const connected = statusMap[el.lineNo];
        const marker = makeMarker(el.lineNo, el.disabled, connected, true, type);
        doc.cm.setGutterMarker(el.lineNo - 1, 'breakpoints', marker);
      }
    });
  };

  markUpdateGutter = (sidekickActions, statusMap, type) => {
    const doc = this._editor.doc;

    //Last State Of SidekickAction from Broker.
    sidekickActions.forEach(el => {
      const lineCount = doc.lineCount();
      if (el.lineNo > 0 && el.lineNo <= lineCount) {
        const connected = statusMap[el.lineNo];
        const marker = makeMarker(el.lineNo, el.disabled, connected, false, type);
        doc.cm.setGutterMarker(el.lineNo - 1, 'breakpoints', marker);
      }
    });
  };

  handleContextMenuOpen(flag) {
    const currentOpenCounter = this.state.contextOpenCounter;
    const nextOpenCounter = currentOpenCounter + 1;
    this.setState({ contextOpen: flag, contextOpenCounter: nextOpenCounter });
  }

  handlePutSidekickActionMenuOpen(flag, lineNumber, sidekickActionType) {
    const currentOpenCounter = this.state.contextOpenCounter;
    const nextOpenCounter = currentOpenCounter + 1;
    this.setState({
      contextLineNumber: lineNumber,
      putSidekickActionMenuOpen: flag,
      contextOpen: false,
      contextOpenCounter: nextOpenCounter,
      sidekickActionType: sidekickActionType,
    });
  }

  handleOuterContextEvent = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleContextMenuItemClick(action) {
    this.props.handleContextClick(action);
    this.handleContextMenuOpen(false);
    this.state.putSidekickActionMenuOpen && this.handlePutSidekickActionMenuOpen(false, -1);
  }

  createContextMenuItems = (action, items) => {
    const headerText = action.payloadType === 'TP' ? 'TRACEPOINT' : 'LOGPOINT';
    const keySuffix = action.payloadType === 'TP' ? '-tp' : '-lp';
    items.push(
      <div
        key={'header' + keySuffix}
        className={'menu-section-title ' + headerText.toLowerCase()}
        onClick={() => {
          this.handleContextMenuItemClick({ ...action, actionType: 'REMOVE' });
        }}
      >
        {headerText}
      </div>,
    );
    const { disabled } = action.payload;
    items.push(
      <div
        key={'remove' + keySuffix}
        className={'menu-item-button'}
        onClick={() => {
          this.handleContextMenuItemClick({ ...action, actionType: 'REMOVE' });
        }}
      >
        Remove
      </div>,
    );

    if (disabled) {
      items.push(
        <div
          key={'enable' + keySuffix}
          className={'menu-item-button'}
          onClick={() => {
            this.handleContextMenuItemClick({ ...action, actionType: 'ENABLE' });
          }}
        >
          Enable
        </div>,
      );
    } else {
      items.push(
        <div
          key={'disable' + keySuffix}
          className={'menu-item-button'}
          onClick={() => {
            this.handleContextMenuItemClick({ ...action, actionType: 'DISABLE' });
          }}
        >
          Disable
        </div>,
      );
    }
    items.push(
      <div
        key={'edit' + keySuffix}
        className={'menu-item-button'}
        onClick={() => {
          this.handleContextMenuItemClick({ ...action, actionType: 'EDIT' });
        }}
      >
        Edit
      </div>,
    );

    items.push(
      <div
        key={'see-events' + keySuffix}
        className={'menu-item-button' + (action.payloadType === 'TP' ? ' last' : '')}
        onClick={() => {
          this.handleContextMenuItemClick({ ...action, actionType: 'EVENTS' });
        }}
      >
        See Events
      </div>,
    );
  };

  getContextMenuItems = () => {
    const items = [];
    const { contextLineNo } = this.state;
    const matchedTP = this.props.tracePoints.find(el => el.lineNo === contextLineNo);
    const matchedLP = this.props.logPoints.find(el => el.lineNo === contextLineNo);
    items.push(
      <div key={'sidekick-actions'} className={'menu-title source-context-header'}>
        SIDEKICK ACTIONS
      </div>,
    );

    if (matchedTP) {
      let action = { payload: matchedTP, payloadType: 'TP' };
      this.createContextMenuItems(action, items);
    }
    if (matchedLP) {
      let action = { payload: matchedLP, payloadType: 'LP' };
      this.createContextMenuItems(action, items);
    }
    if (matchedTP == null || matchedLP == null) {
      matchedTP == null &&
        items.push(
          <div
            key={'add-tracepoint'}
            className={'menu-item-button'}
            onClick={() => {
              this.handlePutSidekickActionMenuOpen(true, contextLineNo, 'TP');
            }}
          >
            Add Tracepoint
          </div>,
        );

      matchedLP == null &&
        items.push(
          <div
            key={'add-logpoint'}
            className={'menu-item-button'}
            onClick={() => {
              this.handlePutSidekickActionMenuOpen(true, contextLineNo, 'LP');
            }}
          >
            Add Logpoint
          </div>,
        );
    }

    return items;
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { sourceCode: cSourceCode } = this.props;
    const { sourceCode: nSourceCode } = nextProps;
    if (cSourceCode !== nSourceCode) {
      return true;
    }

    const { contextOpenCounter: cContextOpenCounter } = this.state;
    const { contextOpenCounter: nContextOpenCounter } = nextState;

    if (cContextOpenCounter !== nContextOpenCounter) {
      return true;
    }

    return false;
  }

  render() {
    const { sourceCode } = this.props;

    return (
      <div onContextMenu={this.handleOuterContextEvent}>
        <CodeMirror
          value={sourceCode}
          options={{
            mode: 'javascript',
            readOnly: true,
            theme: 'monokai',
            lineNumbers: true,
            gutters: ['CodeMirror-linenumbers', 'breakpoints'],
            fixedGutter: true,
            styleActiveLine: true,
          }}
          onChange={(editor, data, value) => {
            //TODO for Hotfix Code Change
          }}
          onContextMenu={(editor, event) => {
            const _this = this;
            setTimeout(() => {
              const editorLineNo = editor.getCursor().line + 1;
              _this.setState({ contextLineNo: editorLineNo });
              _this.contextRef = createContextFromEvent(event);
              _this.handleContextMenuOpen(true);
            }, 0);
            return false;
          }}
          onBeforeChange={(editor, data, value) => {
            console.log('BeforeChange');
          }}
          onViewportChange={(editor, data, value) => {
            console.log('ViewPortChange');
          }}
          onScroll={(editor, data) => {
            // console.log('Scroll')
          }}
          onMouseDown={(editor, event) => {
            console.log('MouseDown');
          }}
          onDragStart={(editor, event) => {
            console.log('DragStart');
          }}
          editorDidMount={editor => {
            this._editor = editor;
            editor.on('scroll', function (cm) {
              // console.log('scroll')
            });

            editor.on('contextmenu', function (cm, event) {
              console.log('context');
            });
          }}
          onGutterClick={(editor, lineNumber, gutter, event) => {
            if (event.buttons === 1) {
              this.props.handleGutterClick(lineNumber + 1);
            }
          }}
        />

        <Popup
          key={this.state.contextOpenCounter}
          id="source-editor-context-menu-popup"
          inverted
          basic
          context={this.contextRef}
          onClose={() => this.handleContextMenuOpen(false)}
          open={this.state.contextOpen}
        >
          <div className={'custom-profile-menu-wrapper'}>
            {this.getContextMenuItems()}
            <div className={'caption'}>*Sidekick Action will be added to the highlighted line</div>
          </div>
        </Popup>
        <Popup
          key={'log-expr-menu'}
          id="source-editor-log-expression-menu-popup"
          inverted
          basic
          context={this.contextRef}
          onClose={() => this.handlePutSidekickActionMenuOpen(false, -1)}
          open={this.state.putSidekickActionMenuOpen}
        >
          <PutSidekickActionPopup
            probeType={this.state.sidekickActionType}
            onAdd={sidekickActionConfig => {
              this.handleContextMenuItemClick({ ...sidekickActionConfig, lineNumber: this.state.contextLineNumber });
            }}
            onCancel={() => {
              this.handlePutSidekickActionMenuOpen(false, -1);
            }}
            sugs={this.props.sugs}
          />
        </Popup>
      </div>
    );
  }
}
