import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SubscriptionExpiredModal } from 'components';

function mapStateToProps(store) {
  return {
    activeWorkspace: store.activeWorkspace,
    onboardingStatus: store.onboardingV2,
    workspaceList: store.workspaceList,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export const SubscriptionExpiredModalContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionExpiredModal),
);
