import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import URL_POOL from 'utils/url-pool';
import './ErrorSnapshots.scss';
import { EventsFilter } from '../TracepointEvents/EventsFilter/EventsFilter';
import { abbrFileName } from 'utils/string-util';
import { ErrorSnapshotsTable } from './ErrorSnapshotsTable';
import { EventDetails } from '../TracepointEvents/EventsDetails/EventDetails';
import { svgArrow, svgCheck } from '../TracepointEvents/TracePointEvents';
import { EventsPanelExpander } from 'components';
import { ExportEventContainer } from 'containers';

export class ErrorSnapshots extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientFilter: '',
      selectedErrorSnapshot: null,
      highlightedRowIndex: -1,
    };
  }

  renderNonEvents = () => {
    return (
      <div className="trace-point-events-container">
        <div className="events-empty-container">
          <div className="info-container">
            <div className="icon-line">
              <Icon className="icon-tp-events-no-event-exist" />
            </div>
            <div className="first-line">Error Snapshots</div>
            <div className="second-line">
              Your error snapshots listed here if you enabled error capturing. If they don't
            </div>
            <a target="_blank" href={URL_POOL.SIDEKICK_DOCS} rel="noopener noreferrer" className="third-line">
              check out our docs
            </a>
          </div>
        </div>
      </div>
    );
  };

  clearEvents = () => {
    this.props.handleErrorSnapshotsClear();
    this.setState({ clientFilter: '' });
  };

  setClientFilterAndResetDetails = clientFilter => {
    this.setState({
      clientFilter: clientFilter,
      highlightedRowIndex: -1,
      selectedErrorSnapshot: null,
    });
  };
  renderErrorsHeader = eventCount => {
    const { clientFilter } = this.state;
    return (
      <div className={'header-group'}>
        <div className={'header'}>Error Snapshots</div>
        <div className={'event-count-label table-text'}> {`${eventCount} Error Snapshots`}</div>
        <Icon className="icon-tp-events-trash" onClick={this.clearEvents} />
        <EventsFilter
          clientFilter={clientFilter}
          setClientFilter={newClientFilter => this.setClientFilterAndResetDetails(newClientFilter)}
        />
      </div>
    );
  };

  getFilteredErrors = () => {
    const { errorSnapshots } = this.props.broker;
    const { clientFilter } = this.state;
    if (clientFilter) {
      return errorSnapshots.filter(el => {
        const { time, applicationName, lineNo } = el;
        const moduleName = abbrFileName(el.fileName);
        const moduleNameAndLineNo = moduleName + ':' + lineNo;
        const fullLine = `${time} ${applicationName} ${moduleNameAndLineNo}`;
        return fullLine.includes(clientFilter);
      });
    } else {
      return [...errorSnapshots];
    }
  };

  handleRowClick = (e, rowIndex) => {
    const filteredSnapshots = this.getFilteredErrors();

    this.setState({
      highlightedRowIndex: rowIndex,
      selectedErrorSnapshot: filteredSnapshots[rowIndex],
    });
  };

  renderNotSelectedEventDetails = () => {
    return (
      <div className="event-details-container">
        <div className="not-selected-event-details-container">
          <div className="left-part">{svgArrow()}</div>
          <div className="center-part">
            <div className="first-line">{svgCheck()}</div>
            <div className="second-line">Select an Error</div>
            <div className="third-line">See the snapshots of that error!</div>
          </div>
          <div className="right-part" />
        </div>
      </div>
    );
  };

  handleCopyFrame = () => {
    this.setState({ copied: true });
    navigator.clipboard.writeText(JSON.stringify(this.state.selectedErrorSnapshot.frames));
    setTimeout(() => this.setState({ copied: false }), 3000);
  };

  render() {
    const { errorSnapshots } = this.props.broker;
    const { selectedErrorSnapshot, highlightedRowIndex } = this.state;
    const filteredErrors = this.getFilteredErrors();
    if (errorSnapshots.length === 0) {
      return <div className={'error-snapshots-container'}>{this.renderNonEvents()}</div>;
    }

    return (
      <div className={'trace-point-events-container'}>
        <EventsPanelExpander position={'right'} selector={'/border/bottom/t2'} />
        <div className={'events-container'}>
          {this.renderErrorsHeader(filteredErrors.length)}
          <ErrorSnapshotsTable
            errorSnapshots={filteredErrors}
            onRowClick={this.handleRowClick}
            highlightedRowIndex={highlightedRowIndex}
          />
        </div>

        {selectedErrorSnapshot ? (
          <div style={{ paddingTop: '15px' }} className="event-details-container">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                top: '0',
                right: '0',
                zIndex: '1000',
              }}
            >
              <ExportEventContainer
                externalStyle={{ marginRight: '24px', marginTop: '3px' }}
                type={2}
                event={this.state.selectedErrorSnapshot}
              />
            </div>
            <div className={'event-details-group-container'}>
              <EventDetails event={selectedErrorSnapshot} type={'variables'} />
            </div>
          </div>
        ) : (
          this.renderNotSelectedEventDetails()
        )}
      </div>
    );
  }
}
