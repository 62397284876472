import { connect } from 'react-redux';
import { getUserRepoContent, getGitTree, notifySourceFocus } from 'store/actions';
import { SourceFileTree } from 'components';

const mapStateToProps = store => ({
  gitTreeContent: store.gitTree,
  gitRepoContent: store.gitRepoContent,
});

const mapDispatchToProps = dispatch => ({
  getGitTree: (sourceProvider, owner, repo, treeSha, success, error) =>
    dispatch(getGitTree(sourceProvider, owner, repo, treeSha, success, error)),

  getUserRepoContent: (sourceProvider, owner, repo, path, ref, successFunc, errorFunc) =>
    dispatch(getUserRepoContent(sourceProvider, owner, repo, path, ref, successFunc, errorFunc)),

  handleSourceFocus(sourceTabIndex, lineNo) {
    dispatch(notifySourceFocus(sourceTabIndex, lineNo));
  },
});

export const SourceFileTreeContainer = connect(mapStateToProps, mapDispatchToProps)(SourceFileTree);
