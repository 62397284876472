import React, { Component } from 'react';
import '../ThundraSidebar/ThundraSidebar.scss';
import { Button, Menu, Popup } from 'semantic-ui-react';
import { changeActiveWorkspaceCmdObj } from 'utils/broker-commands';
import { generateUUID } from 'utils/object-util';
import './WorkspaceSwitcher.scss';
import routeList from 'routes';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import { SidekickConfirmModal } from 'components';
import { findActiveWorkspaceId } from 'utils/workspace-util';

export class WorkspaceSwitcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      workspaceName: '',
      workspaceId: '',
    };
  }

  getWorkspacesAndSetActive = () => {
    this.props.getWorkspaces(response => {
      this.props.getActiveWorkspace(findActiveWorkspaceId(response.value.data.workspaceDtos));
    });
  };

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (prevProps.broker.switchedWorkspaceId !== this.props.broker.switchedWorkspaceId) {
      this.getWorkspacesAndSetActive();
      return;
    }
    if (this.props.broker.switchedWorkspaceId === '-1') {
      toast.error('Error has occurred while changing workspace', ToastConfig);
    }
  }

  closeModal = () => {
    this.setState({ isModalOpen: false, workspaceName: '', workspaceId: '' });
  };

  wrapWithSwitchPopup = (workspaceDTO, trigger) => {
    return (
      <Popup
        position={'right center'}
        key={workspaceDTO.workspace.id + '_popup'}
        content={
          <Button
            onClick={() =>
              this.setState({
                isModalOpen: true,
                workspaceName: workspaceDTO.workspace.workspaceName,
                workspaceId: workspaceDTO.workspace.id,
              })
            }
          >
            Switch to Workspace
          </Button>
        }
        on={'click'}
        trigger={trigger}
      />
    );
  };

  render() {
    return (
      <div className={'workspace-switcher'}>
        {this.props.workspaceList.workspaces.map(workspaceDTO => {
          let isActive = workspaceDTO.workspace.id === this.props.workspaceList.activeWorkspaceId;
          let workspaceSwitcher = (
            <Menu.Item
              key={workspaceDTO.workspace.id}
              active={isActive}
              className={'workspace-item'}
              as={'a'}
              onClick={() => isActive && this.props.history.push(routeList.ide.path)}
            >
              <div>{workspaceDTO.workspace.workspaceName.substring(0, 2).toUpperCase()}</div>
            </Menu.Item>
          );
          if (!isActive) {
            workspaceSwitcher = this.wrapWithSwitchPopup(workspaceDTO, workspaceSwitcher);
          }
          return workspaceSwitcher;
        })}

        <SidekickConfirmModal
          modalOpen={this.state.isModalOpen}
          onClose={this.closeModal}
          content={'IDE content will be cleared. Do you wanna continue?'}
          confirmModalAction={() => {
            const { selectedGitSourceCache } = this.props.sourceCache;
            if (selectedGitSourceCache) {
              const { platform, owner, repository, branch, commit } = selectedGitSourceCache;
              // This is called to reset opened source files from IDE model.
              this.props.handleNotifyIDEContextReset(platform, owner, repository, branch, commit);
            }

            let changeActiveWorkspaceMsg = { ...changeActiveWorkspaceCmdObj };
            changeActiveWorkspaceMsg.workspaceId = this.state.workspaceId;
            changeActiveWorkspaceMsg.id = generateUUID();
            this.props.changeActiveWorkspace(changeActiveWorkspaceMsg);
            this.closeModal();
          }}
        />
      </div>
    );
  }
}
