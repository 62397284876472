import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { UPDATE_WORKSPACE_ACCOUNT } from '../constants/workspace-update-account';

export const updateWorkspaceAccount = (workspaceId, accountId, role) => {
  return {
    type: UPDATE_WORKSPACE_ACCOUNT,
    payload: axios.put(CONFIG.apiUrl + `/workspace/${workspaceId}/accounts/${accountId}?role=${role}`, null, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
