import { connect } from 'react-redux';
import { send as brokerSend } from '@giantmachines/redux-websocket';
import { CollaborativeWorkSwitcher } from 'components';
import { generateUUID } from 'utils/object-util';

function mapStateToProps(store) {
  return {
    broker: store.broker,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleBrokerSend(data) {
      data.id = generateUUID();
      dispatch(brokerSend(data));
    },
  };
}

export const CollaborativeWorkSwitcherContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollaborativeWorkSwitcher);
