import {
  SA_EVENTS_FOCUS_COMPLETED,
  SA_EVENTS_FOCUS_NOTIFY,
  SA_EVENTS_FOCUS_TAB_OPEN_IF_CLOSED,
} from '../constants/sidekick-action-events-focus';

export const notifySidekickActionEventsFocus = (sidekickAction, getReference, type) => {
  return {
    type: SA_EVENTS_FOCUS_NOTIFY,
    payload: { sidekickAction, type, getReference },
  };
};

export const openSidekickActionEventsTabIfClosed = (sidekickAction, getReference, type) => {
  return {
    type: SA_EVENTS_FOCUS_TAB_OPEN_IF_CLOSED,
    payload: { sidekickAction, type, getReference },
  };
};

export const completedSidekickActionEventsFocus = (sidekickAction, getReference, type) => {
  return {
    type: SA_EVENTS_FOCUS_COMPLETED,
    payload: { sidekickAction, type, getReference },
  };
};
