export const GET_JIRA_ACCESSIBLE_RESOURCES = 'GET_JIRA_ACCESSIBLE_RESOURCES';
export const GET_JIRA_ACCESSIBLE_RESOURCES_PENDING = 'GET_JIRA_ACCESSIBLE_RESOURCES_PENDING';
export const GET_JIRA_ACCESSIBLE_RESOURCES_FULFILLED = 'GET_JIRA_ACCESSIBLE_RESOURCES_FULFILLED';
export const GET_JIRA_ACCESSIBLE_RESOURCES_REJECTED = 'GET_JIRA_ACCESSIBLE_RESOURCES_REJECTED';

export const GET_JIRA_PROJECTS = 'GET_JIRA_PROJECTS';
export const GET_JIRA_PROJECTS_PENDING = 'GET_JIRA_PROJECTS_PENDING';
export const GET_JIRA_PROJECTS_FULFILLED = 'GET_JIRA_PROJECTS_FULFILLED';
export const GET_JIRA_PROJECTS_REJECTED = 'GET_JIRA_PROJECTS_REJECTED';

export const GET_JIRA_ISSUE_TYPES = 'GET_JIRA_ISSUE_TYPES';
export const GET_JIRA_ISSUE_TYPES_PENDING = 'GET_JIRA_ISSUE_TYPES_PENDING';
export const GET_JIRA_ISSUE_TYPES_FULFILLED = 'GET_JIRA_ISSUE_TYPES_FULFILLED';
export const GET_JIRA_ISSUE_TYPES_REJECTED = 'GET_JIRA_ISSUE_TYPES_REJECTED';

export const CREATE_JIRA_ISSUE = 'CREATE_JIRA_ISSUE';
export const CREATE_JIRA_ISSUE_PENDING = 'CREATE_JIRA_ISSUE_PENDING';
export const CREATE_JIRA_ISSUE_FULFILLED = 'CREATE_JIRA_ISSUE_FULFILLED';
export const CREATE_JIRA_ISSUE_REJECTED = 'CREATE_JIRA_ISSUE_REJECTED';

export const CREATE_JIRA_ISSUE_COMMENT = 'CREATE_JIRA_ISSUE_COMMENT';
export const CREATE_JIRA_ISSUE_COMMENT_PENDING = 'CREATE_JIRA_ISSUE_COMMENT_PENDING';
export const CREATE_JIRA_ISSUE_COMMENT_FULFILLED = 'CREATE_JIRA_ISSUE_COMMENT_FULFILLED';
export const CREATE_JIRA_ISSUE_COMMENT_REJECTED = 'CREATE_JIRA_ISSUE_COMMENT_REJECTED';

export const FLUSH_JIRA_ACTION_STATES = 'FLUSH_JIRA_ACTION_STATES';
