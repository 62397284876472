import {
  IDE_CONTEXT_RESET_NOTIFY,
  IDE_CONTEXT_RESET_FAILED,
  IDE_CONTEXT_RESET_SUCCEEDED,
  IDE_CONTEXT_TP_RESET_SUCCEEDED,
} from '../constants/ide-context';

export const notifyIDEContextReset = (sourceProvider, repoOwner, repoName, branch, commit) => {
  return {
    type: IDE_CONTEXT_RESET_NOTIFY,
    payload: { data: { sourceProvider, repoOwner, repoName, branch, commit } },
  };
};

export const failedIDEContextReset = contextData => {
  return {
    type: IDE_CONTEXT_RESET_FAILED,
    payload: { data: contextData },
  };
};

export const succeedIDEContextReset = contextData => {
  return {
    type: IDE_CONTEXT_RESET_SUCCEEDED,
    payload: { data: contextData },
  };
};

export const succeedIDETPContextReset = contextData => {
  return {
    type: IDE_CONTEXT_TP_RESET_SUCCEEDED,
    payload: { data: contextData },
  };
};
