import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import { UNSUBSCRIBE_MAIL } from '../constants/user-unsubscribe-mail';

export const unsubscribeMail = (token, email) => {
  return {
    type: UNSUBSCRIBE_MAIL,
    payload: axios.post(CONFIG.serviceUrl + '/mail/unsubscribe', { token, email }),
  };
};
