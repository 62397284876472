import {
  GET_EVENT_HISTORY_FULFILLED,
  GET_EVENT_HISTORY_PENDING,
  GET_EVENT_HISTORY_REJECTED,
  GET_PROBE_HIT_COUNT_FULFILLED,
  GET_PROBE_HIT_COUNT_PENDING,
  GET_PROBE_HIT_COUNT_REJECTED,
} from '../constants/event-history';

const initialState = {
  eventHistory: [],
  probeHitCount: {},

  eventHistoryFetching: false,
  eventHistoryFetched: false,
  eventHistoryError: null,

  probeHitCountFetching: false,
  probeHitCountFetched: false,
  probeHitCountError: null,
};

export default function eventHistory(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_HISTORY_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_EVENT_HISTORY_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        eventHistory: action.payload.data,
      };
    case GET_EVENT_HISTORY_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    case GET_PROBE_HIT_COUNT_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_PROBE_HIT_COUNT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        probeHitCount: action.payload.data,
      };
    case GET_PROBE_HIT_COUNT_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
