import {
  INVITE_TO_WORKSPACE_FULFILLED,
  INVITE_TO_WORKSPACE_PENDING,
  INVITE_TO_WORKSPACE_REJECTED,
} from 'store/constants/workspace-invite';

const createWorkspaceInitialState = {
  invitationResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function inviteToWorkspace(state = createWorkspaceInitialState, action) {
  switch (action.type) {
    case INVITE_TO_WORKSPACE_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case INVITE_TO_WORKSPACE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        invitationResult: action.payload.data,
      };
    case INVITE_TO_WORKSPACE_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
