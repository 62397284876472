import { connect } from 'react-redux';
import { SidekickActions } from 'components';
import { send as brokerSend } from '@giantmachines/redux-websocket';

import {
  addWaitingToSidekickAction,
  getUserRepoContent,
  getWebhooks,
  notifySidekickActionEventsFocus,
  notifySourceFocus,
  setEventBeingProcessed,
  succeedIDETPContextReset,
} from 'store/actions';
import { generateUUID } from 'utils/object-util';
import { getSidekickActionTags } from 'store/actions/sidekick-action-tag-list';

function mapStateToProps(store) {
  return {
    broker: store.broker,
    sidekickActionEdit: store.sidekickActionEdit,
    ideContext: store.ideContext,
    webhookList: store.webhookList,
    saTagList: store.saTagList,
    activeWorkspace: store.activeWorkspace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWebhooks(workspaceId) {
      dispatch(getWebhooks(workspaceId));
    },
    getSATags(workspaceId) {
      dispatch(getSidekickActionTags(workspaceId));
    },
    setEventBeingProcessed(event) {
      dispatch(setEventBeingProcessed(event));
    },
    handleBrokerSend(data) {
      data.id = data?.id || generateUUID();
      dispatch(addWaitingToSidekickAction({ ...data }));
      dispatch(brokerSend(data));
    },
    handleSourceFocus(sourceTabIndex, lineNo) {
      dispatch(notifySourceFocus(sourceTabIndex, lineNo));
    },
    handleSidekickActionEventsFocus(sidekickAction, getReference, type) {
      dispatch(notifySidekickActionEventsFocus(sidekickAction, getReference, type));
    },
    getUserRepoContent(sourceProvider, owner, repo, path, ref, successFunc, errorFunc) {
      dispatch(getUserRepoContent(sourceProvider, owner, repo, path, ref, successFunc, errorFunc));
    },
    handleSucceedIDETPContextReset(contextMetaData) {
      dispatch(succeedIDETPContextReset(contextMetaData));
    },
  };
}

export const SidekickActionsContainer = connect(mapStateToProps, mapDispatchToProps)(SidekickActions);
