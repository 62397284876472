import React, { Component } from 'react';
import routeList from 'routes';
import { WelcomeLayout } from 'layouts';
import WelcomeUserInfo from './WelcomeUserInfo';
import WelcomeWorkspaceInfo from './WelcomeWorkspaceInfo';
import './WelcomePage.scss';

export const WELCOME_PAGE_STATE = {
  USER_INFO: 'USER_INFO',
  WORKSPACE_INFO: 'WORKSPACE_INFO',
  // INVITE_USERS: 'INVITE_USERS',
};
class WelcomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      welcomePageState: this.decideWelcomePageState(),
      workspaceInfoLoading: false,
    };
  }

  decideWelcomePageState = () => {
    let userAccount = this.props.userAccount.userAccount;
    // let workspaceList = this.props.workspaceList;

    if (userAccount.fullName == null || userAccount.company === null || userAccount.companyRole == null) {
      return WELCOME_PAGE_STATE.USER_INFO;
      // } else if (workspaceList.workspaces.length > 0) {
      //   return WELCOME_PAGE_STATE.INVITE_USERS;
    } else {
      return WELCOME_PAGE_STATE.WORKSPACE_INFO;
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newPageState = this.decideWelcomePageState();
    if (prevState.welcomePageState !== newPageState) {
      this.setState({ welcomePageState: newPageState });
    }
  }

  renderWelcomePageContent = () => {
    if (this.state.welcomePageState === WELCOME_PAGE_STATE.USER_INFO) {
      return (
        <WelcomeUserInfo
          userAccount={this.props.userAccount.userAccount}
          onSubmit={profileData => {
            this.props.updateUserProfile(profileData, () => {
              this.props.getUserAccount();
            });
          }}
          loading={this.props.changeUserInformation.updateUserInfoFetching}
        />
      );
    } else if (this.state.welcomePageState === WELCOME_PAGE_STATE.WORKSPACE_INFO) {
      return (
        <WelcomeWorkspaceInfo
          onCreate={workspaceName => {
            this.setState({ workspaceInfoLoading: true });
            this.props.createWorkspace({ workspaceName }, () => {
              this.props.getUserAccount(() => {
                this.props.getWorkspaces(() => {
                  this.props.getActiveWorkspace(this.props.workspaceList.activeWorkspaceId, () => {
                    this.props.history.push(routeList.ide.path);
                  });
                });
              });
            });
          }}
          loading={this.state.workspaceInfoLoading}
        />
      );
    }
    // else if (this.state.welcomePageState === WELCOME_PAGE_STATE.INVITE_USERS) {
    //   return (
    //     <WelcomeInviteUsers
    //       workspaceList={this.props.workspaceList}
    //       activeWorkspace={this.props.activeWorkspace}
    //       invitationResult={this.props.invitationResult}
    //       inviteToWorkspace={this.props.inviteToWorkspace}
    //     />
    //   );
    // }
    return null;
  };

  render() {
    console.log('WelcomePage; props: ', this.props);

    return <WelcomeLayout title={routeList.welcome.title}>{this.renderWelcomePageContent()}</WelcomeLayout>;
  }
}

export default WelcomePage;
