import React, { Component } from 'react';
import { EventsFilter } from '../TracepointEvents/EventsFilter/EventsFilter';
import './AppManagement.scss';
import { AppItem } from './AppItem';
import { clusterApps, getFilters, getLiveAppSelection } from 'utils/application-util';
import URL_POOL from 'utils/url-pool';
import { AppEnvSettingsModal } from './AppEnvSettingsModal';
import { attachRequestCmdObj, detachRequestCmdObj, updateConfigRequestCmdObj } from '../../utils/broker-commands';

export class AppManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appFilter: '',
      modalOpen: false,
      selectedApp: null,
      loading: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    const { appData: apps } = this.props.broker;
    const { appData: prevApps } = prevProps.broker;
    let prevSelectedApps = getLiveAppSelection(prevApps, this.props.appsAndTracePoints.selectedApps);
    let selectedApps = getLiveAppSelection(apps, this.props.appsAndTracePoints.selectedApps);

    if (prevSelectedApps.length !== selectedApps.length) {
      this.props.handleSelectApps(selectedApps);
    }
  }

  handleAppSelect = appKey => {
    const { appData } = this.props.broker;
    let selectedApps = getLiveAppSelection(appData, this.props.appsAndTracePoints.selectedApps);
    let appIndex = selectedApps.indexOf(appKey);

    //toggle array element
    if (appIndex === -1) {
      selectedApps.push(appKey);
    } else {
      selectedApps.splice(appIndex, 1);
    }

    this.props.handleSelectApps(selectedApps);
  };

  filterApps = appList => {
    return appList.filter(app => {
      let searchSentence = app.name + app.stage + app.version;
      return searchSentence.includes(this.state.appFilter);
    });
  };

  renderAppsPlaceholder() {
    return (
      <div className={'apps-and-tps-placeholder'}>
        <h2 className="ui inverted center aligned small icon header">
          <i className="icon-apps-and-tps-select-applications icon" />
          <div className="content">Connect Applications</div>
        </h2>
        <div className="description">
          There are no connected applications. Visit{' '}
          <a href={URL_POOL.SIDEKICK_INSTALL_AGENT_DOC} target={'_blank'} rel="noopener noreferrer">
            docs
          </a>{' '}
          to see how you can connect one.
        </div>
      </div>
    );
  }

  handleSaveAppConfig = config => {
    this.setState({ loading: true });
    const { selectedApp } = this.state;
    const updateConfigRequest = { ...updateConfigRequestCmdObj, config, applicationFilters: [getFilters(selectedApp)] };
    this.props.handleBrokerSend(updateConfigRequest);
    setTimeout(() => this.setState({ modalOpen: false, selectedApp: null, loading: false }), 1500);
  };

  handleAttachDetachClicked = app => {
    if (this.props.onboardingStatus.sandboxEnabled) {
      return;
    }
    const attachDetachAppRequest = app.config?.detached ? { ...attachRequestCmdObj } : { ...detachRequestCmdObj };
    attachDetachAppRequest.applicationFilters = [getFilters(app)];
    this.props.handleBrokerSend(attachDetachAppRequest);
  };

  render() {
    const { appData } = this.props.broker;
    if (appData.length === 0) {
      return <div className={'app-management-container'}>{this.renderAppsPlaceholder()}</div>;
    }

    const selectedApps = getLiveAppSelection(appData, this.props.appsAndTracePoints.selectedApps);
    const appMap = clusterApps(appData);
    const appList = [];
    //eslint-disable-next-line
    for (const [key, value] of appMap.entries()) {
      appList.push(value[0]);
    }
    return (
      <div className={'app-management-container'}>
        <EventsFilter
          clientFilter={this.state.appFilter}
          setClientFilter={newClientFilter => this.setState({ appFilter: newClientFilter })}
          placeholder="Search by app name or tags"
        />

        <div className={'app-item-list'}>
          {this.filterApps(appList).map(app => (
            <AppItem
              onSettingsClicked={app => {
                if (this.props.onboardingStatus.sandboxEnabled) {
                  return;
                }
                this.setState({ modalOpen: true, selectedApp: app });
              }}
              onAttachDetachClicked={this.handleAttachDetachClicked}
              appInstances={appMap.get(app.key)}
              checked={selectedApps.includes(app.key)}
              handleAppSelect={this.handleAppSelect}
            />
          ))}
        </div>

        {this.state.modalOpen && (
          <AppEnvSettingsModal
            app={this.state.selectedApp}
            onClose={() => this.setState({ modalOpen: false, selectedApp: null })}
            onSaveConfig={this.handleSaveAppConfig}
            loading={this.state.loading}
          />
        )}
      </div>
    );
  }
}
