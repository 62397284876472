import {
  BROKER_CLOSED,
  BROKER_ERROR,
  BROKER_MESSAGE,
  BROKER_OPEN,
  ERROR_SNAPSHOTS_CLEAR,
  FLUSH_REFERENCE_EVENT,
  LP_ADD_WAITING,
  LP_EVENTS_CLEAR,
  PD_LP_EVENTS_CLEAR,
  PD_TP_EVENTS_CLEAR,
  SA_CHECK_WAITING,
  SET_EVENT_BEING_PROCESSED,
  TP_ADD_WAITING,
  TP_EVENTS_CLEAR,
} from '../constants/broker';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import { findConfigByApp, generateKey } from 'utils/application-util';
import { generatePathPrefixFromContextMetaData } from 'utils/string-util';
import { findSidekickActionOfEvent, processSidekickActionId } from 'utils/sidekick-action-utils';
import { generateVariables } from 'utils/object-util';

const initialState = {
  reconnectionTryCount: 0,
  brokerConnectionStatus: false,
  appData: [],
  tracePoints: [],
  logPoints: [],
  logPointsWaiting: [],
  tracePointsWaiting: [],
  tracePointEvents: [],
  predefinedTracepointEvents: [],
  predefinedLogPointEvents: [],
  logPointEvents: [],
  errorSnapshots: [],
  switchedWorkspaceId: '',
  referenceEvent: null,
  eventBeingProcessed: {},
  variableSuggestions: [],
};

export default function broker(state = initialState, action) {
  switch (action.type) {
    case BROKER_OPEN:
      return { ...state, brokerConnectionStatus: true, reconnectionTryCount: 0 };
    case BROKER_CLOSED:
      const recReTryCount = state.reconnectionTryCount + 1;
      return {
        ...state,
        brokerConnectionStatus: false,
        appData: [],
        reconnectionTryCount: recReTryCount,
      };
    case BROKER_MESSAGE:
      const dataFromServer = JSON.parse(action.payload.message);
      return processData(state, dataFromServer);
    case BROKER_ERROR:
      console.error(action.payload.message);
      return { ...state, brokerConnectionStatus: false, appData: [] };
    case TP_EVENTS_CLEAR:
      if (action.payload && action.payload.contextMetaData) {
        //Filter with ContextMetaData ..SourceProvider, RepoOwner, RepoName
        const pathPrefix = generatePathPrefixFromContextMetaData(action.payload.contextMetaData);
        const copyEvents = [...state.tracePointEvents];
        const filterEvents = copyEvents.filter(el => !el.tracePointId.includes(pathPrefix));
        return { ...state, tracePointEvents: filterEvents };
      } else {
        return { ...state, tracePointEvents: [] };
      }
    case PD_TP_EVENTS_CLEAR: {
      return { ...state, predefinedTracepointEvents: [] };
    }
    case LP_EVENTS_CLEAR:
      if (action.payload && action.payload.contextMetaData) {
        //Filter with ContextMetaData ..SourceProvider, RepoOwner, RepoName
        const pathPrefix = generatePathPrefixFromContextMetaData(action.payload.contextMetaData);
        const copyEvents = [...state.logPointEvents];
        const filterEvents = copyEvents.filter(el => !el.logPointId.includes(pathPrefix));
        return { ...state, logPointEvents: filterEvents };
      } else {
        return { ...state, logPointEvents: [] };
      }
    case PD_LP_EVENTS_CLEAR: {
      return { ...state, predefinedLogPointEvents: [] };
    }
    case ERROR_SNAPSHOTS_CLEAR: {
      return { ...state, errorSnapshots: [] };
    }
    case TP_ADD_WAITING:
      return { ...state, tracePointsWaiting: [...state.tracePointsWaiting, action.payload] };
    case LP_ADD_WAITING:
      return { ...state, logPointsWaiting: [...state.logPointsWaiting, action.payload] };
    case SA_CHECK_WAITING:
      const currentTime = new Date().getTime();
      const tenSeconds = 10000;
      const activeWaitingArrTP = state.tracePointsWaiting.filter(el => el.actionTime + tenSeconds > currentTime);
      const activeWaitingArrLP = state.logPointsWaiting.filter(el => el.actionTime + tenSeconds > currentTime);

      return { ...state, tracePointsWaiting: [...activeWaitingArrTP], logPointsWaiting: [...activeWaitingArrLP] };
    case FLUSH_REFERENCE_EVENT:
      return { ...state, referenceEvent: null };
    case SET_EVENT_BEING_PROCESSED: {
      return { ...state, eventBeingProcessed: action.payload };
    }
    default:
      return state;
  }
}

const processData = (state, data) => {
  switch (data.name) {
    case 'ListApplicationsResponse':
      data.applications.forEach(application => {
        application.key = generateKey(application);
        let configs = data.applicationConfigs;
        application.config = findConfigByApp(application, configs);
        if (application.tracePoints) {
          application.tracePoints.forEach(tracePoint => (tracePoint.applicationKey = application.key));
        }
        if (!application.tracePoints) {
          application.tracePoints = [];
        }

        if (application.logPoints) {
          application.logPoints.forEach(logPoint => (logPoint.applicationKey = application.key));
        }
        if (!application.logPoints) {
          application.logPoints = [];
        }
      });
      return { ...state, appData: data.applications };
    case 'ListTracePointsResponse':
      if (data.erroneous) {
        toast.error(`${data.errorMessage}`, ToastConfig);
      }
      data.tracePoints.forEach(tp => {
        if (tp?.probeName?.length > 0 && tp?.tags?.length > 0) {
          tp.predefined = true;
        }
      });
      return { ...state, tracePoints: data.tracePoints };
    case 'ListLogPointsResponse':
      if (data.erroneous) {
        toast.error(`${data.errorMessage}`, ToastConfig);
      }
      data.logPoints.forEach(lp => {
        if (lp?.probeName?.length > 0 && lp?.tags?.length > 0) {
          lp.predefined = true;
        }
      });
      return { ...state, logPoints: data.logPoints };
    case 'ApplicationStatusEvent':
      if (data.erroneous) {
        toast.error(`${data.errorMessage}`, ToastConfig);
      }
      return { ...state };
    case 'PutLogPointResponse':
      if (data.source === 'Broker') {
        //Response comes from Broker or AppInstances.. UI speaks Brokers..
        const lpsWaiting = state.logPointsWaiting.filter(el => el.id !== data.requestId);
        const lpsUpdated = [...state.logPoints];

        if (data.erroneous) {
          toast.error(`${data.errorMessage}`, ToastConfig);
        } else if (data.probeConfig) {
          lpsUpdated.push(data.probeConfig);
        }
        return { ...state, logPointsWaiting: [...lpsWaiting], logPoints: lpsUpdated };
      }
      return { ...state };
    case 'EnableLogPointResponse': //Response comes from Broker or AppInstances.. UI speaks Brokers..
    case 'DisableLogPointResponse':
    case 'UpdateLogPointResponse':
      if (data.source === 'Broker') {
        const lpsWaiting = state.logPointsWaiting.filter(el => el.id !== data.requestId);
        const lpsUpdated = [...state.logPoints];

        if (data.erroneous) {
          toast.error(`${data.errorMessage}`, ToastConfig);
        } else if (data.probeConfig) {
          const indexOfLP = state.logPoints.findIndex(el => el.id === data.probeConfig.id);
          if (indexOfLP > -1) {
            if (data.probeConfig?.probeName?.length > 0 && data.probeConfig?.tags?.length > 0) {
              data.probeConfig.predefined = true;
            }
            lpsUpdated[indexOfLP] = data.probeConfig;
          }
        }
        return { ...state, logPointsWaiting: [...lpsWaiting], logPoints: lpsUpdated };
      }
      return { ...state };
    case 'RemoveLogPointResponse':
      if (data.source === 'Broker') {
        const lpWaiting = state.logPointsWaiting.find(el => el.id === data.requestId);
        const lpsWaiting = state.logPointsWaiting.filter(el => el.id !== data.requestId);
        const lpsUpdated = [...state.logPoints];

        if (data.erroneous) {
          toast.error(`${data.errorMessage}`, ToastConfig);
        } else if (lpWaiting) {
          const indexOfLp = lpsUpdated.findIndex(el => el.id === lpWaiting.logPointId);
          if (indexOfLp > -1) {
            lpsUpdated.splice(indexOfLp, 1);
          }
        }
        return { ...state, logPointsWaiting: [...lpsWaiting], logPoints: lpsUpdated };
      }
      return { ...state };
    case 'PutTracePointResponse':
      if (data.source === 'Broker') {
        //Response comes from Broker or AppInstances.. UI speaks Brokers..
        const tpWaitings = state.tracePointsWaiting.filter(el => el.id !== data.requestId);
        const tpsUpdated = [...state.tracePoints];

        if (data.erroneous) {
          toast.error(`${data.errorMessage}`, ToastConfig);
        } else if (data.probeConfig) {
          tpsUpdated.push(data.probeConfig);
        }
        return { ...state, tracePointsWaiting: [...tpWaitings], tracePoints: tpsUpdated };
      }
      return { ...state };
    case 'EnableTracePointResponse': //Response comes from Broker or AppInstances.. UI speaks Brokers..
    case 'DisableTracePointResponse':
    case 'UpdateTracePointResponse':
      if (data.source === 'Broker') {
        const tpWaitings = state.tracePointsWaiting.filter(el => el.id !== data.requestId);
        const tpsUpdated = [...state.tracePoints];

        if (data.erroneous) {
          toast.error(`${data.errorMessage}`, ToastConfig);
        } else if (data.tracePointConfig) {
          const indexOfTP = state.tracePoints.findIndex(el => el.id === data.tracePointConfig.id);
          if (indexOfTP > -1) {
            if (data.tracePointConfig?.probeName?.length > 0 && data.tracePointConfig?.tags?.length > 0) {
              data.tracePointConfig.predefined = true;
            }
            tpsUpdated[indexOfTP] = data.tracePointConfig;
          }
        }
        return { ...state, tracePointsWaiting: [...tpWaitings], tracePoints: tpsUpdated };
      }
      return { ...state };

    case 'RemoveTracePointResponse':
      if (data.source === 'Broker') {
        const tpWaiting = state.tracePointsWaiting.find(el => el.id === data.requestId);
        const tpWaitings = state.tracePointsWaiting.filter(el => el.id !== data.requestId);
        const tpsUpdated = [...state.tracePoints];

        if (data.erroneous) {
          toast.error(`${data.errorMessage}`, ToastConfig);
        } else if (tpWaiting) {
          const indexOfTP = tpsUpdated.findIndex(el => el.id === tpWaiting.tracePointId);
          if (indexOfTP > -1) {
            tpsUpdated.splice(indexOfTP, 1);
          }
        }
        return { ...state, tracePointsWaiting: [...tpWaitings], tracePoints: tpsUpdated };
      }
      return { ...state };

    case 'RemoveBatchTracePointResponse':
      if (data.source === 'Broker') {
        const tpWaiting = state.tracePointsWaiting.find(el => el.id === data.requestId);
        const tpWaitings = state.tracePointsWaiting.filter(el => el.id !== data.requestId);
        const tpsUpdated = [...state.tracePoints];

        if (data.erroneous) {
          toast.error(`${data.errorMessage}`, ToastConfig);
        } else if (tpWaiting) {
          const count = tpWaiting.tracePointIds.length;
          tpWaiting.tracePointIds.forEach(tpId => {
            const indexOfTP = tpsUpdated.findIndex(el => el.id === tpId);
            if (indexOfTP > -1) {
              tpsUpdated.splice(indexOfTP, 1);
            }
          });
          const { deletedTracePointCount } = data;
          toast.success(`${deletedTracePointCount}/${count} Tracepoints Deleted`, ToastConfig);
        }
        return { ...state, tracePointsWaiting: [...tpWaitings], tracePoints: tpsUpdated };
      }
      return { ...state };

    case 'TracePointSnapshotEvent':
      const { predefinedTracepointEvents, tracePointEvents, tracePoints, variableSuggestions } = state;
      const tpOfEvent = findSidekickActionOfEvent(data.tracePointId, tracePoints);
      let eventsClone;
      let variables = generateVariables(data.frames);

      if (tpOfEvent?.predefined) {
        let eventsClone = [...predefinedTracepointEvents];
        data.predefinedEvent = true;
        eventsClone.push(data);
        return {
          ...state,
          predefinedTracepointEvents: eventsClone,
          variableSuggestions: Array.from(new Set([...variableSuggestions, ...variables])),
        };
      }
      eventsClone = [...tracePointEvents];
      eventsClone.push(data);
      return {
        ...state,
        tracePointEvents: eventsClone,
        variableSuggestions: Array.from(new Set([...variableSuggestions, ...variables])),
      };
    case 'LogPointEvent':
      const { predefinedLogPointEvents, logPointEvents, logPoints } = state;
      const lpOfEvent = findSidekickActionOfEvent(data.logPointId, logPoints);
      if (lpOfEvent?.predefined) {
        let eventsClone = [...predefinedLogPointEvents];
        data.predefinedEvent = true;
        eventsClone.push(data);
        return { ...state, predefinedLogPointEvents: eventsClone };
      }
      const logPointEventsClone = [...logPointEvents];
      logPointEventsClone.push(data);
      return { ...state, logPointEvents: logPointEventsClone };
    case 'ErrorStackSnapshotEvent':
      const { errorSnapshots } = state;
      return { ...state, errorSnapshots: [...errorSnapshots, data] };
    case 'SwitchWorkspaceResponse':
      if (data.erroneous) {
        return { ...state, switchedWorkspaceId: '-1' };
      }
      return { ...state, switchedWorkspaceId: data.workspaceId };
    case 'DisableCollaborationResponse':
    case 'EnableCollaborationResponse': {
      return { ...state, logPointEvents: [], tracePointEvents: [] };
    }
    case 'GetReferenceEventResponse': {
      if (data.erroneous) {
        return { ...state, referenceEvent: { erroneous: true } };
      }
      if (!data.referenceEvent) {
        toast.error('No reference event set.', ToastConfig);
        return { ...state, referenceEvent: { erroneous: true } };
      }
      const referenceEvent = JSON.parse(data.referenceEvent.event);
      referenceEvent.referenceEvent = true;
      referenceEvent.predefinedEvent = true;
      if (data.referenceEvent.probeType === 'TRACEPOINT') {
        return {
          ...state,
          referenceEvent: { event: referenceEvent, requestId: data.requestId },
          predefinedTracepointEvents: [...state.predefinedTracepointEvents, referenceEvent],
        };
      } else {
        return {
          ...state,
          referenceEvent: { event: referenceEvent, requestId: data.requestId },
          predefinedLogPointEvents: [...state.predefinedLogPointEvents, referenceEvent],
        };
      }
    }
    case 'PutReferenceEventResponse':
      if (data.erroneous) {
        toast.error(`${data.errorMessage}`, ToastConfig);
      } else if (data.requestId === state.eventBeingProcessed.requestId) {
        const { event } = state.eventBeingProcessed;
        const eventType = event.name === 'LogPointEvent' ? 'logPoint' : 'tracePoint';
        const { predefinedTracepointEvents, eventBeingProcessed, tracePoints, predefinedLogPointEvents, logPoints } =
          state;
        const cloneEvents = eventType === 'logPoint' ? [...predefinedLogPointEvents] : [...predefinedTracepointEvents];
        const searchSidekickActions = eventType === 'logPoint' ? logPoints : tracePoints;
        const saOfEvent = findSidekickActionOfEvent(eventBeingProcessed.event[`${eventType}Id`], searchSidekickActions);
        for (let event of cloneEvents) {
          if (
            event.id === eventBeingProcessed.event.id &&
            event.applicationInstanceId === eventBeingProcessed.event.applicationInstanceId
          ) {
            event.referenceEvent = true;
            continue;
          }
          if (
            processSidekickActionId(event[`${eventType}Id`]) === saOfEvent.id &&
            event.referenceEvent &&
            event.applicationInstanceId === eventBeingProcessed.event.applicationInstanceId
          ) {
            delete event.referenceEvent;
          }
        }
        if (eventType === 'logPoint') {
          return { ...state, eventBeingProcessed: null, predefinedLogPointEvents: cloneEvents };
        } else {
          return { ...state, eventBeingProcessed: null, predefinedTracepointEvents: cloneEvents };
        }
      }

      return { ...state };
    case 'RemoveReferenceEventResponse': {
      if (data.erroneous) {
        toast.error(`${data.errorMessage}`, ToastConfig);
        return { ...state };
      } else if (data.requestId === state?.eventBeingProcessed?.requestId) {
        const { event } = state.eventBeingProcessed;
        const eventType = event.name === 'LogPointEvent' ? 'logPoint' : 'tracePoint';
        const { predefinedTracepointEvents, eventBeingProcessed, tracePoints, predefinedLogPointEvents, logPoints } =
          state;
        const cloneEvents = eventType === 'logPoint' ? [...predefinedLogPointEvents] : [...predefinedTracepointEvents];
        const searchSidekickActions = eventType === 'logPoint' ? logPoints : tracePoints;
        const saOfEvent = findSidekickActionOfEvent(eventBeingProcessed.event[`${eventType}Id`], searchSidekickActions);
        for (let event of cloneEvents) {
          if (
            processSidekickActionId(event[`${eventType}Id`]) === saOfEvent.id &&
            event.applicationInstanceId === eventBeingProcessed.event.applicationInstanceId
          ) {
            delete event.referenceEvent;
          }
        }
        if (eventType === 'logPoint') {
          return { ...state, eventBeingProcessed: null, predefinedLogPointEvents: cloneEvents };
        } else {
          return { ...state, eventBeingProcessed: null, predefinedTracepointEvents: cloneEvents };
        }
      }
      return { ...state };
    }
    case 'AttachRequest':
    case 'DetachRequest':
    case 'UpdateConfigRequest':
      if (data.erroneous) {
        toast.error(`${data.errorMessage}`, ToastConfig);
      }
      return { ...state };

    default:
      return { ...state };
  }
};
