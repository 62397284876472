import React, { Component } from 'react';
import { Icon, Image, Menu, Popup, Segment, Sidebar } from 'semantic-ui-react';
import { AppsAndRepositoriesControllerContainer, BrokerConnectContainer, WorkspaceSwitcherContainer } from 'containers';
import './ThundraSidebar.scss';
import UserMenuItem from './UserMenuItem';
import routeList from 'routes';
import { sidebarDecorator } from 'utils/sidebar-decorator';
import SettingsMenuItem from './SettingsMenuItem';
import bell from 'assets/images/bellhop.png';
import bell_active from 'assets/images/bellhop-active.png';
import dashboardIcon from 'assets/images/dashboard.svg';

import URL_POOL from 'utils/url-pool';

class Bell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      isTrial: false,
    };
  }

  getPopupContent = () => {
    if (this.state.isTrial) {
      return (
        <div>
          Welcome to Sidekick!
          <br /> <br />
          Your trial has started. You can check out the billing page for setting up your plan or reach us for custom
          plans & on-premise alternatives.
          <br /> <br />
          If you have any questions or would like to have a personal onboarding session you can set a meeting anytime
          from{' '}
          <a href={URL_POOL.CALENDLY_BARIS} rel="noopener noreferrer" target="_blank">
            here.
          </a>
          <br /> <br />
          You can click on this Bellhop Bell icon to open this tooltip again.
        </div>
      );
    }
    return (
      <div>
        Welcome to Sidekick!
        <br /> <br />
        If you have any questions or would like to have a personal onboarding session you can set a meeting anytime from{' '}
        <a href={URL_POOL.CALENDLY_BARIS} rel="noopener noreferrer" target="_blank">
          here.
        </a>
        <br /> <br />
        You can click on this Bellhop Bell icon to open this tooltip again.
      </div>
    );
  };

  componentDidMount() {
    const workspaceId = this.props.activeWorkspace.workspace.id;
    this.props.getActiveSubscription(
      workspaceId,
      response => {
        const isTrial = response.value.data.trial;
        this.setState({ isTrial: isTrial });
      },
      () => {},
    );
  }

  render() {
    const isDebugPage = this.props.location.includes('debug');
    const isSandboxPage = this.props.location.includes('sandbox');
    if (!isDebugPage && !isSandboxPage) {
      return null;
    }
    let popupContent = this.getPopupContent();

    return (
      <Popup
        className={'bellhop-ring-popup'}
        trigger={
          <Menu.Item
            className="menu-item"
            as="a"
            onClick={() => {
              this.setState({ popupOpen: true });
            }}
          >
            <Image style={{ width: '16px' }} src={this.state.isTrial ? bell_active : bell} />
          </Menu.Item>
        }
        open={this.state.popupOpen}
        position="right center"
        content={
          <div style={{ position: 'relative' }}>
            {popupContent}{' '}
            <Icon
              style={{ position: 'absolute', right: '0', top: '0' }}
              name={'close'}
              onClick={() => this.setState({ popupOpen: false })}
            />
          </div>
        }
      />
    );
  }
}

export class ThundraSidebar extends Component {
  render() {
    let { workspace } = this.props.activeWorkspace;
    const decorator = sidebarDecorator(workspace.role, workspace.billingAccount);
    return (
      <>
        <Sidebar.Pushable as={Segment}>
          <Sidebar className="side-bar" as={Menu} borderless inverted vertical visible width="very thin">
            <Menu.Item
              as="a"
              className={'menu-item'}
              id={'sidekick-logo'}
              onClick={() => this.props.history.push(routeList.ide.path)}
            >
              <Icon style={{ fontSize: 24 }} className={'icon-bug-left-nav-icon'} />
              <BrokerConnectContainer />
            </Menu.Item>

            {(this.props.match.path.includes('debug') || this.props.match.path.includes('sandbox')) && (
              <AppsAndRepositoriesControllerContainer />
            )}

            <WorkspaceSwitcherContainer />

            <Menu.Item className="menu-item push-to-bottom" as="a" id={'sidebar-intercom-item'}>
              <Icon style={{ fontSize: 24 }} className={'icon-sidebar-intercom'} />
            </Menu.Item>

            <Bell
              location={this.props.match.path}
              activeWorkspace={workspace}
              getActiveSubscription={this.props.getActiveSubscription}
            />

            <Menu.Item
              className="menu-item"
              as="a"
              onClick={() => this.props.history.push(routeList.eventHistory.path)}
            >
              <Image src={dashboardIcon} />
            </Menu.Item>

            {this.props.match.path.includes('debug') && (
              <Menu.Item className="menu-item" as="a" onClick={this.props.startOnboarding}>
                <Icon name="graduation" size={'large'} />
              </Menu.Item>
            )}
            {decorator.renderSettingsMenu && <SettingsMenuItem activeWorkspace={this.props.activeWorkspace} />}
            {!this.props.match.path.includes('sandbox') && <UserMenuItem userAccount={this.props.userAccount} />}
          </Sidebar>
          <Sidebar.Pusher className="sidebar-pusher">{this.props.children}</Sidebar.Pusher>
        </Sidebar.Pushable>
      </>
    );
  }
}
