import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import './Tooltip.scss';

export default class Tooltip extends Component {
  render() {
    const { content, ...restProps } = this.props;
    return (
      <Popup
        // open
        className="sidekick-tooltip"
        position="bottom center"
        trigger={this.props.children}
        {...restProps}
      >
        <Popup.Content>
          <span>{content}</span>
        </Popup.Content>
      </Popup>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
};
