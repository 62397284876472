import React, { Component } from 'react';
import { Button, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { formatDate } from 'utils/date-util';

class ActivePlan extends Component {
  calculateNextBillingDate = () => {
    const { activeSubscription } = this.props.activeSubscription;

    if (activeSubscription.trial) {
      return 'Trial';
    }
    if (activeSubscription.nextInvoiceDate == null) {
      return 'Canceled';
    }

    return formatDate(activeSubscription.nextInvoiceDate * 1000);
  };

  calculateUsers = () => {
    const { activeSubscription } = this.props.activeSubscription;
    return activeSubscription.userLimit ? activeSubscription.userLimit : 'Canceled';
  };

  calculateInstances = () => {
    const { activeSubscription } = this.props.activeSubscription;
    return activeSubscription.applicationLimit ? activeSubscription.applicationLimit : 'Canceled';
  };

  calculateTotal = () => {
    const { activeSubscription } = this.props.activeSubscription;
    return activeSubscription.nextInvoiceTotal ? '$' + activeSubscription.nextInvoiceTotal / 100 : 'N/A';
  };

  render() {
    const { activeSubscription } = this.props.activeSubscription;
    return (
      <Grid className={'active-plan-main-grid'}>
        <GridRow style={{ marginBottom: '12px' }}>
          <GridColumn className={'active-plan-header'}>
            <div className={'plan-name-header'}>{activeSubscription.trial ? 'Trial' : 'Monthly'}</div>
            <Button
              style={{ marginRight: '0' }}
              className={'small-button'}
              content={'Change plan'}
              onClick={this.props.onChangePlanClicked}
            />
          </GridColumn>
        </GridRow>
        <GridRow columns={'equal'}>
          <GridColumn className={'plan-info-column'}>
            <div className={'plan-header'}>Instances</div>
            <div className={'plan-value'} style={{ marginBottom: '14px' }}>
              {this.calculateInstances()}
            </div>
            <div className={'plan-header'}>Users</div>
            <div className={'plan-value'}>{this.calculateUsers()}</div>
          </GridColumn>
          <GridColumn className={'billing-info-column'}>
            <div className={'plan-header'}>Next Billing Date</div>
            <div className={'plan-value'} style={{ marginBottom: '14px' }}>
              {this.calculateNextBillingDate()}
            </div>
            <div className={'plan-header'}>Billing Total</div>
            <div className={'plan-value'}>{this.calculateTotal()}</div>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

export default ActivePlan;
