import {
  SOURCE_TABS_SELECTION_CHANGE,
  SOURCE_TABS_SELECTION_CHANGE_REQUEST,
  LAYOUT_CHANGE,
} from '../constants/source-tabs';

const initialState = {
  sourceTabIndex: 0,
  lineNo: 0,
  layoutUpdateCount: 0,
  sourceTabChangeCount: 0,
};

export default function sourceTabs(state = initialState, action) {
  switch (action.type) {
    case SOURCE_TABS_SELECTION_CHANGE:
      return {
        ...state,
        sourceTabIndex: action.payload.sourceTabIndex,
        lineNo: action.payload.lineNo,
      };
    case SOURCE_TABS_SELECTION_CHANGE_REQUEST:
      return {
        ...state,
        sourceTabIndex: action.payload.sourceTabIndex,
        lineNo: action.payload.lineNo,
        sourceTabChangeCount: ++state.sourceTabChangeCount,
      };
    case LAYOUT_CHANGE:
      return { ...state, layoutChange: ++state.layoutUpdateCount };
    default:
      return state;
  }
}
