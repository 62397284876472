import {
  COMPLETE_INVITATION_FULFILLED,
  COMPLETE_INVITATION_PENDING,
  COMPLETE_INVITATION_REJECTED,
} from '../constants/workspace-complete-invitation';

const completeInvitationInitialState = {
  completeInvitationResult: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function workspaceCompleteInvitation(state = completeInvitationInitialState, action) {
  switch (action.type) {
    case COMPLETE_INVITATION_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case COMPLETE_INVITATION_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        completeInvitationResult: action.payload.data,
      };
    case COMPLETE_INVITATION_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
