import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { COMPLETE_INVITATION } from '../constants/workspace-complete-invitation';

export const completeInvitation = workspaceId => {
  return {
    type: COMPLETE_INVITATION,
    payload: axios.post(CONFIG.apiUrl + `/workspaces/${workspaceId}/completeInvitation`, null, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
