import React, { Component } from 'react';
import { SendToWebhookIconContainer } from 'containers';
import { Icon, Image } from 'semantic-ui-react';
import { CONFIG } from 'utils/config-util';
import jiraIcon from 'assets/images/jira-icon.svg';
import linearIcon from 'assets/images/linear-icon.svg';

const issueURLMap = {
  LINEAR: (clientId, redirectUrl, randomState) =>
    `https://linear.app/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUrl,
    )}&state=${randomState}&scope=write`,
  JIRA: (clientId, redirectUrl, randomState) =>
    `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=write%3Ajira-work%20read%3Ajira-work&redirect_uri=${encodeURIComponent(
      redirectUrl,
    )}&state=${randomState}&response_type=code&prompt=consent`,
};

export class ExportEvent extends Component {
  constructor(props) {
    super(props);
    this.state = { copied: false };
  }
  handleCopyFrame = () => {
    this.setState({ copied: true });
    navigator.clipboard.writeText(JSON.stringify(this.props.event.frames));
    setTimeout(() => this.setState({ copied: false }), 3000);
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          gap: '3px',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...this.props.externalStyle,
        }}
      >
        {this.state.copied ? (
          <span>Copied!</span>
        ) : (
          <Icon
            style={{ marginTop: '-3px', marginRight: '-2px' }}
            name={'copy outline'}
            onClick={this.handleCopyFrame}
          />
        )}
        <Image
          src={jiraIcon}
          onClick={() => {
            this.props.setEventToSend(this.props.event);
            window.location.assign(
              issueURLMap.JIRA(
                CONFIG.ISSUE_MANAGEMENT.JIRA.CLIENT_ID,
                CONFIG.ISSUE_MANAGEMENT.RETURN_URL,
                CONFIG.ISSUE_MANAGEMENT.JIRA.RANDOM_STATE,
              ),
            );
          }}
        />
        <Image
          src={linearIcon}
          onClick={() => {
            this.props.setEventToSend(this.props.event);
            window.location.assign(
              issueURLMap.LINEAR(
                CONFIG.ISSUE_MANAGEMENT.LINEAR.CLIENT_ID,
                CONFIG.ISSUE_MANAGEMENT.RETURN_URL,
                CONFIG.ISSUE_MANAGEMENT.LINEAR.RANDOM_STATE,
              ),
            );
          }}
        />
        <SendToWebhookIconContainer type={this.props.type} event={this.props.event} />
      </div>
    );
  }
}
