import React, { Component } from 'react';
import { Button, Input, Modal, ModalContent } from 'semantic-ui-react';
import { ToastConfig } from 'utils/toast-util';
import { toast } from 'react-toastify';
import URL_POOL from 'utils/url-pool';

export class CreateWorkspaceModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wsName: '',
    };
  }

  render() {
    return (
      <Modal open={this.props.open} className={'confirm-modal'}>
        <ModalContent>
          <Input
            placeholder={'Workspace name'}
            value={this.state.wsName}
            onChange={(e, data) => this.setState({ wsName: data.value })}
          />
        </ModalContent>
        <Modal.Actions>
          <Button
            className={'secondary button-step-info'}
            content={'Cancel'}
            onClick={() => this.props.onClose(false)}
          />
          <Button
            loading={this.props?.loading || false}
            content={'Confirm'}
            onClick={() => this.props.createWs({ workspaceName: this.state.wsName },
              () => this.props.onClose(true),
              () => {
                this.props.onClose(false);
                toast.error(<div>You've reached maximum number of workspaces you own.
                  You can join other workspaces via invitation or contact support  <a href={URL_POOL.SIDEKICK_SUPPORT_MAIL} target={'_blank'} rel="noopener noreferrer">
                    support
                  </a>{' '}to increase
                  your workspace limit.</div>, ToastConfig);
              })}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
