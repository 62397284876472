import {
  OPEN_APPS_AND_TPS,
  APPS_AND_TPS_OPENED,
  OPEN_EVENTS,
  EVENTS_OPENED,
  OPEN_SOURCES,
  SOURCES_OPENED,
} from '../constants/ide-toggle-border-tabs';

export const openAppsAndTracePointsTab = () => {
  return {
    type: OPEN_APPS_AND_TPS,
    payload: '',
  };
};

export const appsAndTracePointsTabOpened = () => {
  return {
    type: APPS_AND_TPS_OPENED,
    payload: '',
  };
};

export const openEventsTab = () => {
  return {
    type: OPEN_EVENTS,
    payload: '',
  };
};

export const eventsTabOpened = () => {
  return {
    type: EVENTS_OPENED,
    payload: '',
  };
};

export const openSourcesTab = () => {
  return {
    type: OPEN_SOURCES,
    payload: '',
  };
};

export const sourcesTabOpened = () => {
  return {
    type: SOURCES_OPENED,
    payload: '',
  };
};
