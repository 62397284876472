import { connect } from 'react-redux';
import { SourceGithubAccountList } from 'components';
import { withRouter } from 'react-router';
import {
  deleteGithubAppAuthorization,
  deleteOnboardingSourceCache,
  deleteSourceCache,
  deleteSourceProviderAccount,
  flushNewAddedSourceProviderData,
  flushSelectedGitSourceCache,
  getSourceCacheList,
  getSourceProviderAccountList,
  getUserRepositories,
  notifyIDEContextReset,
} from 'store/actions';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    sourceProviderAccount: store.sourceProviderAccount,
    sourceCache: store.sourceCache,
    activeWorkspace: store.activeWorkspace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSourceProviderAccountList(onGetSourceProviderAccountListSuccess, onGetSourceProviderAccountListFail) {
      dispatchWithExceptionHandler(
        getSourceProviderAccountList(),
        false,
        onGetSourceProviderAccountListSuccess,
        onGetSourceProviderAccountListFail,
      );
    },
    deleteSourceProviderAccount(
      sourceAccountId,
      onDeleteSourceProviderAccountSuccess,
      onDeleteSourceProviderAccountFail,
    ) {
      console.log('SALC, deleteSourceProviderAccount; sourceAccountId: ', sourceAccountId);
      dispatchWithExceptionHandler(
        deleteSourceProviderAccount(sourceAccountId),
        false,
        onDeleteSourceProviderAccountSuccess,
        onDeleteSourceProviderAccountFail,
      );
    },
    flushNewAddedSourceProviderData() {
      dispatch(flushNewAddedSourceProviderData());
    },

    getSourceCacheList(workspaceId, onGetSourceCacheListSuccess, onGetSourceCacheListFail) {
      dispatchWithExceptionHandler(
        getSourceCacheList(workspaceId),
        false,
        onGetSourceCacheListSuccess,
        onGetSourceCacheListFail,
      );
    },

    deleteSourceCache(sourceId, workspaceId, onDeleteSourceCacheSuccess, onDeleteSourceCacheFail) {
      console.log('SALC, deleteSourceCache; source: ', sourceId);
      if (sourceId === 'onboarding-sample-id') {
        dispatchWithExceptionHandler(
          deleteOnboardingSourceCache(sourceId),
          false,
          onDeleteSourceCacheSuccess,
          onDeleteSourceCacheFail,
        );
      } else {
        dispatchWithExceptionHandler(
          deleteSourceCache(sourceId, workspaceId),
          false,
          onDeleteSourceCacheSuccess,
          onDeleteSourceCacheFail,
        );
      }
    },
    flushSelectedGitSourceCache() {
      dispatch(flushSelectedGitSourceCache());
    },

    getUserRepositories: (sourceProvider, getRepoSuccess, getRepoFail) =>
      dispatch(getUserRepositories(sourceProvider, getRepoSuccess, getRepoFail)),
    deleteGithubAppAuthorization: (deleteGithubAppSuccess, deleteGithubAppFail) =>
      dispatch(deleteGithubAppAuthorization(deleteGithubAppSuccess, deleteGithubAppFail)),

    handleNotifyIDEContextReset(sourceProvider, repoOwner, repoName, branch, commit) {
      dispatch(notifyIDEContextReset(sourceProvider, repoOwner, repoName, branch, commit));
    },
  };
}

export const SourceGithubAccountListContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SourceGithubAccountList),
);
