import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

export class EventsPanelExpander extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabStyle: null,
    };

    this.style = {
      position: 'absolute',
      top: '1px',
      [this.props.position]: '1px',
      zIndex: 1000,
      fontSize: '18px',
      transition: 'transform 0.1s ease',
    };
  }

  handleExpandClick = () => {
    const element = document.querySelector(`[data-layout-path="${this.props.selector}"]`);
    if (this.state.tabStyle) {
      const { top, height, zIndex } = this.state.tabStyle;
      element.style.top = top;
      element.style.zIndex = zIndex;
      element.style.height = height;
      this.setState({ tabStyle: null });
    } else {
      const { top, height, zIndex } = element.style;
      this.setState({ tabStyle: { top, height, zIndex } });
      element.style.top = '0px';
      element.style.zIndex = '100';
      element.style.height = '100vh';
    }
  };

  render() {
    return (
      <Icon
        style={{ ...this.style, transform: `rotate(${this.state.tabStyle == null ? 180 : 0}deg)` }}
        onClick={this.handleExpandClick}
        key={'dropdown'}
        name={'dropdown'}
      />
    );
  }
}
