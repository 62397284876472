export const GET_LINEAR_ACCESS_TOKEN = 'GET_LINEAR_ACCESS_TOKEN';
export const GET_LINEAR_ACCESS_TOKEN_PENDING = 'GET_LINEAR_ACCESS_TOKEN_PENDING';
export const GET_LINEAR_ACCESS_TOKEN_FULFILLED = 'GET_LINEAR_ACCESS_TOKEN_FULFILLED';
export const GET_LINEAR_ACCESS_TOKEN_REJECTED = 'GET_LINEAR_ACCESS_TOKEN_REJECTED';

export const GET_JIRA_ACCESS_TOKEN = 'GET_JIRA_ACCESS_TOKEN';
export const GET_JIRA_ACCESS_TOKEN_PENDING = 'GET_JIRA_ACCESS_TOKEN_PENDING';
export const GET_JIRA_ACCESS_TOKEN_FULFILLED = 'GET_JIRA_ACCESS_TOKEN_FULFILLED';
export const GET_JIRA_ACCESS_TOKEN_REJECTED = 'GET_JIRA_ACCESS_TOKEN_REJECTED';

export const SET_EVENT_TO_SEND = 'SET_EVENT_TO_SEND';

export const FLUSH_EVENT_TO_SEND = 'FLUSH_EVENT_TO_SEND';
