import React, { Component } from 'react';
import './StatusBarSettings.scss';
import { CollaborativeWorkSwitcherContainer } from 'containers';

class StatusBarSettings extends Component {
  render() {
    return (
      <div className={'status-bar-settings'}>
        <CollaborativeWorkSwitcherContainer />
      </div>
    );
  }
}

export default StatusBarSettings;
