import {
  NOTIFY_APPS_AND_REPOS_STATE_CHANGE,
  NOTIFY_APPS_AND_REPOS_STATE_CHANGE_COMPLETED,
  NOTIFY_COLLAPSE_APPS_AND_REPOS,
  NOTIFY_COLLAPSE_APPS_AND_REPOS_COMPLETED,
} from '../constants/ide-apps-and-repositories-management';

export const notifyAppsAndRepositoriesStateChange = payload => {
  return {
    type: NOTIFY_APPS_AND_REPOS_STATE_CHANGE,
    payload,
  };
};

export const notifyAppsAndRepositoriesStateChangeCompleted = () => {
  return {
    type: NOTIFY_APPS_AND_REPOS_STATE_CHANGE_COMPLETED,
  };
};

export const notifyCollapseAppsAndRepositories = () => {
  return {
    type: NOTIFY_COLLAPSE_APPS_AND_REPOS,
  };
};

export const notifyCollapseAppsAndRepositoriesCompleted = () => {
  return {
    type: NOTIFY_COLLAPSE_APPS_AND_REPOS_COMPLETED,
  };
};
