import React, { Component } from 'react';
import { Popup, Table } from 'semantic-ui-react';

import '../TracepointEvents/EventsTable/EventTable.scss';
import { formatTime } from 'utils/date-util';
import { abbrFileName } from 'utils/string-util';

export class ErrorSnapshotsTable extends Component {
  getErrorTooltip = error => {
    return error ? (
      <Popup
        style={{ zIndex: '1000' }}
        trigger={
          <Table.Cell>
            <div style={{ textDecoration: 'underline' }}>{error.name}</div>
          </Table.Cell>
        }
        on={'click'}
        wide={'very'}
        content={
          <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
            <div>Message: {error.message} </div>
            <div>Stack: {error.stack}</div>
          </div>
        }
      />
    ) : (
      <Table.Cell />
    );
  };
  getRows = () => {
    const { onRowClick, highlightedRowIndex, errorSnapshots } = this.props;
    return errorSnapshots.map((el, index) => {
      let { time, applicationName, lineNo, error } = el;
      if (time == null) {
        time = el.createdAt;
      }
      if (applicationName == null) {
        applicationName = el.applicationFilter.name;
      }
      const moduleName = abbrFileName(el.fileName);
      const moduleNameAndLineNo = moduleName + ':' + lineNo;
      const key = `row-${index}`;
      let className = '';
      if (index % 2 === 0) className += ' even';
      else className += ' odd';
      if (index === highlightedRowIndex) className += ' highlighted';
      else className += ' normal';
      return (
        <Table.Row
          className={className}
          key={key}
          onClick={e => onRowClick(e, index)}
          onDoubleClick={e => {
            this.props.onRowDoubleClick && this.props.onRowDoubleClick(e, index);
          }}
        >
          <Table.Cell>{formatTime(time)}</Table.Cell>
          <Table.Cell>{applicationName}</Table.Cell>
          <Table.Cell>{moduleNameAndLineNo}</Table.Cell>
          {this.getErrorTooltip(error)}
          {this.props.childCells &&
            this.props.childCells.length > 0 &&
            this.props.childCells.map((cell, index) => <Table.Cell key={index}>{cell.component}</Table.Cell>)}
        </Table.Row>
      );
    });
  };

  render() {
    return (
      <Table celled className="trace-point-event-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: '25%' }}>Time</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '20%' }}>App Name</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '10%' }}>File Name </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '20%' }}>Error Name </Table.HeaderCell>
            {this.props.childCells &&
              this.props.childCells.length > 0 &&
              this.props.childCells.map((cell, index) => (
                <Table.HeaderCell key={index}>{cell.header}</Table.HeaderCell>
              ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.getRows()}</Table.Body>
      </Table>
    );
  }
}
