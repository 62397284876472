import React, { Component } from 'react';
import './AutocompletePopup.scss';
import { Icon } from 'semantic-ui-react';

export class AutocompleteInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuggestions: false,
      activeSuggestionIndex: 0,
      caretPosition: -1,
      overSugs: false,
      activeSug: '',
    };

    this.suggestionRefs = {};
    this.props.sugs.forEach(sug => (this.suggestionRefs[sug] = React.createRef(null)));
    this.inputRef = React.createRef(null);
  }

  handleEnterPress = () => {
    let { showSuggestions, activeSug, caretPosition } = this.state;
    let { value } = this.props;
    let end = this.inputRef?.current?.selectionEnd;
    let filterKey = '';
    if (caretPosition !== -1 && end != null) {
      filterKey = value.substring(caretPosition, end);
    }
    if (!showSuggestions) {
      return;
    }

    if (activeSug === '') {
      activeSug = this.getFilteredSugs()[0];
    }
    let newValue = value.slice(0, caretPosition) + activeSug + value.slice(caretPosition + filterKey.length);
    this.props.onChange(newValue, () => {
      this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = newValue.length;
      this.setState({ activeSuggestionIndex: 0 });
    });
    this.setState({
      activeSuggestionIndex: 0,
      activeSug: '',
      showSuggestions: false,
      caretPosition: -1,
    });
  };

  closeSuggestions = () => {
    this.setState({
      activeSuggestionIndex: 0,
      activeSug: '',
      showSuggestions: false,
      caretPosition: -1,
    });
  };

  getFilteredSugs = () => {
    const { caretPosition } = this.state;
    let { value } = this.props;
    let end = this.inputRef?.current?.selectionEnd;

    let filterKey = '';
    if (caretPosition !== -1 && end != null && caretPosition !== end) {
      filterKey = value.substring(caretPosition, end);
    }

    return this.props?.sugs?.filter(s => s.includes(filterKey));
  };

  onKeyDown = (e, data) => {
    const { activeSuggestionIndex } = this.state;
    if (e.keyCode === 32 && e.ctrlKey) {
      e.preventDefault();
      if (this.state.showSuggestions) {
        this.closeSuggestions();
        return;
      }
      let end = this.inputRef.current.selectionEnd;
      this.setState({
        caretPosition: end,
        showSuggestions: true,
      });
      return;
    }

    if (e.key === '{') {
      e.preventDefault();
      this.setState({ showSuggestions: true });
      this.props.onChange(this.props.value + '{{}}', () => {
        let end = this.inputRef.current.selectionEnd - 2;
        this.setState({ caretPosition: end, activeSuggestionIndex: 0 });
        this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = end;
      });

      return;
    }

    //ENTER
    if (e.keyCode === 13) {
      this.handleEnterPress();
      // User pressed the up arrow, increment the index
    } else if (e.keyCode === 38) {
      e.preventDefault();
      let filteredSugs = this.getFilteredSugs();
      if (activeSuggestionIndex === 0) {
        return;
      }
      this.setState(
        {
          activeSuggestionIndex: activeSuggestionIndex - 1,
          activeSug: filteredSugs[activeSuggestionIndex + -1],
        },
        () => this.suggestionRefs[filteredSugs[this.state.activeSuggestionIndex]].current.scrollIntoView(),
      );
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      e.preventDefault();
      let filteredSugs = this.getFilteredSugs();
      if (activeSuggestionIndex === filteredSugs.length - 1) {
        return;
      }
      this.setState(
        {
          activeSuggestionIndex: activeSuggestionIndex + 1,
          activeSug: filteredSugs[activeSuggestionIndex + 1],
        },
        () => this.suggestionRefs[filteredSugs[this.state.activeSuggestionIndex]].current.scrollIntoView(),
      );
    }
  };

  render() {
    let filteredSugs = this.getFilteredSugs();
    let filteredSugsList =
      filteredSugs.length > 0 ? (
        filteredSugs?.map((s, index) => (
          <div
            key={`${s}`}
            ref={this.suggestionRefs[s]}
            className={`suggestion` + (index === this.state.activeSuggestionIndex ? ' active' : '')}
            onClick={() => {
              this.setState({ activeSuggestionIndex: index, activeSug: s }, () => this.handleEnterPress());
            }}
          >
            {s}
          </div>
        ))
      ) : (
        <div>No matching variables.</div>
      );

    return (
      <div className={'autocomplete-input'} key={this.props.key}>
        <div className={'ui input'} key={'ui-input-custom'}>
          <input
            onBlur={() => !this.state.overSugs && this.closeSuggestions}
            ref={this.inputRef}
            type="text"
            placeholder={this.props?.placeholder}
            value={this.props.value}
            onKeyDown={this.onKeyDown}
            onChange={e => {
              this.props.onChange(e.target.value);
              this.setState({ activeSuggestionIndex: 0 }, () =>
                this.suggestionRefs[filteredSugs[this.state.activeSuggestionIndex]]?.current?.scrollIntoView(),
              );
            }}
          />
        </div>

        {this.state.showSuggestions && (
          <div
            onMouseOver={() => this.setState({ overSugs: true })}
            onMouseOut={() => this.setState({ overSugs: false })}
            className={'recommendation-popup'}
            key={'sugs-popup'}
          >
            <Icon
              style={{ position: 'absolute', right: '0', top: '0' }}
              name={'close'}
              onClick={this.closeSuggestions}
            />
            {this.props.sugs.length > 0 ? (
              filteredSugsList
            ) : (
              <div>
                No matching variables. Suggestions are made based on captured events, make sure you have collected
                events.
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
