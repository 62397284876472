import {
  UPDATE_WORKSPACE_FULFILLED,
  UPDATE_WORKSPACE_PENDING,
  UPDATE_WORKSPACE_REJECTED,
} from '../constants/workspace-update';

const updateWorkspaceInitialState = {
  updatedWorkspace: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function updateWorkspace(state = updateWorkspaceInitialState, action) {
  switch (action.type) {
    case UPDATE_WORKSPACE_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case UPDATE_WORKSPACE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        updatedWorkspace: action.payload.data,
        error: null,
      };
    case UPDATE_WORKSPACE_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
