import {
  KICK_WORKSPACE_ACCOUNT_FULFILLED,
  KICK_WORKSPACE_ACCOUNT_PENDING,
  KICK_WORKSPACE_ACCOUNT_REJECTED,
} from '../constants/workspace-kick-account';

const kickWorkspaceAccountResultInitialState = {
  kickWorkspaceAccountResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function kickWorkspaceAccount(state = kickWorkspaceAccountResultInitialState, action) {
  switch (action.type) {
    case KICK_WORKSPACE_ACCOUNT_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case KICK_WORKSPACE_ACCOUNT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        kickWorkspaceAccountResult: action.payload.data,
      };
    case KICK_WORKSPACE_ACCOUNT_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
