import { roles } from './role-utils';
import { CONFIG } from './config-util';

export const sidebarDecorator = (accountRole, billingAccount, activeWorkspace) => {
  let renderSettingsMenu = true;

  let settingsMenuItems = {
    workspaceSettings: true,
    billing: true,
    inviteToWorkspace: true,
    integrations: true,
  };

  let userMenuItems = {
    workspaces: true,
  };

  if (CONFIG?.ON_PREM) {
    renderSettingsMenu = false;
    userMenuItems.workspaces = false;
  }

  //if role is user, and it is not billing account, then don't render settings menu
  if (accountRole === roles.USER) {
    if (billingAccount) {
      settingsMenuItems.inviteToWorkspace = false;
      settingsMenuItems.workspaceSettings = false;
      settingsMenuItems.integrations = false;
    } else {
      renderSettingsMenu = false;
    }
  }

  //billing admin cant see workspace settings and invite to workspace
  if (accountRole === roles.BILLING_ADMIN) {
    settingsMenuItems.inviteToWorkspace = false;
    settingsMenuItems.workspaceSettings = false;
    settingsMenuItems.integrations = false;
  }

  // admin cant see billing page
  if (accountRole === roles.ADMIN) {
    settingsMenuItems.billing = false;
  }

  if (billingAccount) {
    settingsMenuItems.billing = true;
  }

  if (activeWorkspace?.parentWorkspaceId != null) {
    settingsMenuItems.billing = false;
  }

  return {
    renderSettingsMenu: renderSettingsMenu,
    settingsMenuItems: settingsMenuItems,
    userMenuItems: userMenuItems,
  };
};
