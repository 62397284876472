import {
  IDE_CONTEXT_RESET_NOTIFY,
  IDE_CONTEXT_RESET_SUCCEEDED,
  IDE_CONTEXT_RESET_FAILED,
} from '../constants/ide-context';

const initialState = {
  resetStatus: '',
  contextMetaData: {},
};

export default function ideContext(state = initialState, action) {
  switch (action.type) {
    case IDE_CONTEXT_RESET_NOTIFY:
    case IDE_CONTEXT_RESET_SUCCEEDED:
    case IDE_CONTEXT_RESET_FAILED:
      return {
        ...state,
        resetStatus: action.type,
        contextMetaData: action.payload.data,
      };
    default:
      return state;
  }
}
