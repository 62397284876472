import React, { Component } from 'react';

export class CopyBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  copyValue = value => {
    navigator.clipboard.writeText(value);
    this.setState({
      copied: true,
    });
    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 2000);
  };

  render() {
    const { value } = this.props;
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', backgroundColor: '#151515' }}
        className={'copy-box'}
      >
        <div>{value}</div>
        {this.state.copied ? (
          <span className={'right-item'}>Copied!</span>
        ) : (
          <i onClick={() => this.copyValue(value)} className={'clickable copy icon'} />
        )}
      </div>
    );
  }
}
