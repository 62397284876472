import React, { Component } from 'react';
import { Button, Dropdown, Input, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';

export class JiraIntegrationClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organisation: null,
      project: null,
      issueType: null,
      summary: null,
      createLoading: false,
    };
  }

  closeModal = () => {
    this.props.flushJiraActions();
    this.props.closeModal();
  };
  async componentDidMount() {
    this.props.getAccessToken(
      this.props.code,
      res => {
        const token = res.value.data.access_token;
        this.props.getJiraOrganisations(
          token,
          () => null,
          () => null,
        );
      },
      () => null,
    );
  }

  issueSummaryDisabled = () => {
    return !(this.state.organisation && this.state.issueType && this.state.project);
  };

  confirmButtonDisabled = () => {
    return !(this.state.organisation && this.state.issueType && this.state.project) && this.state.summary;
  };

  createOrgsOptions = () => {
    if (!this.props.issueManagementJira.organisationsFetched) {
      return [];
    }
    return this.props.issueManagementJira.organisations.map(org => ({ key: org.id, text: org.name, value: org.id }));
  };
  handleOrgsChange = (e, { value }) => {
    this.setState({ organisation: value, project: null, issueType: null });
    this.props.getJiraProjects(
      value,
      res => {
        console.log(res);
      },
      () => null,
    );
  };

  createProjectsOptions = () => {
    if (!this.props.issueManagementJira.projectsFetched) {
      return [];
    }
    return this.props.issueManagementJira.projects.map(proj => ({
      key: proj.id,
      text: proj.name,
      value: proj.id,
    }));
  };
  handleProjectsChange = (e, { value }) => {
    this.setState({ project: value, issueType: null });
    this.props.getJiraIssueTypes(
      value,
      this.state.organisation,
      () => null,
      () => null,
    );
  };

  createIssueTypesOptions = () => {
    if (!this.props.issueManagementJira.issueTypesFetched) {
      return [];
    }
    return this.props.issueManagementJira.issueTypes
      .filter(issue => !issue.subtask)
      .map(proj => ({
        key: proj.id,
        text: proj.name,
        value: proj.id,
      }));
  };

  handleIssueTypeChange = (e, { value }) => {
    this.setState({ issueType: value });
  };

  createIssue = () => {
    this.setState({ createLoading: true });
    this.props.createJiraIssue(
      this.state.organisation,
      {
        fields: {
          summary: this.state.summary,
          project: {
            id: this.state.project,
          },
          issuetype: {
            id: this.state.issueType,
          },
        },
      },
      res => {
        //attach comment
        this.props.createJiraIssueComment(
          this.state.organisation,
          res.payload.id,
          {
            body: {
              type: 'doc',
              version: 1,
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: JSON.stringify(this.props.issueManagementToken.eventToSend.frames),
                      type: 'text',
                    },
                  ],
                },
              ],
            },
          },
          () => {
            this.closeModal();
            toast.success('You successfully created issue on Jira!', ToastConfig);
          },
          () => {
            this.closeModal();
            toast.error('Error happened while creating issue!', ToastConfig);
          },
        );
      },
      () => null,
    );
  };
  render() {
    return (
      <Modal closeOnDimmerClick={false} open={true}>
        <Modal.Header>Create Jira Issue</Modal.Header>
        <Modal.Content>
          <div className={'field'}>
            <div className={'label'}>Organisations</div>
            <Dropdown
              value={this.state.organisation}
              placeholder={'Select Organisation'}
              loading={this.props.issueManagementJira.organisationsFetching}
              onChange={this.handleOrgsChange}
              options={this.createOrgsOptions()}
            />
          </div>
          <div className={'field'}>
            <div className={'label'}>Projects</div>
            <Dropdown
              disabled={this.state.organisation == null}
              value={this.state.project}
              placeholder={'Select Project'}
              loading={this.props.issueManagementJira.projectsFetching}
              onChange={this.handleProjectsChange}
              options={this.createProjectsOptions()}
            />
          </div>
          <div className={'field'}>
            <div className={'label'}>Issue Types</div>
            <Dropdown
              disabled={this.state.project == null}
              value={this.state.issueType}
              placeholder={'Select Issue Type'}
              loading={this.props.issueManagementJira.issueTypesFetching}
              options={this.createIssueTypesOptions()}
              onChange={this.handleIssueTypeChange}
            />
          </div>
          <div className={'field'}>
            <div className={'label'}>Issue Summary</div>
            <Input
              disabled={this.issueSummaryDisabled()}
              value={this.state.summary}
              onChange={e => this.setState({ summary: e.target.value })}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button className={'secondary button-step-info'} content={'Cancel'} onClick={this.closeModal} />
          <Button
            loading={this.state.createLoading}
            content={'Confirm'}
            onClick={this.createIssue}
            disabled={this.confirmButtonDisabled()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
