export const timeToValueUnitPair = seconds => {
  if (seconds >= 3600 && seconds % 3600 === 0) {
    return { value: seconds / 3600, unit: 'hours' };
  } else if (seconds >= 60 && seconds % 60 === 0) {
    return { value: seconds / 60, unit: 'minutes' };
  } else {
    return { value: seconds, unit: 'seconds' };
  }
};

export const convertTimeToUnit = (value, oldUnit, newUnit) => {
  const unitsAsSeconds = { hours: 3600, minutes: 60, seconds: 1 };
  const seconds = value * (unitsAsSeconds[oldUnit] || 1);
  return Math.floor(seconds / (unitsAsSeconds[newUnit] || 1)) || 1;
};
