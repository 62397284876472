import React, { Component } from 'react';
import { MainLayout } from 'layouts';
import routeList from 'routes';

import './EventHistoryPage.scss';
import { Button, Icon } from 'semantic-ui-react';
import { EventsTable } from '../TracepointEvents/EventsTable/EventsTable';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { LogTable } from '../LogPointEvents/LogTable';
import { ErrorSnapshotsTable } from '../ErrorSnapshots/ErrorSnapshotsTable';
import { abbrFileName } from '../../utils/string-util';
import { EventsFilter } from '../TracepointEvents/EventsFilter/EventsFilter';
import { EventHistoryModal } from './EventHistoryModal';
import { svgArrow, svgCheck } from '../TracepointEvents/TracePointEvents';
import { EventDetails } from '../TracepointEvents/EventsDetails/EventDetails';
import { formatTime } from 'utils/date-util';

export const RETENTION = {
  DAYS_7: { label: '7 DAYS', groupBy: 'DAILY', dataLength: 7 },
  DAYS_30: { label: '30 DAYS', groupBy: 'DAILY', dataLength: 30 },
  HOURS_24: { label: '24 HOURS', groupBy: 'HOURLY', dataLength: 24 },
};

export const DAY_MAP = {
  0: 'Sun',
  1: 'Mo',
  2: 'Tu',
  3: 'Wed',
  4: 'Th',
  5: 'Fri',
  6: 'Sat',
};

export const COLOR_MAP = {
  ERRORSNAPSHOT: '#F8524A',
  TRACEPOINT: '#8D80DC',
  LOGPOINT: '#FFD233',
};

const EVENT_TYPES = {
  TRACEPOINT_EVENTS: 'TRACEPOINT EVENTS',
  LOGPOINT_EVENTS: 'LOGPOINT EVENTS',
  ERROR_SNAPSHOTS: 'ERROR SNAPSHOTS',
};
class EventHistoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retention: RETENTION.DAYS_30,
      selectedEventType: EVENT_TYPES.TRACEPOINT_EVENTS,
      tracepointHitCount: [],
      logpointHitCount: [],
      errorsnapshotHitCount: [],
      graphWidth: 50,
      clientFilter: '',
      highlightedRowIndex: -1,
      selectedEvent: null,
      modalOpen: false,
      selectedEventDetailType: 'variables',
    };
    this.eventTypeMap = {
      [EVENT_TYPES.TRACEPOINT_EVENTS]: 'TRACEPOINT',
      [EVENT_TYPES.LOGPOINT_EVENTS]: 'LOGPOINT',
      [EVENT_TYPES.ERROR_SNAPSHOTS]: 'ERRORSNAPSHOT',
    };

    this.graphRef = React.createRef();
  }

  createGraphDate = (response, startDate, dataLength, groupBy) => {
    let countData = response.value.data;
    const graphDataLength = dataLength;
    let graphData = [];
    for (let i = 0; i < graphDataLength; ++i) {
      let loopDate = new Date(startDate);
      if (groupBy === 'DAILY') {
        loopDate.setDate(startDate.getDate() + i);
        loopDate.setHours(24);
      } else {
        loopDate.setHours(startDate.getHours() + i + startDate.getTimezoneOffset() / 60);
      }
      let loopTime = loopDate.getTime();
      const responseItem = countData.find(r => {
        const rDate = new Date(r.hitDate);
        return rDate.getTime() === loopTime;
      });

      if (responseItem) {
        let newDate = new Date(responseItem.hitDate);
        newDate.setHours(newDate.getHours() - startDate.getTimezoneOffset() / 60);
        graphData.push({ hitDate: newDate, hitCount: responseItem.hitCount });
      } else {
        graphData.push({ hitDate: loopDate, hitCount: 0 });
      }
    }
    return graphData;
  };

  createStartDate = retention => {
    const a = new Date();
    const utcDate = new Date(a);
    utcDate.setMinutes(0);
    utcDate.setSeconds(0);
    utcDate.setMilliseconds(0);
    if (retention.label === RETENTION.HOURS_24.label) {
      utcDate.setHours(utcDate.getHours() - 23);
    } else {
      const daysBefore = retention.dataLength;
      utcDate.setDate(utcDate.getDate() - daysBefore);
      utcDate.setHours(8 - utcDate.getTimezoneOffset() / 60);
    }

    return utcDate;
  };

  getEventHistory() {
    const activeWorkspaceId = this.props.activeWorkspace.workspace.workspace.id;
    this.props.getEventHistory(
      this.eventTypeMap[this.state.selectedEventType],
      activeWorkspaceId,
      this.createStartDate(this.state.retention),
      () => null,
      () => null,
    );
  }

  getProbeHitCount = (fileName, lineNo, startDate, eventType, onSuccess) => {
    const activeWorkspaceId = this.props.activeWorkspace.workspace.workspace.id;
    this.props.getProbeHitCount(
      fileName,
      lineNo,
      this.state.retention.groupBy,
      eventType,
      activeWorkspaceId,
      startDate,
      res => {
        this.setState({
          [`${eventType.toLowerCase()}HitCount`]: this.createGraphDate(
            res,
            startDate,
            this.state.retention.dataLength,
            this.state.retention.groupBy,
          ),
        });
        onSuccess();
      },
      () => null,
    );
  };

  getProbeHitCounts = (fileName, lineNo) => {
    const startDate = this.createStartDate(this.state.retention);
    this.getProbeHitCount(fileName, lineNo, startDate, 'TRACEPOINT', () =>
      this.getProbeHitCount(fileName, lineNo, startDate, 'LOGPOINT', () =>
        this.getProbeHitCount(fileName, lineNo, startDate, 'ERRORSNAPSHOT', () => null),
      ),
    );
  };
  componentDidMount() {
    this.getEventHistory();
    this.getProbeHitCounts(null, null);
    this.setState({ graphWidth: this.graphRef.current.width });
  }

  renderHeader = () => {
    return (
      <div className={'event-history-page-header-container'}>
        <div className={'header'}>Event Explorer</div>
        <div className={'button-group'}>
          {Object.entries(RETENTION).map(([key, val]) => (
            <Button
              className={'selection-button' + (this.state.retention.label === val.label ? ' selected' : '')}
              key={key}
              content={val.label}
              onClick={() => {
                this.setState(
                  { retention: val, tracepointHitCount: [], logpointHitCount: [], errorsnapshotHitCount: [] },
                  () => {
                    this.getEventHistory();
                    this.getProbeHitCounts(null, null);
                  },
                );
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  getCustomCells() {
    return [
      {
        header: 'Chart',
        component: (
          <a
            onClick={(e, index) => {
              this.setState({ modalOpen: true, selectedEvent: this.getFilteredEvents()[index] });
            }}
            style={{ display: 'inline-flex' }}
          >
            <Icon name={'chart line'} />
            Show
          </a>
        ),
      },
    ];
  }

  renderGraph = () => {
    let datax = [];

    if (
      this.state.tracepointHitCount.length !== 0 &&
      this.state.logpointHitCount.length !== 0 &&
      this.state.errorsnapshotHitCount.length !== 0
    ) {
      datax = new Array(this.state.retention.dataLength).fill(null).map((_, index) => {
        let date = new Date(`${this.state.tracepointHitCount[index].hitDate}`);
        let name = '';
        if (this.state.retention.groupBy === 'DAILY') {
          name = `${date.getDate()} ${DAY_MAP[date.getDay()]}`;
        } else {
          name = `${date.getHours()}:00`;
        }
        return {
          name: name,
          tracepoint: this.state.tracepointHitCount[index].hitCount,
          logpoint: this.state.logpointHitCount[index].hitCount,
          errorsnapshot: this.state.errorsnapshotHitCount[index].hitCount,
        };
      });
    }

    return (
      <div className={'event-history-page-graph-container'} ref={this.graphRef}>
        <ResponsiveContainer width={this.state.graphWidth} height="90%">
          <BarChart
            width={800}
            height={320}
            data={datax}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fill: '#d9d9d9' }} />
            <YAxis tick={{ fill: '#d9d9d9' }} />
            <Tooltip />
            <Legend />
            <Bar dataKey="errorsnapshot" stackId="a" fill={COLOR_MAP.ERRORSNAPSHOT} />
            <Bar dataKey="logpoint" stackId="b" fill={COLOR_MAP.LOGPOINT} />
            <Bar dataKey="tracepoint" stackId="c" fill={COLOR_MAP.TRACEPOINT} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  getFilteredEvents = () => {
    const { clientFilter } = this.state;

    if (clientFilter.trim().length > 0) {
      return this.props.eventHistory.eventHistory.filter(el => {
        const { time, applicationName, lineNo } = JSON.parse(el.eventData);
        const moduleName = abbrFileName(el.fileName);
        const moduleNameAndLineNo = moduleName + ':' + lineNo;
        const fullLine = `${formatTime(time)} ${applicationName} ${moduleNameAndLineNo} ${
          el.probeTags?.join(' ') || ''
        } ${el.id}`;
        return fullLine.includes(clientFilter);
      });
    } else {
      return [...this.props.eventHistory.eventHistory];
    }
  };

  getTableComponent = () => {
    switch (this.state.selectedEventType) {
      case EVENT_TYPES.LOGPOINT_EVENTS:
        return (
          <LogTable
            onRowClick={(e, index) => this.setState({ selectedEvent: this.getFilteredEvents()[index] })}
            logPointEvents={this.getFilteredEvents().map(log => {
              const eventData = JSON.parse(log.eventData);
              log.logMessage = eventData.logMessage;
              return log;
            })}
            highlightedRowIndex={this.state.highlightedRowIndex}
            childCells={this.getCustomCells()}
          />
        );
      case EVENT_TYPES.TRACEPOINT_EVENTS:
        return (
          <EventsTable
            onRowClick={(e, index) =>
              this.setState({ selectedEvent: this.getFilteredEvents()[index], highlightedRowIndex: index })
            }
            onTraceFilterClick={(e, traceId) => {
              e.stopPropagation();
              e.preventDefault();
              this.setClientFilterAndResetDetails(traceId);
            }}
            apmBaseUrl={this.props.activeWorkspace.workspace.workspaceSettings?.apmBaseUrl}
            events={this.getFilteredEvents()}
            highlightedRowIndex={this.state.highlightedRowIndex}
            childCells={this.getCustomCells()}
          />
        );
      case EVENT_TYPES.ERROR_SNAPSHOTS:
        return (
          <ErrorSnapshotsTable
            onRowClick={(e, index) =>
              this.setState({ selectedEvent: this.getFilteredEvents()[index], highlightedRowIndex: index })
            }
            errorSnapshots={this.getFilteredEvents().map(error => {
              const eventData = JSON.parse(error.eventData);
              error.error = eventData.error;
              return error;
            })}
            highlightedRowIndex={this.state.highlightedRowIndex}
            childCells={this.getCustomCells()}
          />
        );
      default:
        return null;
    }
  };

  getEventDetailsTabButtonClassName = checkBtnId => {
    const { selectedEventDetailType } = this.state;
    if (checkBtnId === selectedEventDetailType) {
      return 'selected-btn';
    }
    return 'normal-btn';
  };

  renderEventDetails = () => {
    if (this.state.selectedEvent == null) {
      return (
        <div className="event-details-container">
          <div className="not-selected-event-details-container">
            <div className="left-part">{svgArrow()}</div>
            <div className="center-part">
              <div className="first-line">{svgCheck()}</div>
              <div className="second-line">Select an Event</div>
              <div className="third-line">See the snapshots of variable!</div>
            </div>
            <div className="right-part" />
          </div>
        </div>
      );
    }
    return (
      <div className={'event-details'}>
        <div className="event-details-container">
          {this.state.selectedEvent.type === 'TRACEPOINT' && (
            <div className="event-details-action-group-container">
              <Button.Group>
                <Button
                  className={this.getEventDetailsTabButtonClassName('variables')}
                  onClick={e => this.setState({ selectedEventDetailType: 'variables' })}
                >
                  Variables
                </Button>
                <Button
                  className={this.getEventDetailsTabButtonClassName('metadata')}
                  onClick={e => this.setState({ selectedEventDetailType: 'metadata' })}
                >
                  Metadata
                </Button>
                <Button
                  className={this.getEventDetailsTabButtonClassName('frames')}
                  onClick={e => this.setState({ selectedEventDetailType: 'frames' })}
                >
                  Frames
                </Button>
              </Button.Group>
            </div>
          )}

          <div style={{ height: 'calc(100% - 40px)' }}>
            <EventDetails
              event={JSON.parse(this.state.selectedEvent.eventData)}
              type={this.state.selectedEventDetailType}
            />
          </div>
        </div>
      </div>
    );
  };
  setClientFilterAndResetDetails = newFilter => {
    this.setState({
      clientFilter: newFilter,
      highlightedRowIndex: -1,
      selectedErrorSnapshot: null,
    });
  };

  renderEvents = () => {
    let tableComponent = this.getTableComponent();

    return (
      <div className={'event-history-page-events-container'}>
        <div className={'section-header'}>
          <div className={'button-group'}>
            {Object.entries(EVENT_TYPES).map(([key, val]) => (
              <Button
                className={'selection-button' + (this.state.selectedEventType === val ? ' selected' : '')}
                key={key}
                content={val}
                onClick={() =>
                  this.setState({ selectedEventType: val, selectedEvent: null, highlightedRowIndex: -1 }, () =>
                    this.getEventHistory(),
                  )
                }
              />
            ))}
          </div>
          <div className={'sub-header'}>
            <div>EVENTS</div>
            <div>
              <EventsFilter
                clientFilter={this.state.clientFilter}
                setClientFilter={newClientFilter => this.setClientFilterAndResetDetails(newClientFilter)}
              />
            </div>
          </div>
        </div>
        <div className={'events-table'}>
          <div className={'table-container'}>{tableComponent}</div>
          {[EVENT_TYPES.TRACEPOINT_EVENTS, EVENT_TYPES.ERROR_SNAPSHOTS].includes(this.state.selectedEventType) &&
            this.renderEventDetails()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <MainLayout title={routeList.eventHistory.title}>
        <div className="event-history-page-wrapper">
          {this.renderHeader()}
          {this.renderGraph()}
          {this.renderEvents()}
          {this.state.modalOpen && (
            <EventHistoryModal
              color={COLOR_MAP[this.state.selectedEvent.type]}
              onClose={() => {
                this.setState({ modalOpen: false, selectedEvent: null });
              }}
              getProbeHitCount={(onSuccess, retention) => {
                const { fileName, lineNo, type } = this.state.selectedEvent;
                const activeWorkspaceId = this.props.activeWorkspace.workspace.workspace.id;

                const startDate = this.createStartDate(retention);
                this.props.getProbeHitCount(
                  fileName,
                  lineNo,
                  retention.groupBy,
                  type,
                  activeWorkspaceId,
                  startDate,
                  res => {
                    onSuccess(this.createGraphDate(res, startDate, retention.dataLength, retention.groupBy));
                  },
                  () => null,
                );
              }}
            />
          )}
        </div>
      </MainLayout>
    );
  }
}

export default EventHistoryPage;
