import React, { Component } from 'react';
import { SidekickConfirmModal } from 'components';
import { disableCollaborationRequestCmdObj, enableCollaborationRequestCmdObj } from 'utils/broker-commands';
import './CollaborativeWorkSwitcher.scss';

export class CollaborativeWorkSwitcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      collaborativeWorkEnabled: false,
      modalAction: null,
    };
  }

  closeModal = () => {
    this.setState({ isModalOpen: false, modalAction: false });
  };

  handleCollaborativeSwitch = () => {
    this.setState({ isModalOpen: true });
  };

  render() {
    const { collaborativeWorkEnabled: collabEnabled } = this.state;
    return (
      <div className={'collaborative-work-switcher'}>
        <div className={'collab-work-button' + (collabEnabled ? ' on' : '')} onClick={this.handleCollaborativeSwitch}>
          {collabEnabled ? 'Collab mode on' : 'Collab mode off'}
        </div>
        <SidekickConfirmModal
          modalOpen={this.state.isModalOpen}
          onClose={this.closeModal}
          content={'Events and logs will be cleared. Do you wanna continue?'}
          confirmModalAction={() => {
            const { collaborativeWorkEnabled } = this.state;
            const command = collaborativeWorkEnabled
              ? disableCollaborationRequestCmdObj
              : enableCollaborationRequestCmdObj;
            this.setState({ collaborativeWorkEnabled: !collaborativeWorkEnabled });
            this.props.handleBrokerSend(command);
            this.closeModal();
          }}
        />
      </div>
    );
  }
}
