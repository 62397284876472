import React, { Component } from 'react';
import URL_POOL from 'utils/url-pool';
import { Button, Checkbox, Dropdown } from 'semantic-ui-react';
import { LOG_LEVEL_OPTIONS } from 'utils/log-level-util';
import { AutocompleteInput } from '../AutocompleteInput/AutocompleteInput';

export class PutSidekickActionPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logExpression: '',
      sidekickActionLogLevel: 'INFO',
      sidekickActionDisabled: false,
      sidekickActionCondition: '',
    };
  }

  renderSidekickActionPanels = () => {
    switch (this.props.probeType) {
      case 'TP':
        return this.renderTracepointFields();
      case 'LP': {
        return this.renderLogpointFields();
      }
      default:
        return null;
    }
  };

  handleLogLevelChange = newLogLevel => {
    this.setState({
      sidekickActionLogLevel: newLogLevel,
    });
  };

  handleEnabledChange = () => {
    this.setState({
      sidekickActionDisabled: !this.state.sidekickActionDisabled,
    });
  };

  renderConditionField = () => {
    return (
      <AutocompleteInput
        placeholder={'Enter condition'}
        value={this.state.sidekickActionCondition}
        onChange={(val, callback) =>
          this.setState(
            {
              sidekickActionCondition: val,
            },
            callback,
          )
        }
        sugs={this.props.sugs}
      />
    );
  };

  renderEnabledField = () => {
    return (
      <div className={'field'} key={'enabled'}>
        <Checkbox label={'Enabled'} checked={!this.state.sidekickActionDisabled} onChange={this.handleEnabledChange} />
      </div>
    );
  };

  createAddConfig = actionType => {
    let sidekickActionConfig = {
      payloadType: this.props.probeType,
      disabled: this.state.sidekickActionDisabled,
      conditionExpression: this.state.sidekickActionCondition,
      actionType: actionType,
    };

    if (this.props.probeType === 'LP') {
      sidekickActionConfig.logExpression = this.state.logExpression;
      sidekickActionConfig.logLevel = this.state.sidekickActionLogLevel;
    }
    return sidekickActionConfig;
  };

  renderActions = () => {
    return (
      <div className={'actions-row'}>
        <Button className={'secondary button-step-info'} content={'Cancel'} onClick={this.props.onCancel} />
        <Button
          className={'secondary button-step-info'}
          content={'Detailed'}
          onClick={() => {
            this.props.onAdd(this.createAddConfig('DETAILED_ADD'));
            this.props.onCancel(false, -1);
          }}
        />
        <Button
          content={'Add'}
          onClick={() => {
            this.props.onAdd(this.createAddConfig('ADD'));
            this.props.onCancel(false, -1);
          }}
        />
      </div>
    );
  };

  renderTracepointFields = () => {
    return (
      <>
        {this.renderConditionField()}
        {this.renderEnabledField()}
      </>
    );
  };

  renderLogpointFields = () => {
    return (
      <>
        <div className={'second-line'}>
          You can use variable values using {'{{mustache_expressions}}'}.{' '}
          <a href={URL_POOL.SIDEKICK_LOG_POINT_DOC} target={'_blank'} rel="noopener noreferrer">
            Read more.
          </a>
        </div>
        {this.renderConditionField()}
        <AutocompleteInput
          key={'log-expression'}
          placeholder={'Enter log Expression'}
          value={this.state.logExpression}
          onChange={(val, callback) =>
            this.setState(
              {
                logExpression: val,
              },
              callback,
            )
          }
          sugs={this.props.sugs}
        />
        <div className={'field'} key={'log-level'}>
          <label>Log Level</label>
          <Dropdown
            className={'time-unit-dropdown'}
            fluid
            selection
            options={LOG_LEVEL_OPTIONS}
            value={this.state.sidekickActionLogLevel}
            onChange={(e, data) => this.handleLogLevelChange(data.value)}
          />
        </div>
        {this.renderEnabledField()}
      </>
    );
  };

  render() {
    return (
      <div className={'add-log-expression-container'}>
        {this.renderSidekickActionPanels()}
        {this.renderActions()}
      </div>
    );
  }
}
