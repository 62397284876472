import React, { Component } from 'react';
import FlexLayout from 'flexlayout-react';
import './IDEComplexLayout.scss';

import { getIDEModel, getIDEModelSourceNode, getIdsOfTabsInIDEModel, storeIDEModel2LocalStorage } from './IDEModel';
import { filterSourceFilesWithContextMetaData, setSourceFiles } from 'utils/source-files';

import 'assets/ide-layout-styles/dark.css';

import {
  NOTIFY_FIND_AND_ADD_SOURCE,
  NOTIFY_SOURCE_EXISTED,
  NOTIFY_SOURCE_FOCUS,
  NOTIFY_SOURCE_NOT_AVAILABLE,
  NOTIFY_SOURCE_SELECT,
} from 'store/constants/source-focus';

import { IDE_CONTEXT_RESET_NOTIFY, IDE_CONTEXT_RESET_SUCCEEDED } from 'store/constants/ide-context';

import {
  AppsAndRepositoriesContainer,
  ErrorSnapshotsContainer,
  LogPointEventsContainer,
  OpenedTraceMapsContainer,
  SidekickActionsContainer,
  ThundraSourceEditorContainer,
  ThundraSourceWelcomeContainer,
  TracePointEventsContainer,
} from 'containers';

import { OPEN_APPS_AND_TPS, OPEN_EVENTS, OPEN_SOURCES } from 'store/constants/ide-toggle-border-tabs';
import { SA_EDIT_NOTIFY } from 'store/constants/sidekick-action-edit';
import { SA_EVENTS_FOCUS_NOTIFY } from 'store/constants/sidekick-action-events-focus';
import StatusBarSettings from '../StatusBarSettings/StatusBarSettings';
import Draggable from 'react-draggable';
import {
  APPS_AND_SOURCES,
  COLLAPSED,
  NOTIFY_APPS_AND_REPOS_STATE_CHANGE,
  NOTIFY_COLLAPSE_APPS_AND_REPOS,
} from 'store/constants/ide-apps-and-repositories-management';

const INITIAL_WIDTH = 240;

export class IDEComplexLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: FlexLayout.Model.fromJson(getIDEModel()),
      leftWidth: INITIAL_WIDTH,
      sidebarDragging: false,
      sidebarDragX: 0,
      draggingBtnLeft: this.getDraggingBtnLeft(),
    };
  }

  glowBorderTab = clsName => {
    const elements = document.getElementsByClassName(clsName);
    if (elements.length > 0) {
      const borderTab = elements[0];
      borderTab.classList.add('glow');
      setTimeout(function () {
        borderTab.classList.remove('glow');
      }, 2000);
    }
  };

  iconFactory = node => {
    //console.log(node._attributes.id);
    if (node._attributes.id === 'RepositoryManagementContainer') {
      return <img alt="source-icon" className={'ide-tab-button-icon left-side sources'} />;
    }
    if (node._attributes.id === 'AppsAndTracePointsContainer') {
      return <img alt="app-tp-icon" className={'ide-tab-button-icon right-side app-and-tracepoint'} />;
    }

    if (node._attributes.id === 'ThundraSourceWelcomeContainer') {
      return <img alt="home-icon" className={'ide-tab-button-icon top-side home'} />;
    }

    return '';
  };

  convertNodeId2SourcePath = id => {
    return id.replace('src#', '');
  };

  convertNodeId2Event = id => {
    const eventId = id.replace('evt#', '');
    const events = this.props.broker.events;
    return events.find(el => el.id === eventId);
  };

  factory = node => {
    const component = node.getComponent();
    switch (component) {
      case 'AppsAndRepositoriesContainer':
        return <AppsAndRepositoriesContainer />;
      case 'SidekickActionsContainer':
        return <SidekickActionsContainer />;
      case 'TracePointEventsContainer':
        return <TracePointEventsContainer />;
      case 'LogPointEventsContainer':
        return <LogPointEventsContainer />;
      case 'ErrorSnapshotsContainer':
        return <ErrorSnapshotsContainer />;
      case 'OpenedTraceMapsContainer':
        return <OpenedTraceMapsContainer />;
      case 'ThundraSourceWelcomeContainer':
        return <ThundraSourceWelcomeContainer />;
      case 'ThundraSourceEditorContainer':
        return <ThundraSourceEditorContainer sourcePath={this.convertNodeId2SourcePath(node.getId())} />;
      default:
        return '';
    }
  };

  modelChanged = data => {
    //console.log(data);
    storeIDEModel2LocalStorage(data);

    //FireEvent
    const event = new Event('ide_layout_updated');
    document.dispatchEvent(event);
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    //This Component Not Needed ReRender...
    //It listen broker and refresh Every 5 seconds..
    //FlexLayout Refresh According to model with its Own Command Mechanism
    //Model Changed Not Made Outside with Props...
    //It works with FlexLayout.Actions Command which manage in IDEComplexLayout...

    const { currentState } = this.props.ideAppsAndRepositoriesManagement;
    const { currentState: nextAaRState } = nextProps.ideAppsAndRepositoriesManagement;
    if (this.state.sidebarDragging !== nextState.sidebarDragging) {
      return true;
    }
    if (currentState !== nextAaRState) {
      return true;
    }
    return false;
  }

  componentWillMount() {
    // console.log("IDECL, CWM; props: ", this.props);
    setSourceFiles(this.props.gitRepoContent.selectedRepoContent);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.gitRepoContent && nextProps.gitRepoContent.selectedRepoContent) {
      // console.log("IDECL, CWRP, if-TRUE; props: ", this.props);
      setSourceFiles(nextProps.gitRepoContent.selectedRepoContent);
    }

    //==============================================================================
    //SOURCE PARTS
    //==============================================================================

    const { notifyStatus: cNotifyStatus } = this.props.sourceFocus;
    const { notifyStatus: nNotifyStatus, sourceTabIndex: nSourceTabIndex, lineNo: nLineNo } = nextProps.sourceFocus;

    if (cNotifyStatus !== nNotifyStatus) {
      switch (nNotifyStatus) {
        case NOTIFY_SOURCE_FOCUS:
          const idMap = this.state.model['_idMap'];
          if (idMap && idMap['src#' + nSourceTabIndex]) {
            nextProps.handleNotifySourceExisted(nSourceTabIndex, nLineNo);
          } else {
            nextProps.handleNotifySourceNotAvailable(nSourceTabIndex, nLineNo);
          }
          break;
        case NOTIFY_SOURCE_NOT_AVAILABLE:
          nextProps.handleNotifyFindAndAddSource(nSourceTabIndex, nLineNo);
          break;
        case NOTIFY_FIND_AND_ADD_SOURCE:
          const newNodeJson = getIDEModelSourceNode(nSourceTabIndex);
          this.state.model.doAction(
            FlexLayout.Actions.addNode(newNodeJson, 'ThundraSourceTabSet', FlexLayout.DockLocation.CENTER, -1),
          );
          nextProps.handleNotifySourceExisted(nSourceTabIndex, nLineNo);
          break;
        case NOTIFY_SOURCE_EXISTED:
          this.state.model.doAction(FlexLayout.Actions.selectTab('src#' + nSourceTabIndex));
          nextProps.handleNotifySourceSelect(nSourceTabIndex, nLineNo);
          break;
        case NOTIFY_SOURCE_SELECT:
          nextProps.handleSourceTabChange(nSourceTabIndex, nLineNo);
          break;
        default:
          break;
      }
    }

    //==============================================================================
    //TRACEPOINT PARTS
    //==============================================================================

    const { notifyStatus: cSANotifyStatus } = this.props.sidekickActionEdit;
    const { notifyStatus: nSANotifyStatus, sidekickAction: nSidekickAction } = nextProps.sidekickActionEdit;

    const rightBorderId = this.state.model
      .getBorderSet()
      .getBorders()
      .find(b => b.getLocation() === FlexLayout.DockLocation.RIGHT)
      .getId();

    if (cSANotifyStatus !== nSANotifyStatus && nSANotifyStatus === SA_EDIT_NOTIFY) {
      this.state.model.doAction(FlexLayout.Actions.updateNodeAttributes(rightBorderId, { selected: 0, visible: true }));
      this.props.handleOpenSidekickActionEditTab(nSidekickAction);
    }

    //==============================================================================
    //EVENTS PARTS
    //==============================================================================
    const bottomBorderId = this.state.model
      .getBorderSet()
      .getBorders()
      .find(b => b.getLocation() === FlexLayout.DockLocation.BOTTOM)
      .getId();

    const { notifyStatus: cSAEventNotifyStatus } = this.props.sidekickActionEventsFocus;
    const {
      notifyStatus: nSAEventNotifyStatus,
      sidekickAction: nSAEventFocus,
      type: nSAType,
      getReference: nSAGetReference,
    } = nextProps.sidekickActionEventsFocus;

    if (cSAEventNotifyStatus !== nSAEventNotifyStatus && nSAEventNotifyStatus === SA_EVENTS_FOCUS_NOTIFY) {
      const tabIndex = nSAType === 'tracePoint' ? 0 : 1;
      this.state.model.doAction(
        FlexLayout.Actions.updateNodeAttributes(bottomBorderId, { selected: tabIndex, visible: true }),
      );
      this.props.handleOpenSidekickActionEventsTabIfClosed(nSAEventFocus, nSAGetReference, nSAType);
    }

    //==============================================================================
    //APPS AND REPOSITORIES
    //==============================================================================
    //AaR stands for AppsAndRepositories
    const { currentState, notifyStatus } = this.props.ideAppsAndRepositoriesManagement;
    const { currentState: nextState, notifyStatus: nAaRNotifyStatus } = nextProps.ideAppsAndRepositoriesManagement;
    if (notifyStatus !== nAaRNotifyStatus) {
      switch (nAaRNotifyStatus) {
        case NOTIFY_APPS_AND_REPOS_STATE_CHANGE: {
          //toggle
          if (currentState === nextState) {
            this.props.handleCollapseAppsAndRepositories(COLLAPSED);
            break;
          }
          this.props.handleAppsAndRepositoriesStateChangeCompleted();
          break;
        }
        case NOTIFY_COLLAPSE_APPS_AND_REPOS: {
          this.props.handleCollapseAppsAndRepositoriesCompleted();
          break;
        }
        default:
          break;
      }
    }

    //==============================================================================
    //TOGGLE BORDERS
    //==============================================================================

    const { status } = this.props.ideToggleBorderTabs;
    const { status: newStatus } = nextProps.ideToggleBorderTabs;
    if (status !== newStatus) {
      switch (newStatus) {
        case OPEN_SOURCES:
          if (currentState === APPS_AND_SOURCES) {
            this.glowBorderTab('apps-and-repos-layout');
          } else {
            this.props.handleAppsAndRepositoriesStateChange(APPS_AND_SOURCES);
          }
          this.props.handleSourcesTabOpened();
          break;
        case OPEN_APPS_AND_TPS:
          this.state.model.doAction(
            FlexLayout.Actions.updateNodeAttributes(rightBorderId, { selected: 0, visible: true }),
          );
          this.glowBorderTab('flexlayout__tab_border_right');
          this.props.handleAppsAndTracePointsTabOpened();
          break;
        case OPEN_EVENTS:
          this.state.model.doAction(
            FlexLayout.Actions.updateNodeAttributes(bottomBorderId, { selected: 0, visible: true }),
          );
          this.glowBorderTab('flexlayout__tab_border_bottom');
          this.props.handleEventsTabOpened();
          break;
        default:
          break;
      }
    }

    //==============================================================================
    //IDE CONTEXT
    //==============================================================================

    const { resetStatus } = this.props.ideContext;
    const { resetStatus: newResetStatus, contextMetaData } = nextProps.ideContext;

    if (resetStatus !== newResetStatus) {
      switch (newResetStatus) {
        case IDE_CONTEXT_RESET_NOTIFY:
          console.log('IDECL; IDE_CONTEXT_RESET_NOTIFY; props, nextProps: ', this.props, nextProps);
          //This Clear SourceFiles Cache According To ContextMetaData...
          setSourceFiles(filterSourceFilesWithContextMetaData(contextMetaData));

          //This Clear TPEvents According To ContextMetaData...
          this.props.handleTracePointEventClear(contextMetaData);

          //This Clear Open Tabs According to ContextMetaData
          const tabsIDs = getIdsOfTabsInIDEModel(contextMetaData);
          tabsIDs.forEach(tabId => {
            const delTabCommand = FlexLayout.Actions.deleteTab(tabId);
            this.state.model.doAction(delTabCommand);
          });

          //This Gives NotificationIDEContext Clear And Trigger Clear Broker Context
          //Then AppsAndTracePoints Components handle TP Partially Clear From Broker
          this.props.handleSucceedIDEContextReset(contextMetaData);
          break;
        case IDE_CONTEXT_RESET_SUCCEEDED:
          this.props.handleUserRepoContentReset();
          break;
        default:
          break;
      }
    }
  }

  onActionUpdated = data => {
    this.props.handleLayoutChange();
    if (data && data.type === 'FlexLayout_SelectTab') {
      if (data.data && data.data.tabNode) {
        const tabId = data.data.tabNode;
        if (tabId.includes('src#')) {
          const sourceId = this.convertNodeId2SourcePath(tabId);
          this.props.handleSourceTabChangeRequest(sourceId, 0);
        }

        //It's developed for HEAP
        //Fire fake click event when IDE Tab Clicked or Triggered
        const button = document.getElementById('IDEComplexLayout_TabFake');
        button.innerHTML = tabId;
        button.click();
      }
    }
    //console.log(data);
    return data;
  };

  getDraggingBtnLeft = () => {
    return 240 + 6;
  };

  handleSideBarDragStart = (e, data) => {
    this.setState({
      sidebarDragging: true,
      sidebarDragX: data.x,
      draggingBtnLeft: this.getDraggingBtnLeft(),
    });
  };

  handleSideBarDrag = (e, data) => {
    this.setState({ sidebarDragX: data.x });
    console.log(this.state);
  };

  handleSideBarDragStop = (e, data) => {
    this.setState({
      sidebarDragging: false,
      sidebarDragX: data.x,
    });
  };
  render() {
    const appsAndReposWidth = this.state.leftWidth + this.state.sidebarDragX;
    const draggerLeft = appsAndReposWidth - this.state.sidebarDragX;
    const appsAndReposCollapsed = this.props.ideAppsAndRepositoriesManagement.currentState === COLLAPSED;
    return (
      <div className={'ide-complex-layout-container'}>
        {!appsAndReposCollapsed && (
          <>
            <div
              className={'apps-and-repos-layout'}
              style={{
                width: `${appsAndReposWidth}px`,
                minWidth: `${appsAndReposWidth}px`,
              }}
            >
              <AppsAndRepositoriesContainer />
            </div>
            <Draggable
              axis="x"
              onStart={this.handleSideBarDragStart}
              onDrag={this.handleSideBarDrag}
              onStop={this.handleSideBarDragStop}
            >
              <button
                className={this.state.sidebarDragging ? 'btn-drag2' : 'btn-drag2'}
                style={{ left: draggerLeft }}
              />
            </Draggable>
          </>
        )}
        <button style={{ display: 'none' }} id="IDEComplexLayout_TabFake" />
        <FlexLayout.Layout
          model={this.state.model}
          factory={this.factory}
          onAction={this.onActionUpdated}
          onModelChange={this.modelChanged}
          iconFactory={this.iconFactory}
        />
        <StatusBarSettings />
      </div>
    );
  }
}
