import React, { Component } from 'react';
import { MainLayout } from 'layouts';
import routeList from 'routes';
import { ProfilePageContainer } from 'containers';
import './SettingsProfilePage.scss';

class SettingsProfilePage extends Component {
  render() {
    return (
      <MainLayout title={routeList.settingsProfile.title}>
        <div className="settings-profile-page-wrapper">
          <ProfilePageContainer />
        </div>
      </MainLayout>
    );
  }
}

export default SettingsProfilePage;
