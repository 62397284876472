import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducer from './reducers';
import reduxWebsocket from '@giantmachines/redux-websocket';
import { CONFIG } from '../utils/config-util';
import storage from 'redux-persist/lib/storage';

//Persist Local Storage
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer, persistStore } from 'redux-persist';

const reduxWebsocketMiddleware = reduxWebsocket({
  reconnectOnClose: true,
});

let isConsoleLoggerActive = false; // Make it true if you want to see action logs @console!
let middleware;

if (process.env.NODE_ENV === 'test') {
  // TODO: Is this 'if' necessary, it is same as else statement!!?
  middleware = applyMiddleware(promise(), thunk, reduxWebsocketMiddleware);
} else if (CONFIG.DEV && isConsoleLoggerActive) {
  const logger = createLogger({ collapsed: true });
  middleware = applyMiddleware(promise(), thunk, reduxWebsocketMiddleware, logger);
} else {
  middleware = applyMiddleware(promise(), thunk, reduxWebsocketMiddleware);
}

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducer);
const composeEnhancers = (CONFIG.DEV && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(middleware);
const store = createStore(persistedReducer, enhancer);
export default store;
export const persistor = persistStore(store);
