export function getLanguage() {
  return (
    navigator.userLanguage ||
    (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
    navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    'en'
  );
}
