import {
  DELETE_APP_AUTHORIZATION_FETCHING,
  DELETE_APP_AUTHORIZATION_FETCHED,
  DELETE_APP_AUTHORIZATION_ERROR,
} from '../constants/git-apps';
import { getSourceProviderOAuthTokenSelector } from "store/selectors";
import store from "store/store";
import { SOURCE_PROVIDER_PLATFORM } from 'utils/source-provider-util';

import { Octokit } from "@octokit/rest";
import { CONFIG } from 'utils/config-util.js';

// GITLAB does not support app deletion from api.

export const deleteGithubAppAuthorization = (successFunction, errFunction) => {

  const githubClientId = CONFIG.GITHUB_CLIENT_ID;
  const githubPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITHUB);
  console.log("deleteGithubAppAuthorization; githubPAT, githubClientId: ", githubPAT, githubClientId);
  const octokit = new Octokit({
    auth: githubPAT,
  });


  return dispatch => {
    dispatch(deleteGithubAppAuthorizationStarted());

    // TODO: it seems that there is a problem with that github deleteAuthorization endpoints;
    // https://github.community/t/delete-authorization-not-working-with-user-octokit/176065
    octokit.apps.deleteAuthorization({
      client_id: githubClientId,
      access_token: githubPAT
    })
      .then(res => {
        dispatch(deleteGithubAppAuthorizationSuccess(res.data));
        // console.log("deleteGithubAppAuthorizationSuccess, res: ", res);  

        if (successFunction) {
          // console.log("SUCCESS");
          successFunction();
        }
      })
      .catch(err => {
        dispatch(deleteGithubAppAuthorizationFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });

  };
};


const deleteGithubAppAuthorizationStarted = () => ({
  type: DELETE_APP_AUTHORIZATION_FETCHING
});

const deleteGithubAppAuthorizationSuccess = (data) => {
  console.log("action, apps, deleteGithubAppAuthorizationSuccess; data: ", data);
  return ({
    type: DELETE_APP_AUTHORIZATION_FETCHED,
    payload: {
      data
    }
  });
}

const deleteGithubAppAuthorizationFailure = error => {
  console.log("action, apps, deleteGithubAppAuthorizationFailure; error: ", error);
  return ({
    type: DELETE_APP_AUTHORIZATION_ERROR,
    payload: {
      error
    }
  });
}
