import React, { Component } from 'react';
import { EventsTable } from './EventsTable/EventsTable';
import { EventDetails } from './EventsDetails/EventDetails';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import './TracepointEvents.scss';
import { CONFIG } from 'utils/config-util';
import { SA_EVENTS_FOCUS_TAB_OPEN_IF_CLOSED } from 'store/constants/sidekick-action-events-focus';
import { EventsFilter } from './EventsFilter/EventsFilter';
import { abbrFileName } from 'utils/string-util';
import URL_POOL from 'utils/url-pool';
import { generateUUID } from 'utils/object-util';
import { findSidekickActionOfEvent, GET_REFERENCE_PURPOSE, processSidekickActionId } from 'utils/sidekick-action-utils';
import { DiffViewerModal } from '../DiffViewerModal/DiffViewerModal';
import { getReferenceEventRequestCmdObj } from 'utils/broker-commands';
import { EventsPanelExpander } from '../EventsPanelExpander/EventsPanelExpander';
import { ExportEventContainer } from 'containers';

export function svgArrow() {
  return (
    <div className="svg-arrow-container">
      <svg width="231" height="72" viewBox="0 0 231 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.43171 24.2959L8.32686 8.67305L24.1594 8.5682L8.43171 24.2959Z" fill="#D4D4D4" fillOpacity="0.76" />
        <path
          d="M17.5 17C17.5 17 73.7933 54.0444 117 67C161.539 80.3552 230.5 56 230.5 56"
          stroke="#D4D4D4"
          strokeOpacity="0.76"
          strokeWidth="2"
          strokeDasharray="6 3"
        />
      </svg>
    </div>
  );
}

export function renderNonEvents(sidekickAction) {
  return (
    <div className="trace-point-events-container">
      <div className="events-empty-container">
        <div className="info-container">
          <div className="icon-line">
            <Icon className="icon-tp-events-no-event-exist" />
          </div>
          <div className="first-line">{sidekickAction} Event Table</div>
          <div className="second-line">Your event listed here soon. If they don't</div>
          <a target="_blank" href={URL_POOL.SIDEKICK_DOCS} rel="noopener noreferrer" className="third-line">
            check out our docs
          </a>
        </div>
      </div>
    </div>
  );
}

export function svgCheck() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C14.6957 0 9.60859 2.10714 5.85786 5.85786C2.10714 9.60859 0 14.6957 0 20C0 25.3043 2.10714 30.3914 5.85786 34.1421C9.60859 37.8929 14.6957 40 20 40V37.5C16.5388 37.5 13.1554 36.4736 10.2775 34.5507C7.39966 32.6278 5.15664 29.8947 3.83211 26.697C2.50757 23.4993 2.16102 19.9806 2.83626 16.5859C3.5115 13.1913 5.17821 10.0731 7.62563 7.62563C10.0731 5.17821 13.1913 3.5115 16.5859 2.83626C19.9806 2.16102 23.4993 2.50757 26.697 3.83211C29.8947 5.15664 32.6278 7.39966 34.5507 10.2775C36.4736 13.1554 37.5 16.5388 37.5 20H40C40 14.6957 37.8929 9.60859 34.1421 5.85786C30.3914 2.10714 25.3043 0 20 0ZM28 39.5L24 24L39.5 28L35 32.5L40 37.5L37.5 40L32.5 35L28 39.5ZM29.3491 16.0158L19.3491 26.0158L17.7778 27.5872L16.2064 26.0158L10.6509 20.4602L13.7936 17.3176L17.7778 21.3018L26.2064 12.8731L29.3491 16.0158Z"
        fill="#D4D4D4"
        fillOpacity="0.4"
      />
    </svg>
  );
}

export class TracePointEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightedRowIndex: -1,
      highlightedRowIndexPD: -1,
      selectedEvent: undefined,
      selectedEventDetailType: 'variables',
      clientFilter: '',
      predefinedFilter: 'all',
      compareEvents: [],
      getReferencePurpose: GET_REFERENCE_PURPOSE.IDLE,
      modalOpen: false,
      getReferenceMessageId: '',
      copied: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    const { notifyStatus, sidekickAction, getReference, type } = this.props.sidekickActionEventsFocus;
    const { referenceEvent, predefinedTracepointEvents } = this.props.broker;

    if (referenceEvent && referenceEvent.requestId === this.state.getReferenceMessageId) {
      if (this.state.getReferencePurpose === GET_REFERENCE_PURPOSE.COMPARE) {
        this.handleCompareIconClicked(referenceEvent.event);
        this.setState({
          modalOpen: true,
        });
      } else if (this.state.getReferencePurpose === GET_REFERENCE_PURPOSE.HIGHLIGHT) {
        if (!referenceEvent.erroneous) {
          const index = predefinedTracepointEvents.length - 1;
          this.setState({
            highlightedRowIndexPD: index,
            selectedEvent: predefinedTracepointEvents[index],
          });
        }
      }
      this.setState({ getReferencePurpose: GET_REFERENCE_PURPOSE.IDLE });
      this.props.flushReferenceEvent();
      return;
    }

    if (notifyStatus === SA_EVENTS_FOCUS_TAB_OPEN_IF_CLOSED && type === 'tracePoint') {
      if (!sidekickAction) {
        this.props.handleTracePointEventFocusCompleted(null, null, null);
        return;
      }
      if (sidekickAction.predefined) {
        if (getReference) {
          const { predefinedTracepointEvents } = this.props.broker;
          const referenceIndex = predefinedTracepointEvents.findIndex(
            event => event.referenceEvent && processSidekickActionId(event.tracePointId) === sidekickAction.id,
          );
          //reference already fetched
          if (referenceIndex > -1) {
            this.setState({
              highlightedRowIndexPD: referenceIndex,
              selectedEvent: predefinedTracepointEvents[referenceIndex],
              predefinedFilter: sidekickAction.id,
            });
          } else {
            this.getReferenceEvent(sidekickAction, GET_REFERENCE_PURPOSE.HIGHLIGHT);
          }
        } else {
          this.setState({ predefinedFilter: sidekickAction.id });
        }
        this.props.handleTracePointEventFocusCompleted(null, null, null);
      } else {
        const { fileName, lineNo } = sidekickAction;
        const moduleNames = abbrFileName(fileName).split('/');
        const newClientFilter = `${moduleNames[moduleNames.length - 1]}:${lineNo}`;
        this.setClientFilterAndResetDetails(newClientFilter);
        this.props.handleTracePointEventFocusCompleted(null, null, null);
      }
    }
  }

  flushReferenceEventFields() {
    return () => {
      this.setState({
        modalOpen: false,
        getReferenceMessageId: '',
      });
    };
  }

  setClientFilterAndResetDetails = clientFilter => {
    this.setState({
      clientFilter: clientFilter,
      highlightedRowIndex: -1,
      selectedEvent: undefined,
      selectedEventDetailType: 'variables',
    });
  };

  setPredefinedFilterAndResetDetails = predefinedFilter => {
    this.setState({
      predefinedFilter: predefinedFilter,
      highlightedRowIndex: -1,
      selectedEvent: undefined,
      selectedEventDetailType: 'variables',
    });
  };

  onEventTableRowClick = (e, rowIndex) => {
    const filteredEvents = this.getFilteredEvents();
    this.setState({
      highlightedRowIndex: rowIndex,
      highlightedRowIndexPD: -1,
      selectedEvent: filteredEvents[rowIndex],
      selectedEventDetailType: 'variables',
    });
  };

  onPredefinedEventTableRowClick = (e, rowIndex) => {
    const filteredEvents = this.getFilteredPDEvents();
    this.setState({
      highlightedRowIndexPD: rowIndex,
      highlightedRowIndex: -1,
      selectedEvent: filteredEvents[rowIndex],
      selectedEventDetailType: 'variables',
    });
  };

  onEventTableTraceIdFilterClick = (e, traceId) => {
    e.preventDefault();
    e.stopPropagation();
    this.setClientFilterAndResetDetails(traceId);
  };

  renderNotSelectedEventDetails = () => {
    return (
      <div className="event-details-container">
        <div className="not-selected-event-details-container">
          <div className="left-part">{svgArrow()}</div>
          <div className="center-part">
            <div className="first-line">{svgCheck()}</div>
            <div className="second-line">Select an Event</div>
            <div className="third-line">See the snapshots of variable!</div>
          </div>
          <div className="right-part" />
        </div>
      </div>
    );
  };

  getEventDetailsTabButtonClassName = checkBtnId => {
    const { selectedEventDetailType } = this.state;
    if (checkBtnId === selectedEventDetailType) {
      return 'selected-btn';
    }
    return 'normal-btn';
  };
  renderSelectedEventDetails = () => {
    const { selectedEvent, selectedEventDetailType } = this.state;
    const classNameOfGroupContainer = `event-details-group-container ${selectedEventDetailType}`;

    return (
      <div className="event-details-container">
        <div className="event-details-action-group-container">
          <Button.Group>
            <Button
              className={this.getEventDetailsTabButtonClassName('variables')}
              onClick={e => this.setState({ selectedEventDetailType: 'variables' })}
            >
              Variables
            </Button>
            <Button
              className={this.getEventDetailsTabButtonClassName('metadata')}
              onClick={e => this.setState({ selectedEventDetailType: 'metadata' })}
            >
              Metadata
            </Button>
            <Button
              className={this.getEventDetailsTabButtonClassName('frames')}
              onClick={e => this.setState({ selectedEventDetailType: 'frames' })}
            >
              Frames
            </Button>
          </Button.Group>
          <ExportEventContainer
            externalStyle={{ marginLeft: 'auto', marginRight: '24px' }}
            type={0}
            event={this.state.selectedEvent}
          />
        </div>
        <div className={classNameOfGroupContainer}>
          <EventDetails event={selectedEvent} type={selectedEventDetailType} />
        </div>
      </div>
    );
  };

  clearEvents = () => {
    this.props.handleTracePointEventClear();
    this.setClientFilterAndResetDetails('');
  };

  clearPredefinedEvents = () => {
    this.props.handlePredefinedTracePointEventClear();
    this.setPredefinedFilterAndResetDetails('all');
  };

  getFilteredEvents = () => {
    const { tracePointEvents } = this.props.broker;
    const { clientFilter } = this.state;
    if (clientFilter) {
      return tracePointEvents.filter(el => {
        const { time, applicationName, traceId, lineNo } = el;
        const moduleName = abbrFileName(el.fileName);
        const moduleNameAndLineNo = moduleName + ':' + lineNo;
        const fullLine = `${time} ${applicationName} ${moduleNameAndLineNo} ${traceId}`;
        return fullLine.includes(clientFilter);
      });
    } else {
      return [...tracePointEvents];
    }
  };

  handlePutReference = (putReferenceEventRequest, tpEvent) => {
    const requestId = generateUUID();
    this.props.setEventBeingProcessed({ requestId: requestId, event: tpEvent });
    this.props.handleBrokerSend({ ...putReferenceEventRequest, id: requestId });
  };

  handleRemoveReference = (removeReferenceEventRequest, tpEvent) => {
    const requestId = generateUUID();
    this.props.setEventBeingProcessed({ requestId: requestId, event: tpEvent });
    this.props.handleBrokerSend({ ...removeReferenceEventRequest, id: requestId });
  };

  handleCompareIconClicked = event => {
    const { compareEvents } = this.state;
    const eventIndex = compareEvents.findIndex(el => el.id === event.id);
    //remove from compare list
    if (eventIndex > -1) {
      compareEvents.splice(eventIndex, 1);
      this.setState({ compareEvents });
      return;
    } //add to compare list
    else if (compareEvents.length > 1) {
      compareEvents.pop();
    }
    compareEvents.push(event);
    this.setState({ compareEvents });
  };

  handleCompare = () => {
    const { compareEvents } = this.state;
    if (compareEvents.length === 2) {
      this.setState({ modalOpen: true });
      return;
    }
    //one event selected, find its reference event
    const { predefinedTracepointEvents, tracePoints } = this.props.broker;
    const tpOfEvent = findSidekickActionOfEvent(compareEvents[0].tracePointId, tracePoints);
    for (const event of predefinedTracepointEvents) {
      if (event.referenceEvent && processSidekickActionId(event.tracePointId) === tpOfEvent.id) {
        this.handleCompareIconClicked(event);
        this.setState({ modalOpen: true });
        return;
      }
    }
    //reference not found, get reference event
    this.getReferenceEvent(tpOfEvent, GET_REFERENCE_PURPOSE.COMPARE);
  };

  getReferenceEvent = (tpOfEvent, purpose) => {
    const requestId = generateUUID();
    this.setState({ getReferenceMessageId: requestId, getReferencePurpose: purpose }, () => {
      let getReferenceEventCmdObj = { ...getReferenceEventRequestCmdObj };
      if (purpose === GET_REFERENCE_PURPOSE.COMPARE) {
        const application = this.props.broker.appData.find(app => app.tracePoints.find(tp => tp.id === tpOfEvent.id));
        if (application) {
          const { stage, version, name } = application;
          getReferenceEventCmdObj.applicationFilter = { stage, version, name };
        }
      }
      getReferenceEventCmdObj.probeId = tpOfEvent.id;
      getReferenceEventCmdObj.probeType = 'TRACEPOINT';
      getReferenceEventCmdObj.id = requestId;
      this.props.handleBrokerSend(getReferenceEventCmdObj);
    });
  };

  renderEventsSectionHeader = eventCount => {
    const { clientFilter } = this.state;
    return (
      <div className={'header-group'}>
        <div className={'header'}>events</div>
        <div className={'event-count-label table-text'}> {`${eventCount} Events`}</div>
        <Icon className="icon-tp-events-trash" onClick={this.clearEvents} />
        <EventsFilter
          clientFilter={clientFilter}
          setClientFilter={newClientFilter => this.setClientFilterAndResetDetails(newClientFilter)}
        />
      </div>
    );
  };

  renderEventsSection = () => {
    const { tracePoints, appData } = this.props.broker;
    const { highlightedRowIndex, compareEvents } = this.state;
    let filteredEvents = this.getFilteredEvents();

    return (
      <div className={'events-section'}>
        {this.renderEventsSectionHeader(filteredEvents.length)}
        {filteredEvents.length !== 0 && (
          <EventsTable
            apmBaseUrl={this.props.activeWorkspace.workspace.workspaceSettings?.apmBaseUrl}
            onRowClick={this.onEventTableRowClick}
            onTraceFilterClick={this.onEventTableTraceIdFilterClick}
            handlePutReference={this.handlePutReference}
            handleRemoveReference={this.handleRemoveReference}
            handleCompareReference={this.handleCompareIconClicked}
            compareEvents={compareEvents}
            highlightedRowIndex={highlightedRowIndex}
            appData={appData}
            events={filteredEvents}
            tracepoints={tracePoints}
          />
        )}
      </div>
    );
  };

  renderSavedEventsSectionHeader = (predefinedOptions, eventCount) => {
    return (
      <div className={'header-group'}>
        <div className={'header'}>saved tracepoints events</div>
        <div className={'event-count-label table-text'}> {`${eventCount} Events`}</div>
        <Icon className="icon-tp-events-trash" onClick={this.clearPredefinedEvents} />
        <Dropdown
          className={'predefined-tps-dropdown'}
          value={this.state.predefinedFilter}
          selection
          options={predefinedOptions}
          onChange={(e, { value }) => {
            this.setPredefinedFilterAndResetDetails(value);
          }}
        />
      </div>
    );
  };

  getFilteredPDEvents = () => {
    const { predefinedTracepointEvents } = this.props.broker;
    return predefinedTracepointEvents.filter(
      event =>
        this.state.predefinedFilter === 'all' ||
        processSidekickActionId(event.tracePointId) === this.state.predefinedFilter,
    );
  };

  renderSavedEventsSection = () => {
    const { highlightedRowIndexPD, compareEvents } = this.state;
    const { predefinedTracepointEvents, appData } = this.props.broker;
    if (predefinedTracepointEvents.length === 0) {
      return null;
    }
    const predefinedTracePoints = this.props.broker.tracePoints.filter(tp => tp.predefined);

    const predefinedOptions = predefinedTracePoints.map(tp => ({ key: tp.id, text: tp.probeName, value: tp.id }));
    predefinedOptions.unshift({ key: 'all', text: 'All', value: 'all' });
    const filteredEvents = this.getFilteredPDEvents();
    return (
      <div className={'saved-events-section'}>
        {this.renderSavedEventsSectionHeader(predefinedOptions, filteredEvents.length)}
        <EventsTable
          apmBaseUrl={this.props.activeWorkspace.workspace.workspaceSettings?.apmBaseUrl}
          onRowClick={this.onPredefinedEventTableRowClick}
          onTraceFilterClick={this.onEventTableTraceIdFilterClick}
          handlePutReference={this.handlePutReference}
          handleRemoveReference={this.handleRemoveReference}
          handleCompareReference={this.handleCompareIconClicked}
          compareEvents={compareEvents}
          appData={appData}
          highlightedRowIndex={highlightedRowIndexPD}
          events={filteredEvents}
          tracepoints={predefinedTracePoints}
        />
      </div>
    );
  };

  getCompareButton = () => {
    const { compareEvents } = this.state;
    let disabled = true;
    let content = 'Compare';
    if (compareEvents.length === 2) {
      disabled = false;
    } else if (compareEvents.length === 1 && compareEvents[0].predefinedEvent && !compareEvents[0].referenceEvent) {
      disabled = false;
      content = 'Compare with reference';
    }

    return <Button disabled={disabled} className={'compare-button'} content={content} onClick={this.handleCompare} />;
  };

  renderCompareEventsSectionHeader = () => {
    return (
      <div className={'header-group'}>
        <div className={'header'}>compare events</div>
        <Icon className="icon-tp-events-trash clickable" onClick={() => this.setState({ compareEvents: [] })} />
        {this.getCompareButton()}
      </div>
    );
  };

  renderCompareEventsSection = () => {
    const { compareEvents } = this.state;
    const { tracePoints, appData } = this.props.broker;
    return (
      <div className={'compare-events-section'}>
        {this.renderCompareEventsSectionHeader()}
        <EventsTable
          apmBaseUrl={this.props.activeWorkspace.workspace.workspaceSettings?.apmBaseUrl}
          onTraceFilterClick={this.onEventTableTraceIdFilterClick}
          handlePutReference={this.handlePutReference}
          handleRemoveReference={this.handleRemoveReference}
          handleCompareReference={this.handleCompareIconClicked}
          appData={appData}
          compareEvents={compareEvents}
          events={compareEvents}
          tracepoints={tracePoints}
        />
      </div>
    );
  };

  renderEvents = () => {
    const { selectedEvent } = this.state;
    return (
      <div className="trace-point-events-container">
        <EventsPanelExpander position={'right'} selector={'/border/bottom/t0'} />
        <div className="events-container">
          {this.state.compareEvents.length > 0 && this.renderCompareEventsSection()}
          {this.renderSavedEventsSection()}
          {this.renderEventsSection()}
        </div>
        {selectedEvent ? this.renderSelectedEventDetails() : this.renderNotSelectedEventDetails()}
      </div>
    );
  };

  render() {
    const { tracePointEvents, predefinedTracepointEvents } = this.props.broker;
    if (tracePointEvents.length || predefinedTracepointEvents.length) {
      return (
        <>
          {this.renderEvents()}
          {this.state.modalOpen && (
            <DiffViewerModal
              modalOpen={this.state.modalOpen}
              compareEvents={this.state.compareEvents}
              onClose={this.flushReferenceEventFields()}
            />
          )}
        </>
      );
    } else {
      return <>{renderNonEvents('Tracepoint')}</>;
    }
  }
}
