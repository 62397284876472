import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import InviteUsers from './InviteUsers';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import './InviteUsersModal.scss';

class InviteUsersModal extends Component {
  constructor(props) {
    super(props);
    let description = 'Start collaborating with your teammates by adding them to your workspace!';
    this.state = {
      description: this.props.modalDescription || description,
    };
  }
  render() {
    return (
      <Modal className={'invite-user-modal'} onClose={() => this.props.closeModal(false)} open={this.props.isOpen}>
        <Modal.Header>Invite Users</Modal.Header>
        <Modal.Description>{this.state.description}</Modal.Description>
        <InviteUsers
          maxNumberOfEmails={this.props.maxNumberOfEmails}
          render={(emails, validateEmails) => {
            return (
              <div className={'invite-users-action-row'}>
                <Button
                  secondary
                  className={'button-step-info'}
                  content={'Cancel'}
                  onClick={() => this.props.closeModal(false)}
                />
                <Button
                  className={'primary'}
                  content={'Invite'}
                  onClick={() => {
                    let isEmailsValid = validateEmails();
                    if (!isEmailsValid) {
                      return;
                    }
                    this.props.inviteToWorkspace(
                      emails,
                      () => {
                        toast.success('Invitation sent successfully', ToastConfig);
                        this.props.closeModal(true);
                      },
                      () => {
                        toast.error('Invitation failed.', ToastConfig);
                        this.props.closeModal(false);
                      },
                    );
                  }}
                  loading={this.props.invitationResult.fetching}
                />
              </div>
            );
          }}
        />
      </Modal>
    );
  }
}

export default InviteUsersModal;
