import Auth0Lock from 'auth0-lock';
import { AUTH_CONFIG } from './auth0-variables';
import URL_POOL from 'utils/url-pool';

//The CDN URL varies by region. Tenants created before 11 June 2020 should use https://cdn.auth0.com
// if the region is the United States, or add eu, au, jp for Europe, Australia, or Japan.
// If your tenant was created after 11 June 2020, use https://cdn.us.auth0.com if the region is the United States.
let configurationBaseUrl = 'https://cdn.us.auth0.com';

const query = new URLSearchParams(window.location.search);

let lock = new Auth0Lock(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, {
  oidcConformant: true,
  autoclose: true,
  container: 'login-container',
  avatar: null,
  defaultDatabaseConnection: AUTH_CONFIG.defaultDatabaseConnection,
  allowedConnections: AUTH_CONFIG.allowedConnectionNames,
  socialButtonStyle: 'big',
  allowForgotPassword: true,
  configurationBaseUrl: configurationBaseUrl,
  prefill: {
    // this is to prefill signup form email field when url is like; app.runsidekick.com/signup?email=osman@thundra.io
    email: query.get('email'),
  },
  additionalSignUpFields: [
    {
      name: 'invited',
      type: 'hidden',
      placeholder: 'invited',
      validator: function () {
        return true;
      },
      value: query.get('invited'),
    },
    {
      name: 'workspaceId',
      type: 'hidden',
      placeholder: 'workspaceId',
      validator: function () {
        return true;
      },
      value: query.get('workspaceId'),
    },
    {
      // These utm params are added to pass to hubspot if users come to signup page from marketing links.
      name: 'utmSource',
      type: 'hidden',
      validator: function () {
        return true;
      },
      value: query.get('utm_source') || 'no_source',
    },
    {
      name: 'utmMedium',
      type: 'hidden',
      validator: function () {
        return true;
      },
      value: query.get('utm_medium') || 'no_medium',
    },
    {
      name: 'utmCampaign',
      type: 'hidden',
      validator: function () {
        return true;
      },
      value: query.get('utm_campaign') || 'no_campaign',
    },
    {
      name: 'utmContent',
      type: 'hidden',
      validator: function () {
        return true;
      },
      value: query.get('utm_content') || 'no_content',
    },
  ],
  auth: {
    redirectUrl: AUTH_CONFIG.callbackUrl,
    responseType: 'token id_token',
    audience: AUTH_CONFIG.apiUrl,
    params: {
      scope: 'openid profile name email',
    },
    sso: true,
  },
  theme: {
    logo: '~assets/images/thundra.png',
    primaryColor: '#2A23D7',
  },
  languageDictionary: {
    signUpTerms: `By clicking Agree & Sign up, you agree to the Sidekick <a href=${URL_POOL.TERMS_OF_SERVICES} rel="noopener noreferrer" target="_blank">Terms of Service.</a>`,
    error: {
      signUp: {
        ER_DUP_ENTRY: 'This email is already in use',
      },
    },
    emailInputPlaceholder: 'Enter email',
    passwordInputPlaceholder: 'Enter password',
    forgotPasswordAction: 'Forgot your password?',
    signUpSubmitLabel: 'AGREE & SIGN UP',
  },
});

export default lock;
