import React, { Component } from 'react';
import { Divider, Form } from 'semantic-ui-react';
import { CardCVCElement, CardExpiryElement, CardNumberElement, PostalCodeElement } from 'react-stripe-elements';

import './CreditCardForm.scss';

class CreditCardForm extends Component {
  render() {
    let stripeStyle = { base: { color: '#D9D9D9', fontWeight: 300 } };
    console.log('CreditCardForm, render; props, state: ', this.props, this.state);

    return (
      <Form className="credit-card-form">
        <Form.Group>
          <Form.Field width={8} className="name-field">
            <label>Name Surname</label>
            <input placeholder="Name Surname" name="name" onChange={this.props.onInputChange} />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={8} error={!!this.props.cardNumberError}>
            <label>Card Number</label>
            <CardNumberElement
              style={stripeStyle}
              onChange={this.props.onCardNumberChange}
              className="card-number-element stripe-input"
            />
          </Form.Field>

          <Form.Field width={4} error={!!this.props.cardExpiryError}>
            <label>Expiry Date</label>
            <CardExpiryElement
              style={stripeStyle}
              onChange={this.props.onCardExpiryChange}
              className="card-expiry-element stripe-input"
            />
          </Form.Field>

          <Form.Field width={4} error={!!this.props.cardCVCError}>
            <label>CVC</label>
            <CardCVCElement
              style={stripeStyle}
              onChange={this.props.onCardCVCChange}
              className="card-cvc-element stripe-input"
            />
          </Form.Field>
        </Form.Group>

        <Divider />

        <Form.Field>
          <label>Billing Address</label>
          <input placeholder="Address" name="address" onChange={this.props.onInputChange} />
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field>
            <label>City</label>
            <input placeholder="City" name="city" onChange={this.props.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>State</label>
            <input placeholder="State" name="state" onChange={this.props.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Country</label>
            <input placeholder="Country" name="country" onChange={this.props.onInputChange} />
          </Form.Field>
          <Form.Field error={!!this.props.postalCodeError}>
            <label>ZIP Code</label>
            <PostalCodeElement
              style={stripeStyle}
              onChange={this.props.onPostalCodeChange}
              className="card-zip-code-element stripe-input"
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default CreditCardForm;
