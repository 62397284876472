import {
  UPDATE_WORKSPACE_ACCOUNT_FULFILLED,
  UPDATE_WORKSPACE_ACCOUNT_PENDING,
  UPDATE_WORKSPACE_ACCOUNT_REJECTED,
} from '../constants/workspace-update-account';

const updateWorkspaceAccountResultInitialState = {
  updateWorkspaceAccountResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function updateWorkspaceAccount(state = updateWorkspaceAccountResultInitialState, action) {
  switch (action.type) {
    case UPDATE_WORKSPACE_ACCOUNT_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case UPDATE_WORKSPACE_ACCOUNT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        updateWorkspaceAccountResult: action.payload.data,
      };
    case UPDATE_WORKSPACE_ACCOUNT_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
