import appsync from 'assets/images/services-new/AWS-AppSync.png';
import aurora from 'assets/images/services-new/Amazon-Aurora.png';
import eventbridge from 'assets/images/services-new/Amazon-EventBridge.png';
import athena from 'assets/images/services-new/Amazon-Athena.png';
import dynamodb from 'assets/images/services-new/Amazon-DynamoDB.png';
import apigateway from 'assets/images/services-new/Amazon-API-Gateway.png';
import s3 from 'assets/images/services-new/AWS-S3.png';
import ses from 'assets/images/services-new/AWS-SES.png';
import lambda from 'assets/images/services-new/AWS-Lambda.png';
import sqs from 'assets/images/services-new/Amazon-SQS.png';
import kinesis from 'assets/images/services-new/Amazon-Kinesis.png';
import firehose from 'assets/images/services-new/Amazon-Firehose.png';
import sns from 'assets/images/services-new/Amazon-SNS.png';
import stepfunctions from 'assets/images/services-new/AWS-StepFunctions.png';
import elasticsearch from 'assets/images/services-new/Amazon-Elasticsearch.png';
import cloudfront from 'assets/images/services-new/Amazon-CloudFront.png';
import cloudwatchlogs from 'assets/images/services-new/Amazon-CloudWatch-Logs.png';
import cloudwatchschedule from 'assets/images/services-new/Amazon-CloudWatch-Schedule.png';

import http from 'assets/images/services-new/HTTP.png';
import auth0 from 'assets/images/services-new/Auth0.png';
import google from 'assets/images/services-new/Gooogle.png';
import slack from 'assets/images/services-new/Slack.png';
import stripe from 'assets/images/services-new/Stripe.png';
import twilio from 'assets/images/services-new/Twilio.png';
import mailgun from 'assets/images/services-new/Mailgun.png';
import spring from 'assets/images/services-new/Spring.png';
import django from 'assets/images/services-new/Django.png';
import express from 'assets/images/services-new/Express.png';
import flask from 'assets/images/services-new/Flask.png';
import kafka from 'assets/images/services-new/Kafka.png';
import vercel from 'assets/images/services-new/VERCEL.png';
import netlify from 'assets/images/services-new/Netlify.png';

import mariadb from 'assets/images/services-new/MariaDB.png';
import mssql from 'assets/images/services-new/MSSQL.png';
import mysql from 'assets/images/services-new/MySQL.png';
import sqlite from 'assets/images/services-new/SQLite.png';
import mongodb from 'assets/images/services-new/MongoDB.png';
import postgresql from 'assets/images/services-new/PostgreSQL.png';
import redis from 'assets/images/services-new/Redis.png';
import unknown from 'assets/images/services-new/Unknown.png';

export const ArrayOfResourceClasses = [
  'aws-appsync',
  'aws-aurora',
  'aws-eventbridge',
  'aws-athena',
  'aws-dynamodb',
  'aws-apigateway',
  'aws-s3',
  'aws-ses',
  'aws-lambda',
  'aws-sqs',
  'aws-kinesis',
  'aws-firehose',
  'aws-sns',
  'aws-stepfunctions',
  'aws-cloudfront',
  'aws-cloudwatch-log',
  'aws-cloudwatch-schedule',
  'elasticsearch',
  'mariadb',
  'mssql',
  'mysql',
  'sqlite',
  'mongodb',
  'postgresql',
  'redis',
  'http',
  'auth0',
  'google',
  'slack',
  'stripe',
  'twilio',
  'mailgun',
  'spring',
  'django',
  'express',
  'flask',
  'kafka',
  'vercel',
  'netlify',
];

export const TOPOLOGY_ICONS = {
  appsync,
  aurora,
  eventbridge,
  athena,
  dynamodb,
  apigateway,
  s3,
  ses,
  lambda,
  sqs,
  kinesis,
  firehose,
  sns,
  stepfunctions,
  elasticsearch,
  cloudfront,
  cloudwatchlogs,
  cloudwatchschedule,

  http,
  auth0,
  google,
  slack,
  stripe,
  twilio,
  mailgun,

  spring,
  django,
  express,
  flask,
  kafka,
  vercel,
  netlify,

  mariadb,
  mssql,
  mysql,
  sqlite,
  mongodb,
  postgresql,
  redis,

  unknown,
};

//This Function Used By Architecture Topology Details Left SideBar
export function generateIconMap(old) {
  let ICONS = TOPOLOGY_ICONS;

  const mapClass = {
    'aws-appsync': ICONS.appsync,
    'aws-aurora': ICONS.aurora,
    'aws-eventbridge': ICONS.eventbridge,
    'aws-athena': ICONS.athena,
    'aws-dynamodb': ICONS.dynamodb,
    'aws-apigateway': ICONS.apigateway,
    'aws-s3': ICONS.s3,
    'aws-ses': ICONS.ses,
    'aws-lambda': ICONS.lambda,
    'aws-sqs': ICONS.sqs,
    'aws-kinesis': ICONS.kinesis,
    'aws-firehose': ICONS.firehose,
    'aws-sns': ICONS.sns,
    'aws-stepfunctions': ICONS.stepfunctions,
    'aws-cloudfront': ICONS.cloudfront,
    'aws-elasticsearch': ICONS.elasticsearch,
    'aws-cloudwatch-log': ICONS.cloudwatchlogs,
    'aws-cloudwatch-schedule': ICONS.cloudwatchschedule,

    http: ICONS.http,
    auth0: ICONS.auth0,
    google: ICONS.google,
    slack: ICONS.slack,
    stripe: ICONS.stripe,
    twilio: ICONS.twilio,
    mailgun: ICONS.mailgun,

    spring: ICONS.spring,
    django: ICONS.django,
    express: ICONS.express,
    flask: ICONS.flask,
    kafka: ICONS.kafka,
    vercel: ICONS.vercel,
    netlify: ICONS.netlify,

    mariadb: ICONS.mariadb,
    mssql: ICONS.mssql,
    mysql: ICONS.mysql,
    sqlite: ICONS.sqlite,
    mongodb: ICONS.mongodb,
    postgresql: ICONS.postgresql,
    pg: ICONS.postgresql,
    redis: ICONS.redis,

    unknown: ICONS.unknown,
  };

  return mapClass;
}

//This function used by Topology(Architecture,TraceMap, UniqueTraces)
export function addIcons2Style(style, old) {
  let ICONS = TOPOLOGY_ICONS;
  let pre = '';

  style
    .selector(`.${pre}aws-appsync`)
    .css({ 'background-image': ICONS.appsync })
    .selector(`.${pre}aws-aurora`)
    .css({ 'background-image': ICONS.aurora })
    .selector(`.${pre}aws-eventbridge`)
    .css({ 'background-image': ICONS.eventbridge })
    .selector(`.${pre}aws-athena`)
    .css({ 'background-image': ICONS.athena })
    .selector(`.${pre}aws-dynamodb`)
    .css({ 'background-image': ICONS.dynamodb })
    .selector(`.${pre}aws-sqs`)
    .css({ 'background-image': ICONS.sqs })
    .selector(`.${pre}aws-sns`)
    .css({ 'background-image': ICONS.sns })
    .selector(`.${pre}aws-stepfunctions`)
    .css({ 'background-image': ICONS.stepfunctions })
    .selector(`.${pre}aws-kinesis`)
    .css({ 'background-image': ICONS.kinesis })
    .selector(`.${pre}aws-firehose`)
    .css({ 'background-image': ICONS.firehose })
    .selector(`.${pre}aws-s3`)
    .css({ 'background-image': ICONS.s3 })
    .selector(`.${pre}aws-ses`)
    .css({ 'background-image': ICONS.ses })
    .selector(`.${pre}aws-lambda`)
    .css({ 'background-image': ICONS.lambda })
    .selector(`.${pre}aws-apigateway`)
    .css({ 'background-image': ICONS.apigateway })
    .selector(`.${pre}elasticsearch`)
    .css({ 'background-image': ICONS.elasticsearch })
    .selector(`.${pre}aws-cloudwatch-log`)
    .css({ 'background-image': ICONS.cloudwatchlogs })
    .selector(`.${pre}aws-cloudwatch-schedule`)
    .css({ 'background-image': ICONS.cloudwatchschedule })
    .selector(`.${pre}aws-cloudfront`)
    .css({ 'background-image': ICONS.cloudfront })
    .selector(`.${pre}postgresql`)
    .css({ 'background-image': ICONS.postgresql })
    .selector(`.${pre}pg`)
    .css({ 'background-image': ICONS.postgresql })
    .selector(`.${pre}mysql`)
    .css({ 'background-image': ICONS.mysql })
    .selector(`.${pre}mariadb`)
    .css({ 'background-image': ICONS.mariadb })
    .selector(`.${pre}mssql`)
    .css({ 'background-image': ICONS.mssql })
    .selector(`.${pre}sqlite`)
    .css({ 'background-image': ICONS.sqlite })
    .selector(`.${pre}mongodb`)
    .css({ 'background-image': ICONS.mongodb })
    .selector(`.${pre}redis`)
    .css({ 'background-image': ICONS.redis })
    .selector(`.${pre}http`)
    .css({ 'background-image': ICONS.http })
    .selector(`.${pre}auth0`)
    .css({ 'background-image': ICONS.auth0 })
    .selector(`.${pre}google`)
    .css({ 'background-image': ICONS.google })
    .selector(`.${pre}slack`)
    .css({ 'background-image': ICONS.slack })
    .selector(`.${pre}stripe`)
    .css({ 'background-image': ICONS.stripe })
    .selector(`.${pre}twilio`)
    .css({ 'background-image': ICONS.twilio })
    .selector(`.${pre}mailgun`)
    .css({ 'background-image': ICONS.mailgun })
    .selector(`.${pre}spring`)
    .css({ 'background-image': ICONS.spring })
    .selector(`.${pre}django`)
    .css({ 'background-image': ICONS.django })
    .selector(`.${pre}flask`)
    .css({ 'background-image': ICONS.flask })
    .selector(`.${pre}express`)
    .css({ 'background-image': ICONS.express })
    .selector(`.${pre}kafka`)
    .css({ 'background-image': ICONS.kafka })
    .selector(`.${pre}vercel`)
    .css({ 'background-image': ICONS.vercel })
    .selector(`.${pre}netlify`)
    .css({ 'background-image': ICONS.netlify })
    .selector(`.${pre}unknown`)
    .css({ 'background-image': ICONS.unknown });
}
