import React, { Component } from 'react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { capitalize } from 'lodash/string';
import { replacer } from 'utils/object-util';
import './DiffViewerModal.scss';

const MODE = {
  UNIFIED: 'UNIFIED',
  SPLIT: 'SPLIT',
};
export class DiffViewerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: MODE.SPLIT,
      oldValue: JSON.stringify(this.props.compareEvents[0], replacer, 2),
      newValue: JSON.stringify(this.props.compareEvents[1], replacer, 2),
    };
  }
  render() {
    const calculatedWidth = this.state.mode === MODE.SPLIT ? '360px' : '750px';
    const { mode } = this.state;
    return (
      <Modal
        open={this.props.modalOpen}
        onClose={() => this.props.onClose()}
        closeOnDimmerClick={false}
        className={'json-diff-modal'}
      >
        <Modal.Header>
          <span> {capitalize(MODE.UNIFIED)}</span>
          <Checkbox
            toggle
            checked={mode === MODE.SPLIT}
            onChange={e => {
              e.stopPropagation();
              const nextMode = mode === MODE.SPLIT ? MODE.UNIFIED : MODE.SPLIT;
              this.setState({ mode: nextMode });
            }}
          />
          <span> {capitalize(MODE.SPLIT)}</span>
        </Modal.Header>
        <Modal.Content>
          <ReactDiffViewer
            oldValue={this.state.oldValue}
            newValue={this.state.newValue}
            compareMethod={DiffMethod.WORDS}
            useDarkTheme={true}
            splitView={this.state.mode === MODE.SPLIT}
            styles={{
              content: { maxWidth: calculatedWidth, width: calculatedWidth, wordBreak: 'break-all' },
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button content={'Close'} onClick={() => this.props.onClose()} />
        </Modal.Actions>
      </Modal>
    );
  }
}
