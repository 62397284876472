import {
  ADD_ONBOARDING_SOURCE_CACHE,
  ADD_SOURCE_CACHE_FULFILLED,
  ADD_SOURCE_CACHE_PENDING,
  ADD_SOURCE_CACHE_REJECTED,
  DELETE_SOURCE_CACHE_FULFILLED,
  DELETE_SOURCE_CACHE_PENDING,
  DELETE_SOURCE_CACHE_REJECTED,
  FLUSH_SELECTED_GIT_SOURCE_CACHE,
  FLUSH_SOURCE_CACHE_LIST,
  GET_SOURCE_CACHE_LIST_FULFILLED,
  GET_SOURCE_CACHE_LIST_PENDING,
  GET_SOURCE_CACHE_LIST_REJECTED,
  GET_SOURCE_LIST_LIMIT_FULFILLED,
  GET_SOURCE_LIST_LIMIT_PENDING,
  GET_SOURCE_LIST_LIMIT_REJECTED,
  REMOVE_ONBOARDING_SOURCE_CACHE_FULFILLED,
  REMOVE_ONBOARDING_SOURCE_CACHE_PENDING,
  REMOVE_ONBOARDING_SOURCE_CACHE_REJECTED,
  SELECT_GIT_SOURCE_CACHE,
  UPDATE_SOURCE_CACHE_FULFILLED,
  UPDATE_SOURCE_CACHE_PENDING,
  UPDATE_SOURCE_CACHE_REJECTED,
} from 'store/constants/source-cache';

const initialState = {
  // selectedGitSourceCache; it is source provider agnostic.
  selectedGitSourceCache: null,

  sourceListLimit: undefined,
  sourceListLimitFetching: false,
  sourceListLimitFetched: false,
  sourceListLimitError: null,

  sourceCacheList: [],
  sourceCacheListFetching: false,
  sourceCacheListFetched: false,
  sourceCacheListError: null,

  addSourceCache: {},
  addSourceCacheFetching: false,
  addSourceCacheFetched: false,
  addSourceCacheError: null,

  updateSourceCache: {},
  updateSourceCacheFetching: false,
  updateSourceCacheFetched: false,
  updateSourceCacheError: null,

  deleteSourceCache: {},
  deleteSourceCacheFetching: false,
  deleteSourceCacheFetched: false,
  deleteSourceCacheError: null,
};

export default function sourceCache(state = initialState, action) {
  switch (action.type) {
    case SELECT_GIT_SOURCE_CACHE:
      return {
        ...state,
        selectedGitSourceCache: action.payload,
      };
    case FLUSH_SELECTED_GIT_SOURCE_CACHE:
      return {
        ...state,
        selectedGitSourceCache: null,
      };
    case FLUSH_SOURCE_CACHE_LIST:
      return {
        ...state,
        sourceCacheList: [],
      };
    case GET_SOURCE_LIST_LIMIT_PENDING:
      return {
        ...state,
        sourceListLimitFetching: true,
        sourceListLimitFetched: false,
        sourceListLimitError: null,
      };
    case GET_SOURCE_LIST_LIMIT_FULFILLED:
      // console.log('GET_SOURCE_LIST_LIMIT_FULFILLED reducer; action: ', action);
      return {
        ...state,
        sourceListLimitFetching: false,
        sourceListLimitFetched: true,
        sourceListLimitError: null,
        sourceListLimit: action.payload.data,
      };
    case GET_SOURCE_LIST_LIMIT_REJECTED:
      return {
        ...state,
        sourceListLimitFetching: false,
        sourceListLimitFetched: false,
        sourceListLimitError: action.payload,
      };

    case GET_SOURCE_CACHE_LIST_PENDING:
      return {
        ...state,
        sourceCacheListFetching: true,
        sourceCacheListFetched: false,
        sourceCacheListError: null,
      };
    case GET_SOURCE_CACHE_LIST_FULFILLED:
      // console.log('GET_SOURCE_CACHE_LIST_FULFILLED reducer; action: ', action);
      return {
        ...state,
        sourceCacheListFetching: false,
        sourceCacheListFetched: true,
        sourceCacheListError: null,
        sourceCacheList: onboardingSourceMergeSourceCacheList(state.sourceCacheList, action.payload.data),
      };
    case GET_SOURCE_CACHE_LIST_REJECTED:
      return {
        ...state,
        sourceCacheListFetching: false,
        sourceCacheListFetched: false,
        sourceCacheListError: action.payload,
      };

    case ADD_ONBOARDING_SOURCE_CACHE:
      console.log('ADD_ONBOARDING_SOURCE_CACHE, reducer; action, state: ', action, state);
      // Onboarding source cache is always the first source to be added!.
      let modifiedSourceCacheList = [action.payload];
      return {
        ...state,
        sourceCacheList: modifiedSourceCacheList,
      };
    case REMOVE_ONBOARDING_SOURCE_CACHE_PENDING:
      console.log('REMOVE_ONBOARDING_SOURCE_CACHE_PENDING, reducer; action, state: ', action, state);
      return {
        ...state,
      };
    case REMOVE_ONBOARDING_SOURCE_CACHE_FULFILLED:
      console.log('REMOVE_ONBOARDING_SOURCE_CACHE_FULFILLED, reducer; action, state: ', action, state);
      const onboardingSourceIndex = state.sourceCacheList.findIndex(source => source.id === 'onboarding-sample-id');
      let newSourceCacheList = state.sourceCacheList;
      if (onboardingSourceIndex >= 0) {
        newSourceCacheList.splice(onboardingSourceIndex, 1);
      }
      return {
        ...state,
        sourceCacheList: newSourceCacheList,
      };
    case REMOVE_ONBOARDING_SOURCE_CACHE_REJECTED:
      console.log('REMOVE_ONBOARDING_SOURCE_CACHE_REJECTED, reducer; action, state: ', action, state);
      return {
        ...state,
      };

    case ADD_SOURCE_CACHE_PENDING:
      return {
        ...state,
        addSourceCacheFetching: true,
        addSourceCacheFetched: false,
        addSourceCacheError: null,
      };
    case ADD_SOURCE_CACHE_FULFILLED:
      // console.log('ADD_SOURCE_CACHE_FULFILLED reducer; action: ', action);
      return {
        ...state,
        addSourceCacheFetching: false,
        addSourceCacheFetched: true,
        addSourceCacheError: null,
        addSourceCache: action.payload.data,
      };
    case ADD_SOURCE_CACHE_REJECTED:
      // console.log('ADD_SOURCE_CACHE_REJECTED reducer; action: ', action);
      return {
        ...state,
        addSourceCacheFetching: false,
        addSourceCacheFetched: false,
        addSourceCacheError: action.payload,
      };

    case UPDATE_SOURCE_CACHE_PENDING:
      return {
        ...state,
        updateSourceCacheFetching: true,
        updateSourceCacheFetched: false,
        updateSourceCacheError: null,
      };
    case UPDATE_SOURCE_CACHE_FULFILLED:
      // console.log('UPDATE_SOURCE_CACHE_FULFILLED reducer; action: ', action);
      return {
        ...state,
        updateSourceCacheFetching: false,
        updateSourceCacheFetched: true,
        updateSourceCacheError: null,
        updateSourceCache: action.payload.data,
      };
    case UPDATE_SOURCE_CACHE_REJECTED:
      // console.log('UPDATE_SOURCE_CACHE_REJECTED reducer; action: ', action);
      return {
        ...state,
        updateSourceCacheFetching: false,
        updateSourceCacheFetched: false,
        updateSourceCacheError: action.payload,
      };

    case DELETE_SOURCE_CACHE_PENDING:
      return {
        ...state,
        deleteSourceCacheFetching: true,
        deleteSourceCacheFetched: false,
        deleteSourceCacheError: null,
      };
    case DELETE_SOURCE_CACHE_FULFILLED:
      console.log('DELETE_SOURCE_CACHE_FULFILLED reducer; action: ', action);
      return {
        ...state,
        deleteSourceCacheFetching: false,
        deleteSourceCacheFetched: true,
        deleteSourceCacheError: null,
        deleteSourceCache: action.payload.data,
      };
    case DELETE_SOURCE_CACHE_REJECTED:
      // console.log('DELETE_SOURCE_CACHE_REJECTED reducer; action: ', action);
      return {
        ...state,
        deleteSourceCacheFetching: false,
        deleteSourceCacheFetched: false,
        deleteSourceCacheError: action.payload,
      };

    default:
      return state;
  }
}

// If old source list contains onboarding source, then keep it within recent source cache list.
function onboardingSourceMergeSourceCacheList(oldArr, newArr) {
  const onboardingSourceCache = oldArr.find(source => source.id === 'onboarding-sample-id');
  if (onboardingSourceCache) {
    newArr.push(onboardingSourceCache);
    return newArr;
  }

  return newArr;
}
