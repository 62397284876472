import {
  SET_DEFAULT_PROBE_WEBHOOK_FULFILLED,
  SET_DEFAULT_PROBE_WEBHOOK_PENDING,
  SET_DEFAULT_PROBE_WEBHOOK_REJECTED,
} from '../constants/workspace-set-default-probe-webhook';

const initialState = {
  setDefaultProbeWebhookResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function setDefaultProbeWebhook(state = initialState, action) {
  switch (action.type) {
    case SET_DEFAULT_PROBE_WEBHOOK_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case SET_DEFAULT_PROBE_WEBHOOK_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        setDefaultProbeWebhook: action.payload.data,
      };
    case SET_DEFAULT_PROBE_WEBHOOK_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
