import {
  RESET_SELECTED_GIT_REPO_CONTENT,
  GET_GITHUB_REPO_CONTENT_FETCHING,
  GET_GITHUB_REPO_CONTENT_FETCHED,
  GET_GITHUB_REPO_CONTENT_ERROR,
  GET_GITLAB_REPO_CONTENT_FETCHING,
  GET_GITLAB_REPO_CONTENT_FETCHED,
  GET_GITLAB_REPO_CONTENT_ERROR,
} from '../constants/git-repo-content';
import { getSourceProviderOAuthTokenSelector } from 'store/selectors';
import store from 'store/store';
import { SOURCE_PROVIDER_PLATFORM, findGitlabProjectIdFromRepoName } from 'utils/source-provider-util';
import { abbrFileName } from 'utils/string-util';

import axios from 'axios';
import { Octokit } from '@octokit/rest';

export const resetUserRepoContent = () => ({
  type: RESET_SELECTED_GIT_REPO_CONTENT,
});

// SAMPLE DATA MODELS;

// gitlabRepoContent:
// content: "c2VydmljZTogZ2l0bGFiLWV4YW1wbGUKcHJvdmlkZXI6CiAgbmFtZTogYXdzCiAgcmVnaW9uOiAke2VudjpBV1NfUkVHSU9OfQogIHJ1bnRpbWU6IG5vZGVqczEwLngKICBlbnZpcm9ubWVudDoKICAgIEFfVkFSSUFCTEU6ICR7ZW52OkFfVkFSSUFCTEV9CgpwbHVnaW5zOgogIC0gc2VydmVybGVzcy1vZmZsaW5lCiAgLSBzZXJ2ZXJsZXNzLWplc3QtcGx1Z2luCiAgLSBzZXJ2ZXJsZXNzLXN0YWNrLW91dHB1dCAjIEFsbG93cyB1cyB0byBvdXRwdXQgZW5kcG9pbnQgdXJsIHRvIGpzb24gZmlsZQoKZnVuY3Rpb25zOgogIGhlbGxvOgogICAgaGFuZGxlcjogc3JjL2hhbmRsZXIuaGVsbG8KICAgIGV2ZW50czoKICAgICAgLSBodHRwOgogICAgICAgICAgcGF0aDogaGVsbG8KICAgICAgICAgIG1ldGhvZDogZ2V0CiAgICAgICAgICBjb3JzOiB0cnVlCgpjdXN0b206CiAgb3V0cHV0OgogICAgaGFuZGxlcjogc3JjL2hhbmRsZXIuaGVsbG8KICAgIGZpbGU6IHN0YWNrLmpzb24K"
// encoding: "base64"
// sha: "6664133dfc422702923a0f86d1f81f0996093759"
// size: 495

// repoContent:
// content: "IyB0aHVuZHJhLXNpZGVraWNr\n"
// download_url: "https://raw.githubusercontent.com/thundra-io/thundra-sidekick/f93a0b7e789abc155aa4ad29f9611042bb52be00/README.md?token=AAD72BNFL4C6SQ6XOB6RMVDBLK7KC"
// encoding: "base64"
// git_url: "https://api.github.com/repos/thundra-io/thundra-sidekick/git/blobs/3b60082edb9c3e15c84aac1e1004be3f8f37e70f"
// html_url: "https://github.com/thundra-io/thundra-sidekick/blob/f93a0b7e789abc155aa4ad29f9611042bb52be00/README.md"
// name: "README.md"
// path: "README.md"
// sha: "3b60082edb9c3e15c84aac1e1004be3f8f37e70f"
// size: 18
// type: "file"
// url: "https://api.github.com/repos/thundra-io/thundra-sidekick/contents/README.md?ref=f93a0b7e789abc155aa4ad29f9611042bb52be00"
// _links: {self: 'https://api.github.com/repos/thundra-io/thundra-si…E.md?ref=f93a0b7e789abc155aa4ad29f9611042bb52be00', git: 'https://api.github.com/repos/thundra-io/thundra-si…it/blobs/3b60082edb9c3e15c84aac1e1004be3f8f37e70f', html: 'https://github.com/thundra-io/thundra-sidekick/blo…93a0b7e789abc155aa4ad29f9611042bb52be00/README.md'}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// gitlabGitTreeData
// id: "6664133dfc422702923a0f86d1f81f0996093759"
// mode: "100644"
// name: "serverless.yml"
// path: "serverless.yml"

// githubTreeData
// mode: "100644"
// path: "README.md"
// sha: "3b60082edb9c3e15c84aac1e1004be3f8f37e70f"
// size: 18
// type: "blob"
// url: "https://api.github.com/repos/thundra-io/thundra-sidekick/git/blobs/3b60082edb9c3e15c84aac1e1004be3f8f37e70f"

export const getUserRepoContent = (sourceProvider, ownerLogin, repoName, path, ref, successFunction, errFunction) => {
  console.log('==> getUserRepoContent');
  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITHUB) {
    console.log('GITHUB ==> getUserRepoContent');
    return getUserRepoContentGithub(ownerLogin, repoName, path, ref, successFunction, errFunction);
  }

  if (sourceProvider === SOURCE_PROVIDER_PLATFORM.GITLAB) {
    // console.log(
    //   'GITLAB ==> getUserRepoContent; sourceProvider, ownerLogin, repoName, path, ref: ',
    //   sourceProvider,
    //   ownerLogin,
    //   repoName,
    //   path,
    //   ref,
    // );

    return getUserRepoContentGitlab(ownerLogin, repoName, path, ref, successFunction, errFunction);
  }
};

// GITLAB
// Ref doc => https://docs.gitlab.com/ee/api/repositories.html#get-a-blob-from-repository
export const getUserRepoContentGitlab = (ownerLogin, repoName, path, blobId, successFunction, errFunction) => {
  // console.log('GITLAB, ACTIONS, getUserRepoContentGitlab; repoName, blobId: ', repoName, blobId);
  const gitlabPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITLAB);
  const projectId = findGitlabProjectIdFromRepoName(repoName);

  const getRepoFileBlobUrl = `https://gitlab.com/api/v4/projects/${projectId}/repository/blobs/${blobId}`;
  return dispatch => {
    dispatch(getGitlabUserRepoContentStarted());

    axios
      .get(getRepoFileBlobUrl, {
        headers: { Authorization: 'Bearer ' + gitlabPAT },
      })
      .then(res => {
        // We enrich gitlab content data to be similar to github content data.
        // 'setSourceFiles' needs 'url' and 'name' fields!
        const gitlabContentData = {
          ...res.data,
          // name field should be last part of path i.e -> petclinic-app/src/main/java/io/thundra/petclinicapp/owner/Owner.java
          name: abbrFileName(path),
          path: path,
          // This url format is set to be same with 'generateFullGitSourceURLPath', it does not have any other purpose!
          url: `gitlab.com/repos/${ownerLogin}/${repoName}/contents/${path}?ref=${blobId}`,
        };
        dispatch(getGitlabUserRepoContentSuccess(gitlabContentData));
        // console.log('getGitlabUserRepoContentSuccess - gitlab; res, gitlabContentData: ', res, gitlabContentData);
        if (successFunction) {
          successFunction();
        }
      })
      .catch(err => {
        dispatch(getGitlabUserRepoContentFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });
  };
};

const getGitlabUserRepoContentStarted = () => ({
  type: GET_GITLAB_REPO_CONTENT_FETCHING,
});

const getGitlabUserRepoContentSuccess = data => {
  // console.log("action, users, getGitlabUserRepoContentSuccess; data: ", data);
  return {
    type: GET_GITLAB_REPO_CONTENT_FETCHED,
    payload: {
      data,
    },
  };
};

const getGitlabUserRepoContentFailure = error => {
  // console.log("action, users, getGitlabUserRepoContentFailure; error: ", error);
  return {
    type: GET_GITLAB_REPO_CONTENT_ERROR,
    payload: {
      error,
    },
  };
};

// GITHUB
// Ref doc => https://octokit.github.io/rest.js/v18#repos-get-content
// Ref doc => https://docs.github.com/en/rest/reference/git#get-a-blob
// Ref doc => https://docs.github.com/en/rest/reference/repos#get-repository-content
export const getUserRepoContentGithub = (ownerLogin, repoName, path, ref, successFunction, errFunction) => {
  // const githubPAT = getSourceProviderOAuthTokenSelector(store.getState());
  const githubPAT = getSourceProviderOAuthTokenSelector(store.getState(), SOURCE_PROVIDER_PLATFORM.GITHUB);
  // console.log("getUserRepoContent; githubPAT: ", githubPAT);
  const octokit = new Octokit({
    auth: githubPAT,
  });

  return dispatch => {
    dispatch(getUserRepoContentStarted());

    octokit.repos
      .getContent({
        owner: ownerLogin,
        repo: repoName,
        path: path,
        ref: ref,
      })
      .then(res => {
        dispatch(getUserRepoContentSuccess(res.data));
        // console.log("getUserRepoContentSuccess, res: ", res);

        if (successFunction) {
          successFunction();
        }
      })
      .catch(err => {
        dispatch(getUserRepoContentFailure(err.message));
        if (errFunction) {
          errFunction();
        }
      });
  };
};

const getUserRepoContentStarted = () => ({
  type: GET_GITHUB_REPO_CONTENT_FETCHING,
});

const getUserRepoContentSuccess = data => {
  // console.log("action, users, getUserRepoContentSuccess; data: ", data);
  return {
    type: GET_GITHUB_REPO_CONTENT_FETCHED,
    payload: {
      data,
    },
  };
};

const getUserRepoContentFailure = error => {
  // console.log("action, users, getUserRepoContentFailure; error: ", error);
  return {
    type: GET_GITHUB_REPO_CONTENT_ERROR,
    payload: {
      error,
    },
  };
};
