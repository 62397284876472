import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { CREATE_WEBHOOK } from '../constants/webhook-create';

export const createWebhook = (workspaceId, type, name, config) => {
  return {
    type: CREATE_WEBHOOK,
    payload: axios.post(
      CONFIG.apiUrl + '/webhooks',
      { workspaceId, type, name, config: JSON.stringify(config) },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
};
