export const RESET_SELECTED_GIT_REPO_CONTENT = 'RESET_SELECTED_GIT_REPO_CONTENT';

export const GET_GITHUB_REPO_CONTENT_FETCHING = 'GET_GITHUB_REPO_CONTENT_FETCHING';
export const GET_GITHUB_REPO_CONTENT_FETCHED = 'GET_GITHUB_REPO_CONTENT_FETCHED';
export const GET_GITHUB_REPO_CONTENT_ERROR = 'GET_GITHUB_REPO_CONTENT_ERROR';

export const GET_GITLAB_REPO_CONTENT_FETCHING = 'GET_GITLAB_REPO_CONTENT_FETCHING';
export const GET_GITLAB_REPO_CONTENT_FETCHED = 'GET_GITLAB_REPO_CONTENT_FETCHED';
export const GET_GITLAB_REPO_CONTENT_ERROR = 'GET_GITLAB_REPO_CONTENT_ERROR';

