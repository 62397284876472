import React, { Component } from 'react';
import './CallbackPage.scss';

export class LoadingPage extends Component {
  render() {
    console.log('LoadingPage, render; props: ', this.props);
    const caption = this.props.caption || 'LOADING...';
    return (
      <div className="loading-view">
        <div className="loading-bg-artwork">
          <div className="loading-bg-image" />
        </div>
        <div className="loading-real">
          <div className="thundra-logo" />
          <div className="loading-message">{caption}</div>
          <div className="loading-lines">
            <div className="load-base">
              <div className="load-in" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default CallbackPage;
