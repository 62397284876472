import React, { Component } from 'react';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import { AddUpdateRepoModalContainer, SourceFileTreeContainer } from 'containers';
import { Tooltip } from 'components';

import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import { hasArrayElement } from 'utils/array-util';
import {
  computeBranchHeadCommitIdAccordingToSourceProvider,
  MODAL_STATE,
  SOURCE_PROVIDER_PLATFORM,
} from 'utils/source-provider-util';

import './SourceExplorer.scss';

export default class SourceExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseOverSelectedRepoTitle: false,
      // Modal open states
      isUpdateBranchCommitModalOpen: false,
      isFetchBranchHeadWarningModalOpen: false,
      isDeleteSelectedRepoWarningModalOpen: false,
    };
  }

  componentDidMount() {
    console.log('SE, CDM; props, state: ', this.props, this.state);
    this.selectGitSourceForFileTree();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('SE, CDU; props, prevProps, state, prevState: ', this.props, prevProps, this.state, prevState);
    this.selectGitSourceForFileTree(prevProps);
  }

  componentWillUnmount() {
    // console.log('SE, CWU');
  }

  selectGitSourceForFileTree = prevProps => {
    const { selectedGitSourceCache, sourceCacheList } = this.props.sourceCache;
    if (!selectedGitSourceCache && hasArrayElement(sourceCacheList)) {
      // If there is no selected repo, select the latest added repo to be explored as default.
      const defaultSource = sourceCacheList[0];
      // console.log('selectGitSourceForFileTree, IF; sourceRepoList, defaultSource: ', sourceRepoList, defaultSource);
      console.log('selectGitSourceForFileTree, IF; sourceCacheList, defaultSource: ', sourceCacheList, defaultSource);
      this.props.selectGitSourceCache({ ...defaultSource });
    }
  };

  renderSourceTree = () => {
    const { isMouseOverSelectedRepoTitle } = this.state;
    const { selectedGitSourceCache } = this.props.sourceCache;
    if (selectedGitSourceCache) {
      const {
        owner: selectedRepoOwnerLogin,
        repository: selectedRepoName,
        branch,
        commit: selectedRepoBranchSha,
        platform,
      } = selectedGitSourceCache;

      const iconName = this.getIconNameForSourceProvider(platform);

      return (
        <div className="source-tree-wrapper">
          <div
            className="sources-branch-commit-info"
            onMouseOver={() => this.setState({ isMouseOverSelectedRepoTitle: true })}
            onMouseLeave={() => this.setState({ isMouseOverSelectedRepoTitle: false })}
          >
            <Tooltip content={this.renderTooltipContent(selectedRepoName, branch, selectedRepoBranchSha)}>
              <div className="selected-repo-text">
                <span>
                  <Icon name={iconName} />
                  {selectedRepoOwnerLogin}/{selectedRepoName}/{branch}
                </span>
              </div>
            </Tooltip>
            {isMouseOverSelectedRepoTitle && (
              <div className="selected-repo-actions">
                <Tooltip content={<span>Remove repository</span>}>
                  <Icon name="trash" onClick={this.handleSelectedSourceDeleteWarningModalOpen} />
                </Tooltip>
                <Tooltip content={<span>Fetch last commit</span>}>
                  <Icon name="sync" onClick={this.handleFetchLastCommitWarningModalOpen} />
                </Tooltip>
                <Popup
                  className="source-provider-ellipsis-popup"
                  basic
                  on="click"
                  position="bottom left"
                  trigger={<Icon name="ellipsis horizontal" />}
                  onUnmount={() => this.setState({ isMouseOverSelectedRepoTitle: false })}
                >
                  <div className="source-provider-popup-button" onClick={this.handleChangeBranchCommitModalOpen}>
                    Change branch & commit
                  </div>
                </Popup>
              </div>
            )}
          </div>

          <SourceFileTreeContainer
            selectedRepoOwnerLogin={selectedRepoOwnerLogin}
            selectedRepoName={selectedRepoName}
            selectedRepoBranchSha={selectedRepoBranchSha}
            gitSourceProvider={platform}
          />
        </div>
      );
    }

    return <div className="source-tree-wrapper"></div>;
  };

  // Delete selected source cache
  handleSelectedSourceDeleteWarningModalOpen = () => {
    console.log('handleSelectedSourceDeleteWarningModalOpen');
    this.setState({
      isDeleteSelectedRepoWarningModalOpen: true,
    });
  };

  handleSelectedSourceDeleteWarningModalClose = () => {
    console.log('handleSelectedSourceDeleteWarningModalClose');
    this.setState({
      isDeleteSelectedRepoWarningModalOpen: false,
    });
  };

  renderSelectedSourceDeleteWarningModal = () => {
    const { isDeleteSelectedRepoWarningModalOpen } = this.state;
    const { deleteSourceCacheFetching } = this.props.sourceCache;

    const deleteSelectedSourceText =
      'Tracepoints and opened files will be lost after deleting selected source repository. Do you want to continue?';
    return (
      <Modal className="change-selected-source-repo-modal" open={isDeleteSelectedRepoWarningModalOpen}>
        <Modal.Content>
          <div className="disconnect-source-content">
            <div className="disconnect-source-title">
              <span>
                <Icon color="yellow" name="warning sign" />
              </span>
              <span>Warning</span>
            </div>
            <div className="disconnect-source-text">
              <span>{deleteSelectedSourceText}</span>
            </div>
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={this.handleSelectedSourceDeleteWarningModalClose}>
            Cancel
          </Button>
          <Button
            primary
            disabled={deleteSourceCacheFetching}
            loading={deleteSourceCacheFetching}
            onClick={this.handleContinueDeleteSelectedSourceClick}
          >
            Let's continue
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  handleContinueDeleteSelectedSourceClick = () => {
    console.log('handleContinueDeleteSelectedSourceClick; props: ', this.props);
    const { selectedGitSourceCache } = this.props.sourceCache;
    const { id: sourceId } = selectedGitSourceCache;
    const { id: workspaceId } = this.props.activeWorkspace.workspace.workspace;

    this.props.deleteSourceCache(sourceId, workspaceId, this.onDeleteSourceCacheSuccess, this.onDeleteSourceCacheError);
  };

  onDeleteSourceCacheSuccess = () => {
    console.log('onDeleteSourceCacheSuccess; props: ', this.props);
    const { selectedGitSourceCache } = this.props.sourceCache;
    const { platform, owner, repository, branch, commit } = selectedGitSourceCache;

    // This is called to reset opened source files from IDE model.
    this.props.handleNotifyIDEContextReset(platform, owner, repository, branch, commit);

    this.props.getSourceCacheList(
      this.props.activeWorkspace.workspace.workspace.id,
      () => {
        console.log('onDeleteSourceCacheSuccess, getSourceCacheList success; props: ', this.props);
        this.props.flushSelectedGitSourceCache();
      },
      () => {
        console.log('onDeleteSourceCacheSuccess, getSourceCacheList error');
      },
    );
    toast.success(`We succesfully delete your repository; ${repository}`, ToastConfig);
    this.handleSelectedSourceDeleteWarningModalClose();
  };

  onDeleteSourceCacheError = () => {
    console.log('onDeleteSourceCacheError');
  };
  ////

  // Selected source cache fetch last commit
  handleFetchLastCommitWarningModalOpen = () => {
    this.setState({
      isFetchBranchHeadWarningModalOpen: true,
    });
  };

  handleFetchLastCommitWarningModalClose = () => {
    this.setState({
      isFetchBranchHeadWarningModalOpen: false,
    });
  };

  renderFetchLastCommitWarningModal = () => {
    const { isFetchBranchHeadWarningModalOpen } = this.state;
    const { updateSourceCacheFetching } = this.props.sourceCache;
    const { isBranchHeadFetching, isGitlabBranchHeadFetching } = this.props.gitBranchData;

    const fetchSelectedSourceLastCommitText =
      'Tracepoints and opened files will be lost after fetching last commit for selected source repository & branch. Do you want to continue?';
    return (
      <Modal className="change-selected-source-repo-modal" open={isFetchBranchHeadWarningModalOpen}>
        <Modal.Content>
          <div className="disconnect-source-content">
            <div className="disconnect-source-title">
              <span>
                <Icon color="yellow" name="warning sign" />
              </span>
              <span>Warning</span>
            </div>
            <div className="disconnect-source-text">
              <span>{fetchSelectedSourceLastCommitText}</span>
            </div>
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={this.handleFetchLastCommitWarningModalClose}>
            Cancel
          </Button>
          <Button
            primary
            disabled={updateSourceCacheFetching || isBranchHeadFetching || isGitlabBranchHeadFetching}
            loading={updateSourceCacheFetching || isBranchHeadFetching || isGitlabBranchHeadFetching}
            onClick={this.handleContinueFetchSelectedSourceLastCommitClick}
          >
            Let's continue
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  handleContinueFetchSelectedSourceLastCommitClick = () => {
    console.log('handleContinueFetchSelectedSourceLastCommitClick');

    const { selectedGitSourceCache } = this.props.sourceCache;
    const { id: sourceId, platform, owner, repository, branch, commit } = selectedGitSourceCache;
    this.props.getUserRepositoryBranchHead(
      platform,
      owner,
      repository,
      branch,
      () => {
        console.log(
          'getUserRepositoryBranchHead success; props, selectedGitSourceCache: ',
          this.props,
          selectedGitSourceCache,
        );

        // This is called to reset opened source files from IDE model.
        this.props.handleNotifyIDEContextReset(platform, owner, repository, branch, commit);

        const source = {
          ...selectedGitSourceCache,
          commit: computeBranchHeadCommitIdAccordingToSourceProvider(platform, this.props.gitBranchData),
        };

        this.props.updateSourceCache(
          sourceId,
          source,
          () => {
            console.log('updateSourceCache succes; props: ', this.props);
            this.props.getSourceCacheList(this.props.activeWorkspace.workspace.workspace.id);

            const { selectedGitSourceCache, updateSourceCache } = this.props.sourceCache;

            // Select github/gitlab source cache if updated source is also selected source at source explorer??
            if (selectedGitSourceCache && repository === updateSourceCache.repository) {
              this.props.selectGitSourceCache({ ...updateSourceCache });
            }

            toast.success(
              `We succesfully fetched last commit for your repo; ${repository}/${branch}/${updateSourceCache.commit}`,
              ToastConfig,
            );

            this.handleFetchLastCommitWarningModalClose();
          },
          () => {
            console.log('updateSourceCache fail');
            toast.error(`Your commit/branch updates to repo ${repository} is failed!`, ToastConfig);
          },
        );
      },
      () => {
        console.log('getUserRepositoryBranchHead fail; props: ', this.props);
      },
    );
  };

  //
  computeSelectedItemClass = repoName => {
    let itemTextClass = 'repo-list-item-text';
    const { selectedGitSourceCache } = this.props.sourceCache;
    if (selectedGitSourceCache && repoName === selectedGitSourceCache.repository) {
      itemTextClass = `${itemTextClass} selected`;
    }

    return itemTextClass;
  };

  handleSelectedRepoListItemSelect = repo => {
    console.log('handleSelectedRepoListItemSelect');
    this.props.selectGitSourceCache({ ...repo });
  };

  renderTooltipContent = (repoName, branchName, commitSHA) => {
    return (
      <span>
        <span>{`Repository: ${repoName}`}</span>
        <br />
        <span>{`Branch: ${branchName}`}</span>
        <br />
        <span>{`Commit SHA: ${commitSHA?.substring(0, 7)}`}</span>
      </span>
    );
  };

  getIconNameForSourceProvider = platform => {
    let iconName = '';
    if (platform === SOURCE_PROVIDER_PLATFORM.GITHUB) {
      iconName = 'github';
    } else if (platform === SOURCE_PROVIDER_PLATFORM.GITLAB) {
      iconName = 'gitlab';
    } else {
      // add bitbucket later!
    }

    return iconName;
  };

  renderSourceCacheList = () => {
    const { sourceCacheList } = this.props.sourceCache;
    // console.log('SE, renderSourceCacheList; sourceCacheList: ', sourceCacheList);

    return (
      <div className="source-cache-list-wrapper">
        <div className="source-cache-list">
          {sourceCacheList.map(repo => {
            // Compute source cache list icons according to platform
            const iconName = this.getIconNameForSourceProvider(repo.platform);

            return (
              <div
                key={`${repo.id}`}
                className="repo-list-item"
                onClick={() => this.handleSelectedRepoListItemSelect(repo)}
              >
                <Tooltip content={this.renderTooltipContent(repo.repository, repo.branch, repo.commit)}>
                  <div className={this.computeSelectedItemClass(repo.repository)}>
                    <Icon name={iconName} />
                    <span>
                      {repo.owner}/{repo.repository}/{repo.branch}
                    </span>
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  //
  handleChangeBranchCommitModalOpen = () => {
    console.log('SE, change branch&commit clickeddd');

    this.setState({
      isUpdateBranchCommitModalOpen: true,
    });
  };

  handleChangeBranchCommitModalClose = () => {
    this.setState({
      isUpdateBranchCommitModalOpen: false,
    });
  };
  //

  render() {
    console.log('SourceExplorer, render; props, state: ', this.props, this.state);
    const { selectedGitSourceCache } = this.props.sourceCache;
    const { isUpdateBranchCommitModalOpen } = this.state;

    return (
      <div className="source-explorer-wrapper">
        {this.renderSourceTree()}
        <div className="sources-margin">
          <div className="border"></div>
        </div>
        {this.renderSourceCacheList()}

        {this.renderSelectedSourceDeleteWarningModal()}
        {this.renderFetchLastCommitWarningModal()}

        {selectedGitSourceCache && isUpdateBranchCommitModalOpen && (
          <AddUpdateRepoModalContainer
            isModalAddOrUpdate={MODAL_STATE.UPDATE_BRANCH_COMMIT}
            selectedSourceCacheForModal={selectedGitSourceCache}
            gitSourceProvider={selectedGitSourceCache.platform}
            isOpen={isUpdateBranchCommitModalOpen}
            onClose={this.handleChangeBranchCommitModalClose}
          />
        )}
      </div>
    );
  }
}
