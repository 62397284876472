import { generateUUID } from 'utils/object-util';

class TreeNode {
  constructor(key, name, isDirectory, parent) {
    this.key = key;
    this.name = name;
    this.isDirectory = isDirectory;
    this.children = [];
    this.parent = parent;
  }

  get path() {
    //if this is the rootnode (this.parent == null) then just return empty string
    //we don't need the rootnode's name appearing in the path of its children
    if (this.parent == null) return '';

    const parentPath = this.parent.path;

    return parentPath === '' ? this.name : parentPath + '/' + this.name;
  }

  isDescendantOf(treeNode) {
    let parent = this.parent;
    while (parent) {
      if (parent === treeNode) {
        return true;
      }
      parent = parent.parent;
    }
    return false;
  }
}

function generateNodeTitle(attrName) {
  return attrName;
}

function generateFolderNodeTitle(attrName, type) {
  return attrName + ':' + type;
}

function generateTreeStructNode(parentNode, data, attrName) {
  const type = data['@type'];
  const value = data['@value'];

  if (Array.isArray(value)) {
    //Check Array
    const childNode = new TreeNode(generateUUID(), generateFolderNodeTitle(attrName, type), true, parentNode);
    parentNode.children.push(childNode);

    //Fix Values Which Has Optimized..
    let valueModified = null;
    if (value.length > 0 && typeof value[0] === 'object' && value[0] !== null) {
      console.log('It s standard Array Structure');
      valueModified = value;
    } else {
      console.log('It s not standard Array Structure, converted to Standart');
      //const isArray = data["@array"];
      const elementType = data['@array-element-type'];
      valueModified = value.map(el => {
        return { '@type': elementType, '@value': el };
      });
    }
    valueModified.forEach((el, index) => {
      generateTreeStructNode(childNode, el, index + '');
    });
  } else if (typeof value === 'object' && value !== null) {
    //Check Object
    const childNode = new TreeNode(generateUUID(), generateFolderNodeTitle(attrName, type), true, parentNode);
    parentNode.children.push(childNode);
    for (const key in value) {
      const objVal = value[key];
      generateTreeStructNode(childNode, objVal, key);
    }
  } else {
    let primitiveNodeName = generateNodeTitle(attrName) + '=' + value;
    const childNode = new TreeNode(generateUUID(), primitiveNodeName, false, parentNode);
    parentNode.children.push(childNode);
  }
}

export function generateTreeStruct(data, eventId) {
  const root = new TreeNode('root', 'root', true, null);
  generateTreeStructNode(root, data, 'Variables'); //`Args of Event:${eventId}`
  return root.children[0];
}
