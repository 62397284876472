import {
  CHANGE_CURRENT_USER_PASSWORD_FULFILLED,
  CHANGE_CURRENT_USER_PASSWORD_PENDING,
  CHANGE_CURRENT_USER_PASSWORD_REJECTED,
} from 'store/constants/password';

const passwordInitialState = {
  fetching: false,
  fetched: false,
  error: null,
};

export default function changePassword(state = passwordInitialState, action) {
  switch (action.type) {
    case CHANGE_CURRENT_USER_PASSWORD_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case CHANGE_CURRENT_USER_PASSWORD_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
      };
    case CHANGE_CURRENT_USER_PASSWORD_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    default:
      return state;
  }
}
