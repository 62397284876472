import { connect } from 'react-redux';
import { ThundraSourceWelcome } from 'components';
import { getOnPremIdeToken, openAppsAndTracePointsTab, openEventsTab, openSourcesTab } from 'store/actions';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    activeWorkspace: store.activeWorkspace,
    onPremIdeToken: store.onPremIdeToken,
    onboardStatus: store.onboardingV2,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleOpenSourcesTab() {
      dispatch(openSourcesTab());
    },
    getOnPremIdeToken(onSuccess, onError) {
      dispatchWithExceptionHandler(getOnPremIdeToken(), false, onSuccess, onError);
    },
    handleOpenAppsAndTracePointsTab() {
      dispatch(openAppsAndTracePointsTab());
    },
    handleOpenEventsTab() {
      dispatch(openEventsTab());
    },
  };
}

export const ThundraSourceWelcomeContainer = connect(mapStateToProps, mapDispatchToProps)(ThundraSourceWelcome);
