import { connect } from 'react-redux';
import { OnboardingWizardV2 } from 'components';
import { changeOnboardingStatus, getOnboardStatus, updateOnboardStatus } from 'store/actions';
import { START_ONBOARDING_COMPLETED } from 'store/constants/onboarding-v2';
import { withRouter } from 'react-router-dom';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    onboardingStatus: store.onboardingV2,
    workspaceOnboardingStatus: store.workspaceOnboardingStatus,

    activeWorkspace: store.activeWorkspace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    completeStartOnboarding() {
      dispatch(changeOnboardingStatus(START_ONBOARDING_COMPLETED));
    },
    getOnboardStatus(workspaceId, onSuccess, onError) {
      dispatchWithExceptionHandler(getOnboardStatus(workspaceId), false, onSuccess, onError);
    },
    updateOnboardStatus(onboardStatus, workspaceId, onUpdateOnboardStatusSuccess, onUpdateOnboardStatusFail) {
      dispatchWithExceptionHandler(
        updateOnboardStatus(onboardStatus, workspaceId),
        false,
        onUpdateOnboardStatusSuccess,
        onUpdateOnboardStatusFail,
      );
    },
  };
}

const OnboardingWizardContainerV2 = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingWizardV2));
export default OnboardingWizardContainerV2;
