export const listAppCmdObj = {
  type: 'Request',
  name: 'ListApplicationsRequest',
  id: '1b92fe85-5710-4178-befc-04e7fd7fb325',
  applicationNames: [],
  applicationStages: [],
  applicationVersions: [],
};

export const listTracePointsCmdObj = {
  type: 'Request',
  name: 'ListTracePointsRequest',
  id: '1b92fe85-5710-4178-befc-04e7fd7fb325',
};

export const listLogPointsCmdObj = {
  type: 'Request',
  name: 'ListLogPointsRequest',
  id: '1b92fe85-5710-4178-befc-04e7fd7fb325',
};

export const putTracePointCmdObj = {
  type: 'Request',
  name: 'PutTracePointRequest',
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e41',
  applicationFilters: [],
  fileName: '',
  lineNo: -1,
  expireSecs: -1,
  expireCount: -1,
  enableTracing: true,
  persist: true,
  webhookIds: [],
};

export const delTracePointCmdObj = {
  type: 'Request',
  name: 'RemoveTracePointRequest',
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e4a',
  tracePointId: '',
  persist: true,
};

export const enableTracePointCmdObj = {
  type: 'Request',
  name: 'EnableTracePointRequest',
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e4a',
  tracePointId: '',
  persist: true,
};

export const disableTracePointCmdObj = {
  type: 'Request',
  name: 'DisableTracePointRequest',
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e4a',
  tracePointId: '',
  persist: true,
};

export const updateTracePointCmdObj = {
  type: 'Request',
  name: 'UpdateTracePointRequest',
  tracePointId: '',
  persist: true,
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e41',
  expireSecs: -1,
  expireCount: -1,
  conditionExpression: '',
  disable: false,
  enableTracing: true,
  webhookIds: [],
};

export const putLogPointCmdObj = {
  type: 'Request',
  name: 'PutLogPointRequest',
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e41',
  applicationFilters: [],
  fileName: '',
  lineNo: -1,
  expireSecs: -1,
  expireCount: -1,
  persist: true,
  logExpression: 'Hello World',
  stdoutEnabled: true,
  logLevel: 'INFO',
  webhookIds: [],
};

export const delLogPointCmdObj = {
  type: 'Request',
  name: 'RemoveLogPointRequest',
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e4a',
  logPointId: '',
  persist: true,
};

export const enableLogPointCmdObj = {
  type: 'Request',
  name: 'EnableLogPointRequest',
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e4a',
  logPointId: '',
  persist: true,
};

export const disableLogPointCmdObj = {
  type: 'Request',
  name: 'DisableLogPointRequest',
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e4a',
  logPointId: '',
  persist: true,
};

export const updateLogPointCmdObj = {
  type: 'Request',
  name: 'UpdateLogPointRequest',
  logPointId: '',
  persist: true,
  id: '8edead1e-1d00-49ed-8183-870ff1ae9e41',
  expireSecs: -1,
  expireCount: -1,
  logExpression: '',
  logLevel: 'INFO',
  stdoutEnabled: true,
  disable: false,
  webhookIds: [],
};

export const removeBatchTracePointCmdObj = {
  type: 'Request',
  name: 'RemoveBatchTracePointRequest',
  id: '5b441d4d-2a21-4e5b-9973-2f11e43653e6',
  persist: true,
  tracePointIds: [],
};

export const changeActiveWorkspaceCmdObj = {
  type: 'Request',
  name: 'SwitchWorkspaceRequest',
  id: 'cf58c8ca-b4d3-11ec-b909-0242ac120002',
  workspaceId: '',
};

export const enableCollaborationRequestCmdObj = {
  type: 'Request',
  name: 'EnableCollaborationRequest',
  id: 'cf58c8ca-b4d3-11ec-b909-0242ac120002',
};

export const disableCollaborationRequestCmdObj = {
  type: 'Request',
  name: 'DisableCollaborationRequest',
  id: 'cf58c8ca-b4d3-11ec-b909-0242ac120002',
};

export const putReferenceEventRequestCmdObj = {
  id: 'cf58c8ca-b4d3-11ec-b909-0242ac120002',
  name: 'PutReferenceEventRequest',
  type: 'Request',
  probeId: '',
  probeType: '',
  event: '',
  applicationFilter: {},
};

export const getReferenceEventRequestCmdObj = {
  id: 'cf58c8ca-b4d3-11ec-b909-0242ac120002',
  name: 'GetReferenceEventRequest',
  type: 'Request',
  probeId: '',
  probeType: '',
  applicationFilter: {},
};

export const removeReferenceEventRequestCmdObj = {
  id: 'cf58c8ca-b4d3-11ec-b909-0242ac120002',
  name: 'RemoveReferenceEventRequest',
  type: 'Request',
  probeId: '',
  probeType: '',
  applicationFilter: {},
};

export const updateConfigRequestCmdObj = {
  id: 'cf58c8ca-b4d3-11ec-b909-0242ac120002',
  name: 'UpdateConfigRequest',
  type: 'Request',
  config: {},
  applicationFilters: [],
};

export const attachRequestCmdObj = {
  id: 'cf58c8ca-b4d3-11ec-b909-0242ac120002',
  name: 'AttachRequest',
  type: 'Request',
  applicationFilters: [],
};

export const detachRequestCmdObj = {
  id: 'cf58c8ca-b4d3-11ec-b909-0242ac120002',
  type: 'Request',
  name: 'DetachRequest',
  applicationFilters: [],
};

export const deleteProbeTagRequestCmdObj = {
  name: 'RemoveProbeTagRequest',
  type: 'Request',
  tag: '',
  probeType: ''
};