import {
  GET_SA_TAGS_FULFILLED,
  GET_SA_TAGS_PENDING,
  GET_SA_TAGS_REJECTED,
} from '../constants/sidekick-action-tag-list';

const initialState = {
  saTags: [],

  fetching: false,
  fetched: false,
  error: null,
};

export default function saTagList(state = initialState, action) {
  switch (action.type) {
    case GET_SA_TAGS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_SA_TAGS_FULFILLED:
      let incomingWebhooks = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        saTags: incomingWebhooks,
      };
    case GET_SA_TAGS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
