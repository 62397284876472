import React from 'react';
import { hasArrayElement } from 'utils/array-util';
import { VariableMonacoTree } from './VariableTree/VariableTree'; //TODO VariableTree moved to NPM Package

export const Variables = ({ event }) => {
  let vDOM = 'NO VARIABLES';
  if (event && hasArrayElement(event.frames)) {
    const variableObj = event.frames[0].variables;
    const variableData = { '@type': 'Array', '@value': {} };
    if (typeof variableObj === 'object' && variableObj !== null) {
      for (const key in variableObj) {
        variableData['@value'][key] = variableObj[key];
      }
    }
    vDOM = <VariableMonacoTree data={variableData} id={event.id} />;
  }

  return <div className="variables-container">{vDOM}</div>;
};
