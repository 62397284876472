import React from 'react';
import { Frames } from './TabComponents/Frames';
import { Variables } from './TabComponents/Variables';
import { Metadata } from './TabComponents/Metadata';
import './EventDetails.scss';

export class EventDetails extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { event: nEvent, type: nType } = nextProps;
    const { event: cEvent, type: cType } = this.props;
    if (nEvent.id !== cEvent.id || nType !== cType) {
      return true;
    }
    return false;
  }

  render() {
    const { event, type } = this.props;
    let vDOM = <Variables event={event} />;
    if (type === 'metadata') vDOM = <Metadata event={event} />;
    else if (type === 'frames') vDOM = <Frames event={event} />;

    if (event) {
      return <div className="event-details-panes">{vDOM}</div>;
    } else {
      return <div className="no-selected-event"></div>;
    }
  }
}
