import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import {
  SourceRepoBranchSelectorContainer,
  SourceRepoCommitSelectorContainer,
  SourceRepoSelectorContainer,
} from 'containers';

import {
  clearRepoTracepointsAndEvents,
  computeCommitIdAccordingToSourceProvider,
  computeRepoOwnerNameAccordingToSourceProvider,
  computeSourceRepositoryNameAccordingToSourceProvider,
  isRepoCachedPreviously,
  isSourceProviderConnected,
  MODAL_STATE,
  selectBranchDataAccordingToSourceProvider,
  selectRepoDataAccordingToSourceProvider,
} from 'utils/source-provider-util';
import { hasArrayElement } from 'utils/array-util';
import { isEmpty } from 'utils/object-util';

import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import './AddUpdateRepoModal.scss';
import URL_POOL from 'utils/url-pool';

// PROPS;
// isModalAddOrUpdate={isSourceRepoModalOpen}
// selectedSourceCacheForModal={selectedUpdateSource}
// gitSourceProvider={SOURCE_PROVIDER_PLATFORM.GITLAB}
// isOpen={isSourceRepoModalOpen !== MODAL_STATE.CLOSED}
// onClose={this.handleSourceModalClose}
export class AddUpdateRepoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRepo: null,
      selectedRepoName: '',
      selectedRepoBranch: null,
      selectedRepoBranchName: '',
      selectedRepoCommit: null,
      selectedRepoCommitSha: '',
    };
  }

  initializeSelectedRepoBranchCommitState = () => {
    this.setState({
      selectedRepo: null,
      selectedRepoName: '',
      selectedRepoBranch: null,
      selectedRepoBranchName: '',
      selectedRepoCommit: null,
      selectedRepoCommitSha: '',
    });
  };

  componentDidMount() {
    console.log('AddUpdateRepoModal, CDM; props, state: ', this.props, this.state);
    const { gitSourceProvider } = this.props;
    this.props.getUserRepositories(gitSourceProvider);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('AURM, CDU; props, prevProps, state, prevState: ', this.props, prevProps, this.state, prevState);

    const { isModalAddOrUpdate, gitSourceProvider } = this.props;

    if (isModalAddOrUpdate === MODAL_STATE.UPDATE_BRANCH_COMMIT) {
      const { selectedSourceCacheForModal } = this.props;

      const repoData = selectRepoDataAccordingToSourceProvider(gitSourceProvider, this.props.gitRepoData);
      const prevRepoData = selectRepoDataAccordingToSourceProvider(gitSourceProvider, prevProps.gitRepoData);

      if (!hasArrayElement(prevRepoData) && hasArrayElement(repoData)) {
        // console.log(
        //   'AURM, CDU, UPDATE; selectedDropdownRepoData; props, prevProps, state, prevState: ',
        //   this.props,
        //   prevProps,
        //   this.state,
        //   prevState,
        // );

        const selectedRepoName = selectedSourceCacheForModal.repository;
        const selectedRepoData = repoData.find(
          repo => computeSourceRepositoryNameAccordingToSourceProvider(gitSourceProvider, repo) === selectedRepoName,
        );
        // console.log('AURM, CDU, UPDATE; selectedRepoName, selectedRepoData: ', selectedRepoName, selectedRepoData);

        // We have (~100) recent active repos listed from repoData.
        // If we previously added/cached your repo to the BE and it is not found on repoData,
        // then it may result undefined as selectedRepoData when you try to update it!
        if (selectedRepoData) {
          const selectedDropdownRepoData = {
            key: selectedRepoName,
            value: selectedRepoName,
            text: selectedRepoName,
            repodata: selectedRepoData,
          };

          this.setState({
            selectedRepo: selectedDropdownRepoData,
            selectedRepoName: selectedRepoName,
          });
        }
      }
    }

    const { isFetching: prevIsFetching, isGitlabBranchDataFetching: prevIsGitlabBranchDataFetching } =
      prevProps.gitBranchData;
    const { isFetched, isGitlabBranchDataFetched } = this.props.gitBranchData;
    const { selectedRepoBranchName: prevSelectedRepoBranchName } = prevState;
    const { selectedRepoBranchName } = this.state;

    if (
      (prevIsFetching && isFetched) ||
      (prevIsGitlabBranchDataFetching && isGitlabBranchDataFetched) ||
      (selectedRepoBranchName && selectedRepoBranchName !== prevSelectedRepoBranchName)
    ) {
      // console.log('AURM, CDU; branchData: ', branchData);
      // This is to find commit sha when branches are fetched or selected.
      const sourceProviderBranchData = selectBranchDataAccordingToSourceProvider(
        gitSourceProvider,
        this.props.gitBranchData,
      );
      const selectedRepoBranchData = sourceProviderBranchData.find(branch => branch.name === selectedRepoBranchName);

      let selectedRepoBranch = null;
      let selectedRepoCommitSha = '';
      if (selectedRepoBranchData) {
        selectedRepoBranch = {
          key: selectedRepoBranchData.name,
          value: selectedRepoBranchData.name,
          text: selectedRepoBranchData.name,
          branchdata: selectedRepoBranchData,
        };

        selectedRepoCommitSha = computeCommitIdAccordingToSourceProvider(
          gitSourceProvider,
          selectedRepoBranchData.commit,
        );
      }

      console.log(
        'AURM,CDU; selectedRepoBranchData, gitSourceProvider, selectedRepoCommitSha: ',
        selectedRepoBranchData,
        gitSourceProvider,
        selectedRepoCommitSha,
      );

      this.setState({
        selectedRepoBranch,
        selectedRepoCommitSha,
      });
    }
  }

  renderAddOrUpdateBranchCommitModal = () => {
    const { isOpen, isModalAddOrUpdate } = this.props;
    const { sourceCacheList, sourceListLimit, addSourceCacheFetching, updateSourceCacheFetching } =
      this.props.sourceCache;
    const { isFetching: isRepoFetching } = this.props.gitRepoData;
    const { isFetching: isBranchFetching } = this.props.gitBranchData;
    const { isFetching: isCommitFetching } = this.props.gitCommitData;
    // console.log('SOURCE MODAL');

    if (isModalAddOrUpdate === MODAL_STATE.ADD_REPO && sourceCacheList.length >= sourceListLimit) {
      return (
        <Modal className="add-source-repo-modal" open={isOpen}>
          <Modal.Content>
            <div className="repo-limit-content">
              <div className="add-repo-title">
                <span>
                  <Icon color="yellow" name="warning sign" />
                </span>
                <span>Warning</span>
              </div>
              <div className="text">
                <span>Sorry, you can't add more than 10 repositories. You can </span>
                <a href={URL_POOL.SIDEKICK_DOCS} target={'_blank'} rel="noopener noreferrer">
                  visit our docs
                </a>
                <span> to learn more.</span>
              </div>
            </div>
          </Modal.Content>

          <Modal.Actions>
            <Button primary onClick={this.handleCloseAddUpdateRepoModal}>
              Got it
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }

    return (
      <Modal className="add-update-source-repo-modal" open={isOpen}>
        <Modal.Content>
          <div className="source-select-content">
            {isModalAddOrUpdate === MODAL_STATE.UPDATE_BRANCH_COMMIT ? (
              <div className="add-repo-title">
                <span>Change Branch & Commit</span>
              </div>
            ) : (
              <div className="add-repo-title">
                <span>Add Repository</span>
              </div>
            )}

            {isModalAddOrUpdate === MODAL_STATE.UPDATE_BRANCH_COMMIT && (
              <div className="change-branch-modal-warning">
                <div className="title">
                  <span>
                    <Icon color="yellow" name="warning sign" />
                  </span>
                  <span>Warning</span>
                </div>
                <div className="text">
                  <span>{'Tracepoints and Events will be lost after changing Branch or Commit.'}</span>
                </div>
              </div>
            )}

            {this.renderSourceRepoSelectionStep()}
            {this.renderSourceBranchSelectionStep()}
            {this.renderSourceCommitSelectionStep()}
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={this.handleCloseAddUpdateRepoModal}>
            Cancel
          </Button>
          <Button
            primary
            onClick={this.handleModalAddUpdateRepositoryClick}
            loading={
              isRepoFetching ||
              isBranchFetching ||
              isCommitFetching ||
              addSourceCacheFetching ||
              updateSourceCacheFetching
            }
            disabled={
              isRepoFetching ||
              isBranchFetching ||
              isCommitFetching ||
              addSourceCacheFetching ||
              updateSourceCacheFetching
            }
          >
            {isModalAddOrUpdate === MODAL_STATE.UPDATE_BRANCH_COMMIT ? 'Save Changes' : 'Add Repository'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  handleCloseAddUpdateRepoModal = () => {
    this.props.onClose();
    this.initializeSelectedRepoBranchCommitState();
  };

  handleModalAddUpdateRepositoryClick = () => {
    console.log('AURM, handleModalAddUpdateRepositoryClick; props, state: ', this.props, this.state);
    const { isModalAddOrUpdate, gitSourceProvider } = this.props;
    const { sourceAccountList, addSourceAccount } = this.props.sourceProviderAccount;
    const { sourceCacheList } = this.props.sourceCache;
    const { id: workspaceId } = this.props.activeWorkspace.workspace.workspace;
    const { selectedRepo, selectedRepoName, selectedRepoBranchName, selectedRepoCommitSha } = this.state;
    const selectedRepoOwnerLogin = computeRepoOwnerNameAccordingToSourceProvider(
      gitSourceProvider,
      selectedRepo.repodata,
    );

    let sourceAccountId = '';
    // We have already had a source account connected with gitSourceProvider
    if (hasArrayElement(sourceAccountList)) {
      const sourceAccount = isSourceProviderConnected(sourceAccountList, gitSourceProvider);
      sourceAccountId = sourceAccount.id;
    } else if (!isEmpty(addSourceAccount)) {
      console.log('We have just connected a new source provider; addSourceAccount: ', addSourceAccount);
      // We have just connected a new source provider.
      // Check for adding new source account Github, Gitlab etc.!!
      sourceAccountId = addSourceAccount.id;
    } else {
      toast.error(`Error while adding repository: Source Account not found!`, ToastConfig);
      return;
    }

    // Create source from selected repo, branch and commit
    const source = {
      sourceAccountId,
      platform: gitSourceProvider,
      owner: selectedRepoOwnerLogin,
      repository: selectedRepoName,
      branch: selectedRepoBranchName,
      commit: selectedRepoCommitSha,
      workspaceId,
    };
    // console.log('SOURCE: ', source);

    // Update branch and commit
    if (isModalAddOrUpdate === MODAL_STATE.UPDATE_BRANCH_COMMIT) {
      const { id: mouseOverRepoId } = this.props.selectedSourceCacheForModal;

      // Before update source cache, clear related tracepoints and events!
      const { handleNotifyIDEContextReset } = this.props;
      clearRepoTracepointsAndEvents(sourceCacheList, selectedRepoName, gitSourceProvider, handleNotifyIDEContextReset);

      this.props.updateSourceCache(
        mouseOverRepoId,
        source,
        () => {
          console.log('updateSourceCache succes; props: ', this.props);
          this.props.getSourceCacheList(
            this.props.activeWorkspace.workspace.workspace.id,
            this.onGetSourceCacheListSuccess,
            this.onGetSourceCacheListError,
          );
          this.initializeSelectedRepoBranchCommitState();

          const { selectedGitSourceCache, updateSourceCache } = this.props.sourceCache;
          // Select github source cache if updated source is also selected source at source explorer.
          if (selectedGitSourceCache && selectedGitSourceCache.repository === updateSourceCache.repository) {
            this.props.selectGitSourceCache({ ...updateSourceCache });
          }

          toast.success(
            `We succesfully update your saved repository/branch/commit; ${selectedRepoName}/${selectedRepoBranchName}/${selectedRepoCommitSha}`,
            ToastConfig,
          );

          this.handleCloseAddUpdateRepoModal();
        },
        () => {
          console.log('updateSourceCache fail');
          toast.error(`Your commit/branch updates to repo ${selectedRepoName} is failed!`, ToastConfig);
        },
      );
    }

    // Add new repo, branch, commit
    if (isModalAddOrUpdate === MODAL_STATE.ADD_REPO) {
      // console.log(
      //   'ADD, sourceCacheList, selectedRepoName, gitSourceProvider; ',
      //   sourceCacheList,
      //   selectedRepoName,
      //   gitSourceProvider,
      //   isRepoCachedPreviously(sourceCacheList, selectedRepoName, gitSourceProvider),
      // );

      // Check for duplicate repo name
      if (isRepoCachedPreviously(sourceCacheList, selectedRepoName, gitSourceProvider)) {
        toast.error(`Your repo ${selectedRepoName} is previously added, can not add it again!`, ToastConfig);
        return;
      }

      this.props.addSourceCache(
        source,
        () => {
          console.log('addSourceCache succes');
          this.props.getSourceCacheList(
            this.props.activeWorkspace.workspace.workspace.id,
            this.onGetSourceCacheListSuccess,
            this.onGetSourceCacheListError,
          );
          this.initializeSelectedRepoBranchCommitState();
          toast.success(
            `We succesfully saved your repo/branch/commit; ${selectedRepoName}/${selectedRepoBranchName}/${selectedRepoCommitSha}`,
            ToastConfig,
          );

          this.handleCloseAddUpdateRepoModal();
        },
        () => {
          console.log('addSourceCache fail');
          toast.error(`We could not add repo!`, ToastConfig);
        },
      );
    }
  };

  onGetSourceCacheListSuccess = () => {
    console.log('AURM, onGetSourceCacheListSuccess; props, state: ', this.props, this.state);
  };

  onGetSourceCacheListError = () => {
    console.log('AURM, onGetSourceCacheListError');
  };

  renderSourceRepoSelectionStep = () => {
    // console.log('AURM, renderSourceRepoSelectionStep; props, state: ', this.props, this.state);
    const { selectedRepo } = this.state;
    const { isModalAddOrUpdate, gitSourceProvider } = this.props;
    const isRepoDropdownDisabled = isModalAddOrUpdate === MODAL_STATE.UPDATE_BRANCH_COMMIT;

    return (
      <div className="source-repo-selection">
        <div className="section-title">
          <span>Repository</span>
        </div>
        <SourceRepoSelectorContainer
          gitSourceProvider={gitSourceProvider}
          isRepoDropdownDisabled={isRepoDropdownDisabled}
          selectedRepo={selectedRepo}
          onSelectRepo={this.handleSelectedRepo}
        />
      </div>
    );
  };

  handleSelectedRepo = selectedRepo => {
    const { gitSourceProvider } = this.props;
    console.log('AURM, handleSelectedRepo; selectedRepo: ', selectedRepo);

    const selectedRepoName = computeSourceRepositoryNameAccordingToSourceProvider(
      gitSourceProvider,
      selectedRepo.repodata,
    );

    // When repo is selected, set default branch name!
    // 'default_branch' works for Github and Gitlab both!
    const selectedRepoBranchName = selectedRepo.repodata.default_branch;

    this.setState({
      selectedRepoName,
      selectedRepo,
      selectedRepoBranchName,
      selectedRepoBranch: null,
    });
  };

  renderSourceBranchSelectionStep = () => {
    const { gitSourceProvider } = this.props;
    const { selectedRepo, selectedRepoName, selectedRepoBranch, selectedRepoBranchName } = this.state;
    let selectedRepoOwnerLogin = '';
    if (selectedRepo && selectedRepo.repodata) {
      selectedRepoOwnerLogin = computeRepoOwnerNameAccordingToSourceProvider(gitSourceProvider, selectedRepo.repodata);
    }

    return (
      <div className="source-branch-selection">
        <div className="section-title">
          <span>Branch</span>
        </div>
        <SourceRepoBranchSelectorContainer
          gitSourceProvider={gitSourceProvider}
          selectedRepo={selectedRepo}
          selectedRepoName={selectedRepoName}
          selectedRepoBranch={selectedRepoBranch}
          selectedRepoBranchName={selectedRepoBranchName}
          selectedRepoOwnerLogin={selectedRepoOwnerLogin}
          onSelectRepoBranch={this.handleSelectedRepoBranch}
        />
      </div>
    );
  };

  handleSelectedRepoBranch = selectedRepoBranch => {
    console.log('AURM, handleSelectedRepoBranch; selectedRepoBranch: ', selectedRepoBranch);
    const selectedRepoBranchName = selectedRepoBranch.branchdata.name;
    this.setState({
      selectedRepoBranch,
      selectedRepoBranchName,
    });
  };

  renderSourceCommitSelectionStep = () => {
    const { gitSourceProvider } = this.props;

    const { selectedRepo, selectedRepoName, selectedRepoBranch, selectedRepoBranchName, selectedRepoCommitSha } =
      this.state;

    let selectedRepoOwnerLogin = '';
    if (selectedRepo && selectedRepo.repodata) {
      selectedRepoOwnerLogin = computeRepoOwnerNameAccordingToSourceProvider(gitSourceProvider, selectedRepo.repodata);
    }

    return (
      <div className="source-commit-selection">
        <div className="section-title">
          <span>Commit</span>
        </div>
        <SourceRepoCommitSelectorContainer
          gitSourceProvider={gitSourceProvider}
          selectedRepo={selectedRepo}
          selectedRepoName={selectedRepoName}
          selectedRepoBranch={selectedRepoBranch}
          selectedRepoBranchName={selectedRepoBranchName}
          selectedRepoOwnerLogin={selectedRepoOwnerLogin}
          selectedRepoCommitSha={selectedRepoCommitSha}
          onSelectRepoCommit={this.handleSelectedRepoCommit}
        />
      </div>
    );
  };

  handleSelectedRepoCommit = selectedRepoCommit => {
    console.log('AURM, handleSelectedRepoCommit; selectedRepoCommit: ', selectedRepoCommit);
    const { gitSourceProvider } = this.props;

    // Find commit id according to source provider here!
    const selectedRepoCommitSha = computeCommitIdAccordingToSourceProvider(
      gitSourceProvider,
      selectedRepoCommit.commitdata,
    );

    this.setState({
      selectedRepoCommit,
      selectedRepoCommitSha,
    });
  };

  render() {
    // console.log('AddUpdateRepoModal, render; props, state: ', this.props, this.state);

    return this.renderAddOrUpdateBranchCommitModal();
  }
}
