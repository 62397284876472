import { GridColumn, GridRow } from 'semantic-ui-react';
import React, { Component } from 'react';
import ActivePlan from './ActivePlan';
import ActivePaymentMethod from './ActivePaymentMethod';

class PlanInfoSection extends Component {
  render() {
    return (
      <>
        <GridRow className={'plan-header-row'}>
          <GridColumn>
            <div className={'small-header'}>Plan</div>
            <div className={'small-header-caption'}>
              We offer a generous base plan with 5 simultaneous application instances and 10 users. If you want to
              increase your limits or support us you can change your billing plan below.
            </div>
          </GridColumn>
        </GridRow>
        <GridRow className={'info-row last-section-row'}>
          <GridColumn className={'active-plan-column'} width={'10'}>
            <ActivePlan
              onChangePlanClicked={this.props.onChangePlanClicked}
              activeSubscription={this.props.activeSubscription}
            />
          </GridColumn>
          <GridColumn className={'active-payment-method-column'} width={'5'}>
            <ActivePaymentMethod
              changePaymentMethod={this.props.onChangePaymentMethodClicked}
              billingCustomer={this.props.billingCustomer}
            />
          </GridColumn>
        </GridRow>
      </>
    );
  }
}

export default PlanInfoSection;
