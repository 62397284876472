import React, { Component } from 'react';
import { Checkbox, Icon, Popup, Table } from 'semantic-ui-react';
import { abbrFileName } from 'utils/string-util';
import { clusterApps } from 'utils/application-util';

export function MoreSettings(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="12" viewBox="0 0 4 12">
      <path
        d="M2.00008 9.33333C2.73646 9.33333 3.33341 9.93029 3.33341 10.6667C3.33341 11.403 2.73646 12 2.00008 12C1.2637 12 0.666748 11.403 0.666748 10.6667C0.666748 9.93029 1.2637 9.33333 2.00008 9.33333ZM2.00008 4.66667C2.73646 4.66667 3.33341 5.26362 3.33341 6C3.33341 6.73638 2.73646 7.33333 2.00008 7.33333C1.2637 7.33333 0.666748 6.73638 0.666748 6C0.666748 5.26362 1.2637 4.66667 2.00008 4.66667ZM2.00008 0C2.73646 0 3.33341 0.596954 3.33341 1.33333C3.33341 2.06971 2.73646 2.66667 2.00008 2.66667C1.2637 2.66667 0.666748 2.06971 0.666748 1.33333C0.666748 0.596954 1.2637 0 2.00008 0Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}

export class SidekickActionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionType: this.props.actionType,
      activeSAId: null,
    };
  }

  calculateSuccessfulSidekickActionCount = (appClusters, sidekickAction) => {
    let totalInstances = 0;
    let successfulInstances = 0;
    const resultObj = {};
    for (const [key, apps] of appClusters.entries()) {
      resultObj[apps[0].name] = { totalInstances: apps.length, successfulInstances: 0 };
      totalInstances += apps.length;
      apps.forEach(app => {
        if (app[this.state.actionType + 's'].find(sa => sa.id === sidekickAction.id)) {
          resultObj[apps[0].name].successfulInstances = resultObj[apps[0].name].successfulInstances + 1;
          successfulInstances++;
        }
      });
    }
    return { resultObj, totalInstances, successfulInstances };
  };

  renderSidekickActionRow = sidekickAction => {
    const { appData, events } = this.props;

    const { disabled, fileName, lineNo } = sidekickAction;
    //actionType is either "logPoint" or "tracePoint". We access to probe points by adding "s" suffix to it.

    const applications = appData.filter(app =>
      app[this.state.actionType + 's'].find(tp => tp.id === sidekickAction.id),
    );

    const appClusters = clusterApps(applications);
    const uniqueApps = [];
    //eslint-disable-next-line
    for (const [key, value] of appClusters.entries()) {
      uniqueApps.push(value[0]);
    }
    const successfullyPutted = this.calculateSuccessfulSidekickActionCount(appClusters, sidekickAction);

    let statusIcon = 'tracepoint-status ';
    if (disabled) {
      statusIcon += 'disabled ';
    }

    if (applications.length) {
      statusIcon += 'app-connected ';
    }

    if (this.state.actionType === 'logPoint') {
      statusIcon = statusIcon.replace('tracepoint', 'logpoint');
    }
    const eventCount = events.filter(el => el.fileName === fileName && el.lineNo === lineNo).length;

    //WAITINGS BEFORE BROKER RESPONSE....
    const allSidekickActionsWaiting = this.props.sidekickActionsWaiting;
    const sidekickActionsWaiting = allSidekickActionsWaiting.find(el => {
      // TODO: Change it later
      // eslint-disable-next-line
      return el && el.fileName === fileName && el.lineNo == lineNo;
    });

    const classWaitingEnabled = sidekickActionsWaiting ? 'waiting' : '';
    let iconEnabledDisabledClassName = disabled ? 'icon-apps-and-tps-enable-tp' : 'icon-apps-and-tps-disable-tp';

    if (sidekickActionsWaiting) {
      if (
        sidekickActionsWaiting.name === 'DisableTracePointRequest' ||
        sidekickActionsWaiting.name === 'EnableTracePointRequest'
      )
        iconEnabledDisabledClassName = 'icon-apps-and-tps-waiting-tp';
    }

    const getFirstColumnData = () => {
      if (!this.props.predefined) {
        const { fileName, lineNo } = sidekickAction;

        return (
          <Popup
            key={sidekickAction.id + '-filename-popup'}
            style={{ overflowWrap: 'break-word' }}
            content={sidekickAction.fileName}
            position={'bottom left'}
            trigger={
              <div>
                <span className="filename-lineno">
                  {abbrFileName(fileName)}:{lineNo}
                </span>
              </div>
            }
            wide
          />
        );
      } else {
        return (
          <div>
            <span className="filename-lineno">{sidekickAction.probeName}</span>
            <Popup
              key={sidekickAction.id + 'predefined-info'}
              className={'predefined-info-popup'}
              trigger={
                <sup>
                  <Icon style={{ marginLeft: '2px' }} name={'info circle'} mini />
                </sup>
              }
              content={
                <div className={'predefined-info'}>
                  <div className={'header'}>Source</div>
                  <div className={'info'}>
                    {abbrFileName(fileName)}:{lineNo}
                  </div>
                  <div className={'header'}>App Name</div>
                  {uniqueApps.map((application, idx, array) => (
                    <span className={'info'}>
                      {application.name}
                      {`${idx === array.length - 1 ? '' : ','}`}
                    </span>
                  ))}
                </div>
              }
              position={'bottom left'}
            />
          </div>
        );
      }
    };

    const getOptions = () => {
      return (
        <Popup
          open={this.state.activeSAId === sidekickAction.id}
          className={'popup-menu'}
          onClose={() => this.setState({ activeSAId: null })}
          trigger={<MoreSettings />}
          content={
            <>
              <div
                className={'popup-menu-item'}
                onClick={event => {
                  event.stopPropagation();
                  this.props.handleEdit(true, sidekickAction, this.state.actionType);
                }}
              >
                Edit
              </div>
              {this.props.predefined && (
                <div
                  className={'popup-menu-item'}
                  onClick={event => {
                    event.stopPropagation();
                    this.setState({ activeSAId: null });
                    this.props.handleSeeReferenceEvent(sidekickAction, this.state.actionType);
                  }}
                >
                  Show reference result
                </div>
              )}
              {this.props.predefined && (
                <div
                  className={'popup-menu-item'}
                  onClick={event => {
                    event.stopPropagation();
                    this.setState({ activeSAId: null });
                    this.props.handleRemoveReferenceEvent(sidekickAction, this.state.actionType);
                  }}
                >
                  Remove reference
                </div>
              )}

              <div
                style={{ borderTop: '2px solid rgba(217, 217, 217, 0.16)' }}
                className={'popup-menu-item'}
                onClick={() => this.props.handleDelete(sidekickAction, this.state.actionType)}
              >
                Remove
              </div>
            </>
          }
        />
      );
    };
    return (
      <Table.Row
        className={classWaitingEnabled + `${this.state.activeSAId === sidekickAction.id ? ' active-row' : ''}`}
        key={sidekickAction.id}
      >
        <Table.Cell>
          <Checkbox
            checked={this.props.selectedSA.has(sidekickAction.id)}
            onChange={() =>
              this.props.handleCheck(this.props.actionType === 'logPoint' ? 'LP' : 'TP', sidekickAction.id)
            }
          />
        </Table.Cell>
        <Table.Cell>
          <div className="tp-source-container">
            <span className={statusIcon + ' app-status-indicator'} />
            {getFirstColumnData(sidekickAction)}
          </div>
        </Table.Cell>
        {!this.props.predefined && (
          <Table.Cell>
            <div className={'tp-app-name-container'}>
              {applications.map(application => (
                <Popup
                  key={application.key + '-metadata-popup'}
                  content={
                    <div>
                      Version: {application.version}
                      <br />
                      Stage: {application.stage}
                      <br />
                    </div>
                  }
                  position={'bottom left'}
                  trigger={<span className={'tp-app-name'}>{application.name}</span>}
                />
              ))}
            </div>
          </Table.Cell>
        )}
        <Table.Cell className={'tracepoint-actions'}>
          <span
            className={'ui mini label tracepoint-action-label event-count'}
            onClick={() => this.props.handleFilterEvents(sidekickAction, this.state.actionType)}
          >
            {eventCount}
          </span>
          <Popup
            key={'successfully-putted-metadata-popup'}
            content={
              <div>
                {Object.entries(successfullyPutted.resultObj).map(([appName, results]) => {
                  return (
                    <div>
                      {appName}: {`${results.totalInstances}/${results.successfulInstances}`}
                      <br />
                    </div>
                  );
                })}
              </div>
            }
            position={'bottom left'}
            trigger={
              <span className={'ui mini label tracepoint-action-label'}>
                {`${successfullyPutted.successfulInstances}/${successfullyPutted.totalInstances}`}
              </span>
            }
          />

          <i
            className={'tracepoint-action icon icon-apps-and-tps-source-focus'}
            onClick={() => this.props.handleFocus(sidekickAction)}
          />
          <i
            className={'tracepoint-action icon ' + iconEnabledDisabledClassName}
            onClick={() => this.props.handleEnableDisable(sidekickAction, disabled, this.state.actionType)}
          />
          <i
            className={`tracepoint-action icon more-settings${
              this.state.activeSAId === sidekickAction.id ? ' active' : ''
            }`}
            onClick={() => {
              this.setState({ activeSAId: sidekickAction.id });
            }}
          >
            {getOptions()}
          </i>
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    const { sidekickActions } = this.props;

    if (sidekickActions && sidekickActions.length === 0) {
      return null;
    }

    return (
      <div>
        <Table className="tracepoints-table" inverted padded basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell width={'six'}>
                <span className="source-header">{!this.props.predefined ? 'Source' : 'Saved'}</span>
              </Table.HeaderCell>
              <Table.HeaderCell width={'ten'}>
                {!this.props.predefined && <span className="app-header">App Name</span>}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{sidekickActions.map(sidekickAction => this.renderSidekickActionRow(sidekickAction))}</Table.Body>
        </Table>
      </div>
    );
  }
}
