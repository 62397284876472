import React, { Component } from 'react';
import { Grid, GridColumn, GridRow, Menu } from 'semantic-ui-react';
import { MainLayout } from 'layouts';
import routeList from 'routes';
import './WorkspacePage.scss';
import WorkspaceTab from './tabs/WorkspaceTab';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import { BillingTabContainer, IntegrationsTabContainer, UsersTabContainer } from 'containers';
import { roles } from 'utils/role-utils';
import { CONFIG } from 'utils/config-util';
import OnpremLicencingTab from './tabs/OnpremLicencingTab';

const TABS = {
  WORKSPACE: 'Workspace',
  USERS: 'Users',
  INTEGRATIONS: 'Integrations',
  BILLING: 'Billing',
  LICENCING: 'Licencing',
};

const TAB_PERMISSIONS = {
  WORKSPACE: { roles: [roles.ADMIN, roles.OWNER] },
  USERS: { roles: [roles.ADMIN, roles.OWNER] },
  INTEGRATIONS: { roles: [roles.ADMIN, roles.OWNER] },
  BILLING: { roles: [roles.BILLING_ADMIN, roles.OWNER], exception: workspace => workspace.billingAccount },
  LICENCING: { roles: [roles.ADMIN, roles.OWNER] },
};
class WorkspacePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeTab: this.decideActiveTab(),
      shouldOpenInviteModal: this.props.location?.state?.openInviteUsersModal,
    };
    if (CONFIG?.ON_PREM) {
      delete TABS.BILLING;
    } else {
      delete TABS.LICENCING;
    }
  }

  decideActiveTab = () => {
    let routeData = this.props.location?.state;
    if (routeData) {
      window.history.replaceState(null, '');
      return routeData.activeTab;
    }
    return TABS.INTEGRATIONS;
  };

  renderTab = () => {
    switch (this.state.activeTab) {
      case TABS.WORKSPACE:
        return (
          <WorkspaceTab
            workspace={this.props.activeWorkspace.workspace}
            onSubmit={(workspaceInfo, apmBaseUrl) => {
              let workspaceId = this.props.activeWorkspace.workspace.workspace.id;
              this.props.saveWorkspaceSettings(workspaceId, { apmBaseUrl, workspaceId }, null);
              this.props.updateWorkspace(workspaceId, workspaceInfo, () => {
                toast.success('Update Successful.', ToastConfig);
                this.props.getWorkspace(workspaceId);
              });
            }}
          />
        );
      case TABS.USERS:
        return <UsersTabContainer openInviteUsersModal={this.state.shouldOpenInviteModal} />;
      case TABS.BILLING:
        return <BillingTabContainer />;
      case TABS.INTEGRATIONS:
        return <IntegrationsTabContainer />;
      case TABS.LICENCING:
        return (
          <OnpremLicencingTab
            setOnpremLicenceResult={this.props.setOnpremLicenceResult}
            setOnpremLicence={this.props.setOnpremLicence}
          />
        );
      default:
        return null;
    }
  };

  renderHeader = () => {
    let { workspaceName } = this.props.activeWorkspace?.workspace?.workspace;
    let { role } = this.props.activeWorkspace.workspace;
    return (
      <div className={'workspace-page-header-container'}>
        <Grid container centered className={'workspace-page-header-grid'}>
          <GridRow>
            <GridColumn>
              <div className={'workspace-page-header'}>{workspaceName} - Workspace Settings</div>
              <div className={'header-caption'}>Manage your workspace data, users and billing information </div>
            </GridColumn>
          </GridRow>
          <GridRow className={'menu-row'}>
            <GridColumn>
              <Menu pointing secondary>
                {Object.entries(TABS).map(([key, val]) => {
                  if (
                    TAB_PERMISSIONS[key].roles.indexOf(role) === -1 ||
                    (TAB_PERMISSIONS[key].exception &&
                      TAB_PERMISSIONS[key].exception(this.props.activeWorkspace.workspace)) ||
                    CONFIG?.EXCLUDED_WORKSPACE_TABS?.indexOf(val.toLowerCase()) > -1
                  ) {
                    return null;
                  }
                  return (
                    <Menu.Item
                      key={val}
                      className={val.toLowerCase()}
                      name={val}
                      active={this.state.activeTab === val}
                      onClick={() => this.setState({ activeTab: val, shouldOpenInviteModal: false })}
                    />
                  );
                })}
              </Menu>
            </GridColumn>
          </GridRow>
        </Grid>
      </div>
    );
  };

  render() {
    console.log('WorkspacePage; props: ', this.props);
    return (
      <MainLayout title={routeList.workspace.title}>
        <div className="workspace-page-wrapper">
          {this.renderHeader()}
          <Grid className={'workspace-page-main-grid'} container centered>
            <GridRow>
              <GridColumn>{this.renderTab()}</GridColumn>
            </GridRow>
          </Grid>
        </div>
      </MainLayout>
    );
  }
}

export default WorkspacePage;
