export const NOTIFY_APPS_AND_REPOS_STATE_CHANGE = 'NOTIFY_APPS_AND_REPOS_STATE_CHANGE';
export const NOTIFY_APPS_AND_REPOS_STATE_CHANGE_COMPLETED = 'NOTIFY_APPS_AND_REPOS_STATE_CHANGE_COMPLETED';

export const NOTIFY_COLLAPSE_APPS_AND_REPOS = 'COLLAPSE_APPS_AND_REPOS';
export const NOTIFY_COLLAPSE_APPS_AND_REPOS_COMPLETED = 'COLLAPSE_APPS_AND_REPOS_COMPLETED';

export const COLLAPSED = 'COLLAPSED';
export const APPS_AND_SOURCES = 'APPS_AND_SOURCES';
export const VERSION_CONTROL = 'VERSION_CONTROL';
export const SEARCH = 'SEARCH';
