export const GET_SOURCE_LIST_LIMIT = 'GET_SOURCE_LIST_LIMIT';
export const GET_SOURCE_LIST_LIMIT_PENDING = 'GET_SOURCE_LIST_LIMIT_PENDING';
export const GET_SOURCE_LIST_LIMIT_FULFILLED = 'GET_SOURCE_LIST_LIMIT_FULFILLED';
export const GET_SOURCE_LIST_LIMIT_REJECTED = 'GET_SOURCE_LIST_LIMIT_REJECTED';

export const GET_SOURCE_CACHE_LIST = 'GET_SOURCE_CACHE_LIST';
export const GET_SOURCE_CACHE_LIST_PENDING = 'GET_SOURCE_CACHE_LIST_PENDING';
export const GET_SOURCE_CACHE_LIST_FULFILLED = 'GET_SOURCE_CACHE_LIST_FULFILLED';
export const GET_SOURCE_CACHE_LIST_REJECTED = 'GET_SOURCE_CACHE_LIST_REJECTED';

export const ADD_SOURCE_CACHE = 'ADD_SOURCE_CACHE';
export const ADD_SOURCE_CACHE_PENDING = 'ADD_SOURCE_CACHE_PENDING';
export const ADD_SOURCE_CACHE_FULFILLED = 'ADD_SOURCE_CACHE_FULFILLED';
export const ADD_SOURCE_CACHE_REJECTED = 'ADD_SOURCE_CACHE_REJECTED';

export const ADD_ONBOARDING_SOURCE_CACHE = 'ADD_ONBOARDING_SOURCE_CACHE';
export const REMOVE_ONBOARDING_SOURCE_CACHE = 'REMOVE_ONBOARDING_SOURCE_CACHE';
export const REMOVE_ONBOARDING_SOURCE_CACHE_PENDING = 'REMOVE_ONBOARDING_SOURCE_CACHE_PENDING';
export const REMOVE_ONBOARDING_SOURCE_CACHE_FULFILLED = 'REMOVE_ONBOARDING_SOURCE_CACHE_FULFILLED';
export const REMOVE_ONBOARDING_SOURCE_CACHE_REJECTED = 'REMOVE_ONBOARDING_SOURCE_CACHE_REJECTED';

export const UPDATE_SOURCE_CACHE = 'UPDATE_SOURCE_CACHE';
export const UPDATE_SOURCE_CACHE_PENDING = 'UPDATE_SOURCE_CACHE_PENDING';
export const UPDATE_SOURCE_CACHE_FULFILLED = 'UPDATE_SOURCE_CACHE_FULFILLED';
export const UPDATE_SOURCE_CACHE_REJECTED = 'UPDATE_SOURCE_CACHE_REJECTED';

export const DELETE_SOURCE_CACHE = 'DELETE_SOURCE_CACHE';
export const DELETE_SOURCE_CACHE_PENDING = 'DELETE_SOURCE_CACHE_PENDING';
export const DELETE_SOURCE_CACHE_FULFILLED = 'DELETE_SOURCE_CACHE_FULFILLED';
export const DELETE_SOURCE_CACHE_REJECTED = 'DELETE_SOURCE_CACHE_REJECTED';

export const SELECT_GIT_SOURCE_CACHE = 'SELECT_GIT_SOURCE_CACHE';
export const FLUSH_SELECTED_GIT_SOURCE_CACHE = 'FLUSH_SELECTED_GIT_SOURCE_CACHE';
export const FLUSH_SOURCE_CACHE_LIST = 'FLUSH_SOURCE_CACHE_LIST';
