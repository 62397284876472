import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { LEAVE_WORKSPACE } from '../constants/workspace-leave';

export const leaveWorkspace = workspaceId => {
  return {
    type: LEAVE_WORKSPACE,
    payload: axios.post(CONFIG.apiUrl + `/workspace/${workspaceId}/accounts/leave`, null, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
