import { combineReducers } from 'redux';
import broker from './broker';
import sourceTabs from './source-tabs';
import sourceFocus from './source-focus';
import sidekickActionEdit from './sidekick-action-edit';
import userAccount from './user-account';
import changePassword from './password';
import changeUserInformation from './user-info';
import tracemap from './tracemap';
import appsAndTracePoints from './apps-and-tracepoints';
import ideToggleBorderTabs from './ide-toggle-border-tabs';

import gitApps from './git-apps';
import gitRepositories from './git-repositories';
import gitBranches from './git-branches';
import gitCommits from './git-commits';
import gitTree from './git-tree';
import gitRepoContent from './git-repo-content';
import sourceProviderAccount from './source-provider-account';
import sourceCache from './source-cache';
import ideContext from './ide-context';
import workspaceOnboardingStatus from './workspace-onboarding';

import createWorkspace from './workspace-create';
import workspaceList from './workspace-list';
import activeWorkspace from './workspace-active';
import updateWorkspace from './workspace-update';
import inviteToWorkspace from './workspace-invite';
import kickWorkspaceAccount from './workspace-kick-account';
import setWorkspaceBillingAccount from './workspace-set-billing-account';
import updateWorkspaceAccount from './workspace-update-account';
import leaveWorkspace from './workspace-leave';

import billingCustomer from './billing-customer';
import upsertCard from './billing-upsert-card';
import activeSubscription from './billing-active-subscription';
import cancelSubscription from './billing-cancel-subscription';
import saveSubscription from './billing-save-subscription';
import billingPlans from './billing-plans';
import billingInvoices from './billing-invoices';

import workspaceBillingAccount from './workspace-get-billing-account';
import inviteBillingAdminToWorkspace from './workspace-invite-billing-admin';
import workspaceCompleteInvitation from './workspace-complete-invitation';
import sidekickActionEventsFocus from './sidekick-actions-events-focus';
import onboardingV2 from './onboarding-v2';

import createWebhook from './webhook-create';
import webhookList from './webhook-list';
import deleteWebhook from './webhook-delete';
import setDefaultProbeWebhook from './workspace-set-default-probe-webhook';
import sendToWebhook from './webhook-send';

import { LOGOUT } from '../constants/logout';
import updateWebhook from './webhook-update';
import onpremIdeToken from './onprem-ide-token';
import { CONFIG } from 'utils/config-util';
import ideAppsAndRepositoriesManagement from './ide-apps-and-repositories-management';
import userUnsubscribeMail from './user-unsubscribe-mail';
import deleteWorkspace from './workspace-delete';
import setOnpremLicence from './onprem-licencing';

import saTagList from './sidekick-action-tag-list';
import issueManagementToken from './issue_management_token';
import issueManagementJiraActions from './issue-management-jira-actions';
import eventHistory from './event-history';

let reducers = {
  broker,
  sourceTabs,
  sourceFocus,
  sidekickActionEdit,
  sidekickActionEventsFocus,

  saTagList,

  userAccount,
  changePassword,
  changeUserInformation,
  userUnsubscribeMail,
  tracemap,
  appsAndTracePoints,
  ideToggleBorderTabs,

  createWorkspace,
  deleteWorkspace,
  updateWorkspace,
  activeWorkspace,
  workspaceList,
  inviteToWorkspace,
  inviteBillingAdminToWorkspace,
  kickWorkspaceAccount,
  workspaceBillingAccount,
  setWorkspaceBillingAccount,
  updateWorkspaceAccount,
  leaveWorkspace,
  workspaceCompleteInvitation,
  workspaceOnboardingStatus,

  eventHistory,

  createWebhook,
  webhookList,
  deleteWebhook,
  setDefaultProbeWebhook,
  updateWebhook,
  sendToWebhook,

  billingCustomer,
  upsertCard,
  activeSubscription,
  cancelSubscription,
  saveSubscription,
  billingPlans,
  billingInvoices,

  gitApps,
  gitRepositories,
  gitBranches,
  gitCommits,
  gitTree,
  gitRepoContent,
  sourceProviderAccount,
  sourceCache,

  ideContext,
  ideAppsAndRepositoriesManagement,

  onboardingV2,

  issueManagementToken,
  issueManagementJira: issueManagementJiraActions,
};

if (CONFIG?.ON_PREM) {
  reducers.onPremIdeToken = onpremIdeToken;
  reducers.onpremLicence = setOnpremLicence;
}

const thundraWebDebuggerApp = combineReducers(reducers);

const thundraWebDebuggerAppRoot = (state, action) => {
  if (action.type === LOGOUT) {
    localStorage.removeItem('persist:root'); // this is to clear redux-persist storage
    return thundraWebDebuggerApp(undefined, action);
  }
  return thundraWebDebuggerApp(state, action);
};

export default thundraWebDebuggerAppRoot;
