import { SA_EDIT_NOTIFY, SA_EDIT_TAB_OPEN_IF_CLOSED } from '../constants/sidekick-action-edit';

const initialState = {
  notifyStatus: '',
  sidekickAction: null,
};

export default function sidekickActionEdit(state = initialState, action) {
  switch (action.type) {
    case SA_EDIT_NOTIFY:
    case SA_EDIT_TAB_OPEN_IF_CLOSED:
      return {
        ...state,
        notifyStatus: action.type,
        sidekickAction: action.payload.sidekickAction,
      };
    default:
      return state;
  }
}
